import { useQuery } from '@tanstack/react-query';

import type { Stake } from 'entities/Stake';
import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

type Response = {
  stakes: Stake[];
  userStakesSum: number;
};

export const useGetAllUserStakesSummaryQuery = (options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>('/dai/total-stakes-summary-by-user', { signal });

      return data;
    },
    queryKey: daiKeys.getAllUserStakesSummary(),
    ...options,
  });
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';
3;
import type { CreateCommentPostRequest, Post } from './types';

export const useCreateCommentPostMutation = (
  options: UseMutationOptions<unknown, Error, CreateCommentPostRequest> = {},
) => {
  return useMutation({
    mutationFn: async (params: CreateCommentPostRequest) => {
      const { data } = await axiosInstance.post<Post>(`/posts/create-comment`, params);

      return data;
    },
    ...options,
  });
};

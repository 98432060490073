import { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import type { TParticipants } from 'entities/DAI/types';
import type { DAITokenType } from 'shared/api/dai/types';
import type { ClassName } from 'shared/types';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { Icon } from 'shared/ui/Icon';

import { Image } from '../Image';

type Props = {
  coinImageSrc?: string;
  coinName?: string;
  description: string;
  mediaSrc?: string;
  onClick?: () => void;
  participants?: TParticipants[];
  priceChangePrc?: number;
  tags?: string[];
  title: string;
  tokenType?: DAITokenType;
  tvl: string;
  userImageSrc?: string;
  userName: string;
} & ClassName;

export const DaiRow = ({
  className,
  coinImageSrc,
  coinName,
  mediaSrc,
  onClick,
  participants = [],
  priceChangePrc,
  title,
  tokenType,
  tvl,
  userImageSrc,
  userName,
}: Props) => {
  const sortedParticipants = useMemo(() => {
    return [...participants].sort((a, b) => (a.avatar && !b.avatar ? -1 : b.avatar && !a.avatar ? 1 : 0));
  }, [participants]);
  return (
    <div className={twMerge('col-span-5 grid grid-cols-subgrid gap-2', className)} onClick={onClick}>
      <div className="flex items-center gap-3">
        {mediaSrc?.endsWith('.mp4') ? (
          <video
            autoPlay
            className="size-10 min-w-10 rounded-lg object-cover"
            loop
            muted
            playsInline
            src={mediaSrc}
          />
        ) : (
          <Image className="size-10 min-w-10 overflow-hidden rounded-lg object-cover" src={mediaSrc || ''} />
        )}

        <div className="line-clamp-2 text-base/5 text-clay-900">{title}</div>
      </div>

      {tokenType !== 'no-token' ? (
        <div className="flex items-center gap-2">
          <div
            className={twMerge(
              'flex size-6 items-center justify-center overflow-hidden rounded-full bg-clay-900 p-1',
              coinImageSrc && 'p-0',
            )}
          >
            {coinImageSrc ? (
              <Image className="size-full object-cover" src={coinImageSrc} />
            ) : (
              <Icon className="size-full text-tusk-200" name="logo" />
            )}
          </div>

          <div className="text-sm font-semibold uppercase">${coinName || '--'}</div>
        </div>
      ) : (
        <div className="my-auto text-xs text-clay-100">No token available</div>
      )}

      <div className="flex items-center gap-2">
        {!!userImageSrc && !!userName && (
          <>
            <div
              className="size-5 rounded-full bg-clay-20 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${userImageSrc})` }}
            ></div>

            <div className="text-sm font-light">{userName}</div>
          </>
        )}
      </div>

      <div className="flex items-center">
        {sortedParticipants.map((p, idx) => {
          return (
            <div
              className={twMerge(
                'relative flex size-10 items-center justify-center rounded-full border-2 border-white bg-tusk-100 bg-cover bg-center bg-no-repeat font-klapt text-lg/none font-bold uppercase',

                // idx <= participants.length - 1 && ' -translate-x-1/2',
                idx === 0 && ' translate-x-0',
              )}
              key={idx}
              style={{
                backgroundImage: `url(${p.avatar})`,
                transform: `translateX(-${50 * idx}%)`,
                // left: `-${50 * idx}%`,
                zIndex: participants.length - idx,
              }}
            >
              <span className="relative -top-px text-lg/none">{!p.avatar ? p.previewLetter || 'A' : ''}</span>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col gap-0.5">
        <div className="flex items-center gap-3">
          <div className="text-lg font-medium">${getTvl({ tvlNes: tvl })}</div>
          {priceChangePrc && (
            <div className="flex items-center gap-1.5">
              <div
                className={twMerge(
                  'flex size-4 items-center justify-center rounded-full bg-green-600/20 text-green-600',
                  priceChangePrc < 0 && 'bg-red-900/20 text-red-900',
                )}
              >
                <Icon className={twMerge('size-3', priceChangePrc > 0 && 'rotate-180')} name="arrowDown" />
              </div>

              <div
                className={twMerge(
                  'text-sm/none font-light text-green-600',
                  priceChangePrc < 0 && ' text-red-900',
                )}
              >
                {priceChangePrc > 0 ? '+' : ''}
                {priceChangePrc}%
              </div>
            </div>
          )}
        </div>
        <div className="text-sm/none font-light text-clay-500">Total value locked</div>
      </div>
    </div>
  );
};

import type { Descendant } from 'slate';

import { useMemo, useState } from 'react';

import { DateTime } from 'luxon';

import type { HuggingfacePostComment } from 'shared/api/huggingface';
import type { PostComment } from 'shared/api/posts';

import { useUser } from 'app/stores/user';
import { timeAgo } from 'shared/helpers/timeAgo';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';
import { PreviewSlate } from 'shared/ui/TextEditor';

import { CreationComment } from './CreationComment';
import { useDeleteComment } from './DeleteComment';
import { EditComment } from './EditComment';
import { NesaAvatar } from './NesaAvatar';

type Props = {
  comment: {
    children: Props['comment'][];
  } & (HuggingfacePostComment | PostComment);
  level: number;
  postId: string;
  type: 'huggingface' | 'nesa';
};

export const NesaComment = ({ comment, level, postId, type }: Props) => {
  const [isEditComment, setIsEditComment] = useState(false);
  const [showReply, setShowReply] = useState(false);

  const deleteComment = useDeleteComment(postId);

  const { user } = useUser();
  const content = useMemo(() => {
    console.log(comment.content);
    return JSON.parse(comment.content) as Descendant[];
  }, [comment]);

  return (
    <div className="flex flex-col gap-0.5">
      <div className="relative flex items-center gap-3.5">
        <NesaAvatar className="size-7 min-w-7" user={comment.author} />
        <div className="flex flex-1 items-center gap-2.5">
          <span className="text-base font-semibold text-clay-900">
            {comment.author.first_name} {comment.author.last_name}
          </span>
          <div className="text-sm font-medium text-clay-300">
            {timeAgo(DateTime.fromMillis(comment.publishedAt))}
          </div>
        </div>
      </div>
      <div className="flex gap-3.5">
        <div className="flex w-8 justify-center">
          {!!comment.children.length && <div className="w-px bg-clay-20"></div>}
        </div>
        <div className="flex flex-1 flex-col gap-2">
          {isEditComment ? (
            <EditComment
              commentId={comment._id}
              initialValue={content}
              onComplete={() => setTimeout(() => setIsEditComment(false))}
              postId={postId}
              type={type}
            />
          ) : (
            <PreviewSlate value={content} />
          )}
          <div className="flex items-center gap-5">
            {level < 2 && (
              <Button
                className="gap-1.5"
                color="secondary"
                onClick={() => setShowReply(!showReply)}
                size="extra-small"
              >
                Reply
                <Icon className="size-3.5" name="reply" />
              </Button>
            )}
            {user?._id === comment.author._id && (
              <Select
                onValueChange={(value) => {
                  if (value === 'edit') {
                    setIsEditComment(true);
                  }
                  if (value === 'delete') {
                    deleteComment(comment._id, type);
                  }
                }}
                trigger={
                  <button className="flex size-6 items-center justify-center rounded bg-transparent outline-none duration-200 hover:bg-clay-20">
                    <Icon className="size-5 rotate-90" name="more" safeArea="0" />
                  </button>
                }
                value={'undefined'}
              >
                <Select.Content className="min-w-32">
                  <Select.Item
                    className="justify-normal"
                    startSlot={<Icon className="mr-2.5 size-4 text-clay-500" name="penLineStroke" />}
                    value="edit"
                  >
                    Edit
                  </Select.Item>
                  <Select.Item
                    className="justify-normal"
                    startSlot={<Icon className="mr-2.5 size-4 text-clay-500" name="trash" />}
                    value="delete"
                  >
                    Delete
                  </Select.Item>
                </Select.Content>
              </Select>
            )}
          </div>
          {showReply && (
            <CreationComment
              onComplete={() => setShowReply(false)}
              parentCommentId={comment._id}
              postId={postId}
              type={type}
            />
          )}

          {!!comment.children.length && (
            <div className="mt-4 flex flex-col gap-6">
              {comment.children.map((comment) => (
                <NesaComment
                  comment={comment}
                  key={comment._id}
                  level={level + 1}
                  postId={postId}
                  type={type}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

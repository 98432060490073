import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { saveAs } from 'file-saver';
import { Download } from 'lucide-react';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import nesaLogo from 'app/assets/images/logo-name-white.svg';
import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { useGetFileByCidListQuery } from 'shared/api/ipfs/useGetFileByCidListQuery';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';
import { useGetQueryHistoryByIdQuery } from 'shared/api/queryHistory/useGetQueryHistoryByIdQuery';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import bgSrc from './assets/bg.png';
import { LockLine } from './ui/LockLine';
import { Row } from './ui/Row';

export const Page = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { proVersion, user } = useUser();

  const { data, isPending } = useGetQueryHistoryByIdQuery({ id: params.id || '' }, { enabled: !!params.id });

  const { data: model, isPending: isModelPending } = useGetModelByIdQuery(data?.modelId || '', {
    enabled: !!data?.modelId,
  });

  const { data: proofFiles, isPending: isProofFileDownloading } = useGetFileByCidListQuery(
    [data?.proofCid || ''],
    {
      enabled: !!data?.proofCid,
    },
  );

  const proofFile = proofFiles?.[0];

  if (!params.id || (data && !isPending && data.userId !== user?._id)) {
    return <Navigate to="/" />;
  }

  const sum = params.id
    .split('')
    .reduce((acc, char) => {
      if (Number.isNaN(Number(char))) return acc;

      acc += Number(char);

      return acc;
    }, 0)
    .toString();

  const isFHE = model?.type === 'text-generation' || model?.type.includes('bert');

  return (
    <div className="absolute inset-0 z-[150] flex size-full flex-col overflow-scroll bg-[#080D16] p-9">
      <BackButton
        className="mb-3 text-clay-400 hover:text-clay-350"
        iconClassName="bg-clay-900 group-hover:bg-clay-800"
        onClick={() => navigate(-1)}
      >
        Back to Playground
      </BackButton>

      <div
        className="pointer-events-none absolute inset-x-0 top-0 size-full bg-cover bg-top bg-no-repeat"
        style={{ backgroundImage: `url(${bgSrc})` }}
      ></div>

      {isPending || isModelPending ? (
        <div className="flex items-center justify-center py-16">
          <Spinner className="size-8" theme="dark" />
        </div>
      ) : (
        <div className="relative mx-auto flex max-w-[960px] flex-col justify-center">
          <div className="mx-auto mb-8 mt-4 flex items-center text-tusk-200 md:mb-16 md:mt-0">
            <img className="mr-4 w-32 border-r border-clay-800/60 pr-4 md:w-40" src={nesaLogo} /> Security
          </div>

          <div className="mx-auto flex items-center gap-4 md:gap-10">
            <div className="text-base font-semibold text-clay-400 md:text-lg">Technology</div>

            <div className="h-14 w-px bg-clay-400"></div>
            <h1 className=" text-left text-xl/none font-medium text-white md:text-3xl/none">
              {isFHE ? (
                <>
                  Equivariant <br /> Encryption (EE){' '}
                </>
              ) : (
                <>DP + TEE</>
              )}
            </h1>

            <div className="flex size-7 items-center justify-center rounded-full bg-tusk-200 md:size-9">
              <Icon className="size-3 text-clay-900 md:size-4" name="check" />
            </div>
            <div className="h-14 w-px bg-clay-400"></div>

            {data?.proofCid && (
              <div className="flex flex-col items-center justify-center">
                <div className="text-base text-white">Download Proof</div>
                <div
                  className={twMerge(
                    'relative flex cursor-pointer items-center gap-1 text-clay-400 transition hover:brightness-125',
                    isProofFileDownloading && 'pointer-events-none opacity-50',
                  )}
                  onClick={() => {
                    if (!proofFile?.data) {
                      toaster.error('No file available. Please try again later.');

                      return;
                    }
                    const blob = new Blob([JSON.stringify(proofFile?.data)], {
                      type: 'application/json;charset=utf-8',
                    });
                    saveAs(blob, 'proof.json');
                  }}
                >
                  <Download className="size-4 text-clay-400" /> .json{' '}
                  {isProofFileDownloading && <Spinner className="absolute -right-6 size-3.5" theme="dark" />}
                </div>
              </div>
            )}
          </div>

          <div className="my-10 w-full border-b border-dashed border-clay-800 2xl:my-12"></div>

          <div className="mx-auto flex flex-col items-center md:flex-row">
            <Icon className="mb-10 size-11 text-tusk-200 md:mb-0 md:mr-12" name="logo" />

            <div className="flex flex-col items-center gap-2 rounded-2xl border border-clay-900 bg-[#0D131C] p-7">
              <Icon className="size-7 rotate-180 text-tusk-200" name="arrowLeftLong" />

              <span className="text-center text-base font-light text-white">
                Request Privately <br /> Inputted
              </span>
            </div>

            <LockLine
              className="h-24 -rotate-90 md:h-auto md:rotate-0"
              iconClassName="rotate-90 md:rotate-0"
            />
            <div className="flex flex-col items-center gap-2 rounded-2xl border border-clay-900 bg-[#0D131C] p-7">
              <Icon className="size-7 stroke-tusk-200" name="bolt" />

              <span className="text-center text-base font-light text-white">
                Request Privately <br /> Executed
              </span>
            </div>

            <LockLine
              className="h-24 -rotate-90 md:h-auto md:rotate-0"
              iconClassName="rotate-90 md:rotate-0"
            />

            <div className="flex flex-col items-center gap-2 rounded-2xl border border-clay-900 bg-[#0D131C] p-7">
              <Icon className="size-6 text-tusk-200" name="check" />

              <span className="text-center text-base font-light text-white">
                Request Privately <br /> Transmitted
              </span>
            </div>
            <Icon className="size-11 text-tusk-200 md:mb-0 md:ml-12" name="logo" />
          </div>

          <div className="my-10 w-full border-b border-dashed border-clay-800 2xl:my-12"></div>

          <div className="grid grid-cols-1 gap-x-2 gap-y-6 md:grid-cols-2 lg:gap-x-3 2xl:gap-y-10">
            <Row
              content={
                data?.date &&
                DateTime.fromISO(data.date).toUTC().toLocaleString({ dateStyle: 'short', timeStyle: 'full' })
              }
              title="Timestamp"
            />
            <Row content={proVersion?.isPro ? 'Nesa Pro User' : 'Nesa Basic User'} title="Account" />
            <Row content={<>{data?.executionTime ?? '--'}s</>} title="Latency" />
            <Row content={data?.sessionId || '--'} title="Session Id" truncateContent />
            <Row content="Nesa Miner" title="Miner" />
            <Row content={data?.transactionHash || '--'} title="Tx Hash" truncateContent />
          </div>
        </div>
      )}

      <div className="mt-auto flex w-full items-center justify-center pt-10 text-center font-light text-clay-600">
        {'NE9874958237958934F33443'.slice(0, 24 - sum.length) + sum}
      </div>
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from './types';

import { daiKeys } from './queryKeys';

type Response = {
  data: DAI;
};

export const useDAIBySlugQuery = (slug: string, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/by-slug/${slug}`);

      return data;
    },
    queryKey: daiKeys.bySlug(slug),
    ...options,
  });
};

// curl -X GET \

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { UseQueryOptions } from 'shared/types';

import { queryKeys } from './queryKeys';

export type SplitsParams = {
  dataset: string;
};

export type DatasetSplit = { config: string; dataset: string; split: string };
type Response = {
  splits: DatasetSplit[];
};

export const useGetDatasetSplitsQuery = (params: SplitsParams, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<Response>(`https://datasets.nesa.ai/splits`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        params,
        paramsSerializer: {
          indexes: null, // no brackets at all
        },
        signal,
      });

      return data;
    },
    queryKey: queryKeys.splits(params),
    ...options,
  });
};

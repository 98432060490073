import type { HTMLAttributeAnchorTarget } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { useUser } from 'app/stores/user';
import { ExternalLink } from 'app/ui/ExternalLink';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Tooltip } from 'shared/ui/Tooltip';

import { GalleryLogo } from '../GalleryLogo';
import { UserLink } from '../UserLink';
import { MyModel } from './ui/MyModel';
import { Search } from './ui/Search';
import { Wallet } from './ui/Wallet';

type Props = {
  onOpenSidebar: () => void;
};

const LINKS: {
  icon: IconName;
  iconClassName?: string;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  to: string;
}[] = [
  { icon: 'podium', iconClassName: 'size-5', label: 'Leaderboard', to: '/leader-board' },
  { icon: 'layerDouble', label: 'Ecosystem', to: '/ecosystem' },
  // { icon: 'book', label: 'Docs', target: '_blank', to: 'https://docs.nesa.ai/nesa' },
  { icon: 'link', label: 'Faucet', to: '/privilege/faucet' },
  { icon: 'compassCircle', label: 'Explorer', target: '_blank', to: 'https://explorer.nesa.ai/' },
];

const BASE_FONTSIZE = 14;

export const GalleryHeader = ({ onOpenSidebar }: Props) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const sm = useMinWidthMediaQuery('sm');
  const lg = useMinWidthMediaQuery('lg');
  const { user } = useUser();
  const { data: account, isConnected } = useAccount();

  const expandedWidth = window.innerWidth - 2 * BASE_FONTSIZE;

  return (
    <>
      <header
        className={twMerge(
          'z-20 flex min-h-max flex-row justify-between gap-2 px-4 pt-8 sm:flex-wrap sm:pt-4 lg:flex-row',
          isSearchExpanded && 'gap-0',
        )}
      >
        {!sm ? (
          <>
            <AnimatePresence>
              {isSearchExpanded && (
                <motion.div
                  animate={{ opacity: 1 }}
                  className="absolute inset-0 z-30 min-h-dvh w-dvw bg-black/40"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  onClick={() => setIsSearchExpanded(false)}
                ></motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                animate={{ width: isSearchExpanded ? '0' : 'auto' }}
                className="flex h-9 items-center gap-4 overflow-hidden"
                exit={{ width: 'auto' }}
                initial={{ width: 'auto' }}
              >
                <div
                  className="size-9 cursor-pointer rounded-lg bg-clay-20 p-2 transition-colors hover:bg-clay-40"
                  onClick={onOpenSidebar}
                >
                  <Icon className="size-3.5 text-clay-350" name="text" />
                </div>
                <GalleryLogo className="ml-1" isBlack />
              </motion.div>
            </AnimatePresence>

            <div className={twMerge('flex h-9 items-center gap-2', isSearchExpanded && 'gap-0')}>
              <AnimatePresence>
                <motion.div
                  animate={{ width: isSearchExpanded ? '0' : 'auto' }}
                  className="h-9"
                  exit={{ width: 'auto' }}
                  initial={{ width: 'auto' }}
                >
                  <Button as={Link} className="ml-3 h-9 gap-2" color="secondary" to="/posts">
                    <div className="relative size-3.5">
                      <Icon className="size-3.5" name="messageSquare" safeArea="0" />
                      <div className="absolute -top-1 right-[-2px] size-2 rounded-full border-2 border-clay-20 bg-red-900" />
                    </div>
                    Posts
                  </Button>
                </motion.div>

                <motion.div
                  animate={{ width: isSearchExpanded ? '0' : 'auto' }}
                  className="h-9"
                  exit={{ width: 'auto' }}
                  initial={{ width: 'auto' }}
                >
                  <Wallet address={account?.bech32Address} isConnected={isConnected} />
                </motion.div>

                <motion.div
                  animate={{
                    width: isSearchExpanded ? `${expandedWidth}px` : '2.25rem',
                  }}
                  className="z-30 h-9 cursor-pointer"
                  exit={{ width: '2.25rem' }}
                  initial={{ width: '2.25rem' }}
                  onClick={() => setIsSearchExpanded(true)}
                >
                  <Search
                    className={twMerge(
                      'size-full',
                      !isSearchExpanded && 'pointer-events-none overflow-hidden',
                    )}
                    classNameInputWrapper="h-9 border-0 rounded-lg bg-clay-20 p-2 hover:bg-clay-20"
                    inheritPopoverWidth
                  />
                </motion.div>

                {!!user && (
                  <motion.div
                    animate={{ width: isSearchExpanded ? '0' : 'auto' }}
                    className="h-9 overflow-hidden"
                    exit={{ width: 'auto' }}
                    initial={{ width: 'auto' }}
                  >
                    <UserLink />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </>
        ) : (
          <>
            <MyModel />

            {lg && <Search className="w-full -translate-y-0.5 lg:w-1/3" inheritPopoverWidth />}

            <div className="relative flex h-10 items-center gap-2">
              {LINKS.map(({ icon, iconClassName, label, target, to }) => {
                return (
                  <Tooltip content={label} key={to} side="bottom" type="light">
                    <div className="h-full">
                      <ExternalLink
                        className="flex size-9 items-center justify-center gap-2 rounded-lg font-medium text-clay-800 transition-colors hover:bg-clay-20 hover:text-clay-800 "
                        key={to}
                        target={target}
                        to={to}
                      >
                        <Icon className={twMerge('size-4 text-clay-400', iconClassName)} name={icon} />
                      </ExternalLink>
                    </div>
                  </Tooltip>
                );
              })}

              <Wallet address={account?.bech32Address} isConnected={isConnected} />
            </div>

            {!lg && <Search className="w-full lg:w-1/3" inheritPopoverWidth />}
          </>
        )}
      </header>
    </>
  );
};

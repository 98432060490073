import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAccount } from 'graz';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';

import { WalletProviderModal } from 'features/WalletProviderModal';
import { StakingModal } from 'pages/DAIOverview/ui/StakingModal/StakingModal';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

type Props = {
  className?: string;
  dai: DAI;
};
export const StakeButton = ({ className, dai }: Props) => {
  const { isConnected } = useAccount();

  const [searchParams, setSearchParams] = useSearchParams();

  const isStakingOpen = searchParams.has('stake-modal');

  const setIsStakingOpen = useCallback(
    (open: boolean) => {
      setSearchParams((prev) => {
        const search = new URLSearchParams(prev);
        if (open) {
          search.set('stake-modal', '1');
        } else {
          search.delete('stake-modal');
        }
        return search;
      });
    },
    [setSearchParams],
  );

  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);

  return (
    <>
      <Button
        className={twMerge(
          'flex h-auto w-full items-center justify-start gap-4 px-3 py-2 md:px-6 md:py-4',
          className,
        )}
        onClick={() => {
          if (!isConnected) {
            setIsWalletProviderOpen(true);
            return;
          }
          setIsStakingOpen(true);
        }}
      >
        <Icon className="size-8 min-w-8 text-white" name="logo" />

        <div className="flex flex-col items-start overflow-hidden text-start font-light">
          <div className="w-full flex-1 truncate text-base text-white md:text-xl">
            Stake $NES{' '}
            {dai.tokenType === 'no-token'
              ? ' for $NES Rewards'
              : dai.token?.ticker
                ? ` for $${dai.token.ticker.toUpperCase()}`
                : ''}
          </div>
          <div className="text-sm text-white/50 md:text-base">
            Earn $NES{' '}
            {dai.tokenType !== 'no-token' && dai.token?.ticker
              ? ` and $${dai.token.ticker.toUpperCase()}`
              : ''}
          </div>
        </div>
      </Button>
      <WalletProviderModal onOpenChange={setIsWalletProviderOpen} open={isWalletProviderOpen} />
      <StakingModal dai={dai} isOpen={isStakingOpen} onOpenChange={setIsStakingOpen} />
    </>
  );
};

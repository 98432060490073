import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

export interface ContentRect {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
}

export const useElementSize = (ref: RefObject<HTMLElement | null>, ...deps: unknown[]): ContentRect => {
  const frame = useRef(0);
  const [rect, set] = useState({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
  });

  const [observer] = useState(
    () =>
      new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry) {
          cancelAnimationFrame(frame.current);

          frame.current = requestAnimationFrame(() => {
            if (ref.current) {
              set(entry.contentRect);
            }
          });
        }
      }),
  );

  useEffect(() => {
    observer.disconnect();

    if (ref.current) {
      observer.observe(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps]);

  return rect;
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type Params = {
  reactionId: string;
};

export const useDeleteDaiCommentReactionMutation = (
  options: UseMutationOptions<null, Error, Params> = {},
) => {
  return useMutation({
    mutationFn: async ({ reactionId }: Params) => {
      const { data } = await axiosInstance.delete<null>(`/dai-comments/reactions/${reactionId}`);

      return data;
    },

    ...options,
  });
};

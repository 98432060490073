import { swapRouter02Abi } from './abi/swapRouter02Abi';

export const swapContract = {
  111888: {
    abi: swapRouter02Abi,
    address: '0xC716CEA6905E0d899453d63aA54892Ef71537074',
  } as const,
};

export const quoteContract = {
  111888: {
    address: '0x91344493CC86F80e92ECc18D87448Dc5f1169e92',
  } as const,
};

import { useMemo } from 'react';

import { DateTime } from 'luxon';

import type { HuggingfacePost, HuggingfacePostComment } from 'shared/api/huggingface';

import huggingFaceSrc from 'app/assets/images/hugging-face-logo.svg';
import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import huggingFaceIconSrc from 'shared/ui/Icon/svg/huggingface.svg';
import { Modal } from 'shared/ui/Modal';

import { Attachments } from './Attachments';
import { CreateReaction, useCreateReaction, useDeleteReaction } from './CreateReaction';
import { CreationComment } from './CreationComment';
import { HuggingFaceContent } from './HuggingFaceContent';
import { NesaComment } from './NesaComment';
import { Reaction } from './Reaction';

type Props = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  post: HuggingfacePost;
};

export const DetailHuggingfacePostModal = ({ isOpen, onOpenChange, post }: Props) => {
  const { user } = useUser();

  const createReaction = useCreateReaction(post.slug, 'huggingface');
  const deleteReaction = useDeleteReaction(post.slug, 'huggingface');

  const comments = useMemo(() => {
    type TreeComment = { children: TreeComment[] } & HuggingfacePostComment;
    const treeComments: Record<string, TreeComment> = {};
    post.comments.forEach((comment) => {
      treeComments[comment._id] = { ...comment, children: [] };
    });
    post.comments.forEach((comment) => {
      if (comment.parentId && treeComments[comment.parentId]) {
        treeComments[comment.parentId].children.push(treeComments[comment._id]);
      }
    });
    return Object.values(treeComments).filter((el) => !el.parentId);
  }, [post]);

  const reactions = useMemo(() => {
    const res = post.reactions.reduce<
      Record<string, { authors: Set<string>; count: number; reaction: string }>
    >((acc, el) => {
      if (acc[el.reaction]) {
        acc[el.reaction].count += 1;
        acc[el.reaction].authors.add(el.author);
      } else {
        acc[el.reaction] = { authors: new Set([el.author]), count: 1, reaction: el.reaction };
      }

      return acc;
    }, {});

    return Object.values(res);
  }, [post]);

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-4xl" innerClassName="p-9">
        <header className="mb-2.5">
          <BackButton onClick={() => onOpenChange(false)}>Nesa Ai Feed</BackButton>
        </header>
        <div className="mt-7 flex flex-wrap items-center gap-6">
          <div className="flex flex-1 items-center gap-5">
            <img
              alt=""
              className="size-12 min-w-12 rounded-full"
              src={post.author.avatarUrl.startsWith('http') ? post.author.avatarUrl : huggingFaceIconSrc}
            />
            <div className="flex flex-col gap-1">
              <div className="text-base font-medium text-clay-500">{post.author.fullname}</div>
              <div className="text-xl font-semibold text-clay-900">{post.author.name}</div>
            </div>
          </div>
          <div className="text-sm font-medium text-clay-300">
            Published {DateTime.fromISO(post.publishedAt).toLocaleString(DateTime.DATE_FULL)}
          </div>
          <div
            className="flex h-[24px] items-center rounded-full bg-clay-10 px-3"
            onClick={(e) => {
              e.stopPropagation();
              window.open(`https://huggingface.co${post.url}`, '_blank');
            }}
          >
            <img alt="" className="w-20 min-w-20" src={huggingFaceSrc} />
          </div>
        </div>
        <div className="mb-5 mt-7 h-px bg-clay-20" />
        <HuggingFaceContent post={post} />

        {!!post.attachments.length && <Attachments attachments={post.attachments} />}

        <footer className="mt-5 flex w-full items-center gap-6 overflow-hidden">
          <div className="flex">
            <Button color="white" size="extra-small">
              Reply
              <Icon className="size-4" name="reply" />
            </Button>
          </div>
          <div className="flex flex-1 justify-end gap-6 overflow-hidden">
            <div className="flex flex-1 justify-end gap-2 overflow-x-auto scrollbar-none">
              {reactions.map((el, i) => (
                <Reaction
                  count={el.count}
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (el.authors.has(user?._id || '')) {
                      deleteReaction(el.reaction);
                    } else {
                      createReaction(el.reaction);
                    }
                  }}
                  reaction={el.reaction}
                  selected={el.authors.has(user?._id || '')}
                />
              ))}
              <CreateReaction postId={post.slug} type="huggingface" />
            </div>
          </div>
        </footer>

        <div className="mt-5">
          <CreationComment postId={post.slug} type="huggingface" />
        </div>

        {comments.length > 0 && (
          <div className="mt-7 border-t border-clay-20 pt-7">
            <div className="flex items-center gap-2.5">
              <div className="text-lg font-semibold text-clay-900">Comments</div>
              <div className="min-w-6 rounded-full bg-primary-800 px-2 py-1 text-center text-sm/none font-semibold text-white">
                {post.comments.length}
              </div>
            </div>
            <div className=" mt-6 flex flex-col gap-6">
              {comments.map((comment) => (
                <NesaComment
                  comment={comment}
                  key={comment._id}
                  level={0}
                  postId={post.slug}
                  type="huggingface"
                />
              ))}
            </div>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

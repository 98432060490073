import type { PeriodType } from './types';

export const chartTabs: { label: string; value: PeriodType }[] = [
  { label: '24H', value: 'day' },
  { label: '1W', value: 'week' },
  { label: '2W', value: '2week' },
  { label: '1M', value: 'month' },
  { label: '1Y', value: 'year' },
];

export const tokenToDisplay: Record<'cosmos' | 'evm', string> = {
  cosmos: 'Nesa',
  evm: 'Nesa EVM',
};

export const mock = {
  list: [
    {
      balance: '9873033456727023123124',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0x364555899bcf443ea764467bc449bcbd51cbff41',
          },
        },
      },
    },
    {
      balance: '47786601231234241123',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0x7a062c3f748274e38e445a4e22ae0bac95f2b390',
          },
        },
      },
    },
    {
      balance: '920297754119131231251',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741b',
          },
        },
      },
    },
    {
      balance: '9202977541191123123126',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741c',
          },
        },
      },
    },
    {
      balance: '920297754119112361235',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741a',
          },
        },
      },
    },
    {
      balance: '9202977541191542351234',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741z',
          },
        },
      },
    },
    {
      balance: '92029775411915123123',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741x',
          },
        },
      },
    },
    {
      balance: '92029775411911255125',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741nb',
          },
        },
      },
    },
    {
      balance: '92029775411915123123',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741rt',
          },
        },
      },
    },
    {
      balance: '9202977541191123123123',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b421741c',
          },
        },
      },
    },
    {
      balance: '64952120805825456559222',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b4217xqwe',
          },
        },
      },
    },
    {
      balance: '64952120805825456559222',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b42174xdczxf',
          },
        },
      },
    },
    {
      balance: '64952120805825456559222',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b4217xcasd',
          },
        },
      },
    },
    {
      balance: '64952120805825456559222',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b4217123asd',
          },
        },
      },
    },
    {
      balance: '64952120805825456559222',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b4217asd1',
          },
        },
      },
    },
    {
      balance: '64952120805825456559222',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b42174123z',
          },
        },
      },
    },
    {
      balance: '64952120805825456559222',
      token: {
        projectName: 'Unknown',
        token: {
          creation: {
            address: '0xa44a3bfaae4c5f7de05e1c91582f5db7b4217443',
          },
        },
      },
    },
    {
      balance: '21969336265',
      token: {
        projectName: 'Nesa USD',
        token: {
          creation: {
            address: '0xcd18bde1f3c201f3e82e34b4fbe8933b6aa3c219',
          },
          ticker: 'USDN',
        },
      },
    },
    {
      balance: '6495212080582545655928',
      token: {
        _id: '67d40352aa7808bf456cb793',
        projectCategory: 'defi',
        projectName: 'car8',
        slug: 'carrrrr',
        token: {
          creation: {
            address: '0x2250fe160117b16b7017a770c1baea7898589926',
            chain: 'nesa-evm',
          },
          image: 'https://cdn-beta.nesa.ai/dai/662d57657f4de830388db819-1741947729823.png',
          ticker: 'carrrr',
        },
      },
    },
    {
      balance: '6495212080582545655928',
      token: {
        _id: '67d40352aa7808bf456cb793',
        projectCategory: 'defi',
        projectName: 'car8',
        slug: 'carrrrr',
        token: {
          creation: {
            address: '0x2250fe160117b16b7017a770c1baea7898589924',
            chain: 'nesa-evm',
          },
          image: 'https://cdn-beta.nesa.ai/dai/662d57657f4de830388db819-1741947729823.png',
          ticker: 'carrrr',
        },
      },
    },
    {
      balance: '6495212080582545655928',
      token: {
        _id: '67d40352aa7808bf456cb793',
        projectCategory: 'defi',
        projectName: 'car8',
        slug: 'carrrrr',
        token: {
          creation: {
            address: '0x2250fe160117b16b7017a770c1baea7898589925',
            chain: 'nesa-evm',
          },
          image: 'https://cdn-beta.nesa.ai/dai/662d57657f4de830388db819-1741947729823.png',
          ticker: 'carrrr',
        },
      },
    },
  ],
};

import type {
  CandlestickData,
  CandlestickSeriesOptions,
  ISeriesApi,
  Time,
  WhitespaceData,
} from 'lightweight-charts';

import { useEffect } from 'react';

import { DateTime } from 'luxon';

import { PriceChartInterval, useGetDAIPriceChartQuery } from 'shared/api/daiStats/useGetDAIPriceChartQuery';

export type TWSeries = ISeriesApi<
  'Candlestick',
  Time,
  CandlestickData<Time> | WhitespaceData<Time>,
  CandlestickSeriesOptions
>;

type Props = {
  daiId: string;
  period: PriceChartInterval;
  series: TWSeries | null;
};

const getStartTimestampByPeriod = (period: PriceChartInterval) => {
  if (period === PriceChartInterval.d1) {
    return DateTime.now().minus({ days: 1 }).toISO();
  }
  if (period === PriceChartInterval.h1) {
    return DateTime.now().minus({ hours: 1 }).toISO();
  }
  if (period === PriceChartInterval.h4) {
    return DateTime.now().minus({ hours: 4 }).toISO();
  }
  if (period === PriceChartInterval.m15) {
    return DateTime.now().minus({ minutes: 15 }).toISO();
  }
  if (period === PriceChartInterval.m1) {
    return DateTime.now().minus({ minutes: 1 }).toISO();
  }
};

export const useRealtimeUpdates = ({ daiId, period, series }: Props) => {
  const currentData = series?.data() || [];

  const latestTime =
    currentData.length > 0 && currentData[currentData.length - 1]
      ? DateTime.fromSeconds(currentData[currentData.length - 1].time as number).toISO()
      : getStartTimestampByPeriod(period);

  const tokenDataQuery = useGetDAIPriceChartQuery(
    { daiId, interval: period, startDate: latestTime! },
    { enabled: !!daiId && !!latestTime, refetchInterval: 5000 },
  );

  useEffect(() => {
    const currentData = series?.data() || [];
    const latestTime = currentData.length > 0 ? (currentData[currentData.length - 1]?.time as number) : 0;

    if (!tokenDataQuery.data?.[0] || tokenDataQuery.data?.[0].time < latestTime) {
      return;
    }

    try {
      series?.update({
        ...tokenDataQuery.data[0],
        time: DateTime.fromMillis(tokenDataQuery.data[0].time).toUnixInteger() as Time,
      });
    } catch (e) {
      console.error(e);
    }
  }, [series, tokenDataQuery.data]);
};

import ReactPlayer from 'react-player';

import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  videoSrc: string;
};

export const ExpandVideo = ({ isOpen, onOpenChange, videoSrc }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-fit !p-0" innerClassName="p-0">
        <div className="size-full h-full max-h-[80vh] max-w-[80vw]">
          <ReactPlayer className="outline-none" controls height="100%" url={videoSrc} width="100%" />
        </div>

        <div
          className="group absolute right-4 top-4 flex size-11 cursor-pointer items-center justify-center rounded-xl bg-black/20 text-white backdrop-blur-md transition-colors hover:text-clay-20"
          onClick={() => onOpenChange(false)}
        >
          <Icon className="size-5 text-white transition-colors group-hover:text-clay-10" name="close" />
        </div>

        {/* <div
          className="group absolute right-[4.25rem] top-4 flex cursor-pointer items-center gap-2 rounded-xl bg-black/20 px-3 py-2.5 text-white backdrop-blur-md transition-colors hover:text-clay-20"
          onClick={() => saveAs(videoSrc, 'video')}
        >
          <Icon name="download" />
          Download
        </div> */}
      </Modal.Content>
    </Modal>
  );
};

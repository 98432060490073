import type { Descendant } from 'slate';

import { useMemo, useState } from 'react';

import { twJoin } from 'tailwind-merge';

import type { Post } from 'shared/api/posts';

import { PreviewSlate } from 'shared/ui/TextEditor';

type Props = {
  post: Post;
  short?: boolean;
};

export const NesaContent = ({ post, short = false }: Props) => {
  const [showReadMore, setShowReadMore] = useState(false);

  const content = useMemo(() => JSON.parse(post.content) as Descendant[], [post]);

  return (
    <div className="relative">
      <div
        className={twJoin('relative text-base text-clay-500', short && 'max-h-[8lh] overflow-hidden')}
        ref={(el) => {
          if (el) {
            setShowReadMore(el.scrollHeight > el.clientHeight);
          }
        }}
      >
        <PreviewSlate value={content} />
      </div>
      {showReadMore && short && (
        <div className="absolute bottom-0 right-4 border-0 bg-white pl-1 text-base text-clay-350 underline">
          ...read more
        </div>
      )}
    </div>
  );
};

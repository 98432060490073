import { useMemo } from 'react';

import { useClickAway } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { Boxes, CircleDollarSign, FileBox, SquareTerminal } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { GalleryLogo } from 'app/ui/GalleryLogo';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon } from 'shared/ui/Icon';

import type { SidebarItem } from './types';

import { UserLink } from '../UserLink';
import { SidebarLink } from './ui/SidebarLink';
import { default as DaiIcon } from './ui/dai.svg?react';

type Props = {
  isMobileSidebarOpen: boolean;
  onOpenChange: (value: boolean) => void;
};

export const GallerySidebar = ({ isMobileSidebarOpen: isExpanded, onOpenChange }: Props) => {
  const { user } = useUser();

  const sm = useMinWidthMediaQuery('sm');

  const menuItems: SidebarItem[] = useMemo(
    () => [
      {
        disabled: false,
        icon: DaiIcon,
        iconClassName: 'size-6',
        name: 'DAI ™',
        path: '/',
      },
      {
        disabled: false,
        icon: Boxes,
        name: 'Models',
        path: '/models',
        strokeWidth: 1.75,
      },
      {
        disabled: false,
        icon: FileBox,
        name: 'Datasets',
        path: '/datasets',
        strokeWidth: 1.75,
      },
      // {
      //   disabled: false,
      //   icon: SquareTerminal,
      //   // iconClassName: 'size-[1.375rem]',
      //   name: 'Agents',
      //   path: '/agents',
      //   strokeWidth: 1.75,
      // },
      {
        disabled: false,
        icon: SquareTerminal,
        name: 'Training',
        path: '/training',
        strokeWidth: 1.75,
      },
      // {
      //   disabled: false,
      //   icon: 'podium',
      //   name: 'Leaderboard',
      //   path: '/leader-board',
      // },
      {
        disabled: false,
        icon: CircleDollarSign,
        name: 'Pricing',
        path: '/pricing',
        strokeWidth: 1.75,
      },
      // {
      //   disabled: false,
      //   icon: 'enterprise',
      //   isStrokeIcon: true,
      //   name: 'Enterprise',
      //   path: '/enterprise',
      // },
      // {
      //   disabled: false,
      //   icon: 'swords',
      //   isStrokeIcon: true,
      //   name: 'Training Ground',
      //   onClick: (e) => {
      //     e.preventDefault();
      //     e.stopPropagation();
      //     toaster.info('Coming soon');
      //   },
      //   path: '/training-ground',
      // },
    ],
    [],
  );

  const ref = useClickAway<HTMLDivElement>(() => {
    onOpenChange(false);
  });

  return (
    <>
      <AnimatePresence>
        {!sm && isExpanded && (
          <motion.div
            animate={{ opacity: 1 }}
            className="absolute inset-0 z-30 min-h-dvh w-dvw bg-black/40"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          ></motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          animate={{
            maxWidth: !isExpanded ? (sm ? '4.25rem' : 0) : '11rem',
            width: !isExpanded ? (sm ? '4.25rem' : 0) : '11rem',
          }}
          className="absolute z-50 flex size-full flex-col overflow-hidden bg-white sm:relative sm:min-w-[4.25rem] sm:overflow-visible"
          initial={{
            maxWidth: sm ? '4.25rem' : 0,
            width: sm ? '4.25rem' : 0,
          }}
          ref={ref}
        >
          <div className="flex grow flex-col items-start justify-between p-4 pt-8 sm:pt-4">
            <div className="flex flex-col items-start">
              <div className={twMerge('mb-7 flex h-9 gap-4')}>
                {!sm && (
                  <div
                    className="flex size-9 cursor-pointer items-center justify-center rounded-lg bg-primary-30 transition-colors hover:bg-primary-100"
                    onClick={() => {
                      onOpenChange(false);
                    }}
                  >
                    <Icon className="size-2.5 text-primary-800" name="x" />
                  </div>
                )}

                <GalleryLogo className="ml-1" isBlack />
              </div>
              <div className="flex flex-col gap-2">
                {menuItems.map(({ iconClassName, isStrokeIcon, ...item }) => (
                  <SidebarLink
                    iconClassName={twMerge(
                      isStrokeIcon ? 'stroke-clay-400 text-transparent ' : '',
                      iconClassName,
                    )}
                    isExpanded={sm ? isExpanded : true}
                    key={item.path}
                    {...item}
                    onClick={(e) => {
                      item.onClick?.(e);
                      if (!sm) onOpenChange(false);
                    }}
                  />
                ))}
              </div>
            </div>

            {user && sm && (
              <div className="flex flex-col items-center gap-3">
                <UserLink className="size-7 text-base/none" />

                <div
                  className={twMerge(
                    'size-9 cursor-pointer items-center rounded-md p-2 text-blue-800 transition-colors hover:bg-blue-50',
                  )}
                  onClick={() => {
                    onOpenChange(!isExpanded);
                  }}
                >
                  <Icon className="size-4" name={isExpanded ? 'panelLeft' : 'panelRight'} />
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

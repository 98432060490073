import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CodesandboxIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

import type { GetModelParamsCb } from './SdkCodeModal';

import { SdkCodeModal } from './SdkCodeModal';

type Props = {
  getModelParams: GetModelParamsCb;
  isOpen: boolean;
  modelId: string;
  onOpenChange: (isOpen: boolean) => void;
};

export const UseModelPopover = ({ getModelParams, isOpen, modelId, onOpenChange }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Popover onOpenChange={onOpenChange} open={isOpen}>
        <Popover.Trigger asChild>
          <div
            className={twMerge(
              'group flex h-9 cursor-pointer items-center gap-0 rounded-lg bg-clay-800 pl-3 pr-2 text-sm text-white',
              isOpen && 'shadow-sm',
            )}
          >
            <Icon
              className="mr-2 size-3.5 text-white transition-colors group-hover:text-primary-800"
              name="rocket"
            />{' '}
            Use this model
            <Icon
              className={twMerge('ml-0.5 size-5 text-white transition-transform', isOpen && 'rotate-180')}
              name="arrowDownSm"
            />
          </div>
        </Popover.Trigger>
        <Popover.Content
          align="start"
          className="min-w-48 rounded-lg p-1"
          collisionPadding={4}
          side="bottom"
          sideOffset={4}
        >
          <div
            className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 text-sm font-light transition-colors hover:bg-clay-10"
            onClick={() => setIsModalOpen(true)}
          >
            <div className=" flex size-6 items-center justify-center">
              <CodesandboxIcon
                className="size-[1.125rem] text-clay-400"
                name="codesandbox"
                strokeWidth={1.5}
              />
            </div>{' '}
            Nesa SDK
          </div>
          <div
            className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 text-sm font-light transition-colors hover:bg-clay-10"
            onClick={() => navigate(`/dai/create?modelId=${modelId}`)}
          >
            <div className=" flex size-6 items-center justify-center">
              <Icon className="size-6 text-clay-400" name="customDai" />
            </div>{' '}
            Use in a DAI
          </div>
        </Popover.Content>
      </Popover>
      <SdkCodeModal getModelParams={getModelParams} isOpen={isModalOpen} onOpenChange={setIsModalOpen} />
    </>
  );
};

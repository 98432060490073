import { useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useDAIListQuery } from 'shared/api/dai/useDAIListQuery';
import { Badge } from 'shared/ui/Badge';
import { HotDaiCard } from 'shared/ui/HotDaiCard/HotDaiCard';
import { Icon } from 'shared/ui/Icon';

type Props = ClassName;

export const TrendingDAIs = ({ className }: Props) => {
  const navigate = useNavigate();

  const { data: daiList, isPending: isListLoading } = useDAIListQuery({
    limit: 5,
    skip: 0,
    sort: 'newest',
    tags: ['trending'],
  });

  return (
    <div className={twMerge('flex flex-col gap-4', className)}>
      <header className="flex items-center gap-3">
        <div className="flex size-8 items-center justify-center rounded-lg bg-red-100">
          <Icon className="size-4 stroke-red-800 text-transparent" name="flame" />
        </div>

        <div className="text-2xl font-medium tracking-tighter">Trending DAI</div>
      </header>

      <div className="flex flex-col gap-3">
        {isListLoading
          ? Array.from({ length: 4 })
              .fill('')
              .map((_, idx) => {
                return <HotDaiCard isLoading key={idx} showMediaNearTitle />;
              })
          : daiList?.list.map((dai) => {
              const founder = dai.members[0];

              return (
                <HotDaiCard
                  chipSlot={
                    dai.isNesaExclusive ? (
                      <Badge className="bg-yellow-500/10 px-2 py-1.5 font-medium uppercase text-yellow-500">
                        exclusive on nesa
                      </Badge>
                    ) : undefined
                  }
                  className="border-transparent bg-clay-10"
                  coinImageSrc={dai.token?.image}
                  coinName={dai.token?.ticker}
                  description={dai.shortDescription}
                  key={dai._id}
                  mediaSrc={dai.profileImage}
                  onClick={() => navigate(`/dai/${dai.slug}`)}
                  // participants={dai.largestStakers || []}
                  participants={(dai.largestStakers || []).map(({ avatar, first_name, last_name }) => ({
                    avatar,
                    firstName: first_name,
                    lastName: last_name,
                    previewLetter: first_name?.slice(0, 1) || last_name?.slice(0, 1) || '',
                  }))}
                  showMediaNearTitle
                  title={dai.projectName}
                  tokenType={dai.tokenType}
                  tvl={getTvl({ tvlNes: dai.tvl })}
                  userImageSrc={founder?.avatar}
                  userName={founder?.name || ''}
                />
              );
            })}
      </div>
    </div>
  );
};

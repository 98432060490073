import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { WalletActivityRequest } from './types';

import { transactionKeys } from './queryKeys';

type Response = {
  limit: number;
  list: {
    _id: string;
    height: number;
    tx: {
      body: {
        messages: [
          {
            '@type': '/agent.v1.MsgRegisterSession';
            account: string;
            model_name: string;
          },
        ];
      };
    };
    tx_response: {
      timestamp: string;
    };
    txhash: string;
  }[];
  skip: number;
};

export const useTransactionsWalletActivityQuery = (
  params: WalletActivityRequest,
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>('/transactions/wallet-activity', {
        params: params,
        timeout: 10000,
      });

      return data;
    },
    queryKey: transactionKeys.walletActivity(params),
    ...options,
  });
};

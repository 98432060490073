import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { useAccount } from 'graz';
import { ArrowUpRight, CheckIcon } from 'lucide-react';

import type { DAI } from 'shared/api/dai/types';

import { useUser } from 'app/stores/user';
import { categories } from 'entities/DAI/const';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { getTvl } from 'pages/DAI/helpers/getTvl';
import { ConnectedModels } from 'pages/DAI/ui/ConnectedModels';
import { DAIChart } from 'pages/DAI/ui/DAIChart';
import { InteractionLogs } from 'pages/DAI/ui/InteractionLogs';
import { TeamMember } from 'pages/DAI/ui/TeamMember';
import { TeamMemberModel } from 'pages/DAI/ui/TeamMemberModel';
import { StakingModal } from 'pages/DAIOverview/ui/StakingModal/StakingModal';
import { useDAIByIdQuery } from 'shared/api/dai/useDAIByIdQuery';
import { useGetDAIConnectedWalletsQuery } from 'shared/api/dai/useGetDAIConnectedWalletsQuery';
import { useGetDAISummaryQuery } from 'shared/api/dai/useGetDAISummaryQuery';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { wrapQueryOptions } from 'shared/helpers/wrapQueryOptions';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Image } from 'shared/ui/Image';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';
import { Tag } from 'shared/ui/Tag';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  daiId: string;
  isOpened: boolean;
  onOpenChange: (open: boolean) => void;
};

export const DAIPopup = ({ daiId, isOpened, onOpenChange }: Props) => {
  const { data: daiData, isError, isPending } = useDAIByIdQuery(...wrapQueryOptions({ id: daiId }));
  const dai = daiData?.data;

  const { isConnected } = useAccount();
  const { user } = useUser();
  const navigate = useNavigate();

  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);
  const [isStakingOpen, setIsStakingOpen] = useState(false);
  const [isTeamMemberOpen, setIsTeamMemberOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<DAI['members'][number]>();

  const { data } = useGetDAIConnectedWalletsQuery({ daiId });
  const wallets = data?.list;
  const walletAddresses = (wallets || [])?.map((w) => w.walletAddress);

  const { data: daiSummary, isPending: isSummaryPending } = useGetDAISummaryQuery(
    { daiId: daiId, userId: user?._id, walletAddresses },
    { enabled: !!daiId },
  );

  if (isPending || isError || !dai) {
    return null;
  }
  // const { data: txResponse } = useTransactionsWalletActivityQuery(
  //   { limit: 100, skip: 0, walletAddresses: walletAddresses },
  //   { enabled: !!walletAddresses.length },
  // );

  return (
    <>
      <Modal onOpenChange={onOpenChange} open={isOpened}>
        <Modal.Content className="w-full md:max-w-2xl lg:max-w-6xl 4xl:max-w-4xl" innerClassName="!p-0">
          <AnimateRoute>
            <div className="relative flex flex-col">
              <AnimatePresence>
                <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
                  {dai.cover.endsWith('.mp4') ? (
                    <video
                      autoPlay
                      className="h-72 w-full object-cover"
                      loop
                      muted
                      playsInline
                      src={dai.cover}
                    />
                  ) : (
                    <img
                      alt="cover art"
                      className="h-72 w-full object-cover"
                      loading="lazy"
                      src={dai.cover}
                    />
                  )}
                  <Modal.CloseButton className="rounded-lg bg-clay-20/50" />

                  <div>
                    <div className="relative -top-16 mx-6 my-4 grid grid-cols-1 gap-3 rounded-xl bg-white bg-gradient-to-t from-transparent to-clay-20/15 p-6 md:gap-x-8 lg:grid-cols-5 2xl:grid-cols-7">
                      <div className="col-span-1 flex flex-col gap-4 lg:col-span-3 2xl:col-span-4">
                        <div className="flex flex-col gap-2 md:flex-row md:gap-4">
                          <div className="">
                            {dai.profileImage && (
                              <Image
                                className="size-12 overflow-hidden rounded-full object-cover sm:size-16 lg:size-24"
                                src={dai.profileImage}
                              />
                            )}
                          </div>
                          <div className="flex flex-col gap-2">
                            {dai.tokenType !== 'no-token' ? (
                              <div className="flex items-center gap-4">
                                <div className="size-6 overflow-hidden rounded-full bg-clay-800">
                                  {dai.token?.image && (
                                    <Image className="size-full object-cover" src={dai.token?.image} />
                                  )}
                                </div>
                                <span className="font-light">${dai.token?.ticker || '--'}</span>
                                <Tag
                                  className="rounded-2xl border-none bg-[#DBDBDB] px-2 font-medium text-clay-380"
                                  tag="#54"
                                />
                                <Tag
                                  className="rounded-2xl border-none bg-[#173BDA1A]/10 px-2 font-medium capitalize text-[#173BDA]"
                                  leftIcon={<CheckIcon className="size-5" />}
                                  tag="Verifed"
                                />
                              </div>
                            ) : (
                              <div className="text-sm text-clay-100">No token available</div>
                            )}
                            <div className="text-2xl font-semibold tracking-tighter lg:text-4xl">
                              {dai.projectName}
                            </div>

                            <div className="flex flex-wrap items-center gap-1.5 md:gap-3">
                              {[dai.projectCategory].map((tag, idx) => {
                                const category = categories.find((cat) => cat.value === tag);
                                return (
                                  <div
                                    className="rounded-lg bg-clay-10 px-2 py-1 text-sm capitalize text-clay-380"
                                    key={idx}
                                  >
                                    {category?.label || ''}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="text-base font-light text-clay-350 2xl:mr-12">
                          {dai.shortDescription}
                        </div>
                      </div>
                      <div className="col-span-1 flex flex-col gap-4 lg:col-span-2 2xl:col-span-3">
                        {dai.productLink && (
                          <Button
                            className="flex justify-between p-2.5 text-base font-semibold shadow-md"
                            color="white"
                            onClick={() =>
                              window.open(
                                dai.productLink.startsWith('https://')
                                  ? dai.productLink
                                  : `https://${dai.productLink}`,
                              )
                            }
                          >
                            <div className="flex w-4/5">
                              <span className="truncate">Try {dai.projectName || ''}</span>
                              &nbsp;
                              <span>now</span>
                            </div>

                            <ArrowUpRight className="size-5" />
                          </Button>
                        )}

                        <div className="flex flex-col gap-2">
                          <div className="text-sm text-clay-400 md:text-base">Total Value Locked</div>
                          <div className="flex items-center gap-3 text-2xl font-semibold tracking-tighter sm:text-3xl lg:text-5xl">
                            ${getTvl({ locales: 'en-US', tvlNes: dai.tvl })}
                            {/*<div className="flex items-center gap-1.5">*/}
                            {/*  <div*/}
                            {/*    className={twMerge(*/}
                            {/*      'flex size-6 items-center justify-center rounded-full bg-green-600/20 text-green-600',*/}
                            {/*      priceChangePrc < 0 && 'bg-red-900/20 text-red-900',*/}
                            {/*    )}*/}
                            {/*  >*/}
                            {/*    <Icon*/}
                            {/*      className={twMerge('size-4', priceChangePrc > 0 && 'rotate-180')}*/}
                            {/*      name="arrowDown"*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*  <div*/}
                            {/*    className={twMerge(*/}
                            {/*      'text-base/none font-medium tracking-normal text-green-600',*/}
                            {/*      priceChangePrc < 0 && ' text-red-900',*/}
                            {/*    )}*/}
                            {/*  >*/}
                            {/*    {priceChangePrc > 0 ? '+' : ''}*/}
                            {/*    {priceChangePrc}%*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                        <Button
                          className="flex h-auto w-full items-center justify-start gap-4 px-3 py-2 md:px-6 md:py-3.5"
                          onClick={() => {
                            if (!isConnected) {
                              setIsWalletProviderOpen(true);
                              return;
                            }
                            setIsStakingOpen(true);
                          }}
                        >
                          <Icon className="size-8 text-white" name="logo" />

                          {isConnected ? (
                            <div className="flex flex-col items-start gap-1 font-light">
                              <div className="text-base text-white xl:text-lg/none">
                                Stake $NES{' '}
                                {dai.token?.ticker ? ` for $${dai.token.ticker.toUpperCase()}` : ''}
                              </div>
                              <div className="text-sm text-white/50 md:text-base/none">
                                Earn $NES {dai.token?.ticker ? ` and $${dai.token.ticker.toUpperCase()}` : ''}
                              </div>
                            </div>
                          ) : (
                            <div className="text-start text-lg font-light">Connect Wallet to Stake</div>
                          )}
                        </Button>
                        {dai?.token?.creation?.address && (
                          <Button
                            className="items-center justify-start gap-4 bg-clay-900 p-6 hover:bg-clay-900"
                            onClick={() => {
                              navigate(`/dai/${dai?.slug}`, { state: { isModalOpen: true } });
                            }}
                          >
                            <div className="text-lg text-white">Swap</div>
                          </Button>
                        )}
                      </div>

                      <DAIChart
                        className="col-span-1 max-h-64 lg:col-span-5 2xl:col-span-7"
                        daiId={dai._id}
                        walletAddresses={(wallets || [])?.map((w) => w.walletAddress)}
                      />
                    </div>
                    <div className="relative col-span-1 grid grid-cols-1 md:grid-cols-2 lg:col-span-7">
                      <div className="flex flex-col gap-1.5 border-b border-r border-clay-20 px-6 py-3">
                        <div className="text-base font-normal text-clay-350">Address</div>
                        <div className="flex flex-col">
                          {wallets?.map((wallet) => {
                            return (
                              <div className="flex items-center gap-2 text-base font-medium" key={wallet._id}>
                                {getShortenedAddress(wallet.walletAddress)}{' '}
                                <CopyToClipboard
                                  onCopy={() => toaster.success('Copied')}
                                  text={wallet.walletAddress || ''}
                                >
                                  <Icon
                                    className="size-4 cursor-pointer text-clay-350 transition-colors hover:text-clay-800"
                                    name="copy"
                                  />
                                </CopyToClipboard>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col gap-1.5 border-b border-clay-20 px-6 py-3">
                        <div className="text-base font-normal text-clay-350">Stakers</div>
                        <div className="flex items-center gap-2 text-base font-medium">
                          {isSummaryPending ? <Spinner className="size-4" /> : daiSummary?.stakers ?? '--'}
                        </div>
                      </div>{' '}
                      <div className="flex flex-col gap-1.5 border-b border-r border-clay-20 px-6 py-3">
                        <div className="text-base font-normal text-clay-350">Total Supply</div>
                        <div className="flex items-center gap-2 text-base font-medium">
                          {(dai.token?.totalSupply && Number(dai.token?.totalSupply)?.toLocaleString()) ||
                            '--'}
                        </div>
                      </div>{' '}
                      <div className="flex flex-col gap-1.5 border-b border-clay-20 px-6 py-3">
                        <div className="flex items-center gap-1 text-base font-normal text-clay-350">
                          Airdrop to Stakers{' '}
                          <Tooltip
                            content={
                              <div className="max-w-52">
                                Nesa does not control any DAI token rewards or airdrops and does not vet DAI
                                claims or information. Before staking and trading, always do your own
                                research.
                              </div>
                            }
                            delayDuration={100}
                            side="top"
                          >
                            <Icon className="size-4 text-clay-350" name="infoCircle" />
                          </Tooltip>
                        </div>
                        <div className="flex items-center gap-2 text-base font-medium">
                          {dai.token?.totalSupply && dai.token?.reservedForStakers
                            ? `${((dai.token?.totalSupply * dai.token.reservedForStakers) / 100).toLocaleString()} (${dai.token.reservedForStakers}%)`
                            : '--'}
                        </div>
                      </div>
                    </div>
                    <ConnectedModels
                      connectedModelIds={dai.connectedModels || []}
                      modelDescriptions={dai.modelDescriptions}
                    />
                    <div className="grid grid-cols-1 gap-4 p-6 md:grid-cols-2 md:gap-x-12">
                      <div className="flex flex-col gap-4">
                        <h2 className="text-xl md:text-2xl">{dai.tagline}</h2>
                        <p className="whitespace-pre-line text-base font-light text-clay-350">
                          {dai.description}
                        </p>
                      </div>
                      <div className="flex flex-col gap-3">
                        <h2 className="mb-3 text-2xl font-semibold md:text-3xl">{dai.projectName} Team</h2>
                        {dai.isTeamDoxxed && (
                          <div className="mb-10 flex w-fit items-center gap-1.5 rounded-full bg-tusk-100/80 px-2 py-1 text-xs font-medium">
                            <Icon className="size-2.5" name="check" /> Doxxed
                          </div>
                        )}

                        <div className="grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-3">
                          {dai.members.map((member) => {
                            return (
                              <TeamMember
                                avatar={member.avatar}
                                key={member.id}
                                name={member.name}
                                onClick={() => {
                                  setSelectedMember(member);
                                  setIsTeamMemberOpen(true);
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 flex flex-col gap-2 border-b border-clay-20 p-6 pb-8 md:pb-12">
                      <div className="text-base text-clay-350">Official Links</div>
                      <div className="flex flex-wrap items-center gap-1.5 md:gap-3">
                        {dai.socials.map(({ href, type }) => {
                          return (
                            <div
                              className="cursor-pointer rounded-full bg-clay-10 px-2.5 py-1 text-sm font-medium capitalize transition-colors hover:bg-clay-20"
                              key={href}
                              onClick={() =>
                                window.open(href.startsWith('https://') ? href : `https://${href}`, '_blank')
                              }
                            >
                              {type}
                            </div>
                          );
                        })}
                        {/* {['website', 'Docs', 'Whitepaper', 'X/Twitter', 'Discord'].map((name) => {
            return (
              <div className="rounded-full bg-clay-10 px-2.5 py-1 text-sm font-medium" key={name}>
                {name}
              </div>
            );
          })} */}
                      </div>
                    </div>
                    <InteractionLogs
                      createdAt={dai.createdAt}
                      daiId={dai._id}
                      daiName={dai.projectName}
                      tokenData={dai.token}
                    />
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </AnimateRoute>
        </Modal.Content>
      </Modal>

      <StakingModal
        className="lg:!max-w-[57.99rem]"
        dai={dai}
        isOpen={isStakingOpen}
        onOpenChange={setIsStakingOpen}
      />
      {selectedMember && (
        <TeamMemberModel
          isOpen={isTeamMemberOpen}
          member={selectedMember}
          onOpenChange={setIsTeamMemberOpen}
        />
      )}
      <WalletProviderModal onOpenChange={setIsWalletProviderOpen} open={isWalletProviderOpen} />
    </>
  );
};

// TODO: make reused parts shareable

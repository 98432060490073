import { Link } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

type Props = {
  imgSrc?: string;
  index: number;
  showDecimals: boolean;
  slug: string;
  title: string;
  value: number;
  volumeChangePrc: number;
};

export const TopPerformingDAIRow = ({
  imgSrc,
  index,
  showDecimals,
  slug,
  title,
  value,
  volumeChangePrc,
}: Props) => {
  const isPositiveChange = volumeChangePrc >= 0;
  const isNegativeChange = volumeChangePrc < 0;
  return (
    <Link
      className="col-span-3 grid cursor-pointer grid-cols-subgrid justify-between gap-2"
      to={`/dai/${slug}`}
    >
      <div className="flex items-center gap-3 overflow-hidden">
        <span className="min-w-3 text-center text-clay-350">{index}</span>
        <div
          className="size-[1.375rem] min-w-[1.375rem] rounded-full bg-cover bg-center bg-no-repeat"
          style={imgSrc ? { backgroundImage: `url(${imgSrc})` } : { backgroundColor: '#ECEDF1' }}
        ></div>
        <div className="truncate text-base font-medium text-clay-500">{title}</div>
      </div>
      <div className="flex min-w-fit items-center justify-end gap-2">
        <span className="min-w-fit">
          <span className="text-clay-500">$</span>
          {value.toLocaleString(undefined, {
            maximumFractionDigits: showDecimals ? 2 : 0,
            minimumFractionDigits: showDecimals ? 2 : 0,
          })}
        </span>
      </div>
      <div className="flex items-center gap-1">
        <div
          className={twMerge(
            'flex size-4 items-center justify-center rounded-full bg-clay-800/20 text-clay-800',
            isPositiveChange && 'bg-green-600/20 text-green-600',
            isNegativeChange && 'bg-red-900/20 text-red-900',
          )}
        >
          <Icon className={twMerge('size-3', isPositiveChange && 'rotate-180')} name="arrowDown" />
        </div>
        <span
          className={twMerge(
            'text-clay-800',
            isPositiveChange && ' text-green-600',
            isNegativeChange && 'text-red-900',
          )}
        >
          {Math.round(volumeChangePrc) < 1
            ? volumeChangePrc.toLocaleString('en-US', { maximumFractionDigits: 2 })
            : Math.round(volumeChangePrc)}
          %
        </span>
      </div>
    </Link>
  );
};

import { useCallback, useState } from 'react';

import { CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core';
import { Pool, Route, SwapQuoter } from '@uniswap/v3-sdk';
import { decodeAbiParameters } from 'viem';
import { usePublicClient, useReadContract } from 'wagmi';

import { uniswapV3PoolAbi } from 'shared/config/abi/uniswapV3PoolAbi';

type Props = {
  poolAddress: `0x${string}`;
  tokenIn: { address: string; decimals: number };
  tokenOut: { address: string; decimals: number };
};

export const useSwapQuote = ({ poolAddress, tokenIn, tokenOut }: Props) => {
  const poolConfig = {
    abi: uniswapV3PoolAbi,
    address: poolAddress,
  } as const;

  const [isLoading, setIsLoading] = useState(false);

  const { data: token0 } = useReadContract({ ...poolConfig, functionName: 'token0' });
  const { data: token1 } = useReadContract({ ...poolConfig, functionName: 'token1' });
  const { data: fee } = useReadContract({ ...poolConfig, functionName: 'fee' });
  const { data: liquidity } = useReadContract({ ...poolConfig, functionName: 'liquidity' });
  const { data: slot } = useReadContract({ ...poolConfig, functionName: 'slot0' });

  const client = usePublicClient();

  const getPoolState = useCallback(() => {
    if (!liquidity || !slot) throw new Error('Pool contract has not been initialized');

    return {
      liquidity: liquidity,
      sqrtPriceX96: slot[0],
      tick: slot[1],
    } as const;
  }, [liquidity, slot]);

  const getQuote = useCallback(
    async (amount: number | string, tradeType: TradeType = TradeType.EXACT_INPUT) => {
      if (!token0 || !token1 || !fee || !tokenIn || !tokenOut) return;

      setIsLoading(true);

      const state = getPoolState();

      const poolTokenIn = new Token(111888, tokenIn.address, tokenIn.decimals);
      const poolTokenOut = new Token(111888, tokenOut.address, tokenOut.decimals);

      const currencyAmountToTrade = CurrencyAmount.fromRawAmount(
        tradeType === TradeType.EXACT_OUTPUT ? poolTokenOut : poolTokenIn,
        amount,
      );

      const pool = new Pool(
        poolTokenIn,
        poolTokenOut,
        fee,
        state.sqrtPriceX96.toString(),
        state.liquidity.toString(),
        state.tick,
      );

      const swapRoute = new Route([pool], poolTokenIn, poolTokenOut);

      const { calldata } = SwapQuoter.quoteCallParameters(swapRoute, currencyAmountToTrade, tradeType, {
        useQuoterV2: true,
      }) as { calldata: `0x${string}` };

      const quoteCallReturnData = await client?.call({
        data: calldata,
        to: '0x91344493CC86F80e92ECc18D87448Dc5f1169e92',
      });

      if (!quoteCallReturnData?.data) return;

      const result = decodeAbiParameters([{ type: 'uint256' }] as const, quoteCallReturnData?.data);
      setIsLoading(false);

      return result[0];
    },
    [client, fee, getPoolState, token0, tokenIn, token1, tokenOut],
  );

  return { getQuote, isLoading };
};

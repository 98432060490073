import type { WillChangeEvent } from '@egjs/react-flicking';

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Flicking from '@egjs/react-flicking';
import { useWindowSize } from '@uidotdev/usehooks';
import { twMerge } from 'tailwind-merge';

import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useDAIListQuery } from 'shared/api/dai/useDAIListQuery';
import { Badge } from 'shared/ui/Badge';
import { HotDaiCard } from 'shared/ui/HotDaiCard/HotDaiCard';
import { Icon } from 'shared/ui/Icon';

const getCardsPerPage = (width: null | number): number => {
  if (!width) return 1;
  if (width > 1439) {
    return 3;
  }
  if (width > 599) {
    return 2;
  }
  return 1;
};

export const HottestDAIs = () => {
  const ref = useRef<Flicking>(null);

  const navigate = useNavigate();
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { width } = useWindowSize();

  const cardsPerPage = getCardsPerPage(width);

  const { data: daiList, isPending: isListLoading } = useDAIListQuery({
    limit: 5,
    skip: 0,
    sort: 'newest',
    tags: ['hot'],
  });
  const totalPages = Math.ceil((daiList?.list.length || 0) / cardsPerPage);

  return (
    <div className="flex flex-col overflow-hidden rounded-xl border border-clay-20 bg-gradient-to-tr from-transparent via-transparent via-60% to-primary-800/20 shadow-md">
      <header className="my-2 flex items-center justify-between px-4">
        <div className="text-lg font-medium tracking-tight">Hottest DAI</div>

        <div
          className="flex cursor-pointer items-center gap-1.5 text-primary-800 hover:brightness-90"
          onClick={() => navigate(`/dai/explore`)}
        >
          Explore more <Icon name="arrowUpRight" />
        </div>
      </header>

      <Flicking
        align="prev"
        bound
        cameraClass="flex"
        cameraTag="div"
        circular={false}
        defaultIndex={0}
        horizontal
        noPanelStyleOverride
        onWillChange={(e: WillChangeEvent) => {
          setActiveCardIndex(e.index);
        }}
        ref={ref}
        renderOnSameKey={false}
        viewportTag="div"
      >
        {isListLoading
          ? Array.from({ length: 4 })
              .fill('')
              .map((_, idx) => {
                return (
                  <div
                    className="mr-2 min-h-64 w-80 min-w-80 max-w-80 overflow-hidden md:w-96 md:min-w-96 md:max-w-96"
                    key={idx}
                  >
                    <HotDaiCard isLoading key={idx} />
                  </div>
                );
              })
          : daiList?.list.map((dai, idx) => {
              const founder = dai.members[0];

              return (
                <div className="mr-2 pl-0" key={idx}>
                  <HotDaiCard
                    chipSlot={
                      dai.isNesaExclusive ? (
                        <Badge className="bg-yellow-500/10 px-2 py-1.5 font-medium uppercase text-yellow-500">
                          exclusive on nesa
                        </Badge>
                      ) : undefined
                    }
                    className={twMerge(
                      'h-full w-80 max-w-80 md:w-96 md:max-w-96',
                      idx === activeCardIndex &&
                        idx + cardsPerPage < daiList?.list.length &&
                        'rounded-l-none border-l-0',
                      idx === daiList?.list.length - 1 && 'rounded-r-none border-r-0',
                    )}
                    coinImageSrc={dai.token?.image}
                    coinName={dai.token?.ticker}
                    description={dai.shortDescription}
                    key={dai._id}
                    mediaSrc={dai.cover}
                    onClick={() => navigate(`/dai/${dai.slug}`)}
                    // participants={dai.largestStakers || []}
                    participants={(dai.largestStakers || []).map(({ avatar, first_name, last_name }) => ({
                      avatar,
                      firstName: first_name,
                      lastName: last_name,
                      previewLetter: first_name?.slice(0, 1) || last_name?.slice(0, 1) || '',
                    }))}
                    tags={[dai.projectCategory]}
                    title={dai.projectName}
                    tokenType={dai.tokenType}
                    tvl={getTvl({ tvlNes: dai.tvl })}
                    userImageSrc={founder?.avatar}
                    userName={founder?.name || ''}
                  />
                </div>
              );
            })}
      </Flicking>

      <div className="mt-4 flex items-center justify-between px-4 pb-3">
        <div>
          {[...Array(totalPages)].map((_, index) => (
            <div
              className={twMerge(
                'mx-1 inline-block size-2 cursor-pointer rounded-lg bg-clay-100 text-base',
                index === currentPage - 1 && 'bg-primary-800',
              )}
              key={index}
              onClick={() => {
                if (ref.current?.animating || !daiList?.list) return;
                ref.current?.moveTo(index * cardsPerPage);
                setCurrentPage(index + 1);
              }}
            />
          ))}
        </div>
        <div className="flex gap-2">
          <Icon
            className={twMerge(
              'size-4 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
              currentPage - 1 === 0 && 'cursor-not-allowed opacity-50',
            )}
            name="arrowLeftLong"
            onClick={() => {
              if (ref.current?.animating || !daiList?.list) return;
              if (currentPage - 1 > 0) {
                ref.current?.moveTo(activeCardIndex - cardsPerPage);
                setCurrentPage(currentPage - 1);
              }
            }}
          />
          <Icon
            className={twMerge(
              'size-4 rotate-180 cursor-pointer rounded-md bg-clay-10 p-1 text-clay-500 transition-colors hover:bg-clay-20',
              currentPage === totalPages && 'cursor-not-allowed opacity-50',
            )}
            name="arrowLeftLong"
            onClick={() => {
              if (ref.current?.animating || !daiList?.list) return;
              if (currentPage < totalPages) {
                ref.current?.moveTo(activeCardIndex + cardsPerPage);
                setCurrentPage(currentPage + 1);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAIComment } from './types';

import { daiCommentsKeys } from './queryKeys';

type Params = {
  content: string;
  daiId: string;
  repliedTo?: string;
};

type Response = {
  data: DAIComment;
};

export const useCreateDaiCommentMutation = (options: UseMutationOptions<Response, Error, Params> = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<Response>(`/dai-comments`, params);

      return data;
    },
    onSuccess: (commentData, params) => {
      const queryKey = daiCommentsKeys.list({ daiId: params.daiId, limit: 10, repliedTo: params.repliedTo });

      const currentCommentsData = queryClient.getQueryData<{ pageParams: number[]; pages: DAIComment[][] }>(
        queryKey,
      );

      queryClient.setQueryData(queryKey, {
        ...(currentCommentsData || {}),
        pageParams: currentCommentsData?.pageParams || [0],
        pages: [[commentData.data, ...(currentCommentsData?.pages?.[0] || [])]],
      });

      const fullQueryKey = daiCommentsKeys.list({ daiId: params.daiId, limit: 10 });

      const fullCurrentCommentsData = queryClient.getQueryData<{
        pageParams: number[];
        pages: DAIComment[][];
      }>(fullQueryKey);

      const updatedData = fullCurrentCommentsData?.pages?.[0].map((item) =>
        item._id === commentData.data.repliedTo
          ? { ...item, commentsCount: (item.commentsCount || 0) + 1 }
          : item,
      );

      console.log('updatedData', updatedData);

      queryClient.setQueryData(fullQueryKey, {
        ...(fullCurrentCommentsData || {}),
        pageParams: fullCurrentCommentsData?.pageParams || [0],
        pages: [updatedData, ...((fullCurrentCommentsData?.pages || [])?.slice(1) || [])],
      });
    },
    ...options,
  });
};

import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { HuggingfacePost } from 'shared/api/huggingface';
import type { Post } from 'shared/api/posts';

import { queryClient } from 'app/queryClient';
import { useUser } from 'app/stores/user';
import { huggingfaceKeys } from 'shared/api/huggingface';
import {
  useCreateHuggingfaceReactionPost,
  useCreateReactionPostMutation,
  useDeleteHuggingfaceReactionPost,
  useDeleteReactionPostMutation,
} from 'shared/api/posts';
import { postsKeys } from 'shared/api/posts/queryKeys';
import { uuidv4 } from 'shared/helpers/uuid';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

const reactionList = ['🔥', '🚀', '👀', '❤️', '🤗', '😎', '➕', '🧠', '👍', '🤝', '😔', '🤯'];

type Props = {
  postId: string;
  type: 'huggingface' | 'nesa';
};

export const CreateReaction = ({ postId, type }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const createReaction = useCreateReaction(postId, type);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover onOpenChange={setIsOpen} open={isOpen}>
        <Popover.Trigger>
          <button className="flex size-9 items-center justify-center rounded-full border border-clay-20 bg-white">
            <Icon className="size-3 text-clay-300" name="plus" />
          </button>
        </Popover.Trigger>
        <Popover.Content>
          <div className="grid grid-cols-4 gap-1.5">
            {reactionList.map((reaction) => (
              <button
                className="size-8 rounded-full bg-white pt-0.5 text-center text-xs leading-8 duration-200 hover:bg-clay-20"
                key={reaction}
                onClick={() => {
                  createReaction(reaction);
                  setIsOpen(false);
                }}
              >
                {reaction}
              </button>
            ))}
          </div>
        </Popover.Content>
      </Popover>
    </div>
  );
};

export const useCreateReaction = (postId: string, type: 'huggingface' | 'nesa') => {
  const [searchParams] = useSearchParams();

  const sort = searchParams.get('sort') || 'trending';

  const { mutateAsync: createReaction } = useCreateReactionPostMutation();
  const { mutateAsync: createHuggingfaceReaction } = useCreateHuggingfaceReactionPost();

  const { user } = useUser();

  return useCallback(
    async (reaction: string) => {
      const newReaction = { author: user?._id || '', id: uuidv4(), reaction: reaction };
      if (type === 'nesa') {
        const queryKey = postsKeys.postList();
        const data: { pages: Post[][] } | undefined = queryClient.getQueryData(queryKey);
        if (data) {
          queryClient.setQueryData(queryKey, {
            ...data,
            pages: data.pages.map((page) =>
              page.map((post) =>
                post._id === postId
                  ? {
                      ...post,
                      reactions: [...post.reactions.filter((el) => el.author !== user?._id), newReaction],
                    }
                  : post,
              ),
            ),
          });
        }

        await createReaction({ postId, reaction });
        return;
      }
      const queryKey = huggingfaceKeys.postList({ sort });
      const data: { pages: HuggingfacePost[][] } | undefined = queryClient.getQueryData(queryKey);
      if (data) {
        queryClient.setQueryData(queryKey, {
          ...data,
          pages: data.pages.map((page) =>
            page.map((post) =>
              post.slug === postId
                ? {
                    ...post,
                    reactions: [...post.reactions.filter((el) => el.author !== user?._id), newReaction],
                  }
                : post,
            ),
          ),
        });
      }
      await createHuggingfaceReaction({ postId, reaction });
    },
    [user?._id, type, sort, createHuggingfaceReaction, postId, createReaction],
  );
};

export const useDeleteReaction = (postId: string, type: 'huggingface' | 'nesa') => {
  const [searchParams] = useSearchParams();
  const sort = searchParams.get('sort') || 'trending';

  const { mutateAsync: deleteReaction } = useDeleteReactionPostMutation();
  const { mutateAsync: deleteHuggingfaceReaction } = useDeleteHuggingfaceReactionPost();

  const { user } = useUser();

  return useCallback(
    async (reaction: string) => {
      if (type === 'nesa') {
        const queryKey = postsKeys.postList();
        const data: { pages: Post[][] } | undefined = queryClient.getQueryData(queryKey);
        if (data) {
          queryClient.setQueryData(queryKey, {
            ...data,
            pages: data.pages.map((page) =>
              page.map((post) =>
                post._id === postId
                  ? {
                      ...post,
                      reactions: post.reactions.filter(
                        (el) => el.reaction !== reaction || el.author !== user?._id,
                      ),
                    }
                  : post,
              ),
            ),
          });
        }
        queryClient.cancelQueries({ queryKey: queryKey });

        await deleteReaction({ postId, reaction });
        return;
      }

      const queryKey = huggingfaceKeys.postList({ sort });
      const data: { pages: HuggingfacePost[][] } | undefined = queryClient.getQueryData(queryKey);
      if (data) {
        queryClient.setQueryData(queryKey, {
          ...data,
          pages: data.pages.map((page) =>
            page.map((post) =>
              post.slug === postId
                ? {
                    ...post,
                    reactions: post.reactions.filter((el) => el.author !== user?._id),
                  }
                : post,
            ),
          ),
        });
      }
      await deleteHuggingfaceReaction({ postId, reaction });
    },
    [postId, deleteReaction, deleteHuggingfaceReaction, sort, user, type],
  );
};

import type { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  buttonText?: string;
  className?: string;
  description?: string;
  middleSlot?: ReactNode;
  onButtonClick?: () => void;
  startSlot?: ReactNode;
  title?: string;
};

export const SettingLine = ({
  buttonText,
  className,
  description,
  middleSlot,
  onButtonClick,
  startSlot,
  title,
}: Props) => {
  return (
    <div
      className={twMerge(
        'flex min-h-16 flex-col justify-between gap-2 py-2 md:flex-row md:items-center',
        className,
      )}
    >
      <div className="flex flex-col gap-1.5 md:justify-center">
        {startSlot}
        {title && <div className="text-sm text-clay-900">{title}</div>}
        {description && <div className="max-w-56 text-xs font-light text-clay-500">{description}</div>}
      </div>
      <div className="flex items-center justify-between md:w-[26rem]">
        {middleSlot}
        {buttonText && (
          <div
            className="cursor-pointer rounded-lg border border-clay-40 px-2 py-1.5 text-xs text-clay-900 shadow-sm hover:bg-clay-10"
            onClick={onButtonClick}
          >
            {buttonText}
          </div>
        )}
      </div>
    </div>
  );
};

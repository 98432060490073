import { Sparkles, Zap } from 'lucide-react';

import { Badge } from 'shared/ui/Badge';

export const GeneratedResult = () => {
  return (
    <div className="flex flex-col gap-3 px-4">
      <div className="flex items-center justify-between">
        <Badge className="flex items-center gap-1 rounded-lg bg-clay-10 px-2 py-1.5">
          <Sparkles className="size-3" /> Generated Result
        </Badge>

        <div className="flex items-center gap-1.5 text-[12px] text-clay-700">
          <Zap className="size-4 text-clay-350" /> {`< 3sec avg`}
        </div>
      </div>

      <div className="h-[300px] w-full rounded-xl bg-clay-20"></div>
      <div className=" font-light text-clay-500">
        This tool helps you create visualizations of complex concepts using natural language or PDF papers:
        Text Prompt: Describe the concept you want to visualize PDF Upload: Upload a research paper to extract
        key visualizations
      </div>
    </div>
  );
};

import { Waypoints } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Button } from 'shared/ui/Button';
import { Select } from 'shared/ui/Select';
import { toaster } from 'shared/ui/Toast';

const sortOption = [
  { label: 'Trending', value: 'trending' },
  { label: 'Newest', value: 'recent' },
] as const;

export type FunctionSort = (typeof sortOption)[number]['value'];

type Props = {
  onSortChange: (value: FunctionSort) => void;
  sort: FunctionSort;
} & ClassName;

export const Header = ({ className, onSortChange, sort }: Props) => {
  return (
    <div className={twMerge('relative flex flex-wrap items-center justify-between gap-3', className)}>
      <div className="flex flex-col ">
        <h1 className="text-2xl font-bold text-clay-1000">Functions</h1>
        <p className="text-base font-normal text-clay-500">
          Functions execute more complex AI tasks on-chain and can be integrated into DAI.
        </p>
      </div>

      <div className="ml-auto flex min-w-fit items-center gap-2">
        <Select
          className="h-10 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
          iconClassName="mr-1"
          iconName="arrowDownUp"
          onValueChange={(value) => onSortChange(value as FunctionSort)}
          placeholder="Sort by"
          placeholderClassName="font-medium text-clay-600"
          value={sort}
        >
          <Select.Content className="z-10 p-1">
            <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-normal text-blue-800">
              Sort by
            </div>

            {sortOption.map((item) => {
              return (
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  key={item.value}
                  value={item.value}
                >
                  {item.label}
                </Select.Item>
              );
            })}
          </Select.Content>
        </Select>

        <Button color="dark" onClick={() => toaster.info('Coming soon')}>
          <Waypoints className="size-4 text-[current]" /> Upload Function
        </Button>
      </div>
    </div>
  );
};

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';
import { ImagePlus, Info } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';

import { ExternalLink } from 'app/ui/ExternalLink';
// import { useUploadImageMutation } from 'shared/api/ipfs/useUploadImageMutation';
import { Button } from 'shared/ui/Button';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { RadioGroup } from 'shared/ui/Radio';
import { Select } from 'shared/ui/Select';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';

import type { FormValues, TokenChain } from '../types';

import { getFileSrc } from '../helpers/getFileSrc';
import { StepHeader } from './StepHeader';

const chainOptions: { label: string; value: TokenChain }[] = [
  { label: 'Base', value: 'base' },
  { label: 'Arbitrum', value: 'arbitrum' },
  { label: 'BNB', value: 'bnb' },
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'Optimism', value: 'optimism' },
  { label: 'Polygon', value: 'polygon' },
];

export const Tokenomics = ({ daiToEdit }: { daiToEdit?: DAI }) => {
  const {
    control,
    formState: { errors },
    register,
    trigger,
  } = useFormContext<FormValues>();
  const { field: tokenomicsTypeField } = useController({ control, name: 'tokenomicsType' });
  const { field: tokenImageField } = useController({ control, name: 'tokenImage' });
  const { field: tokenChainField } = useController({ control, name: 'tokenChain' });
  const { field: tokenStartDateField } = useController({ control, name: 'tokenStartDate' });

  const tokenPrice = useWatch({ control, name: 'tokenPrice' });
  const tokenSupply = useWatch({ control, name: 'tokenSupply' });
  const isNesaExclusive = useWatch({ control, name: 'isNesaExclusive' });

  const marketCap = tokenPrice && tokenSupply ? Number(tokenPrice) * Number(tokenSupply) : '-';

  console.log('err', errors);
  // const { mutateAsync: uploadImage } = useUploadImageMutation();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles[0].size > 500000) {
      toaster.error('Your file should be less than 500KB');

      return;
    }

    const bitMap = await createImageBitmap(acceptedFiles[0]);

    if (bitMap.height > 350 && bitMap.width > 350) {
      toaster.error('Your file resolution should be less than 300x300px');

      return;
    }

    console.log('acceptedFiles', acceptedFiles);
    tokenImageField.onChange(acceptedFiles[0]);
    // setUploadedFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });
  const {
    getInputProps: getInputProps2,
    getRootProps: getRootProps2,
    isDragActive: isDragActive2,
  } = useDropzone({ onDrop });

  const isChangeDisabled = daiToEdit?.tokenType === 'create';

  console.log('tokenomicsTypeField', tokenomicsTypeField.value);

  return (
    <div className="m-auto flex w-full max-w-3xl flex-col rounded-2xl border border-clay-20  bg-white shadow-lg ">
      <StepHeader stepNumber={3} title="Tokenomics" />

      <div className="flex flex-col gap-3">
        <RadioGroup onValueChange={tokenomicsTypeField.onChange} value={tokenomicsTypeField.value}>
          <div className="border-b border-clay-20 p-4">
            <div className="cursor-pointer" onClick={() => tokenomicsTypeField.onChange('create')}>
              <RadioGroup.Item
                className="font-medium"
                labelClassName="text-lg tracking-tight cursor-pointer"
                value="create"
              >
                Launch a New Token
              </RadioGroup.Item>
            </div>

            <AnimatePresence>
              {tokenomicsTypeField.value === 'create' && (
                <motion.div
                  animate={{ height: 'auto', opacity: 1 }}
                  className="overflow-hidden"
                  exit={{ height: 0, opacity: 0 }}
                  initial={{ height: 0, opacity: 0 }}
                >
                  <div className="py-4">
                    <div>
                      <Label className="text-xs">
                        Token Image <span className="ml-1 text-red-900">*</span>
                      </Label>

                      <div className="flex flex-col gap-4">
                        <div
                          {...getRootProps()}
                          className={twMerge(
                            'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
                            'font-normal text-clay-400 transition-all duration-200',
                            isDragActive && 'border-primary-800',
                            // isChangeDisabled && 'pointer-events-none opacity-50',
                          )}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-4">
                                {tokenImageField.value ? (
                                  <img
                                    className="size-12 rounded-lg object-cover"
                                    src={getFileSrc(tokenImageField.value)}
                                  />
                                ) : (
                                  <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                                    <ImagePlus className="size-4 text-clay-350" />
                                  </div>
                                )}

                                <div className="flex flex-col gap-0.5">
                                  <div className="font-medium tracking-tight text-clay-800">
                                    Upload token logo
                                  </div>
                                  <div className="text-sm font-light text-clay-350">
                                    SVG, PNG, JPG or GIF (rec. 300 x 300px)
                                  </div>
                                </div>
                              </div>

                              <Button color="white" size="extra-small">
                                Browse
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 grid grid-cols-3 items-end gap-3">
                      <Input
                        error={errors.ticker?.message || ''}
                        errorSpacing
                        {...register('ticker')}
                        disabled={isChangeDisabled}
                        label={
                          <span>
                            Ticker Symbol
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />
                      <Input
                        className="col-span-1"
                        disabled={isChangeDisabled}
                        error={errors.tokenSupply?.message || ''}
                        errorSpacing
                        max={1000000000}
                        min={1000000}
                        type="number"
                        {...register('tokenSupply', {
                          onChange: () => {
                            trigger('tokenLPAmount');
                          },
                          validate: (value) => {
                            if (!value) return 'Field is required';
                            if (value < 1000000) return 'Total supply should be >= 1,000,000';
                            if (value > 1000000000000) return 'Total supply should be <= 1,000,000,000,000';
                          },
                        })}
                        label={
                          <span>
                            Total Token Supply
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />
                      <Input
                        className="col-span-1"
                        disabled={isChangeDisabled}
                        error={errors.stakersReserved?.message || ''}
                        errorSpacing
                        max={50}
                        min={5}
                        type="number"
                        {...register('stakersReserved', {
                          onChange: () => {
                            trigger('tokenLPAmount');
                          },
                          validate: (value, formValues) => {
                            if (formValues.tokenomicsType !== 'create') return undefined;

                            console.log('value', value);
                            const errorMessage =
                              value === undefined ||
                              Number.isNaN(Number(value)) ||
                              Number(value) < 5 ||
                              Number(value) > 50
                                ? 'Value should be between 5 and 50 percent'
                                : undefined;

                            if (errorMessage) return errorMessage;
                          },
                        })}
                        label={
                          <span className="inline-flex flex-wrap items-center">
                            {`% Supply "Awarded to" Stakers`}
                            <span className="ml-1 text-red-900">*</span>

                            <Tooltip
                              className="max-w-[240px]"
                              content={`This portion of your token's total supply will later be awarded to users who stake into your DAI`}
                              delayDuration={100}
                              side="top"
                            >
                              <Info className="ml-1.5 size-4 min-w-4 cursor-pointer text-clay-350 transition-colors hover:text-primary-800" />
                            </Tooltip>
                          </span>
                        }
                        placeholder="-"
                      />

                      <Input
                        className="col-span-2"
                        disabled={isChangeDisabled && daiToEdit.token?.initialLPAmount !== undefined}
                        error={errors.tokenLPAmount?.message || ''}
                        errorSpacing
                        max={25}
                        min={5}
                        type="number"
                        {...register('tokenLPAmount', {
                          validate: (value, formValues) => {
                            if (formValues.tokenomicsType !== 'create') return undefined;

                            if (value === undefined || Number.isNaN(Number(value)) || Number(value) < 0) {
                              return 'Value should be above 0';
                            }

                            if (Number(value) < 5 || Number(value) > 25)
                              return 'Value should be between 5 and 25 percent';

                            const tokenPrice = formValues.tokenPrice;
                            const tokenSupply = formValues.tokenSupply;

                            if (!tokenPrice || Number.isNaN(Number(tokenPrice)) || !tokenSupply)
                              return 'token price, total supply should be filled first';

                            if ((Number(tokenPrice) * tokenSupply * Number(value)) / 100 < 100000) {
                              return '(Token Price * Total Supply * LP%/100) must be >= $100,000';
                            }
                          },
                        })}
                        label={
                          <span className="inline-flex items-center">
                            % Supply Placed in Liquidity Pool
                            <span className="ml-1 text-red-900">*</span>
                            <Tooltip
                              content={
                                <div className="max-w-[260px]">
                                  This portion of your token&apos;s total supply will be placed into a
                                  liquidity pool upon token creation. More tokens placed into the LP lead to
                                  decreased price impact in either direction during token trades. For more
                                  information, see{' '}
                                  <ExternalLink
                                    className="!text-sm underline"
                                    target="_blank"
                                    to="https://www.rareskills.io/post/uniswap-v3-concentrated-liquidity"
                                  >
                                    this
                                  </ExternalLink>{' '}
                                  article
                                </div>
                              }
                              side="top"
                            >
                              <Info className="ml-1.5 size-4 cursor-pointer text-clay-350 transition-colors hover:text-primary-800" />
                            </Tooltip>
                          </span>
                        }
                        placeholder="15"
                      />
                      <Input
                        disabled={isChangeDisabled && daiToEdit.token?.initialPrice !== undefined}
                        error={errors.tokenPrice?.message || ''}
                        errorSpacing
                        max={100}
                        min={0.0001}
                        type="number"
                        {...register('tokenPrice', {
                          onChange: () => {
                            trigger('tokenLPAmount');
                          },
                          validate: (value, formValues) => {
                            if (formValues.tokenomicsType !== 'create') return undefined;

                            if (value === undefined || Number.isNaN(Number(value)) || Number(value) < 0) {
                              return 'Value should be above 0';
                            }

                            if (Number(value) < 0.0001) return 'Should be more than $0.0001 per token';
                            if (Number(value) > 100) return 'Should be less than $100 per token';
                          },
                        })}
                        label={
                          <div className="flex items-center">
                            Initial Token Price (USD)
                            <span className="ml-1 text-red-900">*</span>
                            <Tooltip
                              className="max-w-[240px]"
                              content={`This value directly impacts your token's market cap: Token Price * Total Token Supply = Market Cap. Given traders can claim $1,000 USDN each day, consider a market cap that will be conducive to your desired level of price impact across trades.`}
                              delayDuration={100}
                              side="top"
                            >
                              <Info className="ml-1.5 size-4 cursor-pointer text-clay-350 transition-colors hover:text-primary-800" />
                            </Tooltip>
                          </div>
                        }
                        placeholder="0.01"
                      />

                      <div className="flex h-full flex-col gap-1">
                        <div className="flex items-center text-xs text-clay-400 2xl:text-sm">
                          Market Cap{' '}
                          <Tooltip
                            content={`Token Price * Total Token Supply = Market Cap`}
                            delayDuration={100}
                            side="top"
                          >
                            <Info className="ml-1.5 size-4 cursor-pointer text-clay-350 transition-colors hover:text-primary-800" />
                          </Tooltip>
                        </div>
                        <div className="my-auto h-9 pb-4 font-medium">
                          ${marketCap.toLocaleString('en-US')}
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="border-b border-clay-20 p-4">
            <div
              className={twMerge(
                isChangeDisabled && 'pointer-events-none opacity-50',
                isNesaExclusive && 'opacity-50',
              )}
            >
              <Tooltip
                content={isNesaExclusive ? '"Nesa Exclusive" DAIs must launch on Nesa ' : ''}
                delayDuration={100}
                side="right"
              >
                <div
                  className={twMerge('max-w-fit cursor-pointer', isNesaExclusive && 'cursor-not-allowed')}
                  onClick={() => !isNesaExclusive && tokenomicsTypeField.onChange('import')}
                >
                  <RadioGroup.Item
                    className=" font-medium"
                    disabled={isChangeDisabled || isNesaExclusive}
                    labelClassName={twMerge(
                      'cursor-pointer text-lg tracking-tight',
                      isNesaExclusive && 'pointer-events-none',
                    )}
                    value="import"
                  >
                    Import an Existing Token
                  </RadioGroup.Item>
                </div>
              </Tooltip>

              <AnimatePresence>
                {tokenomicsTypeField.value === 'import' && (
                  <motion.div
                    animate={{ height: 'auto', opacity: 1 }}
                    className="overflow-hidden"
                    exit={{ height: 0, opacity: 0 }}
                    initial={{ height: 0, opacity: 0 }}
                  >
                    <div className="pt-4">
                      <Select
                        className="mb-3 border border-clay-20 bg-white"
                        label={
                          <span className="text-sm font-normal text-clay-500">
                            Chain
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        onValueChange={tokenChainField.onChange}
                        placeholder="-"
                        value={tokenChainField.value}
                      >
                        <Select.Content>
                          {chainOptions.map(({ label, value }) => {
                            return (
                              <Select.Item key={value} value={value}>
                                {label}
                              </Select.Item>
                            );
                          })}
                        </Select.Content>
                      </Select>

                      <Input
                        label={
                          <span>
                            Token Contract Address
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        {...register('tokenContractAddress', {
                          validate: (value) =>
                            value && /^0x[a-fA-F0-9]{40}$/.test(value) ? undefined : 'Address is not valid',
                          // validate: (value) => (/value?.startsWith('0x') ? '' : 'Address is not valid'),
                        })}
                        error={errors.tokenContractAddress?.message || ''}
                        errorSpacing
                        maxLength={42}
                        placeholder="0x00000000000..."
                        // placeholder="0x0000000000000000000000000000000000000000"
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <div
            className={twMerge(
              'border-b border-clay-20 p-4',
              isChangeDisabled && 'pointer-events-none opacity-50',
            )}
          >
            <div className="cursor-pointer" onClick={() => tokenomicsTypeField.onChange('later')}>
              <RadioGroup.Item
                className=" font-medium"
                labelClassName="text-lg tracking-tight cursor-pointer"
                value="later"
              >
                Launch a Token Later
              </RadioGroup.Item>
            </div>

            <AnimatePresence>
              {tokenomicsTypeField.value === 'later' && (
                <motion.div
                  animate={{ height: 'auto', opacity: 1 }}
                  className="overflow-hidden"
                  exit={{ height: 0, opacity: 0 }}
                  initial={{ height: 0, opacity: 0 }}
                >
                  <div className="py-4">
                    <div>
                      <Label className="text-xs">
                        Token Image <span className="ml-1 text-red-900">*</span>
                      </Label>

                      <div className="flex flex-col gap-4">
                        <div
                          {...getRootProps2()}
                          className={twMerge(
                            'flex cursor-pointer flex-col rounded-lg border-[1.2px] border-dashed border-clay-40 p-3',
                            'font-normal text-clay-400 transition-all duration-200',
                            isDragActive2 && 'border-primary-800',
                          )}
                        >
                          <input {...getInputProps2()} />
                          {isDragActive2 ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-4">
                                {tokenImageField.value ? (
                                  <img
                                    className="size-12 rounded-lg object-cover"
                                    src={getFileSrc(tokenImageField.value)}
                                  />
                                ) : (
                                  <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
                                    <ImagePlus className="size-4 text-clay-350" />
                                  </div>
                                )}

                                <div className="flex flex-col gap-0.5">
                                  <div className="font-medium tracking-tight text-clay-800">
                                    Upload token logo
                                  </div>
                                  <div className="text-sm font-light text-clay-350">
                                    SVG, PNG, JPG or GIF (rec. 300 x 300px)
                                  </div>
                                </div>
                              </div>

                              <Button color="white" size="extra-small">
                                Browse
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 grid grid-cols-3 gap-2">
                      <Input
                        error={errors.ticker?.message || ''}
                        errorSpacing
                        {...register('ticker')}
                        label={
                          <span>
                            Ticker Symbol
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />

                      <Input
                        className="col-span-2"
                        disabled={isChangeDisabled}
                        error={errors.tokenSupply?.message || ''}
                        errorSpacing
                        max={1000000000}
                        min={1000000}
                        type="number"
                        {...register('tokenSupply', {
                          validate: (value) => {
                            if (!value) return 'Field is required';
                            if (value < 1000000) return 'Total supply should be >= 1,000,000';
                            if (value > 1000000000000) return 'Total supply should be <= 1,000,000,000,000';
                          },
                        })}
                        label={
                          <span>
                            Total Token Supply
                            <span className="ml-1 text-red-900">*</span>
                          </span>
                        }
                        placeholder="-"
                      />

                      <Input
                        disabled={isChangeDisabled}
                        error={errors.stakersReserved?.message || ''}
                        errorSpacing
                        max={50}
                        min={5}
                        type="number"
                        {...register('stakersReserved', {
                          // max: 100,
                          // min: 0,
                          // required: true,
                          validate: (value, formValues) => {
                            if (formValues.tokenomicsType !== 'create') return undefined;

                            console.log('value', value);
                            return value === undefined ||
                              Number.isNaN(Number(value)) ||
                              Number(value) < 5 ||
                              Number(value) > 50
                              ? 'Value should be between 5 and 50 percent'
                              : undefined;
                          },
                        })}
                        label={
                          <span className="inline-flex items-center">
                            {`% Supply "Awarded to" Stakers`}
                            <span className="ml-1 text-red-900">*</span>

                            <Tooltip
                              className="max-w-[240px]"
                              content={`This portion of your token's total supply will later be awarded to users who stake into your DAI`}
                              delayDuration={100}
                              side="top"
                            >
                              <Info className="ml-1.5 size-4 cursor-pointer text-clay-350 transition-colors hover:text-primary-800" />
                            </Tooltip>
                          </span>
                        }
                        placeholder="-"
                      />

                      <Input
                        disabled={isChangeDisabled}
                        error={errors.tokenPrice?.message || ''}
                        errorSpacing
                        max={100}
                        min={0.0001}
                        type="number"
                        {...register('tokenPrice', {
                          validate: (value, formValues) => {
                            if (
                              formValues.tokenomicsType !== 'create' &&
                              formValues.tokenomicsType !== 'later'
                            )
                              return undefined;

                            if (value === undefined || Number.isNaN(Number(value)) || Number(value) < 0) {
                              return 'Value should be above 0';
                            }

                            if (Number(value) < 0.0001) return 'Should be more than $0.0001 per token';
                            if (Number(value) > 100) return 'Should be less than $100 per token';
                          },
                        })}
                        label={
                          <div className="flex items-center">
                            Initial Token Price (USD)
                            <span className="ml-1 text-red-900">*</span>
                            <Tooltip
                              className="max-w-[240px]"
                              content={`This value directly impacts your token's market cap: Token Price * Total Token Supply = Market Cap. Given traders can claim $1,000 USDN each day, consider a market cap that will be conducive to your desired level of price impact across trades.`}
                              delayDuration={100}
                              side="top"
                            >
                              <Info className="ml-1.5 size-4 cursor-pointer text-clay-350 transition-colors hover:text-primary-800" />
                            </Tooltip>
                          </div>
                        }
                        placeholder="0.01"
                      />

                      <div className="flex h-full flex-col gap-1">
                        <div className="flex items-center text-xs text-clay-400 2xl:text-sm">
                          Market Cap{' '}
                          <Tooltip
                            content={`Token Price * Total Token Supply = Market Cap`}
                            delayDuration={100}
                            side="top"
                          >
                            <Info className="ml-1.5 size-4 cursor-pointer text-clay-350 transition-colors hover:text-primary-800" />
                          </Tooltip>
                        </div>
                        <div className="my-auto h-9 pb-4 font-medium">
                          ${marketCap.toLocaleString('en-US')}
                        </div>
                      </div>

                      <div className="flex max-w-72 flex-col">
                        <Label className="mb-1 text-sm font-normal text-clay-500">
                          Estimated Launch Date
                        </Label>
                        <DatePicker
                          minDate={new Date()}
                          onDateChange={tokenStartDateField.onChange}
                          selectedDate={tokenStartDateField.value || null}
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div
            className={twMerge(
              'border-none border-clay-20 p-4',
              isChangeDisabled && 'pointer-events-none opacity-50',
            )}
          >
            <div className="cursor-pointer" onClick={() => tokenomicsTypeField.onChange('no-token')}>
              <RadioGroup.Item
                className=" font-medium"
                labelClassName="text-lg tracking-tight cursor-pointer"
                value="no-token"
              >
                {`Don't Launch a Token`}
              </RadioGroup.Item>
            </div>
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

import type { ComponentProps, ReactNode } from 'react';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

import { WalletType, useDisconnect, useSuggestChainAndConnect } from 'graz';
import { twMerge } from 'tailwind-merge';

import { checkInstalledWallet } from 'features/WalletProvider/WalletProvider';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { toaster } from 'shared/ui/Toast';
import cosmostationSrc from 'shared/ui/assets/cosmostation.svg';
import keplrSrc from 'shared/ui/assets/keplrSmLogo.svg';
import leapSrc from 'shared/ui/assets/leap.svg';
import metamaskSrc from 'shared/ui/assets/metamask.svg';

type WalletItem = {
  name: string;
  src: string;
  walletType?: WalletType;
};

const walletProvider: WalletItem[] = [
  {
    name: 'Metamask',
    src: metamaskSrc,
    walletType: WalletType.METAMASK_SNAP_LEAP,
  },
  {
    name: 'Keplr',
    src: keplrSrc,
    walletType: WalletType.KEPLR,
  },
  {
    name: 'Leap',
    src: leapSrc,
    walletType: WalletType.LEAP,
  },
  {
    name: 'Cosmostation',
    src: cosmostationSrc,
    walletType: WalletType.COSMOSTATION,
  },
];

export const Wallet = ({
  address,
  addressText,
  alignPopup,
  isConnected,
}: {
  address: ReactNode | undefined;
  addressText?: string;
  alignPopup?: ComponentProps<typeof Popover.Content>['align'];
  isConnected: boolean;
}) => {
  const { isLoading, suggestAndConnectAsync } = useSuggestChainAndConnect();
  const navigate = useNavigate();

  const { disconnect } = useDisconnect();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  if (isConnected) {
    return (
      <Popover onOpenChange={setPopoverOpen} open={isPopoverOpen}>
        <Popover.Trigger>
          <div className="flex h-9 cursor-pointer items-center justify-between gap-1 rounded-lg bg-clay-20 px-3">
            <div className={twMerge('flex flex-1 overflow-hidden text-sm font-normal text-clay-900')}>
              {address ? (typeof address === 'string' ? getShortenedAddress(address) : address) : ''}
            </div>
            <Icon className="size-6 min-w-6 p-0 text-clay-900" name="arrowDownSm" />
          </div>
        </Popover.Trigger>
        <Popover.Content align={alignPopup || 'end'} className="mt-2 w-40 rounded-lg p-1">
          <CopyToClipboard onCopy={() => toaster.success('Copied')} text={addressText || ''}>
            <div className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 transition-colors hover:bg-clay-20">
              <Icon className="size-3.5 text-clay-350" name="copy" />
              <span className="text-sm font-normal">Copy Address</span>
            </div>
          </CopyToClipboard>

          <div
            className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 transition-colors hover:bg-clay-20"
            onClick={() => {
              navigate('/wallet');
            }}
          >
            <Icon className="size-3.5 text-clay-350" name="wallet" />
            <span className="text-sm font-normal">Wallet</span>
          </div>
          <div
            className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 transition-colors hover:bg-clay-20"
            onClick={() => {
              disconnect();
              setPopoverOpen(false);
            }}
          >
            <Icon className="size-3.5 text-red-900" name="unplug" />{' '}
            <span className="text-sm font-normal">Disconnect</span>
          </div>
        </Popover.Content>
      </Popover>
    );
  }

  return (
    <Popover onOpenChange={setPopoverOpen} open={isPopoverOpen}>
      <Popover.Trigger>
        <Button as="div" className="h-9 w-full pl-3 pr-4" color="secondary">
          <Icon className="size-3.5 text-clay-400" name="wallet" />
          Wallet
        </Button>
      </Popover.Trigger>
      <Popover.Content align={alignPopup || 'end'} className="mt-2 rounded-lg p-4">
        <div className="flex w-60 flex-col rounded-lg bg-white">
          <div className="text-lg font-semibold text-clay-900">Connect a Wallet</div>
          <div className="mt-1 text-sm/4 font-light text-clay-500">
            Choose how you want to connect. There are several wallet providers.
          </div>
          <div className="mt-4 flex flex-col gap-1">
            {walletProvider.map((item) => (
              <div
                className={twMerge(
                  'flex h-11 cursor-pointer items-center gap-4 rounded-lg bg-clay-10 px-4 transition-colors hover:bg-clay-20',
                  isLoading && 'pointer-events-none select-none opacity-50',
                )}
                key={item.name}
                onClick={async () => {
                  try {
                    if (item.walletType && !checkInstalledWallet(item.walletType)) {
                      toaster.error(`Please install ${item.name} Wallet from the Chrome Store`);
                      return;
                    }

                    await suggestAndConnectAsync({
                      chainInfo: nesaTestnet,
                      walletType: item.walletType,
                    });

                    setPopoverOpen(false);
                  } catch (e) {
                    toaster.error('Something went wrong');
                  }
                }}
              >
                <img className="w-7" src={item.src} />
                <div className="text-sm font-semibold text-clay-1000">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiStatsKeys } from '.';

export enum PriceChartInterval {
  unknown,
  m1,
  m15,
  h1,
  h4,
  d1,
}

export type DAIPriceChartProps = {
  daiId: string;
  interval: PriceChartInterval;
  startDate: string;
};

type Response = { close: number; high: number; low: number; open: number; time: number }[];

export const useGetDAIPriceChartQuery = (
  { interval = PriceChartInterval.h1, ...params }: DAIPriceChartProps,
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/dai-stats/price-history`, {
        params: {
          ...params,
          interval,
        },
        signal,
      });

      return data;
    },
    queryKey: daiStatsKeys.getPriceChart({ ...params, interval }),
    retry: false,
    ...options,
  });
};

import { X } from 'lucide-react';

import { StakingAssetsTable } from 'widgets/StakingAssetsTable';

type Props = {
  onClose: () => void;
};

export const MyStakedAssets = ({ onClose }: Props) => {
  return (
    <div className="relative min-h-[34.75rem] rounded-2xl bg-white p-4 md:p-6">
      <X
        className="absolute right-4 top-4 size-5 cursor-pointer text-clay-350 transition-colors hover:text-red-900"
        onClick={onClose}
      />
      <h1 className="mb-3 text-xl font-semibold tracking-tight md:mb-4 md:text-3xl">My Staked Assets</h1>

      <div className="max-h-[420px] overflow-y-auto">
        <StakingAssetsTable />
      </div>
    </div>
  );
};

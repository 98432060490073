import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { UseQueryOptions } from 'shared/types';

import type { StatParams } from './types';

import { queryKeys } from './queryKeys';

export type DatasetStat = {
  column_name: string;
  column_statistics: {
    histogram: {
      bin_edges: number[];
      hist: number[];
    };
    max: number;
    mean: number;
    median: number;
    min: number;
    nan_count: number;
    nan_proportion: number;
    std: number;
  };
  column_type: string;
};

export type StatResponse = {
  num_examples: number;
  partial: boolean;
  statistics: DatasetStat[];
};
// https://datasets-server.huggingface.co/statistics?dataset=fka%2Fawesome-chatgpt-prompts&config=default&split=train

export const useDatasetStatsQuery = (params: StatParams, options: UseQueryOptions<StatResponse> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<StatResponse>(`https://datasets.nesa.ai/statistics`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        params,
        paramsSerializer: {
          indexes: null,
        },
        signal,
      });

      return data;
    },
    queryKey: queryKeys.stats(params),
    ...options,
  });
};

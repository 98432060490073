import type { Address, PublicClient } from 'viem';

import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import { erc20Abi, getContract } from 'viem';
import { useAccount, useChainId, usePublicClient } from 'wagmi';

import type { QueryOptions } from 'shared/types';

type Params = {
  accountAddress: Address | undefined;
  contractAddress: Address;
  currencyAddress: Address;
  pc?: PublicClient | undefined;
};
export const getAllowanceQueryKey = ({ accountAddress, contractAddress, currencyAddress, pc }: Params) => {
  return ['erc20', 'allowance', accountAddress, currencyAddress, contractAddress, pc];
};

const getQueryOptions = <TData = bigint>(params: Params, options?: QueryOptions<bigint, unknown, TData>) => {
  const { accountAddress, contractAddress, currencyAddress, pc } = params;
  return queryOptions<bigint, unknown, TData>({
    enabled: Boolean(accountAddress && pc),
    queryFn: async () => {
      invariant(pc, 'useAllowancesQuery. pc is undefined');
      invariant(accountAddress, 'useAllowancesQuery. address is undefined');

      const contract = getContract({
        abi: erc20Abi,
        address: currencyAddress,
        client: pc,
      });

      return contract.read.allowance([accountAddress, contractAddress]);
    },
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getAllowanceQueryKey({
      accountAddress,
      contractAddress,
      currencyAddress,
    }),
    ...options,
  });
};

type UseAllowanceQueryParams = {
  contractAddress: Address;
  currencyAddress: Address;
};

export const useAllowanceQuery = <TData = bigint>(
  params: UseAllowanceQueryParams,
  options?: QueryOptions<bigint, unknown, TData>,
) => {
  const { address: accountAddress } = useAccount();
  const chainId = useChainId();
  const pc = usePublicClient({ chainId });

  const { contractAddress, currencyAddress } = params;

  return useQuery(
    getQueryOptions<TData>(
      {
        accountAddress,
        contractAddress,
        currencyAddress,
        pc,
      },
      options,
    ),
  );
};

type UseAllowancesQueryParams = {
  contractAddress: Address;
  currencyAddresses: Address[];
};
export const useAllowancesQueries = <TData = bigint>(
  params: UseAllowancesQueryParams,
  options?: QueryOptions<bigint, unknown, TData>,
) => {
  const { contractAddress, currencyAddresses } = params;
  const chainId = useChainId();
  const pc = usePublicClient({ chainId });
  const { address } = useAccount();

  return useQueries({
    queries: currencyAddresses.map((currencyAddress) => {
      return getQueryOptions<TData>(
        {
          accountAddress: address,
          contractAddress,
          currencyAddress,
          pc,
        },
        options,
      );
    }),
  });
};

import type { PropsWithChildren } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useDisconnect, useAccount as wagmiAccount } from 'wagmi';

import type { ActiveWallet } from 'pages/Wallet/types';

import { BackButton } from 'app/ui/BackButton';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';

import { tokenToDisplay } from './config';

export const Header = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { walletType = 'cosmos' } = useParams<{
    walletType: ActiveWallet;
  }>();

  const { disconnect } = useDisconnect();
  const { address: evmAccountAddress, isConnected: isWagmiConnected } = wagmiAccount();

  return (
    <AnimateRoute className="mx-auto flex h-screen w-full max-w-7xl flex-col px-4 py-10">
      <div>
        <div className="sticky top-0 z-50 mb-6 flex min-h-14 items-start justify-between bg-clay-10 2xs:items-center">
          <BackButton
            className="gap-3 text-2xl font-semibold text-clay-900 hover:text-clay-900"
            onClick={() => navigate('/')}
          >
            Wallet
          </BackButton>
          <div className="group flex flex-col items-start gap-2 2xs:flex-row">
            {walletType === 'evm' && isWagmiConnected && evmAccountAddress && (
              <button
                className="flex translate-y-1 items-center justify-center gap-x-1 px-3 py-2 text-sm font-medium"
                onClick={() => {
                  disconnect();
                }}
              >
                <span>{getShortenedAddress(evmAccountAddress)}</span>
                <Icon
                  className="size-3.5 text-red-900/80 transition-colors group-hover:text-red-900"
                  name="unplug"
                />
              </button>
            )}

            <Select
              className="justify-between gap-1 rounded-lg border border-[#D9DCE4] bg-white p-3 text-base text-[#222833] hover:cursor-pointer hover:bg-white"
              onValueChange={(v) => {
                navigate(`/wallet/${v}/assets`, { replace: true });
              }}
              required
              titleValue={tokenToDisplay[walletType]}
              value={walletType}
            >
              <Select.Content>
                <Select.Item value="cosmos">{tokenToDisplay['cosmos']}</Select.Item>
                <Select.Item value="evm">{tokenToDisplay['evm']}</Select.Item>
              </Select.Content>
            </Select>
          </div>
        </div>
        {children}
      </div>
    </AnimateRoute>
  );
};

import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

import type { RepoFolder } from '../../mock';

import { RepoFile } from '../File';

type Props = {
  folder: RepoFolder;
  initialOpen?: boolean;
  onFileChange: (path: string) => void;
  path: string;
  selectedFilePath?: string;
};

export const Folder = ({ folder, initialOpen = false, onFileChange, path, selectedFilePath }: Props) => {
  const folderPath = path ? `${path}/${folder.name}` : folder.name;

  const [isOpen, setIsOpen] = useState(
    () => (selectedFilePath && selectedFilePath.startsWith(folderPath)) || initialOpen,
  );

  return (
    <>
      <div className="flex cursor-pointer items-center py-2" onClick={() => setIsOpen(!isOpen)}>
        <Icon
          className={twMerge('size-5 -rotate-90 duration-200', isOpen && 'rotate-0')}
          name="arrowDownSm"
          safeArea="0"
        />
        <Icon
          className={twMerge(
            'ml-0.5 size-4 stroke-clay-900 text-clay-300 duration-200',
            isOpen && 'text-transparent',
          )}
          name="folderOpen"
        />
        <div className="ml-2 line-clamp-1 flex-1 text-sm font-medium text-clay-500">{folder.name}</div>
      </div>
      {isOpen && (
        <div className="ml-2 border-l border-clay-20 pl-1">
          {folder.folders.map((folder) => (
            <Folder
              folder={folder}
              key={folder.name}
              onFileChange={onFileChange}
              path={folderPath}
              selectedFilePath={selectedFilePath}
            />
          ))}
          {folder.files.map((file) => (
            <RepoFile
              file={file}
              key={file.name}
              onFileChange={onFileChange}
              path={folderPath}
              selectedFilePath={selectedFilePath}
            />
          ))}
        </div>
      )}
    </>
  );
};

import { Link } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { categories } from 'entities/DAI/const';

export const DAICategories = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="text-lg font-medium">DAI Categories</div>

      <div className="flex flex-col gap-1.5">
        {categories.map((category) => {
          return (
            <Link
              className={twMerge(
                'cursor-pointer rounded-lg px-3 py-2 text-sm font-medium text-clay-500 transition-colors hover:bg-clay-10',
              )}
              key={category.value}
              to={`/dai/explore?category=${category.value}`}
            >
              {category.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

import { Navigate, Outlet } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { GalleryLayout as Layout } from 'app/ui/GalleryLayout';

interface Props {
  redirectRoute?: string;
}

export const AuthGuard = ({ redirectRoute = '/auth' }: Props) => {
  const { isPending, user } = useUser();

  if (user) {
    return (
      <Layout>
        <Outlet context={{ user }} />
      </Layout>
    );
  }

  if (isPending) {
    return null;
  }

  return <Navigate replace to={redirectRoute} />;
};

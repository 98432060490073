import { Link } from 'react-router-dom';

import { AlertTriangle } from 'lucide-react';
import { useAccount, useBalance } from 'wagmi';

import { ClaimUsdButton } from 'features/ClaimUsdButton';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { Spinner } from 'shared/ui/Spinner';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  onClaimSuccess?: () => void;
};

export const TradeHeader = ({ onClaimSuccess }: Props) => {
  const { address } = useAccount();
  const nativeBalanceQuery = useBalance({ address });
  const isZeroEth = nativeBalanceQuery.data?.value === 0n;

  if (!address) return null;

  return (
    <div className="my-2 flex flex-wrap items-center justify-between gap-x-2 gap-y-1.5">
      <div>
        <div className=" text-clay-400">
          Your EVM wallet: <span className="font-medium text-clay-800">{getShortenedAddress(address)}</span>
          <Tooltip
            content={
              <>
                You can get testnet ETH on
                <Link
                  className="ml-1.5 text-sm text-primary-800 transition hover:brightness-110"
                  target="_blank"
                  to="https://bridge-evm.test.nesa.ai/"
                >
                  Nesa EVM Bridge
                </Link>
              </>
            }
            delayDuration={100}
            side="top"
          >
            <span className="ml-1 inline-flex cursor-default items-center">
              ({isZeroEth && <AlertTriangle className="ml-0.5 mr-1 size-4 text-yellow-500" />}
              {nativeBalanceQuery.isPending ? (
                <Spinner className="size-4" />
              ) : nativeBalanceQuery.data ? (
                fromUnesToNes(
                  nativeBalanceQuery.data?.value.toString(),
                  nativeBalanceQuery.data?.decimals,
                ).toFixed(2)
              ) : (
                '--'
              )}{' '}
              ETH){' '}
            </span>
          </Tooltip>
        </div>
      </div>
      <ClaimUsdButton onClaimSuccess={onClaimSuccess} />
    </div>
  );
};

type NonNullableParams<T, K extends keyof T> = {
  [P in keyof Pick<T, K>]: NonNullable<T[P]>;
} & Omit<T, K>;

export function wrapQueryOptions<Params extends object, Key extends keyof Params>(
  params: Params,
  ...names: Key[]
) {
  const keys = names.length ? names : (Object.keys(params) as Key[]);
  return [params, { enabled: keys.every((key) => !!params[key]) }] as [
    NonNullableParams<Params, Key>,
    { enabled: boolean },
  ];
}

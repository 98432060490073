import { twMerge } from 'tailwind-merge';

type Props = {
  onTabChange: (tab: Props['tab']) => void;
  tab: 'MARKETCAP' | 'TVL';
};

export const Title = ({ onTabChange, tab }: Props) => {
  return (
    <div className="flex items-center justify-between">
      <p className="text-xl font-semibold">Top Performing DAI</p>
      <div className="relative flex h-[32px] items-center gap-1 rounded-[28px] bg-clay-20 px-[8px]">
        <div
          className={twMerge(
            'absolute top-[4px] h-[24px] rounded-[28px] bg-white transition-all duration-300 ease-in-out',
            tab === 'TVL' ? 'left-[4px]' : 'left-[calc(42%)]',
          )}
          style={{
            width: tab === 'TVL' ? 'calc(40%)' : 'calc(53%)',
          }}
        ></div>
        <div
          className={twMerge(
            'z-10 cursor-pointer rounded-[28px] px-1.5 py-1',
            tab === 'TVL' && 'text-primary-800',
          )}
          onClick={() => onTabChange('TVL')}
        >
          <span className={twMerge('text-sm font-bold')}>TVL</span>
        </div>
        <div
          className={twMerge(
            'z-10 cursor-pointer rounded-2xl px-1.5 py-1',
            tab === 'MARKETCAP' && 'text-primary-800',
          )}
          onClick={() => onTabChange('MARKETCAP')}
        >
          <span className={twMerge('text-sm font-bold')}>MCAP</span>
        </div>
      </div>
    </div>
  );
};

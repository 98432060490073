import type { Id } from 'react-toastify';

import type { FormEvent } from 'react';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Turnstile } from '@marsidev/react-turnstile';
import { AxiosError } from 'axios';
import { twJoin } from 'tailwind-merge';
import isEmail from 'validator/es/lib/isEmail';

import { useUser } from 'app/stores/user';
import { useSignUpMutation } from 'shared/api/user/useSignUpMutation';
import { PRIVACY_LINK, TERMS_LINK } from 'shared/const/links';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { toaster } from 'shared/ui/Toast';

import { AppleButton } from '../ui/AppleButton';
import { GoogleButton } from '../ui/GoogleButton';
import { Header } from '../ui/Header';
import { Or } from '../ui/Or';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
};

export const SignUp = () => {
  const navigate = useNavigate();

  const { updateUser } = useUser();

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [step, setStep] = useState<'form' | 'initial'>('initial');
  const [isAgree, setIsAgree] = useState(false);
  const [isAgreeError, setIsAgreeError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const { isPending: isPendingSignUp, mutateAsync } = useSignUpMutation();

  const toastId = useRef<Id | undefined>(undefined);

  const {
    formState: { errors, isValid },
    getValues,
    register,
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
    },
    mode: 'onChange',
  });

  const showErrorValidToast = () => {
    toastId.current = toaster.error(
      'Please fill in the required fields',
      {},
      { toastId: toastId.current || undefined, updateId: toastId.current || undefined },
    );
  };

  const handleSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid) {
      showErrorValidToast();
      return;
    }

    if (!isAgree) {
      showErrorValidToast();
      return setIsAgreeError(true);
    }

    if (!showCaptcha && !captchaToken) {
      return setShowCaptcha(true);
    }

    if (!captchaToken) {
      toastId.current = toaster.error(
        'You have to submit captcha first',
        {},
        { toastId: toastId.current || undefined, updateId: toastId.current || undefined },
      );
      return;
    }

    try {
      const { email, firstName, lastName, password, passwordConfirmation } = getValues();
      if (password !== passwordConfirmation) return;

      const data = await mutateAsync({ captchaToken, email, firstName, lastName, password });

      if (data.whitelist) {
        navigate('/whitelist');
        return;
      }

      await updateUser(data.user);

      navigate('/auth/phone', { state: { email } });
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  console.log(isPendingSignUp || !isValid || !captchaToken || !isAgree);
  console.log({ captchaToken, isAgree, isPendingSignUp, isValid });

  return (
    <>
      <Header description="No credit card required. Cancel anytime." title="Create your free account" />

      {step === 'initial' && (
        <>
          <div className="flex flex-col gap-3">
            <GoogleButton />
            <AppleButton />
          </div>
          <Or />
          <form
            className="flex flex-col gap-2"
            onSubmit={(e) => {
              e.preventDefault();

              if (isEmail(getValues().email)) {
                setStep('form');
              } else {
                setError('email', { message: 'Invalid email' });
              }
            }}
          >
            <Input
              errorSpacing
              {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
              classNameInput="!text-center"
              error={errors.email?.message}
              placeholder="Enter your email address"
            />
            <Button type="submit">Continue</Button>
          </form>
          <div className="mt-3 text-center text-xs font-normal text-clay-500">
            By continuing, you agree to Nesa{'’'}s{' '}
            <a
              className="underline duration-200 hover:text-primary-900"
              href={TERMS_LINK}
              rel="noreferrer"
              target="_blank"
            >
              Terms
              <br />
              of Service
            </a>{' '}
            and{' '}
            <a
              className="underline duration-200 hover:text-primary-900"
              href={PRIVACY_LINK}
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </div>
          <div className="mt-5 text-center text-sm font-semibold text-clay-900">
            Already have an account?{' '}
            <Link
              className="cursor-pointer text-primary-800 duration-200 hover:text-primary-900"
              to="/auth/signin"
            >
              Sign In
            </Link>
          </div>
        </>
      )}

      {step === 'form' && (
        <>
          <form className="flex flex-col gap-1" onSubmit={handleSubmitForm}>
            <div className="flex gap-3">
              <Input
                className="flex-1"
                errorSpacing
                {...register('firstName', {
                  validate: (val) => (val.trim().length === 0 ? 'Required field' : true),
                })}
                disabled={isPendingSignUp}
                error={errors.firstName?.message}
                label={
                  <span>
                    First Name <span className="text-red-900">*</span>
                  </span>
                }
                placeholder="Enter your first name"
              />
              <Input
                className="flex-1"
                errorSpacing
                {...register('lastName', {
                  validate: (val) => (val.trim().length === 0 ? 'Required field' : true),
                })}
                disabled={isPendingSignUp}
                error={errors.lastName?.message}
                label={
                  <span>
                    Last Name <span className="text-red-900">*</span>
                  </span>
                }
                placeholder="Enter your last name"
              />
            </div>
            <Input
              errorSpacing
              {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
              disabled={isPendingSignUp}
              error={errors.email?.message}
              label={
                <span>
                  Email <span className="text-red-900">*</span>
                </span>
              }
              placeholder="Enter your email address"
            />
            <div className="flex gap-3">
              <Input
                {...register('password', {
                  validate: (val) => {
                    return val.length < 6 ? 'Min 6 characters' : true;
                  },
                })}
                disabled={isPendingSignUp}
                error={errors.password?.message}
                errorSpacing
                label={
                  <span>
                    Password <span className="text-red-900">*</span>
                  </span>
                }
                placeholder="Enter your password"
                type="password"
              />
              <Input
                {...register('passwordConfirmation', {
                  validate: (value, formValues) =>
                    formValues.password !== value ? 'Passwords do not match' : true,
                })}
                disabled={isPendingSignUp}
                error={errors.passwordConfirmation?.message}
                errorSpacing
                label={
                  <span>
                    Re-type Password <span className="text-red-900">*</span>
                  </span>
                }
                placeholder="Confirm your password"
                type="password"
              />
            </div>
            <Checkbox.CheckboxWrapper className="mb-4">
              <Checkbox.Control
                checked={isAgree}
                isError={isAgreeError}
                onChange={(e) => setIsAgree(e.target.checked)}
              />
              <Checkbox.CheckboxLabel>
                I have read, understand and agree to the{' '}
                <a
                  className="underline duration-200 hover:text-primary-800"
                  href={TERMS_LINK}
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms and Conditions
                </a>{' '}
                and the{' '}
                <a
                  className="underline duration-200 hover:text-primary-900"
                  href={PRIVACY_LINK}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>{' '}
                that govern the Nesa platform.
              </Checkbox.CheckboxLabel>
            </Checkbox.CheckboxWrapper>

            <div className={twJoin(showCaptcha ? 'flex justify-center' : 'hidden')}>
              <Turnstile
                onSuccess={setCaptchaToken}
                options={{ appearance: 'interaction-only', theme: 'light' }}
                siteKey={import.meta.env.VITE_CLOUDFLARE_SITE_KEY}
                style={{ alignSelf: 'center', maxWidth: '100%', minWidth: 'fit-content' }}
              />
            </div>

            <Button
              className="mt-4"
              disabled={isPendingSignUp || !isValid || !isAgree}
              isLoading={isPendingSignUp}
              type="submit"
            >
              Continue
            </Button>
          </form>
        </>
      )}
    </>
  );
};

import { useEffect, useState } from 'react';

type Props = { wait?: number };

export const DelayedBorder = ({ wait = 500 }: Props) => {
  const [visible, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setVisibility(true);
    }, wait);
    return () => clearTimeout(id);
  }, [wait]);

  return visible && <div className="animate-border absolute inset-0 overflow-hidden rounded-lg border-2" />;
};

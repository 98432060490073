import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Plus, Trash2 } from 'lucide-react';

import { categories } from 'entities/DAI/const';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { TextArea } from 'shared/ui/TextArea';

import type { FormValues, SocialLinkType, TeamMember } from '../types';

import { ExistingMembersModal } from './ExistingMembersModal';
import { NewMemberModal } from './NewMemberModal';
import { StepHeader } from './StepHeader';

const urlRegex = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;

export const socialLinkTypes: { label: string; value: SocialLinkType }[] = [
  { label: 'Discord', value: 'discord' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Whitepaper', value: 'whitepaper' },
  { label: 'Telegram', value: 'telegram' },
  { label: 'Google Scholar', value: 'google-scholar' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Other', value: 'other' },
];

export const Description = () => {
  const [isNewMemberOpen, setIsNewMemberOpen] = useState(false);
  const [isExistingMemberOpen, setIsExistingMemberOpen] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState<TeamMember>();

  const {
    control,
    formState: { errors, touchedFields },
    register,
  } = useFormContext<FormValues>();
  const { field: categoryField } = useController({ control, name: 'category' });
  const { field: socialLinksField } = useController({ control, name: 'socialLinks' });
  const { field: membersField } = useController({ control, name: 'members' });

  console.log('membersField');

  return (
    <div className="m-auto flex w-full max-w-3xl flex-col rounded-2xl border border-clay-20  bg-white shadow-lg ">
      <StepHeader stepNumber={2} title="About the Project" />

      <div className="flex flex-col gap-3 border-b border-clay-20 p-4">
        <Select
          className="w-full border border-clay-20 bg-white pl-3"
          label={
            <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
              Project Category <span className="text-red-900">*</span>
            </span>
          }
          onValueChange={categoryField.onChange}
          placeholder="Select Project Category"
          value={categoryField.value}
        >
          <Select.Content>
            {categories.map((item) => {
              return (
                <Select.Item key={item.value} value={item.value}>
                  {item.label}
                </Select.Item>
              );
            })}
          </Select.Content>
        </Select>

        <div className="relative">
          <TextArea
            {...register('description', { required: true })}
            className="pb-1.5"
            innerClassName="text-sm"
            label={
              <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
                Long Description <span className="text-red-900">*</span>
              </span>
            }
            maxLength={3000}
          />

          <div className="-top-3 text-xs font-light text-clay-380">
            This is a long description for your DAI.
          </div>
        </div>

        <Input
          {...register('productLink', {
            required: true,
            validate: (val) => {
              console.log('val', val);
              return !val ? 'Field is required' : urlRegex.test(val) ? undefined : 'Link should be valid';
            },
          })}
          error={errors.productLink?.message}
          errorSpacing
          label={
            <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
              Product Link (beta) <span className="text-red-900">*</span>
            </span>
          }
          startSlot={
            <div className="relative -left-2 flex h-full grow items-center justify-center rounded-l-lg bg-clay-10 pl-2 pr-3 text-clay-350">
              https://
            </div>
          }
          startSlotClassName="m-0 h-full flex items-center justify-center"
        />
      </div>

      <div className="border-b border-clay-20 p-4">
        <h2 className="mb-4 text-lg font-semibold">Social Links</h2>

        <div className="mb-3 flex flex-col gap-2">
          {socialLinksField.value.map((value, idx) => {
            return (
              <div className="flex items-end gap-4" key={idx}>
                <Input
                  error={
                    touchedFields.socialLinks && (urlRegex.test(value.href) ? '' : 'Link should be valid')
                  }
                  errorSpacing
                  label={
                    <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
                      Product Link (beta) <span className="text-red-900">*</span>
                    </span>
                  }
                  onChange={(e) => {
                    socialLinksField.onChange(
                      socialLinksField.value.map((item, i) =>
                        i === idx ? { ...item, href: e.target.value || '' } : item,
                      ),
                    );
                  }}
                  startSlot={
                    <div className="relative -left-2 flex h-full grow items-center justify-center rounded-l-lg bg-clay-10 pl-2 pr-3 text-clay-350">
                      https://
                    </div>
                  }
                  startSlotClassName="m-0 h-full flex items-center justify-center"
                  value={value.href || ''}
                />

                <Select
                  className="min-w-32 border border-clay-20 bg-white pl-3"
                  onValueChange={(type) => {
                    socialLinksField.onChange(
                      socialLinksField.value.map((item, i) => (i === idx ? { ...item, type: type } : item)),
                    );
                  }}
                  value={value.type}
                  wrapperClassName="pb-4"
                >
                  <Select.Content>
                    {socialLinkTypes.map(({ label, value }) => {
                      return (
                        <Select.Item key={value} value={value}>
                          {label}
                        </Select.Item>
                      );
                    })}
                  </Select.Content>
                </Select>

                {idx > 0 && (
                  <Button
                    className="group mb-4 flex h-10 items-center justify-center px-3"
                    color="red"
                    onClick={() => {
                      socialLinksField.onChange(socialLinksField.value.filter((_, i) => i !== idx));
                    }}
                  >
                    <Trash2 className="size-4 " />
                  </Button>
                )}
              </div>
            );
          })}
        </div>

        <Button
          color="secondary"
          onClick={() => {
            socialLinksField.onChange([...socialLinksField.value, { href: '', type: 'whitepaper' }]);
          }}
          size="extra-small"
        >
          <Plus className="size-4" /> Add
        </Button>
      </div>

      <div className="p-4">
        <h2 className="mb-4 text-lg font-semibold">
          Team Members <span className="text-red-900">*</span>
        </h2>

        {membersField.value.map((value, idx) => {
          return (
            <div className="flex items-center justify-between gap-3" key={idx}>
              <div className="flex items-center gap-3">
                <div className="size-8 overflow-hidden rounded-full bg-clay-20">
                  {value.avatar && <img className="size-full object-cover" src={value.avatar} />}
                </div>

                <div className="text-sm">{value.name}</div>

                <div className="text-xs font-light italic text-clay-400">{value.title}</div>
              </div>

              <div className="flex items-center justify-end gap-2">
                <Button
                  className="group mb-4 flex h-7 items-center justify-center px-3"
                  color="white"
                  onClick={() => {
                    setMemberToEdit?.(value);

                    setIsNewMemberOpen(true);
                    // membersField.onChange(membersField.value.filter((_, i) => i !== idx));
                  }}
                  size="extra-small"
                >
                  Edit
                </Button>
                <Button
                  className="group mb-4 flex h-7 items-center justify-center px-3"
                  color="red"
                  onClick={() => {
                    membersField.onChange(membersField.value.filter((_, i) => i !== idx));
                  }}
                  size="extra-small"
                >
                  Unlink
                </Button>
              </div>
            </div>
          );
        })}

        <div className="mt-4 flex items-center gap-3">
          <Button
            color="secondary"
            onClick={() => {
              setMemberToEdit(undefined);
              setIsNewMemberOpen(true);
              // socialLinksField.onChange([...socialLinksField.value, { href: '', type: 'whitepaper' }]);
            }}
            size="extra-small"
          >
            <Plus className="size-4" /> Create New
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setIsExistingMemberOpen(true);
              // socialLinksField.onChange([...socialLinksField.value, { href: '', type: 'whitepaper' }]);
            }}
            size="extra-small"
          >
            <Plus className="size-4" /> Add Existing Member
          </Button>
        </div>
      </div>

      {isNewMemberOpen && (
        <NewMemberModal
          isOpen={isNewMemberOpen}
          member={memberToEdit}
          onOpenChange={setIsNewMemberOpen}
          onSuccess={(member, isEdited) => {
            if (!isEdited) {
              setIsExistingMemberOpen(true);

              return;
            }

            setMemberToEdit(undefined);
            membersField.onChange(membersField.value.map((m) => (m.id === member?.id ? member : m)));
          }}
        />
      )}
      <ExistingMembersModal
        initialMemberIds={[]}
        isOpen={isExistingMemberOpen}
        onCreate={() => setIsNewMemberOpen(true)}
        onOpenChange={setIsExistingMemberOpen}
        onSelect={(selected) => {
          membersField.onChange([
            ...membersField.value,
            ...selected.filter((item) => {
              const isIncluded = membersField.value.find(({ id }) => id === item.id);

              return !isIncluded;
            }),
          ]);

          setIsExistingMemberOpen(false);
        }}
      />
    </div>
  );
};

import type { Descendant } from 'slate';

import { useMemo, useState } from 'react';

import { queryClient } from 'app/queryClient';
import { useUploadImageMutation } from 'shared/api/ipfs/useUploadImageMutation';
import { useCreatePostMutation } from 'shared/api/posts';
import { postsKeys } from 'shared/api/posts/queryKeys';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { TextEditor } from 'shared/ui/TextEditor';
import { serialize } from 'shared/ui/TextEditor/libs';
import { toaster } from 'shared/ui/Toast';
type Props = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
};

export const CreatePostModal = ({ isOpen, onOpenChange }: Props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<Descendant[]>([
    { children: [{ text: '' }], type: 'paragraph' },
    { children: [{ text: '' }], type: 'paragraph' },
    { children: [{ text: '' }], type: 'paragraph' },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: createPost } = useCreatePostMutation();
  const { mutateAsync: uploadImage } = useUploadImageMutation();

  const isValid = Boolean(title.trim() && serialize(content).trim());

  const handleCreatePost = async () => {
    try {
      setIsLoading(true);
      const links = await Promise.allSettled(files.map((file) => uploadImage({ file })));
      const filtered = links
        .filter(
          (el): el is PromiseFulfilledResult<{ cid: string; name?: string }> => el.status === 'fulfilled',
        )
        .map((el) => el.value.cid);

      await createPost({
        attachments: filtered,
        content: JSON.stringify(content),
        title,
      });
      setContent([{ children: [{ text: '' }], type: 'paragraph' }]);
      setTitle('');
      setFiles([]);
      onOpenChange(false);
      setIsLoading(false);
      queryClient.invalidateQueries({ queryKey: postsKeys.postList() });
      toaster.success('The post has been published');
    } catch {
      setIsLoading(false);
      toaster.error('Something went wrong');
    }
  };

  const images = useMemo(() => {
    return files.map((file) => ({ file, url: URL.createObjectURL(file) }));
  }, [files]);

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-4xl" innerClassName="p-9">
        <h2 className="mb-7 border-b border-clay-20 pb-5 text-3xl font-semibold text-clay-900">New Post</h2>

        <div className="flex flex-col gap-4">
          <div className="w-full max-w-md">
            <Input
              label={
                <span>
                  Post Title <span className="text-red-900">*</span>
                </span>
              }
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <TextEditor
            onChange={setContent}
            onFileChange={(files) => setFiles((prev) => [...prev, ...files])}
            value={content}
          />

          {!!images.length && (
            <div className="flex gap-2 overflow-x-auto">
              {images.map((file) => (
                <div className="relative" key={file.url}>
                  <img alt="" className="h-24 min-h-24 max-w-none cursor-pointer rounded-lg" src={file.url} />
                  <div className="group absolute inset-0 flex items-center justify-center rounded-lg bg-transparent duration-200 hover:bg-clay-900/50">
                    <button
                      className="opacity-0 duration-200 group-hover:opacity-100"
                      onClick={() => setFiles((prev) => prev.filter((el) => file.file !== el))}
                    >
                      <Icon className=" size-7 text-white" name="close" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <footer className="mt-7 flex justify-between">
          <Button color="secondary" disabled={isLoading} onClick={() => onOpenChange(false)}>
            Discard
          </Button>
          <Button disabled={isLoading || !isValid} isLoading={isLoading} onClick={() => handleCreatePost()}>
            Create Post
          </Button>
        </footer>
      </Modal.Content>
    </Modal>
  );
};

import { evmCoins } from 'shared/config/coins';

export const parseSwapParameters = ({
  calldata,
  chainId = 111888,
  tokenInAddress,
}: {
  calldata: string;
  chainId?: number;
  tokenInAddress: string;
}) => {
  const start = 2;
  const soAddress = 64;
  const so24 = 64;
  const so160 = 64;
  const so256 = 64;
  const e1 = start + soAddress;
  const e2 = e1 + soAddress;
  const e3 = e2 + so24;
  const e4 = e3 + soAddress;
  const e5 = e4 + so256;
  const e6 = e5 + so256;
  const e7 = e6 + so160;
  const parameters = calldata.slice(10);

  const amountIn = `0x` + parameters.slice(e5, e6 - 2);

  if (tokenInAddress.toLowerCase() === evmCoins.nesaUSD[chainId].address.toLowerCase()) {
    return { amountIn, amountOutMinimum: `0x` + parameters.slice(e4, e5 - 2) };
  } else {
    return { amountIn, amountOutMinimum: `0x` + parameters.slice(e6, e7 - 2) };
  }
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type Params = {
  commentId: string;
  reason: string;
};

type Response = {
  data: unknown;
};

export const useReportCommentMutation = (options: UseMutationOptions<Response, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async ({ commentId, ...params }: Params) => {
      const { data } = await axiosInstance.post<Response>(`/dai-comments/${commentId}/report`, params);

      return data;
    },

    ...options,
  });
};

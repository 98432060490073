import { AxiosError } from 'axios';

import { toaster } from 'shared/ui/Toast';

export const catchError = (e: unknown, defaultMessage = 'Something went wrong') => {
  console.error(e);
  if (e instanceof AxiosError) {
    const axiosError = e.response?.data?.message;
    toaster.error(axiosError || defaultMessage);
  } else {
    toaster.error(defaultMessage);
  }
};

import type { ComponentProps, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { formatCurrency } from 'shared/helpers/formatCurrency';
import { NumericInput } from 'shared/ui/NumericInput';

export type InputProps = {
  bottomLabel?: string;
  bottomValue?: string;
  className?: string;
  decimals: number;
  disabled?: boolean;
  errorMessage?: string;
  label?: ReactNode;
  name?: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onMaxClick?: () => void;
  ref?: ComponentProps<'input'>['ref'];
  slot?: ReactNode;
  usdValue?: string;
  value: string;
};

export const Input = (props: InputProps) => {
  const {
    bottomLabel = 'Balance',
    bottomValue,
    className,
    decimals,
    disabled,
    errorMessage,
    label,
    name,
    onBlur,
    onChange,
    onMaxClick,
    ref,
    slot,
    usdValue,
    value,
  } = props;

  const formattedUSD = usdValue ? formatCurrency(usdValue, { decimalScale: 2, prefix: '$' }) : '';

  return (
    <>
      <div className={twMerge('rounded-lg border-none px-5 py-3', ' bg-clay-10', className)}>
        {label && <div className={twMerge('mb-2 text-sm text-clay-350')}>{label}</div>}

        <div className="flex items-center justify-between gap-x-1.5">
          {slot}

          <NumericInput
            className={twMerge('w-full text-end text-clay-900 outline-none', errorMessage && 'text-red-900')}
            decimalScale={decimals}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            onValueChange={(values) => {
              onChange(values.value);
            }}
            placeholder="0"
            ref={ref}
            value={value}
            variant="currency"
          />
        </div>
        {(formattedUSD || bottomValue || onMaxClick) && (
          <div className="mt-2 flex items-center">
            <span className="mr-auto inline-flex text-sm text-clay-800">{formattedUSD}</span>

            {bottomValue && (
              <span className="mr-2 inline-flex text-sm text-clay-800">
                {bottomLabel}: {formatCurrency(bottomValue)}
              </span>
            )}
            {onMaxClick && (
              <button
                className="outline-hidden cursor-pointer text-sm text-[currentColor] transition-colors hover:brightness-125"
                onClick={onMaxClick}
                type="button"
              >
                {`[ Max ]`}
              </button>
            )}
          </div>
        )}
      </div>
      {errorMessage && <span className="mt-1 text-xs text-red-900">{errorMessage}</span>}
    </>
  );
};

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

import type { ChartData } from './Chart';

import { Chart } from './Chart';

type Props = {
  changePrc: number;
  chartData: ChartData[];
  title: string;
  total: string;
};

export const NetworkStatsRow = ({ changePrc, chartData, title, total }: Props) => {
  const roundedChange =
    Math.round(changePrc) < 1
      ? changePrc.toLocaleString(undefined, { maximumFractionDigits: 2 })
      : Math.round(changePrc);

  return (
    <>
      <div className="flex items-center gap-2">
        <p className="text-lg font-medium text-clay-380">{title}</p>
        <div className="flex items-center gap-1">
          <div
            className={twMerge(
              'flex size-4 items-center justify-center rounded-full bg-clay-800/20 text-clay-800',
              changePrc >= 0 && 'bg-green-600/20 text-green-600',
              changePrc < 0 && 'bg-red-900/20 text-red-900',
            )}
          >
            <Icon className={twMerge('size-3', changePrc >= 0 && 'rotate-180')} name="arrowDown" />
          </div>
          <span
            className={twMerge(
              'text-clay-800',
              changePrc >= 0 && 'text-green-600',
              changePrc < 0 && 'text-red-900',
            )}
          >
            {roundedChange}%
          </span>
        </div>
      </div>
      <Chart data={chartData} total={total} />
    </>
  );
};

import type { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { Icon } from 'shared/ui/Icon';

export type SidebarMenuItem = {
  icon: IconName;
  isActive?: boolean;
  isStrokeIcon?: boolean;
  label: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  to: string;
};

type Props = {
  menuItems: SidebarMenuItem[];
};

export const Sidebar = ({ menuItems }: Props) => {
  return (
    <aside className="flex w-full flex-row gap-2 overflow-x-auto lg:max-w-48 lg:flex-col">
      {menuItems.map((el, i) => (
        <NavLink
          className={({ isActive }) => {
            return twMerge(
              'flex items-center gap-2 whitespace-nowrap rounded-lg px-3 py-2 duration-200 hover:bg-clay-20',
              ((typeof el.isActive === 'boolean' && el.isActive) ||
                (isActive && typeof el.isActive !== 'boolean')) &&
                'bg-clay-20 hover:bg-clay-40',
            );
          }}
          key={i}
          onClick={el.onClick}
          to={el.to}
        >
          <Icon
            className={twMerge(
              'size-4',
              el.isStrokeIcon ? 'stroke-clay-350 text-transparent' : 'text-clay-350',
            )}
            name={el.icon}
            safeArea="0"
          />
          <span className="text-sm text-clay-900">{el.label}</span>
        </NavLink>
      ))}
    </aside>
  );
};

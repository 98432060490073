import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiStatsKeys } from './queryKeys';

export type TopTotalByMCAP = {
  chartData: { count: number; date: string }[];
  diff: number;
  total: number;
};

export const useGetTopTotalByMCAPStatsQuery = (options: UseQueryOptions<TopTotalByMCAP> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<TopTotalByMCAP>(`/dai-stats/top-mcap-total`, {
        signal,
        timeout: 20000,
      });

      return data;
    },
    queryKey: daiStatsKeys.getTopTotalByMCAPStats(),
    ...options,
  });
};

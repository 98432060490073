import type { PeriodType } from 'pages/Wallet/types';
import type { ReqStatItem } from 'shared/api/models/useGetModelStatisticsByPeriod';

// import { Chart } from 'pages/Wallet/ui/Chart';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  data?: ReqStatItem[];
  isLoading: boolean;
  period: PeriodType;
};
export const ChartContent = ({ data = [], isLoading }: Props) => {
  const hasChatData = (data.length || 0) > 0;

  // const dataToPaint = data.map(({ count, date }) => ({ countTransactions: count, date }));

  return (
    <div className="relative -ml-1 -mr-2 h-60">
      {(isLoading || !hasChatData) && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-clay-20 px-5 py-2 text-sm font-semibold text-clay-500">
          {isLoading ? <Spinner className="size-5 text-primary-1000" /> : 'No Data'}
        </div>
      )}
      {/* <Chart data={dataToPaint} isEmptyData={!hasChatData} periodType={period} /> */}
    </div>
  );
};

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon } from 'shared/ui/Icon';

type Props = { iconClassName?: string } & ClassName;
export const LockLine = ({ className, iconClassName }: Props) => {
  return (
    <div className={twMerge('flex items-center gap-1', className)}>
      <div className="h-px w-7 bg-clay-900"></div>
      <div className="flex size-8 items-center justify-center rounded-full bg-clay-900 p-1">
        <Icon className={twMerge('size-4 text-clay-400', iconClassName)} name="lock" />
      </div>
      <div className="h-px w-7 bg-clay-900"></div>
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from '../dai/types';

import { daiStatsKeys } from './queryKeys';

export type TopDAIsByMCAP = {
  dai: DAI;
  diff: number;
  total: number;
};

export const useGetTopDAIsByMCAPStatsQuery = (options: UseQueryOptions<TopDAIsByMCAP[]> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<TopDAIsByMCAP[]>(`/dai-stats/top-mcap-dais`, {
        signal,
        timeout: 20000,
      });

      return data;
    },
    queryKey: daiStatsKeys.getTopDAIsByMCAPStats(),
    ...options,
  });
};

export type TableSetting = { title: string; type: 'active' | 'hidden'; value: string };

export const tableFilters: Record<
  string,
  {
    name: string;
    settingList: TableSetting[];
    sortList: { title: string; value: string; withBorder?: boolean }[];
  }
> = {
  dai: {
    name: 'dai',
    settingList: [
      // { title: 'Project Name', type: 'active', value: 'projectName' },
      { title: 'Project Category', type: 'active', value: 'projectCategory' },
      { title: 'TVL', type: 'active', value: 'tvl' },
      { title: 'Starkers', type: 'active', value: 'token' },
      { title: 'Airdrop', type: 'active', value: 'cover' },
    ],
    sortList: [
      { title: 'Newest', value: 'newest' },
      { title: 'Oldest', value: 'oldest' },
    ],
  },
  datasets: {
    name: 'datasets',
    settingList: [
      // { title: 'Dataset', type: 'active', value: 'datasetId' },
      { title: 'Downloads', type: 'active', value: 'downloads' },
      { title: 'Rows', type: 'active', value: 'nRows' },
      { title: 'Likes', type: 'active', value: 'likes' },
      { title: 'Size', type: 'active', value: 'nBytesFiles' },
    ],
    sortList: [
      { title: 'Trending', value: 'trending' },
      { title: 'Most likes', value: 'likes' },
      { title: 'Recently created', value: 'created' },
      { title: 'Recently updated', value: 'modified' },
      { title: 'Most rows', value: 'most_rows' },
      { title: 'Least rows', value: 'least_rows' },
    ],
  },
  models: {
    name: 'models',
    settingList: [
      // { title: 'Model', type: 'active', value: 'name' },
      { title: 'Category', type: 'active', value: 'type' },
      { title: 'Ranking', type: 'active', value: 'ranking' },
      { title: 'Likes', type: 'active', value: 'likes' },
      { title: 'Downloads', type: 'active', value: 'downloads' },
      { title: 'Price', type: 'active', value: 'price' },
      { title: 'Latency', type: 'active', value: 'latency' },
    ],
    sortList: [
      { title: 'Name Ascending', value: 'name-asc' },
      { title: 'Name Decending', value: 'name-desc', withBorder: true },
      { title: 'Most Likes', value: 'likes-desc' },
      { title: 'Less Likes', value: 'likes-asc', withBorder: true },
      { title: 'Most Rated', value: 'ranking-desc' },
      { title: 'Less Rated', value: 'ranking-asc', withBorder: true },
      { title: 'Most Downloaded', value: 'downloads-desc' },
      { title: 'Less Downloaded', value: 'downloads-asc', withBorder: true },
      { title: 'Price Highest', value: 'price-desc' },
      { title: 'Price Lowest', value: 'price-asc' },
    ],
  },

  stakers: {
    name: 'stakers',
    settingList: [
      { title: 'Category', type: 'active', value: 'type' },
      { title: 'Projects', type: 'active', value: 'projects' },
      { title: 'TVL', type: 'active', value: 'TVL' },
      { title: 'stakes', type: 'active', value: 'stakes' },
    ],
    sortList: [
      { title: 'Total Amount', value: 'totalCount' },
      { title: 'Stakes Count', value: 'stakesLength' },
    ],
  },
};

import { BigNumber } from 'bignumber.js';

type Options = {
  decimalScale?: number;
  prefix?: string;
  suffix?: string;
};

export const formatCurrency = (value: number | string, options?: Partial<Options>) => {
  let formatted = new BigNumber(value).toPrecision();
  const [int, decimals] = formatted.split('.');
  formatted = `${int}${decimals ? `.${decimals.slice(0, options?.decimalScale ?? 2)}` : ''}`;

  // toFormat(
  //   options?.decimalScale ?? 2,
  //   BigNumber.ROUND_FLOOR
  // );

  if (options?.prefix) {
    formatted = `${options.prefix}${formatted}`;
  }

  if (options?.suffix) {
    formatted = `${formatted}${options.suffix}`;
  }

  return formatted;
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { modelsKeys } from './queryKeys';

type Response = {
  modelLimit: number;
  modelLimitLeft: number;
  modelLimitUsed: number;
  totalLimit: number;
  totalLimitLeft: number;
  totalLimitUsed: number;
  updateLimitDate: string;
};

export const useModelLimitsQuery = (params: { modelId: string }, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/models/overview/limits`, {
        params,
        signal,
      });

      return data;
    },
    queryKey: modelsKeys.limits(params),
    staleTime: 0,
    ...options,
  });
};

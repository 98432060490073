import type { ComponentProps } from 'react';
import { useState } from 'react';

import { useMeasure } from '@uidotdev/usehooks';
import { twMerge } from 'tailwind-merge';

import { useProductPrices } from 'app/stores/prices';
import { useUser } from 'app/stores/user';
import { UpgradeToProModal, upgradeToProBulletList } from 'features/UpgradeToProModal';
import { NESA_PRO_PRICE_BY_MONTH } from 'shared/const';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';

import { accordions, tables } from './const';
import { Accordion, AccordionProvider } from './ui/accordion';
import * as C from './ui/card-ui';
import faqBgSrc from './ui/faq-bg.svg';
import { Table } from './ui/table-ui';

const items = [
  'Access to all Nesa Playground models',
  'Ability to upload custom models',
  'Testnet faucet access',
];

// const itemsPro = [
//   'Earn 5x the Nesa Playground points',
//   'Unlimited* inference credits',
//   'Unrestricted custom model pricing',
//   'Spotlight your custom models',
//   'Early access to new models',
//   'Nesa Pro Badge',
// ];

// const itemsEnterprise = [
//   'SSO and SAML support',
//   'Hybrid + intranet deployment strategies',
//   'Custom inference geolocation',
//   'Advanced logging dashboard',
//   'Granular access control',
//   'Priority Slack/Email support',
// ];

export const PricingPage = () => {
  // const [loadingStripe, setLoadingStripe] = useState<ProductStripeType | null>(null);

  const { data: prices } = useProductPrices();
  const priceByMonth = prices?.['nesa.pro.month'];

  // const paymentSubscription = useProductStripePayment('/pricing');

  // const upgradeToProByStripe = async (productType: ProductStripeType) => {
  //   setLoadingStripe(productType);

  //   try {
  //     await paymentSubscription(productType);
  //   } catch {
  //     setLoadingStripe(null);
  //   }
  // };

  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);

  const [faqBlockRef, { height: faqBlockHeight }] = useMeasure<HTMLDivElement>();

  const { proVersion } = useUser();

  return (
    <>
      <div
        className="absolute inset-0 -top-4 -z-50"
        style={{
          backdropFilter: `blur(148px)`,
          background: `radial-gradient(73.33% 73.33% at 50% 25%, #DDD3F0 0%, rgba(247, 247, 247, 0) 28%)`,
        }}
      ></div>
      <AnimateRoute className="relative">
        <div className="mx-auto w-full max-w-7xl px-4 pt-8">
          <div className="mb-12 flex flex-col items-center gap-4 text-center">
            <div className="rounded-full bg-white px-3.5 py-2 text-sm text-clay-900 shadow-checkbox">
              Pricing
            </div>
            <TitlePage>Empowering scalable and secure AI solutions.</TitlePage>
            <div className="max-w-xl text-lg/6 text-clay-500">
              Elevate your AI workloads with secure, streamlined execution and access a multitude of models,
              all in one place.
            </div>
          </div>

          <div className="mx-auto grid w-full max-w-[53.5rem] grid-cols-1 gap-4 md:grid-cols-2">
            <C.Card>
              <C.CardHeader>
                <C.CardTitle>Basic</C.CardTitle>
                <C.CardDescription>Limited Access</C.CardDescription>
              </C.CardHeader>
              <C.CardItemWrapper>
                {items.map((el, i) => (
                  <C.CardItem key={i}>{el}</C.CardItem>
                ))}
              </C.CardItemWrapper>
              <C.CardFooter>
                <div>
                  <C.CardPriceDescription>Forever</C.CardPriceDescription>
                  <C.CardPrice>Free</C.CardPrice>
                </div>
                <Button color="secondary" disabled>
                  {proVersion?.isPro ? 'Upgraded' : 'Currently Active'}
                </Button>
              </C.CardFooter>
            </C.Card>

            <C.Card className="shadow-card-large">
              <C.CardHeader>
                <div className="flex items-center gap-2">
                  <C.CardTitle>Nesa</C.CardTitle>
                  <div className=" rounded-lg bg-blue-600 px-3 py-2 text-xl/none font-bold text-white">
                    PRO
                  </div>
                </div>
                <C.CardDescription>Unlimited Access</C.CardDescription>
              </C.CardHeader>
              <C.CardItemWrapper>
                {upgradeToProBulletList.map((el, i) => (
                  <C.CardItem className={twMerge(el.bold && 'font-bold')} key={i}>
                    {el.text}
                  </C.CardItem>
                ))}
              </C.CardItemWrapper>
              <C.CardFooter>
                <div>
                  <C.CardPriceDescription>Upgrade for</C.CardPriceDescription>
                  <C.CardPrice>
                    $
                    {priceByMonth?.unit_amount
                      ? `${priceByMonth.unit_amount / 100}`
                      : NESA_PRO_PRICE_BY_MONTH}{' '}
                    <span className=" text-base text-clay-500">/Month</span>
                  </C.CardPrice>
                </div>
                <Button color="blue" disabled={proVersion?.isPro} onClick={() => setIsUpgradeOpen(true)}>
                  {proVersion?.isPro ? 'Currently Active' : 'Upgrade'}
                </Button>
              </C.CardFooter>
            </C.Card>

            {/* <C.Card>
          <C.CardHeader>
            <C.CardTitle>Nesa for Enterprise</C.CardTitle>
            <C.CardDescription>Accelerate your AI Roadmap</C.CardDescription>
          </C.CardHeader>
          <C.CardItemWrapper>
            {itemsEnterprise.map((el, i) => (
              <C.CardItem key={i}>{el}</C.CardItem>
            ))}
          </C.CardItemWrapper>
          <C.CardFooter>
            <div>
              <C.CardPriceDescription>Starting at</C.CardPriceDescription>
              <C.CardPrice>
                ${NESA_ENTERPRISE_PRICE_BY_MONTH} <span className=" text-base text-clay-500">/Month</span>
              </C.CardPrice>
            </div>
            <Button
              isLoading={loadingStripe === 'nesa.enterprise.year'}
              onClick={() => upgradeToProByStripe('nesa.enterprise.year')}
            >
              Start Now
            </Button>
          </C.CardFooter>
        </C.Card> */}
          </div>

          <div className="my-12 flex flex-col items-center gap-4 text-center">
            <div className="rounded-full bg-white px-3.5 py-2 text-sm text-clay-900 shadow-checkbox">
              API Pricing
            </div>
            <TitlePage>Simple and flexible. Only pay for what you use</TitlePage>
            <Button as="a" href="https://enterprise.nesa.ai" rel="noreferrer" size="medium" target="_blank">
              Contact Sales
            </Button>
          </div>

          <div className="grid grid-cols-3 gap-4">
            {tables.map((el, i) => (
              <Table
                category={el.category}
                description={el.description}
                key={i}
                rows={el.rows}
                title={el.title}
              />
            ))}
          </div>

          <div
            className="absolute inset-x-0 bottom-0 rotate-180 overflow-hidden"
            style={{
              background: `radial-gradient(223.2% 168.24% at 54.17% 0%, rgba(186, 154, 255, 0.3) 0%, rgba(183, 216, 255, 0.5) 31%, #F7F7F7 57.24%)`,

              height: `${faqBlockHeight}px`,
            }}
          >
            <img alt="" className="absolute left-0 top-0 w-full rotate-180 opacity-60" src={faqBgSrc} />
          </div>

          <div className="relative z-10 mt-24 pb-28 pt-16" ref={faqBlockRef}>
            <h2 className="mb-12 max-w-full text-center text-6xl font-semibold text-clay-900">
              Frequently asked questions
            </h2>
            <div className="grid grid-cols-1 gap-2.5">
              <AccordionProvider>
                {accordions.map(({ children, title }, i) => (
                  <Accordion key={i} title={title}>
                    <p className="w-full overflow-hidden text-lg font-light text-clay-900">{children}</p>
                  </Accordion>
                ))}
              </AccordionProvider>
            </div>
          </div>

          <UpgradeToProModal onOpenChange={setIsUpgradeOpen} open={isUpgradeOpen} redirectPath="/pricing" />
        </div>
      </AnimateRoute>
    </>
  );
};

const TitlePage = (props: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge(
        'w-full max-w-2xl text-5xl font-semibold leading-none tracking-tight text-clay-900 2xl:text-[3.25rem]',
        props.className,
      )}
    />
  );
};

import { AxiosError } from 'axios';

export const getErrorMessage = (error: unknown) => {
  if (error instanceof AxiosError) {
    const errorData = error?.response?.data?.['error'];
    const errorFull = error?.response?.data;

    const isEmptyError =
      !!errorData?.includes('The dataset does not exist') || !!errorData?.includes('Not found') || false;

    return {
      empty: isEmptyError,
      error:
        errorFull && 'cause_message' in errorFull
          ? errorFull['cause_message']
          : typeof errorData === 'string'
            ? errorData
            : undefined,
    };
  }

  return {};
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from '../dai/types';

type Response = {
  list: {
    _id: string;
    daiId: Pick<DAI, '_id' | 'profileImage' | 'projectName' | 'slug'>;
    date: string;
    price: number;
    type: 2;
  }[];
};

type Params = {
  daiId: string;
  limit?: number;
  skip?: number;
};

export const getDAIPriceQueryKey = (params: Params) => {
  return ['daiStatistics', 'price', params] as const;
};

export const useGetDAIPriceQuery = (
  { daiId, ...params }: Params,
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai-statistics/${daiId}/price`, {
        params: params,
      });

      return data;
    },
    queryKey: getDAIPriceQueryKey({ ...params, daiId }),
    ...options,
  });
};

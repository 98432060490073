import { useMemo, useState } from 'react';

import { DateTime } from 'luxon';

import type { HuggingfacePost as HuggingfacePostType } from 'shared/api/huggingface';

import huggingFaceSrc from 'app/assets/images/hugging-face-logo.svg';
import { useUser } from 'app/stores/user';
import { timeAgo } from 'shared/helpers/timeAgo';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import huggingFaceIconSrc from 'shared/ui/Icon/svg/huggingface.svg';

import { Attachments } from './Attachments';
import { CreateReaction, useCreateReaction, useDeleteReaction } from './CreateReaction';
import { DetailHuggingfacePostModal } from './DetailHuggingfacePostModal';
import { HuggingFaceContent } from './HuggingFaceContent';
import { Reaction } from './Reaction';

type Props = {
  post: HuggingfacePostType;
};

export const HuggingfacePost = ({ post }: Props) => {
  const [isExpandedPost, setIsExpandedPost] = useState(false);

  const { user } = useUser();

  const createReaction = useCreateReaction(post.slug, 'huggingface');
  const deleteReaction = useDeleteReaction(post.slug, 'huggingface');

  const reactions = useMemo(() => {
    const res = post.reactions.reduce<
      Record<string, { authors: Set<string>; count: number; reaction: string }>
    >((acc, el) => {
      if (acc[el.reaction]) {
        acc[el.reaction].count += 1;
        acc[el.reaction].authors.add(el.author);
      } else {
        acc[el.reaction] = { authors: new Set([el.author]), count: 1, reaction: el.reaction };
      }

      return acc;
    }, {});

    return Object.values(res);
  }, [post]);

  return (
    <>
      <div
        className="cursor-pointer rounded-xl bg-white p-5 shadow-checkbox"
        onClick={() => setIsExpandedPost(true)}
      >
        <header className="mb-7 flex items-center gap-3.5">
          <img
            alt=""
            className="size-7 min-w-7 rounded-full"
            src={post.author.avatarUrl.startsWith('http') ? post.author.avatarUrl : huggingFaceIconSrc}
          />
          <div className="flex flex-1 items-center gap-6">
            <h4 className="line-clamp-1 flex-1 text-base font-medium">
              <span className="text-clay-900">{post.author.name}</span>{' '}
              <span className="text-clay-500">posted an update</span>
            </h4>
            <div className="flex items-center gap-3.5">
              <span className="text-sm font-medium text-clay-300">
                {timeAgo(DateTime.fromISO(post.updatedAt))}
              </span>
              <div
                className="flex h-[24px] items-center rounded-full bg-clay-10 px-3"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`https://huggingface.co${post.url}`, '_blank');
                }}
              >
                <img alt="" className="w-20" src={huggingFaceSrc} />
              </div>
            </div>
          </div>
        </header>
        <div className="pl-[2.625rem]">
          <HuggingFaceContent post={post} short />

          {!!post.attachments.length && <Attachments attachments={post.attachments} />}

          <footer className="mt-5 flex w-full items-center gap-6 overflow-hidden">
            <div className="flex">
              <Button color="white" size="extra-small">
                Reply
                <Icon className="size-4" name="reply" />
              </Button>
            </div>
            <div className="flex flex-1 justify-end gap-6 overflow-hidden">
              <div className="flex items-center gap-1.5 text-clay-500">
                <Icon className="size-4" name="messageSquare" />
                <span className="whitespace-nowrap text-sm font-medium">{post.comments.length} Comments</span>
              </div>
              <div className="flex gap-2 overflow-x-auto scrollbar-none">
                {reactions.map((el, i) => (
                  <Reaction
                    count={el.count}
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (el.authors.has(user?._id || '')) {
                        deleteReaction(el.reaction);
                      } else {
                        createReaction(el.reaction);
                      }
                    }}
                    reaction={el.reaction}
                    selected={el.authors.has(user?._id || '')}
                  />
                ))}
                <CreateReaction postId={post.slug} type="huggingface" />
              </div>
            </div>
          </footer>
        </div>
      </div>
      <DetailHuggingfacePostModal isOpen={isExpandedPost} onOpenChange={setIsExpandedPost} post={post} />
    </>
  );
};

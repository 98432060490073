import srcAWS from './ui/assets/applications/AWS.png';
import srcAethir from './ui/assets/applications/Aethir.png';
import srcAmeliaNetwork from './ui/assets/applications/AmeliaNetwork.png';
import srcAptos from './ui/assets/applications/Aptos.png';
import srcBNBChain from './ui/assets/applications/BNBChain.png';
import srcCLVClover from './ui/assets/applications/CLVClover.png';
import srcCerboAI from './ui/assets/applications/CerboAI.png';
import srcCollar from './ui/assets/applications/Collar.png';
import srcComputeLabs from './ui/assets/applications/ComputeLabs.png';
import srcCreatorBid from './ui/assets/applications/CreatorBid.png';
import srcCrush from './ui/assets/applications/Crush.png';
import srcDIN from './ui/assets/applications/DIN.png';
import srcDMAILAI from './ui/assets/applications/DMAILAI.png';
import srcDNA from './ui/assets/applications/DNA.png';
import srcElfinMetaverse from './ui/assets/applications/ElfinMetaverse.png';
import srcFlock from './ui/assets/applications/Flock.png';
import srcFractionAI from './ui/assets/applications/FractionAI.png';
import srcGPTVerse from './ui/assets/applications/GPTVerse.png';
import srcGoogleCloud from './ui/assets/applications/GoogleCloud.png';
import srcGudchain from './ui/assets/applications/Gudchain.png';
import srcHoloworldAI from './ui/assets/applications/HoloworldAI.png';
import srcInfinityGround from './ui/assets/applications/InfinityGround.png';
import srcIrisnet from './ui/assets/applications/Irisnet.png';
import srcLaikaAI from './ui/assets/applications/LaikaAI.png';
import srcLucidlyFinance from './ui/assets/applications/LucidlyFinance.png';
import srcLyn from './ui/assets/applications/Lyn.png';
import srcMEET48 from './ui/assets/applications/MEET48.png';
import srcMicrosoftAzure from './ui/assets/applications/MicrosoftAzure.png';
import srcMindNetwork from './ui/assets/applications/MindNetwork.png';
import srcMode from './ui/assets/applications/Mode.png';
import srcMongoDBLabs from './ui/assets/applications/MongoDBLabs.png';
import srcNetwork3 from './ui/assets/applications/Network3.png';
import srcNillion from './ui/assets/applications/Nillion.png';
import srcOpenLayer from './ui/assets/applications/OpenLayer.png';
import srcOpentask from './ui/assets/applications/Opentask.png';
import srcPINGPONG from './ui/assets/applications/PINGPONG.png';
import srcParamiAIME from './ui/assets/applications/ParamiAIME.png';
import srcParasail from './ui/assets/applications/Parasail.png';
import srcPredXai from './ui/assets/applications/PredXai.png';
import srcPrivasea from './ui/assets/applications/Privasea.png';
import srcPublicAI from './ui/assets/applications/PublicAI.png';
import srcQuaere from './ui/assets/applications/Quaere.png';
import srcQuestflow from './ui/assets/applications/Questflow.png';
import srcReclaimProtocol from './ui/assets/applications/ReclaimProtocol.png';
import srcSecondlive from './ui/assets/applications/Secondlive.png';
import srcSei from './ui/assets/applications/Sei.png';
import srcSpaceAndTime from './ui/assets/applications/SpaceAndTime.png';
import srcStabilityWorldAI from './ui/assets/applications/StabilityWorldAI.png';
import srcStory from './ui/assets/applications/Story.png';
import srcTNA from './ui/assets/applications/TNA.png';
import srcTonX from './ui/assets/applications/TonX.png';
import srcUptickNetwork from './ui/assets/applications/UptickNetwork.png';
import srcWeb3Go from './ui/assets/applications/Web3Go.png';
import srcXION from './ui/assets/applications/XION.png';
import srcXionSpace from './ui/assets/applications/XionSpace.png';
import srcdFusion from './ui/assets/applications/dFusion.png';
import srcionet from './ui/assets/applications/ionet.png';
import srciotex from './ui/assets/applications/iotex.png';
import srcshhfun from './ui/assets/applications/shh.fun.png';
import DeveloperResourcesDocIcon from './ui/assets/developer-resources/documentation.svg?react';
import DeveloperResourcesPayIcon from './ui/assets/developer-resources/pay.svg?react';
import DeveloperResourcesTutorialIcon from './ui/assets/developer-resources/tutorial.svg?react';
import srcBNB from './ui/assets/technology-partners/BNB.png';

export const anchorCards = [
  {
    description: 'Pick a dApp from the Nesa ecosystem to explore.',
    link: '#applications',
    linkText: 'Choose',
    title: 'Applications',
  },
  {
    description: 'Integrate your own decentralized protocol with Nesa SDK.',
    link: 'https://docs.nesa.ai/nesa/using-nesa/via-sdk',
    linkText: 'Start',
    title: 'Protocols',
  },
  {
    description: 'Discover how your smart contract can fuse with AI.',
    link: 'http://docs.nesa.ai/',
    linkText: 'Learn More',
    title: 'Smart Contracts',
  },
];

export const applicationTabs = [
  { title: 'ALL', value: 'all' },
  { title: 'DEFI', value: 'defi' },
  { title: 'CHAT', value: 'chat' },
  { title: 'SOCIAL', value: 'social' },
];

export const applications = [
  {
    description:
      'Nillion decentralizes trust for sensitive data in the same way that blockchains decentralized transactions.',
    icon: srcNillion,
    link: 'https://nillion.com/',
    title: 'Nillion',
    type: 'defi',
  },
  {
    description:
      'pace and Time (SxT) is the verifiable compute layer that scales zero-knowledge proofs on a decentralized data warehouse to deliver trustless data processing to smart contracts, LLMs, and enterprises.',
    icon: srcSpaceAndTime,
    link: 'https://www.spaceandtime.io/',
    title: 'Space and Time',
    type: 'defi',
  },
  {
    description:
      'io.net Cloud is a decentralized computing network that allows machine learning engineers to access scalable distributed  clusters at a fraction of the cost of comparable centralized services.',
    icon: srcionet,
    link: 'https://io.net/',
    title: 'io.net',
    type: 'social',
  },
  {
    description:
      'Crush is a multi-chain gaming protocol that leverages large language models and blockchain capabilities to create a new class of interactive and intelligent game agents.',
    icon: srcCrush,
    link: 'https://www.crush.xyz/',
    title: 'Crush',
    type: 'chat',
  },
  {
    description:
      'PublicAI is a distributed AI network enables every human: contribute to AI and share the benefits that connects businesses and individuals with a global network of workers.',
    icon: srcPublicAI,
    link: 'https://www.publicai.io/',
    title: 'PublicAI',
    type: 'chat',
  },
  {
    description:
      'Creator.Bid is designed to empower creators to create, market, and scale their content and brands using AI Agents.',
    icon: srcCreatorBid,
    link: 'https://creator.bid/',
    title: 'Creator.Bid',
    type: 'chat',
  },
  {
    description:
      'Dmail Network is an AI-powered decentralized communication infrastructure built to provide encrypted emails, unified notifications, and targeted marketing across multiple chains and dApps for users, developers, and marketers.',
    icon: srcDMAILAI,
    link: 'https://dmail.ai/',
    title: 'DMAIL.AI',
    type: 'social',
  },
  {
    description: 'Laika is designed and optimized for web3 and blockchain, ‍unlike other AI assistants.',
    icon: srcLaikaAI,
    link: 'https://www.laika-ai.io/',
    title: 'Laika AI',
    type: 'defi',
  },
  {
    description: `Mind Network is one of the first tokenized startups to apply the long-awaited 'Holy Grail of Encryption' to Web3. Mind Network is a Fully Homomorphic Encryption (FHE) Layer leading the era of  "HTTPZ", an end-to-end encryption internet.`,
    icon: srcMindNetwork,
    link: 'https://mindnetwork.xyz/',
    title: 'Mind Network',
    type: 'chat',
  },
  {
    description:
      'Holoworld brings your imagination to life. Create your favorite character from personality to looks, let them become a virtual star!.',
    icon: srcHoloworldAI,
    link: 'https://www.holoworldai.com/',
    title: 'Holoworld AI',
    type: 'social',
  },
  {
    description:
      'OpenLayer is the first trustworthy data layer empowered by users that combines optimistic execution, cryptographic verification, and decentralized physical infrastructure, to facilitate the next level of scale and connectivity in web3.',
    icon: srcOpenLayer,
    link: 'https://openlayers.org/',
    title: 'OpenLayer',
    type: 'defi',
  },
  {
    description: `DNA's are digital companions with superhuman intelligence and charm. They live to serve you and keep you company, and are accessible 24/7 across various platforms.`,
    icon: srcDNA,
    link: 'https://dnax.ai/',
    title: 'DNA',
    type: 'social',
  },
  {
    description: `Reclaim Protocol has SDKs for web, mobile and on-chain platform. You can use these SDKs to build applications that have access to users' activity, reputation, and identity from websites across the internet.`,
    icon: srcReclaimProtocol,
    link: 'https://www.reclaimprotocol.org/',
    title: 'Reclaim Protocol',
    type: 'defi',
  },
  {
    description: `Web3Go is the Data Intelligence Network that Empowers AI with Crypto. The first network with protocols and communities for the revolution of data flow and AI agent building.`,
    icon: srcWeb3Go,
    link: 'https://web3go.xyz/',
    title: 'Web3Go',
    type: 'chat',
  },
  {
    description:
      'Pushing the boundaries of blockchain technology through open source development, Sei stands to unlock a brand new design space for consumer facing applications.',
    icon: srcSei,
    link: 'https://www.sei.io/',
    title: 'Sei',
    type: 'social',
  },
  {
    description:
      'Manta Pacific is the first EVM-equivalent ZK-application platform that is scalable and secure through Celestia DA and Polygon zkEVM.',
    icon: srcAptos,
    link: 'https://manta.network/',
    title: 'Manta Network',
    type: 'defi',
  },
  {
    description: 'The spiritual metaverse where AI meets astrology.',
    icon: srcQuaere,
    link: 'https://www.quaere.ai/',
    title: 'Quaere',
    type: 'chat',
  },
  {
    description: 'Providing the highest quality human-annotated data for training AI models.',
    icon: srcFractionAI,
    link: 'https://fractionai.xyz/',
    title: 'Fraction AI',
    type: 'social',
  },
  {
    description:
      'Empowering 900 million Telegram users with secure, high-yield savings solutions, offering 15% APY through innovative arbitrage strategies.',
    icon: srcLucidlyFinance,
    link: 'https://lucidly.finance/',
    title: 'Lucidly Finance',
    type: 'defi',
  },
  {
    description:
      'Collar is a money market protocol for hedging pegged cryptoassets. Protect your portfolio by borrowing against it with 100% LTV and no risk of liquidation.',
    icon: srcCollar,
    link: 'https://collar.org/',
    title: 'Collar Finance',
    type: 'chat',
  },
  {
    description: 'An AI-native onchain infinity world.',
    icon: srcInfinityGround,
    link: 'https://linktr.ee/InfinityGround',
    title: 'Infinity Ground',
    type: 'social',
  },
  {
    description:
      'Network3 builds an AI Layer2 helping AI developers worldwide train or validate models in scale quickly, conveniently, and efficiently.',
    icon: srcNetwork3,
    link: 'https://network3.io/',
    title: 'Network3',
    type: 'defi',
  },
  {
    description: 'Unlock yield from the AGI future now.',
    icon: srcComputeLabs,
    link: 'https://twitter.com/Compute_Labs',
    title: 'Compute Labs',
    type: 'chat',
  },
  {
    description: 'CLV is a one-stop platform for cross-chain and decentralized applications.',
    icon: srcCLVClover,
    link: 'https://clv.org/',
    title: 'CLV Clover',
    type: 'social',
  },
  {
    description: 'A text-to-workflow tool to automate tasks acrossplatforms, together.',
    icon: srcQuestflow,
    link: 'https://www.questflow.ai/',
    title: 'Questflow',
    type: 'defi',
  },
  {
    description:
      'The naming protocol for Bitcoin designed to improve its usability and broaden its array of assets and primitives.',
    icon: srcTNA,
    link: 'https://tna-btc.com/',
    title: 'TNA',
    type: 'chat',
  },
  {
    description:
      'SecondLive is a hub for Metaverse inhabitants. More than 1 million users are gathering here to facilitate self-expression, unleash creativity and build a dreaming parallel universe.',
    icon: srcSecondlive,
    link: 'https://secondlive.world/',
    title: 'Secondlive',
    type: 'chat',
  },
  {
    description:
      'The platform with incentivized collaboration, enabling easy integration of external data and enhancing domain-specific LLM accuracy through Retrieval-Augmented Generation technology.',
    icon: srcFlock,
    link: 'https://beta.flock.io/',
    title: 'Flock',
    type: 'chat',
  },
  {
    description:
      'Story Protocol transforms IPs into networks that transcend mediums and platforms, unleashing global creativity and liquidity.',
    icon: srcStory,
    link: 'https://www.storyprotocol.xyz/',
    title: 'Story',
    type: 'chat',
  },
  {
    description:
      'Aethir is a cloud computing infrastructure platform that revolutionizes the ownership, distribution, and utilization paradigms of enterprise-grade graphical processing units (GPUs).',
    icon: srcAethir,
    link: 'https://www.aethir.com/',
    title: 'Aethir',
    type: 'chat',
  },
  {
    description:
      'A global marketplace that connects AI freelancers and business needs. Enable more people to achieve self-worth with AI tools.',
    icon: srcOpentask,
    link: 'https://opentaskai.com/',
    title: 'Opentask',
    type: 'chat',
  },
  {
    description: 'Predx.ai builds the most informative and engaging prediction market, powered by AI.',
    icon: srcPredXai,
    link: 'https://predx.ai/',
    title: 'PredX.ai',
    type: 'chat',
  },
  {
    description: `Amelia network boosts AI agents' coevolution through transparent bounty games.`,
    icon: srcAmeliaNetwork,
    link: 'https://aimelia.network/',
    title: 'Amelia.network',
    type: 'chat',
  },

  {
    description: `The one-stop generative AI platform, AI accelerator & AI hub for web3.`,
    icon: srcStabilityWorldAI,
    link: 'https://app.stabilityworld.ai/',
    title: 'Stability World AI',
    type: 'social',
  },
  {
    description: `An all-in-one eSports gaming platform and open-world metaverse built on Elfin Lands.`,
    icon: srcElfinMetaverse,
    link: 'https://elfinmetaverse.com/',
    title: 'Elfin Metaverse',
    type: 'social',
  },
  {
    description: `The first AI-enabled digital asset platform powered by Chain Abstraction.`,
    icon: srcXionSpace,
    link: 'https://xionspace.com/',
    title: 'XionSpace',
    type: 'social',
  },
  {
    description: `XION empowers developers and brands to create frictionless Web3 experiences, with a Generalized Abstraction layer that removes technical barriers for all users.`,
    icon: srcXION,
    link: 'https://xion.burnt.com/',
    title: 'XION',
    type: 'social',
  },
  {
    description: `Pioneering the future of on-chain advertising, Parami Protocol seamlessly integrates  AI, NFTs, and DeFi, shaping the foundation for a decentralized and equitable advertising landscape.`,
    icon: srcParamiAIME,
    link: 'https://aime.parami.io/',
    title: 'Parami $AIME',
    type: 'social',
  },
  {
    description: `Pioneering Proof of Federated Learning and Edge AI Computation. Decentralizing AGI with personalized AI edge models and a computing network.`,
    icon: srcCerboAI,
    link: 'https://www.cerboai.com/',
    title: 'CerboAI',
    type: 'social',
  },
  {
    description: `An omni-chain #DePINFi money market & decentralized network mining aggregator.`,
    icon: srcPINGPONG,
    link: 'https://www.pingpong.build/',
    title: 'PINGPONG',
    type: 'defi',
  },
  {
    description: `Privasea AI Network is a privacy-preserving machine learning project that uses Fully Homomorphic Encryption as its core technology.`,
    icon: srcPrivasea,
    link: 'https://www.privasea.ai/',
    title: 'Privasea',
    type: 'social',
  },
  {
    description: `DIN is the Data Intelligence Network that empowers AI with crypto.`,
    icon: srcDIN,
    link: 'https://din.lol/',
    title: 'DIN',
    type: 'social',
  },
  {
    description: `MEET48 is an immersive intelligent social metaverse that integrates AI large models & web3, featuring idol entertainment content, AI-driven content & Web3 gaming.`,
    icon: srcMEET48,
    link: 'https://www.meet48.xyz/',
    title: 'MEET48',
    type: 'social',
  },
  {
    description: `Lyn is the first open-source foundational video model and super-agential multimodal ecosystem that was designed to deliver an open dream machine to the world.`,
    icon: srcLyn,
    link: '',
    title: 'Lyn',
    type: 'social',
  },
  {
    description: `A community-driven blockchain ecosystem of Layer-1 and Layer-2 scaling solutions.`,
    icon: srcBNBChain,
    link: 'https://www.bnbchain.org/',
    title: 'BNB Chain',
    type: 'defi',
  },
  {
    description: `dFusion AI is a protocol to create community-driven networked sources of truth with powerful economic incentives.`,
    icon: srcdFusion,
    link: 'https://www.dfusion.ai/',
    title: 'dFusion',
    type: 'social',
  },
  {
    description: `GCP is a public cloud vendor that offers a suite of computing services to do everything from data management to compute infrastructure, with many tools in between.`,
    icon: srcGoogleCloud,
    link: 'https://cloud.google.com/',
    title: 'Google Cloud',
    type: 'social',
  },
  {
    description: `AWS is designed to allow application providers, ISVs, and vendors to quickly and securely host your applications.`,
    icon: srcAWS,
    link: 'https://aws.amazon.com/',
    title: 'AWS',
    type: 'social',
  },
  {
    description: `Microsoft Azure offers management, access and development of applications and services to individuals, companies, and governments through its global infrastructure.`,
    icon: srcMicrosoftAzure,
    link: 'https://azure.microsoft.com/',
    title: 'Microsoft Azure',
    type: 'social',
  },
  {
    description: `MongoDB is a non-relational, open-source database management system (DBMS) that stores and processes data in documents instead of tables and rows.`,
    icon: srcMongoDBLabs,
    link: 'https://learn.mongodb.com/',
    title: 'Mongo DB Labs',
    type: 'social',
  },
  {
    description: `IoTeX is an open source platform at the intersection of blockchain and the Internet of Things, on a mission to enable the future decentralized machine economy.`,
    icon: srciotex,
    link: 'https://iotex.io/',
    title: 'iotex',
    type: 'social',
  },
  {
    description: `Gudchain stands as a pioneering platform crafted purposely to advance the use of blockchain in gaming.`,
    icon: srcGudchain,
    link: 'https://gudchain.com/',
    title: 'Gudchain',
    type: 'defi',
  },
  {
    description: `TONX is the SuperApp platform layer that enables builders to create the new Web3 economy on TON.`,
    icon: srcTonX,
    link: 'https://tonx.ai/',
    title: 'TonX',
    type: 'social',
  },
  {
    description: `Your uncensored, fully private AI. On shh.fun there are no restrictions like on other AI platforms, so ask for anything that you want.`,
    icon: srcshhfun,
    link: 'https://shh.fun/',
    title: 'shh.fun',
    type: 'chat',
  },
  {
    description: `IRISnet (IRIS) is a protocol designed to be the foundation for next-generation applications.`,
    icon: srcIrisnet,
    link: 'https://www.irisnet.org/',
    title: 'Irisnet',
    type: 'social',
  },
  {
    description: `Uptick Network is building business-grade infrastructure and ecosystems for non-fungible tokens (NFTs).`,
    icon: srcUptickNetwork,
    link: 'https://www.uptick.network/',
    title: 'Uptick Network',
    type: 'social',
  },
  {
    description: `Parasail connects established assets and DePIN service proofs to enable new DePIN projects to build confidence in their services more efficiently.`,
    icon: srcParasail,
    link: 'https://www.parasail.network/',
    title: 'Parasail',
    type: 'social',
  },
  {
    description: `GPTVerse is an AI hub and multi-platform gateway to a next-level dApp experience.`,
    icon: srcGPTVerse,
    link: 'https://gptverse.art/',
    title: 'GPTVerse',
    type: 'social',
  },
  {
    description: `Mode L2 scales DeFi through on-chain agents and AI-powered financial applications. Mode is building the AIFi economy.`,
    icon: srcMode,
    link: 'https://www.mode.network/',
    title: 'Mode',
    type: 'social',
  },
];

export const developerResourcesCards = [
  {
    description: 'Documentation for the Nesa network.',
    icon: <DeveloperResourcesDocIcon className="size-14 lg:size-12" />,
    link: 'http://docs.nesa.ai/',
    linkText: 'Read',
    title: 'Nesa documentation',
  },
  {
    description: 'An overview of paying for queries on Nesa.',
    icon: <DeveloperResourcesPayIcon className="size-14 lg:size-12" />,
    link: 'https://docs.nesa.ai/nesa/using-nesa/via-web/submitting-a-query',
    linkText: 'Learn More',
    title: 'PayForQuery',
  },
  {
    description: 'Learn how to validate data on the Nesa network.',
    icon: <DeveloperResourcesTutorialIcon className="size-14 lg:size-12" />,
    link: 'https://docs.nesa.ai/nesa/run-a-nesa-node/installation',
    linkText: 'Read',
    title: 'Validator tutorial',
  },
];

export const developerResourcesCards2 = [
  {
    description: 'Stream your AI queries through Nesa via API.',
    link: 'http://docs.nesa.ai/',
    linkText: 'Querystream API',
    title: 'Querystream',
  },
  {
    description: 'Use the Nesa-node API to execute consensus on Nesa.',
    link: 'http://docs.nesa.ai/',
    linkText: 'Node API Docs',
    title: 'Node API',
  },
];

export const technologyPartners = [
  {
    description: `The community-driven blockchain ecosystem\nof Layer-1 and Layer-2 scaling solutions.`,
    icon: srcBNB,
    link: 'https://www.bnbchain.org/',
    title: 'BNB',
  },
];

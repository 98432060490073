import { useNavigate } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { DatasetCard } from 'features/DatasetCard';
import { useGetDatasetsByUserQuery } from 'shared/api/datasets/useGetDatasetsByUserQuery';
import { wrapQueryOptions } from 'shared/helpers/wrapQueryOptions';
import { Button } from 'shared/ui/Button';

import emptySrc from './assets/emptyDatasets.svg';

const gridCls =
  'grid w-full grid-flow-dense grid-cols-1 justify-between gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';

export const UserDatasetsList = () => {
  const { user } = useUser();
  const { data: datasets, isPending } = useGetDatasetsByUserQuery(...wrapQueryOptions({ userId: user?._id }));
  const navigate = useNavigate();

  if (isPending) {
    return (
      <div className={gridCls}>
        {Array.from({ length: 10 })
          .fill('')
          .map((_, idx) => (
            <DatasetCard className="bg-white" isLoading key={idx} />
          ))}
      </div>
    );
  }

  if (!datasets || !datasets.list.length) {
    return (
      <>
        <div className="my-auto flex flex-col items-center justify-center gap-1.5 py-10">
          <div className="mb-2 flex h-20 items-end justify-center">
            <img alt={emptySrc} className="h-auto w-20" src={emptySrc} />
          </div>

          <h1 className="text-center text-2xl ">No Dataset uploaded yet</h1>
          <div className="text-center text-base font-light text-clay-500">
            Earn when your Dataset is used in training
          </div>

          <Button className="mt-3.5" onClick={() => navigate('/datasets?openCreationModal=1')}>
            Upload a Dataset
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className={gridCls}>
      {datasets.list.map((dataset) => {
        return (
          <DatasetCard
            className="bg-white"
            key={dataset._id}
            likes={dataset.likes || 0}
            // modalities={dataset.datasetsServerInfo?.modalities}
            onClick={() => navigate(`/datasets/${dataset._id.replace('/', '---')}`)}
            timestamp={dataset.timestamp}
            title={dataset.datasetId}
          />
        );
      })}
    </div>
  );
};

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import type { NodeChatPeriod } from 'shared/api/nodes/types';

import { BackButton } from 'app/ui/BackButton';
import { getWorkerType } from 'features/UserNodeList/ui/NodeCard';
import { NodeStatus } from 'features/UserNodeList/ui/NodeStatus';
import { useNodeChartQuery } from 'shared/api/nodes/useNodeChartQuery';
import { useNodeQuery } from 'shared/api/nodes/useNodeQuery';
import { useNodeStatQuery } from 'shared/api/nodes/useNodeStatQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { NodeSpecChip } from 'shared/ui/NodeSpecChip/NodeSpecChip';
import { Spinner } from 'shared/ui/Spinner';

import mockSrc from './assets/mock.png';
import { Bars } from './ui/Bars';
import { StatCard } from './ui/StatCard';

export const chartTabs: { label: string; value: NodeChatPeriod }[] = [
  { label: '24H', value: 'day' },
  { label: '1W', value: 'week' },
  { label: '1M', value: 'month' },
  { label: '6M', value: '6month' },
  { label: '1Y', value: 'year' },
];

const getPeriodTextByType = (period: NodeChatPeriod) => {
  if (period === 'day') return 'hour';
  if (['month', 'week'].includes(period)) return 'day';

  return 'month';
};

export const Page = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [periodTab, setPeriodTab] = useState<NodeChatPeriod>('day');

  const { data, isPending } = useNodeQuery({ nodeId: id! }, { enabled: !!id });
  const { data: stat, isPending: isStatPending } = useNodeStatQuery({ nodeId: id! }, { enabled: !!id });

  const { data: chartData, isPending: isChartDataPending } = useNodeChartQuery(
    { nodeId: id!, period: periodTab },
    { enabled: !!id },
  );
  const node = data?.node;

  if (!node) {
    return isPending ? (
      <div className="flex size-full flex-col items-center justify-center py-10">
        <Spinner className="size-6" />
      </div>
    ) : (
      <div className="flex size-full flex-col items-center justify-center py-10">
        <div className="text-center font-light text-clay-500">Node is not found</div>
      </div>
    );
  }

  const responsesCountPerPeriod = chartData?.reduce((acc, item) => acc + item.count, 0) || 0;
  const avgResponsesPerPeriod = chartData
    ? (responsesCountPerPeriod / chartData.length).toFixed(2)
    : undefined;

  const workerType = getWorkerType(node);
  const specs =
    workerType === 'cpu-worker'
      ? node.specs.cpu.join(',')
      : workerType === 'gpu-worker'
        ? node.specs.gpu.join(',')
        : 'Unknown';

  return (
    <AnimateRoute className="mx-auto w-full max-w-7xl px-4 py-10">
      <BackButton className="mb-4" onClick={() => navigate(-1)}>
        My Node
      </BackButton>

      <Card className="!px-6 !pb-6 !pt-3">
        <header className="mb-4 flex flex-wrap items-center justify-between gap-1.5 border-b border-clay-20 pb-4 pt-2">
          <div className="flex flex-wrap items-center">
            <div className="mr-3 border-r border-clay-20 pr-3 text-base/none md:text-xl/none">
              {node?.moniker || 'Node'}
            </div>

            <div className="text-sm md:text-base">
              ID: <span className="font-light text-clay-500">{node._id}</span>
            </div>
          </div>

          <NodeStatus status={node.status} />
        </header>

        <div className="flex flex-col gap-4 border-b border-clay-20 pb-4 md:flex-row">
          <div
            className="size-40 rounded-lg bg-clay-20 bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${mockSrc})` }}
          ></div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col items-start gap-3 md:flex-row md:items-center">
              <div className="flex items-center gap-2 rounded-lg bg-clay-10 px-3 py-2 text-sm font-normal">
                <Icon className="size-4" name="cpu" />
                {workerType ? `${workerType === 'gpu-worker' ? 'GPU' : 'CPU'} worker` : 'Unknown'}
              </div>
              <NodeSpecChip specs={specs} />
            </div>

            <p className="font-light text-clay-500">
              This node is a top performing network resource, rated in the top 1% of performance on the
              inference network.
            </p>
          </div>
        </div>

        <div className="flex flex-col py-4">
          <div className="mb-2 flex flex-col items-start justify-between gap-2 md:flex-row md:items-center">
            <h1 className="text-xl">Request served</h1>
            <div className="flex items-center gap-1.5">
              {chartTabs.map((tab) => (
                <button
                  aria-selected={tab.value === periodTab}
                  className={twMerge(
                    'h-7 w-11 rounded-lg border border-clay-20 bg-white text-center text-sm font-medium leading-7 text-clay-800 transition-colors hover:border-clay-100 hover:text-clay-800 xs:w-12',
                    'aria-selected:border-primary-800 aria-selected:bg-primary-800 aria-selected:text-white aria-selected:hover:border-primary-800 aria-selected:hover:text-white',
                  )}
                  key={tab.value}
                  onClick={() => setPeriodTab(tab.value)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          <Bars data={chartData || []} period={periodTab} />

          <div className="mt-4 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">
            <StatCard label="Average #NES earned per day" value="--" />
            <StatCard label="Avg NES earned per request" value="--" />
            <StatCard
              label="Total request served "
              value={
                <div className="flex h-9 ">
                  {isStatPending ? (
                    <Spinner className="size-6" />
                  ) : (
                    stat?.responseCount?.toLocaleString() ?? '--'
                  )}
                </div>
              }
            />
            <StatCard
              label={`Avg request served per ${getPeriodTextByType(periodTab)}`}
              value={
                <div className="flex h-9">
                  {isChartDataPending ? <Spinner className="size-6" /> : avgResponsesPerPeriod ?? '--'}
                </div>
              }
            />
          </div>
        </div>
      </Card>
    </AnimateRoute>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { queryKeys } from './queryKeys';

export type GetReactionParams = {
  datasetId: string;
  userId: string;
};

export type DatasetReaction = {
  _id: string;
  datasetId: string;
  timestamp: number;
  type: 'like';
  userId: string;
};

type Response = {
  data: DatasetReaction | undefined;
};

export const useDatasetUserReactionsQuery = (
  params: GetReactionParams,
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/datasets/reactions`, {
        params,
        signal,
      });

      return data;
    },
    queryKey: queryKeys.reaction(params),
    ...options,
  });
};

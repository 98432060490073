/* eslint-disable perfectionist/sort-objects */
export const dataSetSourceOptions = [
  { label: 'Local', value: 'local' },
  { label: 'Nesa Hub', value: 'nesa-hub' },
];

export const categoryTypes = [
  'text-generation',
  'text-classification',
  'question-answering',
  'image-generation',
  'image-classification',
  'object-detection',
];

export const parameters: Record<
  string,
  Record<
    string,
    {
      default: number | string;
      label: string;
      options?: string[];
      type: string;
    }
  >
> = {
  'image-classification': {
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
  },
  'image-generation': {
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
  },
  'object-detection': {
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    max_doc_stride: {
      type: 'number',
      label: 'Max doc stride',
      default: 128,
    },
    max_seq_length: {
      type: 'number',
      label: 'Max sequence length',
      default: 512,
    },
  },
  'question-answering': {
    batch_size: {
      default: 8,
      label: 'Batch size',
      type: 'number',
    },
    epochs: {
      default: 3,
      label: 'Epochs',
      type: 'number',
    },
    gradient_accumulation: {
      default: 1,
      label: 'Gradient accumulation',
      type: 'number',
    },
    lr: {
      default: 0.00003,
      label: 'Learning rate',
      type: 'number',
    },
    max_seq_length: {
      default: 128,
      label: 'Max sequence length',
      type: 'number',
    },
    mixed_precision: {
      default: 'fp16',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      type: 'dropdown',
    },
    optimizer: {
      default: 'adamw_torch',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      type: 'dropdown',
    },
    scheduler: {
      default: 'linear',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      type: 'dropdown',
    },
  },
  'text-classification': {
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    max_seq_length: {
      type: 'number',
      label: 'Max sequence length',
      default: 128,
    },
  },
  'text-generation': {
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    max_seq_length: {
      type: 'number',
      label: 'Max sequence length',
      default: 128,
    },
  },
};

export const fullParameters: Record<
  string,
  Record<
    string,
    {
      default: boolean | number | string;
      label: string;
      options?: boolean[] | string[];
      type: string;
    }
  >
> = {
  'image-classification': {
    auto_find_batch_size: {
      type: 'dropdown',
      label: 'Auto find batch size',
      options: [true, false],
      default: false,
    },
    eval_strategy: {
      type: 'dropdown',
      label: 'Evaluation strategy',
      options: ['epoch', 'steps'],
      default: 'epoch',
    },
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    early_stopping_patience: {
      type: 'number',
      label: 'Early stopping patience',
      default: 5,
    },
    early_stopping_threshold: {
      type: 'number',
      label: 'Early stopping threshold',
      default: 0.01,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    logging_steps: {
      type: 'number',
      label: 'Logging steps',
      default: -1,
    },
    max_grad_norm: {
      type: 'number',
      label: 'Max grad norm',
      default: 1,
    },
    save_total_limit: {
      type: 'number',
      label: 'Save total limit',
      default: 1,
    },
    seed: {
      type: 'number',
      label: 'Seed',
      default: 42,
    },
    warmup_ratio: {
      type: 'number',
      label: 'Warmup proportion',
      default: 0.1,
    },
    weight_decay: {
      type: 'number',
      label: 'Weight decay',
      default: 0,
    },
  },
  'image-generation': {
    auto_find_batch_size: {
      type: 'dropdown',
      label: 'Auto find batch size',
      options: [true, false],
      default: false,
    },
    eval_strategy: {
      type: 'dropdown',
      label: 'Evaluation strategy',
      options: ['epoch', 'steps'],
      default: 'epoch',
    },
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    early_stopping_patience: {
      type: 'number',
      label: 'Early stopping patience',
      default: 5,
    },
    early_stopping_threshold: {
      type: 'number',
      label: 'Early stopping threshold',
      default: 0.01,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    logging_steps: {
      type: 'number',
      label: 'Logging steps',
      default: -1,
    },
    max_grad_norm: {
      type: 'number',
      label: 'Max grad norm',
      default: 1,
    },
    save_total_limit: {
      type: 'number',
      label: 'Save total limit',
      default: 1,
    },
    seed: {
      type: 'number',
      label: 'Seed',
      default: 42,
    },
    warmup_ratio: {
      type: 'number',
      label: 'Warmup proportion',
      default: 0.1,
    },
    weight_decay: {
      type: 'number',
      label: 'Weight decay',
      default: 0,
    },
  },
  'object-detection': {
    auto_find_batch_size: {
      type: 'dropdown',
      label: 'Auto find batch size',
      options: [true, false],
      default: false,
    },
    eval_strategy: {
      type: 'dropdown',
      label: 'Evaluation strategy',
      options: ['epoch', 'steps'],
      default: 'epoch',
    },
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    early_stopping_patience: {
      type: 'number',
      label: 'Early stopping patience',
      default: 5,
    },
    early_stopping_threshold: {
      type: 'number',
      label: 'Early stopping threshold',
      default: 0.01,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    image_square_size: {
      type: 'number',
      label: 'Image square size',
      default: 600,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    logging_steps: {
      type: 'number',
      label: 'Logging steps',
      default: -1,
    },
    max_grad_norm: {
      type: 'number',
      label: 'Max grad norm',
      default: 1,
    },
    save_total_limit: {
      type: 'number',
      label: 'Save total limit',
      default: 1,
    },
    seed: {
      type: 'number',
      label: 'Seed',
      default: 42,
    },
    warmup_ratio: {
      type: 'number',
      label: 'Warmup proportion',
      default: 0.1,
    },
    weight_decay: {
      type: 'number',
      label: 'Weight decay',
      default: 0,
    },
  },
  'question-answering': {
    auto_find_batch_size: {
      type: 'dropdown',
      label: 'Auto find batch size',
      options: [true, false],
      default: false,
    },
    eval_strategy: {
      type: 'dropdown',
      label: 'Evaluation strategy',
      options: ['epoch', 'steps'],
      default: 'epoch',
    },
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    early_stopping_patience: {
      type: 'number',
      label: 'Early stopping patience',
      default: 5,
    },
    early_stopping_threshold: {
      type: 'number',
      label: 'Early stopping threshold',
      default: 0.01,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    logging_steps: {
      type: 'number',
      label: 'Logging steps',
      default: -1,
    },
    max_doc_stride: {
      type: 'number',
      label: 'Max doc stride',
      default: 128,
    },
    max_grad_norm: {
      type: 'number',
      label: 'Max grad norm',
      default: 1,
    },
    max_seq_length: {
      type: 'number',
      label: 'Max sequence length',
      default: 512,
    },
    save_total_limit: {
      type: 'number',
      label: 'Save total limit',
      default: 1,
    },
    seed: {
      type: 'number',
      label: 'Seed',
      default: 42,
    },
    warmup_ratio: {
      type: 'number',
      label: 'Warmup proportion',
      default: 0.1,
    },
    weight_decay: {
      type: 'number',
      label: 'Weight decay',
      default: 0,
    },
  },
  'text-classification': {
    auto_find_batch_size: {
      type: 'dropdown',
      label: 'Auto find batch size',
      options: [true, false],
      default: false,
    },
    eval_strategy: {
      type: 'dropdown',
      label: 'Evaluation strategy',
      options: ['epoch', 'steps'],
      default: 'epoch',
    },
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    early_stopping_patience: {
      type: 'number',
      label: 'Early stopping patience',
      default: 5,
    },
    early_stopping_threshold: {
      type: 'number',
      label: 'Early stopping threshold',
      default: 0.01,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    logging_steps: {
      type: 'number',
      label: 'Logging steps',
      default: -1,
    },
    max_grad_norm: {
      type: 'number',
      label: 'Max grad norm',
      default: 1,
    },
    max_seq_length: {
      type: 'number',
      label: 'Max sequence length',
      default: 128,
    },
    save_total_limit: {
      type: 'number',
      label: 'Save total limit',
      default: 1,
    },
    seed: {
      type: 'number',
      label: 'Seed',
      default: 42,
    },
    warmup_ratio: {
      type: 'number',
      label: 'Warmup proportion',
      default: 0.1,
    },
    weight_decay: {
      type: 'number',
      label: 'Weight decay',
      default: 0,
    },
  },
  'text-generation': {
    auto_find_batch_size: {
      type: 'dropdown',
      label: 'Auto find batch size',
      options: [true, false],
      default: false,
    },
    eval_strategy: {
      type: 'dropdown',
      label: 'Evaluation strategy',
      options: ['epoch', 'steps'],
      default: 'epoch',
    },
    mixed_precision: {
      type: 'dropdown',
      label: 'Mixed precision',
      options: ['fp16', 'bf16', 'none'],
      default: 'fp16',
    },
    optimizer: {
      type: 'dropdown',
      label: 'Optimizer',
      options: ['adamw_torch', 'adamw', 'adam', 'sgd'],
      default: 'adamw_torch',
    },
    scheduler: {
      type: 'dropdown',
      label: 'Scheduler',
      options: ['linear', 'cosine', 'cosine_warmup', 'constant'],
      default: 'linear',
    },
    batch_size: {
      type: 'number',
      label: 'Batch size',
      default: 8,
    },
    early_stopping_patience: {
      type: 'number',
      label: 'Early stopping patience',
      default: 5,
    },
    early_stopping_threshold: {
      type: 'number',
      label: 'Early stopping threshold',
      default: 0.01,
    },
    epochs: {
      type: 'number',
      label: 'Epochs',
      default: 3,
    },
    gradient_accumulation: {
      type: 'number',
      label: 'Gradient accumulation',
      default: 1,
    },
    lr: {
      type: 'number',
      label: 'Learning rate',
      default: 0.00005,
    },
    logging_steps: {
      type: 'number',
      label: 'Logging steps',
      default: -1,
    },
    max_grad_norm: {
      type: 'number',
      label: 'Max grad norm',
      default: 1,
    },
    max_seq_length: {
      type: 'number',
      label: 'Max sequence length',
      default: 128,
    },
    save_total_limit: {
      type: 'number',
      label: 'Save total limit',
      default: 1,
    },
    seed: {
      type: 'number',
      label: 'Seed',
      default: 42,
    },
    warmup_ratio: {
      type: 'number',
      label: 'Warmup proportion',
      default: 0.1,
    },
    weight_decay: {
      type: 'number',
      label: 'Weight decay',
      default: 0,
    },
  },
};

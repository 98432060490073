import type { SwapOptions } from '@uniswap/v3-sdk';
import type { Address } from 'viem';

import { useMutation } from '@tanstack/react-query';
import { CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core';
import { Pool, Route, SwapQuoter, SwapRouter, TickMath, Trade } from '@uniswap/v3-sdk';
import invariant from 'tiny-invariant';
import { decodeAbiParameters, getContract } from 'viem';
import { waitForTransactionReceipt } from 'viem/actions';
import { useAccount, useChainId, usePublicClient, useWalletClient } from 'wagmi';

import { uniswapV3PoolAbi } from 'shared/config/abi/uniswapV3PoolAbi';
import { quoteContract, swapContract } from 'shared/config/contracts';
import { catchError } from 'shared/helpers/parseAxiosError';

import { parseSwapParameters } from './helpers/parseSwapParameters';

type Params = {
  amount: number | string;
  poolAddress: Address;
  tokenIn: { address: Address; decimals: number };
  tokenOut: { address: Address; decimals: number };
  tradeType?: TradeType;
};

export const useSwapMutation = () => {
  const chainId = useChainId();
  const { data: wc } = useWalletClient({ chainId });

  const swapContractAddress = swapContract[chainId as keyof typeof swapContract].address;
  const swapContractAbi = swapContract[chainId as keyof typeof swapContract].abi;
  const { address: accountAddress } = useAccount();
  const client = usePublicClient();

  return useMutation({
    mutationFn: async ({
      amount,
      poolAddress,
      tokenIn,
      tokenOut,
      tradeType = TradeType.EXACT_INPUT,
    }: Params) => {
      invariant(wc, 'useSwapMutation. wc is undefined');
      invariant(accountAddress, 'useSwapMutation. accountAddress is undefined');

      const poolContract = getContract({
        abi: uniswapV3PoolAbi,
        address: poolAddress,
        client: wc,
      });

      const swapContract = getContract({
        abi: swapContractAbi,
        address: swapContractAddress,
        client: wc,
      });

      const fee = await poolContract.read.fee();
      const liquidity = await poolContract.read.liquidity();
      const slot = await poolContract.read.slot0();
      const token0 = await poolContract.read.token0();

      const poolTokenIn = new Token(111888, tokenIn.address, tokenIn.decimals);
      const poolTokenOut = new Token(111888, tokenOut.address, tokenOut.decimals);

      const currencyAmountToTrade = CurrencyAmount.fromRawAmount(
        tradeType === TradeType.EXACT_OUTPUT ? poolTokenOut : poolTokenIn,
        amount,
      );

      const pool = new Pool(
        poolTokenIn,
        poolTokenOut,
        fee,
        slot[0].toString(),
        liquidity.toString(),
        slot[1],
      );

      const swapRoute = new Route([pool], poolTokenIn, poolTokenOut);

      const { calldata } = SwapQuoter.quoteCallParameters(swapRoute, currencyAmountToTrade, tradeType, {
        useQuoterV2: true,
      }) as { calldata: `0x${string}` };

      const quoteCallReturnData = await client?.call({
        data: calldata,
        to: quoteContract[chainId as 111888].address,
      });

      if (!quoteCallReturnData?.data) return;

      const [amountOut] = decodeAbiParameters([{ type: 'uint256' }] as const, quoteCallReturnData?.data);

      const uncheckedTrade = Trade.createUncheckedTrade({
        inputAmount: currencyAmountToTrade,
        outputAmount: CurrencyAmount.fromRawAmount(poolTokenOut, amountOut.toString()),
        route: swapRoute,
        tradeType,
      });

      const options: SwapOptions = {
        deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
        recipient: accountAddress,
        slippageTolerance: new Percent(50, 10_000), // 50 bips, or 0.50%
      };

      const methodParameters = SwapRouter.swapCallParameters([uncheckedTrade], options);

      const { amountOutMinimum } = parseSwapParameters({
        calldata: methodParameters.calldata,
        tokenInAddress: tokenIn.address,
      });

      const {
        request: { args, ...options1 },
      } = await swapContract.simulate.exactInputSingle([
        {
          amountIn: BigInt(amount),
          amountOutMinimum: BigInt(amountOutMinimum),
          fee,
          recipient: accountAddress,
          sqrtPriceLimitX96:
            tokenIn.address.toLowerCase() === token0.toLowerCase()
              ? BigInt(TickMath.MIN_SQRT_RATIO.toString(10)) + BigInt(1)
              : BigInt(TickMath.MAX_SQRT_RATIO.toString(10)) - BigInt(1),
          tokenIn: tokenIn.address,
          tokenOut: tokenOut.address,
        },
      ]);

      const hash = await swapContract.write.exactInputSingle(args, options1);

      const receiptPromise = waitForTransactionReceipt(wc, { hash });

      return await receiptPromise;
    },
    onError: (e) => catchError(e),
  });
};

import type { ComponentProps } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { Icon, type IconName } from '../Icon';

type AddProps = {
  icon?: IconName;
  isDropdown?: boolean;
  isOpen?: boolean;
};

export const FilterButton = ({
  children,
  className,
  icon,
  isDropdown = true,
  isOpen,
  ref,
  ...props
}: AddProps & ComponentProps<'button'>) => {
  return (
    <button
      {...props}
      className={twMerge(
        'flex h-9 w-fit items-center rounded-lg bg-clay-10 p-3 text-sm outline-none transition-all hover:bg-clay-40 focus:outline-none',
        className,
      )}
      ref={ref}
    >
      {icon && <Icon className="mr-2 size-3.5 text-blue-800" name={icon} />}
      <span>{children}</span>
      {isDropdown && (
        <div className="ml-auto">
          <motion.div
            animate={{ rotate: isOpen ? -180 : 0 }}
            initial={{ rotate: 0 }}
            transition={{ duration: 0.25 }}
          >
            <Icon className="size-6 p-0 text-clay-500" name="arrowDownSm" safeArea="0" />
          </motion.div>
        </div>
      )}
    </button>
  );
};

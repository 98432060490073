import type { HTMLMotionProps } from 'framer-motion';

import { type PropsWithChildren } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

export const Card = ({ children, className, ...props }: PropsWithChildren<HTMLMotionProps<'div'>>) => {
  return (
    <motion.div
      className={twMerge('w-full rounded-lg border border-clay-20 bg-white p-3 xs:p-3 xs:px-4', className)}
      {...props}
    >
      {children}
    </motion.div>
  );
};

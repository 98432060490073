import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { HuggingfacePost } from 'shared/api/huggingface';
import type { Post } from 'shared/api/posts';

import { queryClient } from 'app/queryClient';
import { huggingfaceKeys } from 'shared/api/huggingface';
import {
  postsKeys,
  useDeleteCommentPostMutation,
  useDeleteHuggingfaceCommentPostMutation,
} from 'shared/api/posts';

export const useDeleteComment = (postId: string) => {
  const [searchParams] = useSearchParams();

  const sort = searchParams.get('sort') || 'trending';

  const { mutateAsync: deleteComment } = useDeleteCommentPostMutation();
  const { mutateAsync: deleteHuggingfaceComment } = useDeleteHuggingfaceCommentPostMutation();

  return useCallback(
    async (commentId: string, type: 'huggingface' | 'nesa') => {
      if (type === 'nesa') {
        const queryKey = postsKeys.postList();
        const data: { pages: Post[][] } | undefined = queryClient.getQueryData(queryKey);
        if (data) {
          queryClient.setQueryData(queryKey, {
            ...data,
            pages: data.pages.map((page) =>
              page.map((post) =>
                post._id === postId
                  ? {
                      ...post,
                      comments: post.comments.filter((comment) => comment._id !== commentId),
                    }
                  : post,
              ),
            ),
          });
        }

        await deleteComment(commentId);

        return;
      }

      const queryKey = huggingfaceKeys.postList({ sort });
      const data: { pages: HuggingfacePost[][] } | undefined = queryClient.getQueryData(queryKey);
      if (data) {
        queryClient.setQueryData(queryKey, {
          ...data,
          pages: data.pages.map((page) =>
            page.map((post) =>
              post.slug === postId
                ? {
                    ...post,
                    comments: post.comments.filter((comment) => comment._id !== commentId),
                  }
                : post,
            ),
          ),
        });
      }
      await deleteHuggingfaceComment(commentId);
    },
    [deleteComment, postId, sort, deleteHuggingfaceComment],
  );
};

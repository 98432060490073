import { useMemo, useState } from 'react';

import { DateTime } from 'luxon';

import type { Post } from 'shared/api/posts';

import nesaSrc from 'app/assets/images/logo-name-black.svg';
import { useUser } from 'app/stores/user';
import { timeAgo } from 'shared/helpers/timeAgo';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import { CreateReaction, useCreateReaction, useDeleteReaction } from './CreateReaction';
import { DetailNesaPost } from './DetailNesaPostModal';
import { NesaAttachment } from './NesaAttachment';
import { NesaAvatar } from './NesaAvatar';
import { NesaContent } from './NesaContent';
import { Reaction } from './Reaction';

type Props = {
  post: Post;
};

export const NesaPost = ({ post }: Props) => {
  const { user } = useUser();

  const [isExpandedPost, setIsExpandedPost] = useState(false);

  const createReaction = useCreateReaction(post._id, 'nesa');
  const deleteReaction = useDeleteReaction(post._id, 'nesa');

  const reactions = useMemo(() => {
    const res = post.reactions.reduce<
      Record<string, { authors: Set<string>; count: number; reaction: string }>
    >((acc, el) => {
      if (acc[el.reaction]) {
        acc[el.reaction].count += 1;
        acc[el.reaction].authors.add(el.author);
      } else {
        acc[el.reaction] = { authors: new Set([el.author]), count: 1, reaction: el.reaction };
      }

      return acc;
    }, {});

    return Object.values(res);
  }, [post]);

  return (
    <>
      <div
        className="cursor-pointer rounded-xl bg-white p-5 shadow-checkbox"
        onClick={() => setIsExpandedPost(true)}
      >
        <header className="mb-7 flex items-center gap-3.5">
          <NesaAvatar className="size-7 min-w-7" user={post.author} />
          <div className="flex flex-1 items-center gap-6">
            <h4 className="line-clamp-1 flex-1 text-base font-medium">
              <span className="text-clay-900">{post.author.first_name || post.author.email}</span>{' '}
              <span className="text-clay-500">posted an update</span>
            </h4>
            <div className="flex items-center gap-3.5">
              <span className="text-sm font-medium text-clay-300">
                {timeAgo(DateTime.fromMillis(post.updatedAt))}
              </span>
              <div className="flex h-[24px] items-center rounded-full bg-clay-10 px-3">
                <img alt="" className="h-2" src={nesaSrc} />
              </div>
            </div>
          </div>
        </header>
        <div className="pl-[2.625rem]">
          <NesaContent post={post} short />

          {!!post.attachments.length && (
            <div
              className="mt-5 flex gap-2 overflow-x-auto scrollbar-none"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {post.attachments.map((cid) => (
                <NesaAttachment cid={cid} key={cid} />
              ))}
            </div>
          )}

          <footer className="mt-5 flex w-full items-center gap-6 overflow-hidden">
            <div className="flex">
              <Button color="white" size="extra-small">
                Reply
                <Icon className="size-4" name="reply" />
              </Button>
            </div>
            <div className="flex flex-1 justify-end gap-6 overflow-hidden">
              <div className="flex items-center gap-1.5 text-clay-500">
                <Icon className="size-4" name="messageSquare" />
                <span className="whitespace-nowrap text-sm font-medium">{post.comments.length} Comments</span>
              </div>
              <div className="flex gap-2 overflow-x-auto scrollbar-none">
                {reactions.map((el, i) => (
                  <Reaction
                    count={el.count}
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (el.authors.has(user?._id || '')) {
                        deleteReaction(el.reaction);
                      } else {
                        createReaction(el.reaction);
                      }
                    }}
                    reaction={el.reaction}
                    selected={el.authors.has(user?._id || '')}
                  />
                ))}
                <CreateReaction postId={post._id} type="nesa" />
              </div>
            </div>
          </footer>
        </div>
      </div>
      <DetailNesaPost isOpen={isExpandedPost} onOpenChange={setIsExpandedPost} post={post} />
    </>
  );
};

import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { useAvatarUploadMutation } from 'shared/api/user/useAvatarUploadMutation';
import { useSendEmailVerificationMutation } from 'shared/api/verification/useSendEmailVerificationMutation';
import { useVerifyDiscordMutation } from 'shared/api/verification/useVerifyDiscordMutation';
import { useVerifyTwitterMutation } from 'shared/api/verification/useVerifyTwitterMutation';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { FilePicker } from 'shared/ui/FilePicker';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';
import { Switch } from 'shared/ui/Switch';
import { toaster } from 'shared/ui/Toast';

import discordSrc from '../assets/discord.svg';
import twitterSrc from '../assets/twitter.svg';
import { SettingLine } from './SettingLine';

type Props = {
  handleSaveSetting: () => Promise<void>;
};

export const General = ({ handleSaveSetting }: Props) => {
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [language, setLanguage] = useState('English');
  const [dateFormat, setDateFormat] = useState('DD/MM/YY');
  const [openImagePicker, setOpenImagePicker] = useState(false);

  const { isUpdating, updateUser, user } = useUser();
  const { formState, register } = useFormContext();
  const { errors } = formState;

  const avatarMutation = useAvatarUploadMutation();

  const { mutateAsync: sendVerificationEmal } = useSendEmailVerificationMutation();
  const { mutateAsync: verifyDiscord } = useVerifyDiscordMutation();
  const { mutateAsync: verifyTwitter } = useVerifyTwitterMutation();

  const isEmailVerified = user?.verification?.email;
  const isTwitterVerified = user?.verification?.twitter?.id;
  const isDiscordVerified = user?.verification?.discord?.id;

  const handleVerifyEmail = async () => {
    try {
      if (!user) {
        return;
      }

      await sendVerificationEmal();

      toaster.success('Email is sent');
    } catch (e) {
      console.error('send error', e);
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  const handleSaveImage = async (file: File) => {
    try {
      if (!user) return;

      const res = await avatarMutation.mutateAsync({ file, userId: user?._id });

      console.log('res', res);
      // const profileImage = await uploadImage({ file });
      updateUser({ ...res.data });
    } catch (e) {
      console.error('send error', e);
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Card className="flex flex-col xs:px-6 xs:pb-2">
        <SettingLine
          buttonText={openImagePicker ? '' : 'Edit'}
          className="border-b border-clay-20"
          middleSlot={
            openImagePicker ? (
              <FilePicker
                accept=".jpeg,.png,.svg,.jpg"
                className=" w-full flex-1"
                onFileChange={([file]) => {
                  handleSaveImage(file);
                  setOpenImagePicker(false);
                }}
              />
            ) : (
              <div
                className={twMerge(
                  'relative flex size-11 items-center justify-center overflow-hidden rounded-full bg-tusk-100 pb-1 font-klapt text-lg/none font-bold uppercase transition-colors',
                  user?.avatar && 'bg-white pb-0',
                )}
              >
                {user?.avatar && <img className="relative size-11 rounded-full" src={user?.avatar} />}
                {!user?.avatar &&
                  !avatarMutation.isPending &&
                  (user?.first_name?.slice(0, 1) ?? (user?.email?.slice(0, 1) || '-'))}
                {(isUpdating || avatarMutation.isPending) && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white/20">
                    <Spinner className="size-4" />
                  </div>
                )}
              </div>
            )
          }
          onButtonClick={() => setOpenImagePicker(true)}
          title="Profile"
        ></SettingLine>

        <SettingLine
          buttonText="Edit"
          className="border-b border-clay-20"
          middleSlot={<span className="text-sm font-light text-clay-500">Private</span>}
          onButtonClick={() => toaster.info('Coming soon')}
          title="Status"
        ></SettingLine>

        <SettingLine
          buttonText={isNameEdit || isUpdating ? '' : 'Edit'}
          className="border-b border-clay-20"
          middleSlot={
            isNameEdit ? (
              <div className="flex w-full flex-col gap-3">
                <Input
                  classNameLabel="mb-2 text-xs font-light text-clay-500"
                  disabled={isUpdating}
                  label={
                    <>
                      First Name<span className="ml-1 text-red-900">*</span>
                    </>
                  }
                  placeholder="Enter your first name"
                  required
                  {...register('firstName')}
                  error={errors.firstName?.message as string | undefined}
                />
                <Input
                  classNameLabel="mb-2 text-xs font-light text-clay-500"
                  disabled={isUpdating}
                  label={
                    <>
                      Last Name<span className="ml-1 text-red-900">*</span>
                    </>
                  }
                  placeholder="Enter your last name"
                  {...register('lastName')}
                  error={errors.lastName?.message as string | undefined}
                />
                <Button
                  className="w-fit"
                  disabled={isUpdating}
                  isLoading={isUpdating}
                  onClick={async () => {
                    try {
                      await handleSaveSetting();
                      setIsNameEdit(false);
                    } catch (error) {
                      toaster.error('Failed to update name');
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            ) : (
              <span className="text-sm font-light text-clay-500">
                {user?.first_name}&nbsp;{user?.last_name}
              </span>
            )
          }
          onButtonClick={() => setIsNameEdit(true)}
          title="Name"
        ></SettingLine>

        <SettingLine
          buttonText={isEmailVerified ? '' : 'Verify'}
          className="border-b border-clay-20"
          middleSlot={
            <div className="flex gap-5 text-sm font-light text-clay-500">
              {user?.email}
              {isEmailVerified && (
                <div className="ml-2 flex items-center rounded-xl bg-green-600/10 px-1.5 py-1 text-xs font-medium text-green-600">
                  <Icon className="mr-1.5 size-2.5 text-green-600" name="check" />
                  Verified
                </div>
              )}
            </div>
          }
          onButtonClick={() => handleVerifyEmail()}
          title="Email address"
        ></SettingLine>

        <SettingLine
          description="Link your accounts to NESA Playground for easier sharing. Earn more with joining the community"
          middleSlot={
            <div className="mt-3 flex w-full flex-col gap-3">
              <div className={twMerge('flex items-center', !isTwitterVerified && 'justify-between')}>
                <div className="flex w-40 items-center gap-3">
                  <div className="flex size-8 shrink-0 items-center justify-center rounded-2xl bg-clay-20">
                    <img className="size-4" src={twitterSrc} />
                  </div>
                  <span className="truncate text-sm text-clay-900">
                    {user?.verification?.twitter?.username || ''}
                  </span>
                </div>

                {isTwitterVerified ? (
                  <div className="ml-2 flex items-center rounded-xl bg-green-600/10 px-1.5 py-1 text-xs font-medium text-green-600">
                    <Icon className="mr-1.5 size-2.5 text-green-600" name="check" />
                    Verified
                  </div>
                ) : (
                  <div
                    className="ml-2 cursor-pointer rounded-lg border border-clay-40 px-2 py-1.5 text-xs text-clay-900 shadow-sm hover:bg-clay-10"
                    onClick={async () => {
                      const { link } = await verifyTwitter();
                      window.open(link, '_self');
                    }}
                  >
                    Link
                  </div>
                )}
              </div>

              <div className={twMerge('flex items-center', !isDiscordVerified && 'justify-between')}>
                <div className="flex w-40 items-center gap-3">
                  <div className="flex size-8 shrink-0 items-center justify-center rounded-2xl bg-clay-20">
                    <img className="size-4" src={discordSrc} />
                  </div>
                  <span className="truncate text-sm text-clay-900">
                    {user?.verification?.discord?.username || ''}
                  </span>
                </div>

                {isDiscordVerified ? (
                  <div className="ml-2 flex items-center rounded-xl bg-green-600/10 px-1.5 py-1 text-xs font-medium text-green-600">
                    <Icon className="mr-1.5 size-2.5 text-green-600" name="check" />
                    Verified
                  </div>
                ) : (
                  <div
                    className="ml-2 cursor-pointer rounded-lg border border-clay-40 px-2 py-1.5 text-xs text-clay-900 shadow-sm hover:bg-clay-10"
                    onClick={async () => {
                      const { link } = await verifyDiscord();
                      window.open(link, '_self');
                    }}
                  >
                    Link
                  </div>
                )}
              </div>
            </div>
          }
          title="Linked Accounts"
        ></SettingLine>
      </Card>

      <Card className="flex flex-col xs:px-6 xs:pb-2">
        <SettingLine
          className="border-b border-clay-20"
          middleSlot={
            <Select
              className="h-9 w-48 rounded-lg border border-clay-40 bg-white px-3 text-sm shadow-sm"
              onValueChange={setLanguage}
              placeholderClassName="text-sm font-light text-clay-500"
              value={language}
            >
              <Select.Content className="z-10 rounded-lg p-2 shadow-sm">
                <Select.Item
                  className="aria-selected:bg-clay-10 hover:bg-clay-10"
                  hasIndicator={false}
                  key="English"
                  value="English"
                >
                  English
                </Select.Item>
              </Select.Content>
            </Select>
          }
          title="Language"
        />

        <SettingLine
          className="border-b border-clay-20"
          middleSlot={
            <Label className="flex !cursor-not-allowed items-center gap-2">
              <Switch disabled />
              GMT&nbsp;+04:00
            </Label>
          }
          title="Automatic TimeZone"
        ></SettingLine>

        <SettingLine
          className="border-b border-clay-20"
          middleSlot={
            <Label>
              <Switch
                checked={user?.is_subscribed_email || false}
                disabled={isUpdating}
                onCheckedChange={(isChecked) =>
                  updateUser({ is_subscribed_email: isChecked }, { sendRequest: true })
                }
              />
            </Label>
          }
          title="Receive news by email"
        ></SettingLine>

        <SettingLine
          middleSlot={
            <Select
              className="h-9 w-48 rounded-lg border border-clay-40 bg-white px-3 text-sm shadow-sm"
              onValueChange={(val) => {
                setDateFormat(val);
              }}
              placeholderClassName="text-sm font-light text-clay-500"
              value={dateFormat}
            >
              <Select.Content className="z-10 w-48 rounded-lg p-2 shadow-sm">
                <Select.Item
                  className="aria-selected:bg-clay-10 hover:bg-clay-10"
                  hasIndicator={false}
                  key="DD/MM/YY"
                  value="DD/MM/YY"
                >
                  DD/MM/YY
                </Select.Item>

                {/* <Select.Item
                  className="aria-selected:bg-clay-10 hover:bg-clay-10"
                  hasIndicator={false}
                  key="MM/DD/YY"
                  value="MM/DD/YY"
                >
                  MM/DD/YY
                </Select.Item> */}
              </Select.Content>
            </Select>
          }
          title="Date format"
        ></SettingLine>
      </Card>
    </div>
  );
};

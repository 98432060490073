import type { DAIPriceChartProps } from './useGetDAIPriceChartQuery';

export const daiStatsKeys = {
  base: ['daiStats'],
  getEvmBalancesByAddress: (params: { address: string; limit?: number; skip?: number }) => [
    ...daiStatsKeys.base,
    'evm-balances',
    params,
  ],
  getPriceChart: (params: DAIPriceChartProps) => [...daiStatsKeys.base, 'price-chart', params],
  getTopDAIsByMCAPStats: () => [...daiStatsKeys.base, 'top-mcap-dais'],
  getTopDAIsByTVLStats: () => [...daiStatsKeys.base, 'top-tvl-dais'],
  getTopTotalByMCAPStats: () => [...daiStatsKeys.base, 'top-mcap-total'],
  getTopTotalByTVLStats: () => [...daiStatsKeys.base, 'top-tvl-total'],
  getUserPortfolioBalance: (params: { address: string }) => [
    ...daiStatsKeys.base,
    'portfolio-balance',
    params,
  ],
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from './types';

type Params = { connectedWalletIds: string[] } & Omit<DAI, '_id' | 'creator' | 'timestamp'>;

type Response = { data: DAI; walletAddress: string };

export const useCreateDaiMutation = (options: UseMutationOptions<Response, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<Response>(`/dai/create`, params);

      return data;
    },
    ...options,
  });
};

import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAIComment } from './types';

import { daiCommentsKeys } from './queryKeys';

type Response = {
  limit: number;
  list: DAIComment[];
  skip: number;
  totalCount: number;
};

type Params = {
  daiId: string;
  limit: number;
  repliedTo?: string;
};

export const useDAICommentsInfiniteQuery = (
  { daiId, limit, ...params }: Params,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: DAIComment[] = [], all: DAIComment[][]) =>
      last.length === limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<Response>(`/dai-comments/${daiId}/list`, {
        params: { limit, skip: pageParam * limit, ...params },
        signal,
      });

      return res.data.list;
    },
    queryKey: daiCommentsKeys.list({ daiId, limit, ...params }),
    ...(options || {}),
  });
};

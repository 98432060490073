// /cosmos/tx/v1beta1/txs/9EDD95C240E4682011CCFDC543DE490011A9971A170E86D0B914358183FAFF12

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { Network } from 'shared/helpers/getBaseEndpoints';
import type { UseQueryOptions } from 'shared/types';

import { getBaseEndpoints } from 'shared/helpers/getBaseEndpoints';

import type { GetAccountBalances } from './types';

import { chainKeys } from './queryKeys';

type Params = {
  address: string;
  network?: Network;
};

export const useBalancesQuery = (
  { address, network = 'testnet' }: Params,
  options: UseQueryOptions<GetAccountBalances> = {},
) => {
  const baseUrl = getBaseEndpoints(network);

  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<GetAccountBalances>(
        `${baseUrl}/cosmos/bank/v1beta1/balances/${address}`,
        { signal },
      );

      return data;
    },
    queryKey: chainKeys.balances({ account: address, network: baseUrl }),
    ...options,
  });
};

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { PeriodType } from 'pages/Wallet/types';

import { BackButton } from 'app/ui/BackButton';
import { ModelCard } from 'features/ModelCard';
import { chartTabs } from 'pages/Wallet/config';
import { useGetLLMModelStatisticsByPeriod } from 'shared/api/messages/useGetLLMModelStatisticsByPeriod';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';
import { useGetModelStatistics } from 'shared/api/models/useGetModelStatistics';
import { useGetModelStatisticsByPeriod } from 'shared/api/models/useGetModelStatisticsByPeriod';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import { ChartContent } from './ui/ChartContent';

export const Page = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [periodTab, setPeriodTab] = useState<PeriodType>('day');

  const { data: model, isError, isPending } = useGetModelByIdQuery(id!, { enabled: !!id });
  const { data: stats } = useGetModelStatistics({ ids: [id!] }, { enabled: !!id });
  const { data: chartData, isPending: isLoadingChart } = useGetModelStatisticsByPeriod(
    { groupBy: periodTab, id: id! },
    { enabled: !!id && !!model && model?.type !== 'text-generation' },
  );

  const { data: llmChartData, isPending: isLoadingLLMChart } = useGetLLMModelStatisticsByPeriod(
    { groupBy: periodTab, id: id! },
    { enabled: !!id && model?.type === 'text-generation' },
  );

  const isLLM = !!id && model?.type === 'text-generation';

  const periodRequests = (isLLM ? llmChartData : chartData)?.reduce((acc, { count }) => acc + count, 0);
  const isLoadingData = isLLM ? isLoadingLLMChart : isLoadingChart;

  if ((!model && !isPending) || isError) {
    return (
      <div className="flex size-full flex-col items-center justify-center gap-4 text-center text-base font-light text-clay-400">
        Model not found
        <Button onClick={() => navigate('/')}>Go Home</Button>
      </div>
    );
  }

  if (!model || isPending) {
    return (
      <div className="flex size-full items-center justify-center py-12">
        <Spinner className="size-6 " />
      </div>
    );
  }

  return (
    <AnimateRoute className="mx-auto max-w-7xl px-4 py-10">
      <div className="mb-4 flex flex-wrap items-center justify-between">
        <BackButton onClick={() => navigate(-1)}>My Model</BackButton>

        <div className="flex flex-wrap gap-4">
          <Button color="secondary" onClick={() => toaster.info('Coming soon')} variant="filled-light">
            Download Model
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <ModelCard {...model} requestCount={stats?.data[model._id].requestCount} />
        <Card>
          <div className="flex flex-wrap items-center gap-3">
            <h2 className="text-lg font-normal">Requests served</h2>

            <div className="flex gap-1.5">
              {chartTabs.map((tab) => (
                <button
                  aria-selected={tab.value === periodTab}
                  className="h-7 w-11 rounded-full bg-clay-20 text-center text-sm font-medium leading-7 text-clay-700 transition-colors aria-selected:bg-primary-800 aria-selected:text-white hover:bg-primary-100 hover:text-clay-700 aria-selected:hover:bg-primary-1000 aria-selected:hover:text-white xs:w-12"
                  key={tab.value}
                  onClick={() => setPeriodTab(tab.value)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-[3fr_1fr]">
            <ChartContent
              data={isLLM ? llmChartData : chartData}
              isLoading={isLLM ? isLoadingLLMChart : isLoadingChart}
              period={periodTab}
            />

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-4 rounded-xl border border-clay-20 px-4 py-3">
                <div className="flex size-11 items-center justify-center rounded-lg bg-primary-40 p-1.5">
                  <Icon className="size-full text-primary-800" name="chartBar" />
                </div>
                <div className="flex flex-col">
                  <div className="text-xl text-primary-800">
                    {isLoadingData ? <Spinner className="mb-2 size-5" /> : periodRequests || '-'}
                  </div>
                  <div className="text-base">Requests served</div>
                  <div className="text-sm font-light text-clay-500">Last {periodTab}</div>
                </div>
              </div>
              <div className="flex items-center gap-4 rounded-xl border border-clay-20 px-4 py-3">
                <div className="flex size-11 items-center justify-center rounded-lg bg-primary-40 p-1.5">
                  <Icon className="size-full text-primary-800" name="cloudCheck" />
                </div>
                <div className="flex flex-col">
                  <div className="text-xl text-primary-800">
                    {stats?.data[model._id].requestCount.toLocaleString()}
                  </div>
                  <div className="text-base">Total requests served</div>
                  <div className="text-sm font-light text-clay-500">All time</div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </AnimateRoute>
  );
};

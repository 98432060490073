import { useAccount, useQuerySmart } from 'graz';

import { stakingContractAddress } from 'entities/Stake';
import { fromNesToUSD } from 'shared/helpers/fromNesToUSD';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { Spinner } from 'shared/ui/Spinner';

type Response = {
  total_num_stakes_claimed: number;
  total_num_stakes_initiated: number;
  total_staked: string;
  total_unstaked: string;
};
export const UserTotalStakes = () => {
  const { data: account, isConnected } = useAccount();

  const { data, isLoading } = useQuerySmart<Response, Error>({
    address: stakingContractAddress,
    queryMsg: {
      get_account_stats: {
        account: account?.bech32Address,
      },
    },
  });

  return (
    <div className="flex flex-col gap-1.5">
      <div className="text-sm/none text-clay-400">My total staked</div>
      <div className="text-xl/none font-semibold">
        {!isConnected ? (
          '--'
        ) : isLoading ? (
          <Spinner className="size-4" />
        ) : (
          `$${data?.total_staked ? fromNesToUSD(fromUnesToNes(data?.total_staked).toString()).toLocaleString('en-US', { maximumFractionDigits: 2 }) : '-'}`
        )}
      </div>
    </div>
  );
};

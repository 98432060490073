import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';
import { useAccount as wagmiAccount } from 'wagmi';

import { useDaiTransfersInfiniteQuery } from 'shared/api/daiTransfers';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { Icon } from 'shared/ui/Icon';

import type { TableItem } from './ui/Table';

import nesaSrc from './assets/nesa.svg';
import { Table } from './ui/Table';

export const EvmTransactions = () => {
  const { address: evmAccountAddress } = wagmiAccount();

  const {
    data: evmTransactionHistory,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
  } = useDaiTransfersInfiniteQuery(
    {
      limit: 20,
      walletAddress: evmAccountAddress ?? '',
    },
    { enabled: Boolean(evmAccountAddress) },
  );

  const tableItems: TableItem[] =
    evmTransactionHistory?.pages.flatMap((p) => {
      return p.list
        .filter((t) => t.args.from !== t.args.to)
        .map((transaction) => {
          const isDAI = Boolean(transaction?.dai);
          const isUSDN = Boolean(transaction?.usdn);
          const transactionType = transaction.args.from === evmAccountAddress ? 'Sent' : 'Received';

          return {
            avatar: isDAI ? transaction.dai?.profileImage : isUSDN ? nesaSrc : undefined,
            id: transaction.id,
            link: `https://explorer-evm.test.nesa.ai/tx/${transaction.transactionHash}`,
            rightAcc: (
              <div className="flex justify-end">
                <div
                  className={twMerge(
                    'flex size-4 items-center text-clay-380',
                    transactionType === 'Received' && 'text-green-600',
                  )}
                >
                  <Icon
                    className={twMerge('size-3', transactionType === 'Received' && 'rotate-180')}
                    name="arrowDown"
                  />
                </div>
              </div>
            ),
            rightValue:
              fromUnesToNes(transaction.args.valueString, isDAI ? 18 : isUSDN ? 6 : 1)
                .toNumber()
                .toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }) || '0',
            subtitle: DateTime.fromSeconds(transaction.timestamp).toFormat('yyyy MMM dd HH:mm'),
            title:
              isDAI && transaction.dai?.projectName
                ? transaction.dai?.projectName
                : isUSDN
                  ? 'USDN'
                  : 'Unknown',
          };
        });
    }) || [];

  return (
    <Table
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isPending={isPending}
      // items={[...tableItems, ...tableItems, ...tableItems]}
      items={tableItems}
    />
  );
};

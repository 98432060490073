import { useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { EllipsisVertical, Flag, Reply, Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { DAIComment } from 'shared/api/daiComments/types';
import type { ClassName } from 'shared/types';

import { useUser } from 'app/stores/user';
import { daiCommentsKeys } from 'shared/api/daiComments/queryKeys';
import { useAddDaiCommentReactionMutation } from 'shared/api/daiComments/useAddDaiCommentReactionMutation';
import { useDeleteDaiCommentReactionMutation } from 'shared/api/daiComments/useDeleteDaiCommentReactionMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { timeAgo } from 'shared/helpers/timeAgo';
import { uuidv4 } from 'shared/helpers/uuid';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

import { AddReactionButton } from './AddReactionButton';

type Props = {
  author: string;
  authorId: string;
  authorImage?: string;
  commentId: string;
  commentsCount?: number;
  daiId: string;
  isDeleting?: boolean;
  onDeleteComment: () => void;
  onReply?: () => void;
  onReport?: () => void;
  reactions?: DAIComment['reactions'];
  repliedTo?: string;
  text: string;
  timestamp: number;
} & ClassName;

export const CommentCard = ({
  author,
  authorId,
  authorImage,
  className,
  commentId,
  commentsCount,
  daiId,
  isDeleting,
  onDeleteComment,
  onReply,
  onReport,
  reactions = [],
  repliedTo,
  text,
  timestamp,
}: Props) => {
  const { user } = useUser();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteReaction } = useDeleteDaiCommentReactionMutation({
    onError: (_err, _newData, context) => {
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });

      queryClient.setQueryData(
        queryKey,
        typeof context === 'object' && context && 'previous' in context ? context['previous'] : undefined,
      );

      const commentQueryKey = daiCommentsKeys.comment({ commentId });

      queryClient.setQueryData(
        commentQueryKey,
        typeof context === 'object' && context && 'previousComment' in context
          ? context['previousComment']
          : undefined,
      );
    },
    onMutate: async (params) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });
      await queryClient.cancelQueries({ queryKey });
      // Snapshot the previous value
      const previousData = queryClient.getQueryData<{ pageParams: number[]; pages: DAIComment[][] }>(
        queryKey,
      );
      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, {
        ...previousData,
        pages: previousData?.pages.map((page) =>
          page.map((item) =>
            item._id === commentId
              ? { ...item, reactions: item.reactions?.filter(({ _id }) => _id !== params.reactionId) }
              : item,
          ),
        ),
      });

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const commentQueryKey = daiCommentsKeys.comment({ commentId });
      await queryClient.cancelQueries({ queryKey: commentQueryKey });
      // Snapshot the previous value
      const previousComment = queryClient.getQueryData<{ data: DAIComment }>(commentQueryKey);
      // Optimistically update to the new value
      queryClient.setQueryData(commentQueryKey, {
        data: {
          ...(previousComment?.data || {}),
          reactions: previousComment?.data?.reactions?.filter(({ _id }) => _id !== params.reactionId),
        },
      });

      // // Return a context object with the snapshotted value
      return { previous: previousData, previousComment };
    },
    // Always refetch after error or success:
    onSettled: () => {
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });
      queryClient.invalidateQueries({ queryKey });
      const commentQueryKey = daiCommentsKeys.comment({ commentId });
      queryClient.invalidateQueries({ queryKey: commentQueryKey });
    },
  });

  const { mutateAsync: addReaction } = useAddDaiCommentReactionMutation({
    onError: (_err, _newData, context) => {
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });

      queryClient.setQueryData(
        queryKey,
        typeof context === 'object' && context && 'previous' in context ? context['previous'] : undefined,
      );

      const commentQueryKey = daiCommentsKeys.comment({ commentId });

      queryClient.setQueryData(
        commentQueryKey,
        typeof context === 'object' && context && 'previousComment' in context
          ? context['previousComment']
          : undefined,
      );
    },
    onMutate: async (params) => {
      const newReactionId = `custom-${uuidv4()}`;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });
      await queryClient.cancelQueries({ queryKey });
      // Snapshot the previous value
      const previousData = queryClient.getQueryData<{ pageParams: number[]; pages: DAIComment[][] }>(
        queryKey,
      );
      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, {
        ...previousData,
        pages: previousData?.pages.map((page) =>
          page.map((item) =>
            item._id === params.commentId
              ? {
                  ...item,
                  reactions: [
                    ...(item.reactions || []),
                    { _id: newReactionId, author: user?._id, reaction: params.reaction },
                  ],
                }
              : item,
          ),
        ),
      });

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const commentQueryKey = daiCommentsKeys.comment({ commentId });
      await queryClient.cancelQueries({ queryKey: commentQueryKey });
      // Snapshot the previous value
      const previousComment = queryClient.getQueryData<{ data: DAIComment }>(commentQueryKey);
      // Optimistically update to the new value

      if (previousComment) {
        queryClient.setQueryData(commentQueryKey, {
          data: {
            ...(previousComment?.data || {}),
            reactions: [
              ...(previousComment?.data?.reactions || []),
              { _id: newReactionId, author: user?._id, reaction: params.reaction },
            ],
          },
        });
      }

      // // Return a context object with the snapshotted value
      return { previous: previousData, previousComment };
    },
    // Always refetch after error or success:
    onSettled: () => {
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });
      queryClient.invalidateQueries({ queryKey });
      const commentQueryKey = daiCommentsKeys.comment({ commentId });
      queryClient.invalidateQueries({ queryKey: commentQueryKey });
    },
  });

  const reactionsMap = useMemo(
    () =>
      reactions.reduce<Record<string, { count: number; reactionIds: string[]; users: string[] }>>(
        (acc, item) => {
          acc[item.reaction] = {
            count: (acc[item.reaction]?.count || 0) + 1,
            reactionIds: [...(acc[item.reaction]?.reactionIds || []), item._id],
            users: [...(acc[item.reaction]?.users || []), item.author],
          };

          return acc;
        },
        {},
      ),
    [reactions],
  );

  const handleRemove = async (reactionId: string) => {
    try {
      if (reactionId.startsWith('custom-')) return;
      await deleteReaction({ reactionId });
    } catch (e) {
      catchError(e);
    }
  };

  const reactionArray = Object.entries(reactionsMap);
  return (
    <div className={twMerge('flex gap-3', className)}>
      <div
        className="size-6 min-w-6 overflow-hidden rounded-full bg-clay-20 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${authorImage})` }}
      ></div>
      <div className="flex grow flex-col gap-3">
        <div className="flex items-center justify-between gap-3">
          <div className=" text-sm text-clay-600">
            <span className="mr-1 text-clay-900 underline">{author}</span>
            posted an <span className="text-clay-900">update</span>
          </div>
          <div className="flex min-w-fit items-center gap-2">
            <div className="min-w-fit text-xs font-light text-clay-300">
              {timeAgo(DateTime.fromMillis(timestamp))}
            </div>
            {!!user && (
              <Popover>
                <Popover.Trigger className="flex size-7 min-w-7 items-center justify-center rounded-full border border-clay-20">
                  <EllipsisVertical className="size-4 text-clay-350" />
                </Popover.Trigger>
                <Popover.Content className="px-2">
                  {user?._id === authorId && (
                    <div
                      className={twMerge(
                        'group flex cursor-pointer items-center gap-2 rounded-md py-2.5 pl-2 pr-4 text-sm/none font-light text-clay-500 transition hover:bg-clay-10',
                        isDeleting && 'pointer-events-none opacity-50',
                      )}
                      onClick={onDeleteComment}
                    >
                      <Trash className="size-3 cursor-pointer  text-clay-350 transition group-hover:text-red-900" />{' '}
                      <span className="text-sm/none">Delete</span>{' '}
                    </div>
                  )}

                  <div
                    className={twMerge(
                      'group flex cursor-pointer items-center gap-2 rounded-md py-2.5 pl-2 pr-4 text-sm/none font-light text-clay-500 transition hover:bg-clay-10',
                    )}
                    onClick={onReport}
                  >
                    <Flag className="size-3 cursor-pointer  text-clay-350 transition group-hover:text-red-900" />{' '}
                    <span className="text-sm/none">Report</span>{' '}
                  </div>
                </Popover.Content>
              </Popover>
            )}
          </div>
        </div>

        <div className="text-sm font-light text-clay-600">{text}</div>

        <div className="flex flex-wrap items-center justify-between gap-3">
          {!!onReply && (
            <Button className="h-8 gap-1.5 pl-2 text-xs text-clay-400" color="white" onClick={onReply}>
              <Reply className="size-4" />
              Reply
            </Button>
          )}

          <div className="ml-auto flex flex-wrap items-center justify-end gap-x-4 gap-y-2 text-xs">
            {commentsCount !== undefined && (
              <div className="flex items-center gap-1 text-clay-400">
                <Icon className="size-3.5 text-clay-400" name="messageSquare" /> {commentsCount} comments
              </div>
            )}

            <div className="flex flex-wrap items-center justify-end gap-2">
              {reactionArray.map(([reaction, data]) => {
                const reactionIdx = data.users?.findIndex((userId) => userId === user?._id);
                const hasUserEnabledReaction = reactionIdx >= 0;
                return (
                  <div
                    className={twMerge(
                      'flex h-7 cursor-pointer select-none items-center justify-center gap-2 rounded-full border border-clay-20 pl-1.5 pr-2 text-clay-300 transition hover:border-primary-1000',
                      hasUserEnabledReaction && 'border-primary-800 bg-primary-40 text-primary-800',
                    )}
                    key={reaction}
                    onClick={() => {
                      if (hasUserEnabledReaction) {
                        handleRemove(data.reactionIds[reactionIdx]);
                      } else {
                        addReaction({ commentId: commentId, reaction: reaction });
                      }
                    }}
                  >
                    <span className="relative top-px text-lg/none">{reaction}</span>
                    <span className="text-xs/none">{data.count}</span>
                  </div>
                );
              })}

              {!!user && (
                <AddReactionButton
                  className="ml-auto"
                  commentId={commentId}
                  daiId={daiId}
                  repliedTo={repliedTo}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

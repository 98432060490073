import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { LiveEvent } from 'shared/api/dai/types';
import type { ClassName } from 'shared/types';

import { fromNesToUSD } from 'shared/helpers/fromNesToUSD';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';

import { DAIPopup } from './DAIPopup';
import { DelayedBorder } from './DelayedBorder';

import './borderAnimation.css';

type Props = {
  event: LiveEvent;
  index: number;
  onIntersectionChange?: (index: number) => void;
  onOpenChange: (v: boolean) => void;
} & ClassName;

export const TokenEvent = ({
  className,
  event: { _id, amount, company_id, type, uniqueKey },
  index,
  onOpenChange,
}: Props) => {
  const [daiPopupVisible, setDaiPopupVisibility] = useState(false);

  if (!company_id?._id) return null;
  const formattedAmount = +fromNesToUSD(fromUnesToNes(amount || '0').toString()).toFixed(2);

  return (
    <AnimatePresence>
      <motion.div
        animate={{ opacity: 1, x: 0 }}
        className={twMerge(
          'relative flex cursor-pointer items-center overflow-hidden rounded-lg p-2',
          !company_id?._id && 'cursor-default',
          // intersection?.isIntersecting && 'bg-red-900',
          className,
        )}
        // exit={{ opacity: 0, x: 50 }}
        initial={{ opacity: 0, x: -150 }}
        key={uniqueKey || _id}
        layout
        onClick={() => {
          setDaiPopupVisibility(true);
          onOpenChange(true);
        }}
        transition={{ damping: 20, stiffness: 100, type: 'spring' }}
      >
        {index === 0 && <DelayedBorder wait={350} />}

        <span className="max-w-36 truncate font-medium">{company_id?.projectName}</span>
        <span className="ml-2 min-w-4 text-clay-500">received</span>
        <div className="ml-4">
          <span className="min-w-1 text-clay-500">$</span>
          <span className="ml-0.5 min-w-4 font-semibold">{formattedAmount < 1 ? 1 : formattedAmount}</span>
        </div>

        <div
          className={twMerge(
            'ml-4 h-6 w-auto min-w-4 rounded-md bg-white p-1 text-xs font-semibold uppercase',
            type === 'createStake' ? 'text-green-500' : 'text-primary-800',
          )}
        >
          {type === 'createStake' ? 'STAKE' : 'SWAP'}
        </div>
      </motion.div>
      {company_id?._id && daiPopupVisible && (
        <DAIPopup
          daiId={company_id._id}
          isOpened={daiPopupVisible}
          onOpenChange={(v) => {
            setDaiPopupVisibility(v);
            onOpenChange(v);
          }}
        />
      )}
    </AnimatePresence>
  );
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type UploadParams = {
  datasetId: string;
  file: File;
  path: string;
};

export const useUploadDatasetMutation = (
  options: UseMutationOptions<{ data: string }, Error, UploadParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ datasetId, file, path }: UploadParams) => {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('datasetId', datasetId);
      formData.append('path', path);

      const { data } = await axiosInstance.post<{ data: string }>(`/datasets/upload/file`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 6 * 60 * 1000,
      });

      return data;
    },
    ...options,
  });
};

import { useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { ChevronLeftIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { categories } from 'entities/DAI/const';
import { TrendingDAIs } from 'pages/DAIOverview/ui/TrendingDAIs';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import { ExploreDAI as ExploreDAIComponent } from '../DAIOverview/ui/ExploreDAI';

export const Page = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCategory = searchParams.get('category') || undefined;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="rounded-b-xl bg-white" ref={ref}>
        <div className="grid grid-cols-1 lg:grid-cols-4">
          {/* main column */}
          <div className="col-span-1 border-r border-clay-20 p-3 md:col-span-3 md:p-6">
            <div className="mb-6 flex flex-col justify-between gap-3 md:flex-row md:items-center">
              <div className="flex flex-col gap-1">
                <h1 className="text-3xl font-black md:text-4xl">Explore Nesa</h1>
                <div className="text-base font-light text-clay-500 md:text-lg">
                  Explore the <span className="font-semibold">decentralized AI</span> projects and protocols
                  on Nesa Chain.
                </div>
              </div>

              <div className="flex gap-2">
                <Button as={Link} asIcon color="secondary" to="/">
                  <ChevronLeftIcon className="size-4 text-clay-300" strokeWidth={1.5} />
                </Button>
                <Button as={Link} className="pl-3" color="dark" to="/dai/create">
                  <Icon className="size-5 text-white" name="customDai" />
                  Create a DAI <sup className="-ml-0.5 text-[6.5px] font-light">TM</sup>
                </Button>
              </div>
            </div>

            <ExploreDAIComponent defaultViewType="row" withSearchInput />
          </div>

          {/* right section */}
          <div className="flex flex-col gap-6 p-3 md:p-6">
            <TrendingDAIs />
            <div className="flex flex-col gap-3">
              <div className="text-lg font-medium">DAI Categories</div>

              <div className="flex flex-col gap-1.5">
                {categories.map((category) => {
                  const isSelected = category.value === selectedCategory;
                  return (
                    <div
                      className={twMerge(
                        'cursor-pointer rounded-lg px-3 py-2 text-sm font-medium text-clay-500 transition-colors hover:bg-clay-10',
                        isSelected && 'bg-clay-10 text-clay-800',
                      )}
                      key={category.value}
                      onClick={() => {
                        setSearchParams((prev) => {
                          const newSearch = new URLSearchParams(prev);
                          if (isSelected) {
                            newSearch.delete('category');
                          } else {
                            newSearch.set('category', category.value);
                          }
                          return newSearch;
                        });
                        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                      }}
                    >
                      {category.label}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

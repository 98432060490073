import type { LlmMessage } from 'shared/api/messages/types';

import { queryClient } from 'app/queryClient';
import { getLlmSessionMessageQueryKey } from 'shared/api/messages/useGetLlmSessionMessagesQuery';

import type { LLMQueryParams } from '../ui/LLMParams';

import { tokenizer } from './hotoke';

type Props = {
  _id: string;
  isEncoded?: boolean;
  messageText: string;
  modelName: string;
  params: LLMQueryParams;
  role: 'assistant' | 'user';
  sessionId?: string;
  userId: string;
};

export const addLLMMessage = ({
  _id,
  isEncoded,
  messageText,
  modelName,
  params,
  role,
  sessionId = 'default',
  userId,
}: Props) => {
  // get current messages
  const historyQueryKey = getLlmSessionMessageQueryKey({ modelName, sessionId, userId });
  const currentMessagesData: { pages: LlmMessage[][] } = queryClient.getQueryData(historyQueryKey) || {
    pages: [],
  };

  const pages = currentMessagesData.pages || [];
  const lastMsg = pages?.[0]?.[0];

  // if last message is from ai then it means that FE received the next message part
  const isLastMsgFromAi = lastMsg?.history.role === 'assistant';
  const isNewMessage = role === 'user' || (role === 'assistant' && !isLastMsgFromAi);

  if (isNewMessage) {
    // if there is only one message from ai then it means that user's message is saved to default sessionId
    const isCurrentFromAi = role === 'assistant' && !lastMsg;

    const messageToAdd = {
      _id: _id,
      history: { content: isEncoded ? tokenizer.decode([messageText]) : messageText || '', role },
      // history: { content: messageText || '', role },
      model_name: modelName,
      model_params: { temperature: params.temperature, top_p: params.topP },
      session: { session_id: sessionId, user_id: userId },
      timestamp: Date.now(),
    };

    if (isCurrentFromAi) {
      const defaultQueryKey = getLlmSessionMessageQueryKey({ modelName, sessionId: 'default', userId });
      const defaultMessagesData: { pages: LlmMessage[][] } = queryClient.getQueryData(defaultQueryKey) || {
        pages: [],
      };

      const firstMsg = defaultMessagesData.pages?.[0]?.[0];
      const hasUserDefault = firstMsg?.history.role === 'user';

      if (hasUserDefault) {
        queryClient.setQueryData(historyQueryKey, {
          ...defaultMessagesData,
          pages: [
            [
              messageToAdd,
              { ...firstMsg, session: { ...firstMsg.session, session_id: sessionId } },
              ...(pages[0] || []),
            ],
            ...pages.slice(1),
          ],
        });

        return;
      }
    }

    queryClient.setQueryData(historyQueryKey, {
      pageParams: [0],
      ...currentMessagesData,
      pages: [[messageToAdd, ...(pages[0] || [])], ...pages.slice(1)],
    });

    return;
  }

  queryClient.setQueryData(historyQueryKey, {
    ...currentMessagesData,
    pageParams: [0],

    pages: [
      [
        {
          ...lastMsg,
          history: {
            ...lastMsg.history,
            content: lastMsg.history.content + (isEncoded ? tokenizer.decode([messageText]) : messageText),
          },
        },
        ...currentMessagesData.pages[0].slice(1),
      ],
      ...currentMessagesData.pages.slice(1),
    ],
  });
};

import type { Model } from 'shared/api/models/types';
import type { TableColumn } from 'shared/ui/Table';

import { formatNumber } from 'shared/helpers/formatNumber';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { getPrice } from 'shared/helpers/getPrice';
import { Pricing } from 'shared/ui/GalleryCard/ui/Pricing';
import { Icon } from 'shared/ui/Icon';

export const getColumns = (
  settings: Set<string>,
  total = 0,
  sortType: 'asc' | 'desc',
): TableColumn<keyof Model, Model>[] => {
  const columns: TableColumn<keyof Model, Model>[] = [
    {
      key: '_id',
      renderTd: (_, index) => (
        <div className="text-center font-semibold text-clay-900">
          {sortType === 'asc' ? total - index : index + 1}
        </div>
      ),
      thClassName: 'w-[5rem]',
      title: (
        <div className="flex justify-center">
          <Icon className="size-4" name="crown" />
        </div>
      ),
    },
    {
      key: 'name',
      renderTd: ({ name }) => {
        const [title, des] = name.split('/');
        return (
          <div className="">
            {des && title && <div className="text-xs font-semibold capitalize text-clay-300">{title}</div>}
            <div className="line-clamp-3 w-full text-sm font-normal capitalize text-clay-900">
              {(des || title).split('_').join(' ')}
            </div>
          </div>
        );
      },
      thClassName: 'w-full',
      title: 'Model',
      withSort: true,
    },
    {
      key: 'type',
      renderTd: ({ type }) => getCategoryLabel(type),
      thClassName: 'w-[20%] min-w-[12rem]',
      title: 'Category',
    },
    {
      key: 'ranking',
      renderTd: ({ ranking }) => (typeof ranking === 'number' ? ranking : '---'),
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Ranking',
      withSort: true,
    },
    {
      key: 'likes',
      renderTd: ({ likes }) => (typeof likes === 'number' ? formatNumber(likes, { decimals: 0 }) : '---'),
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Likes',
      withSort: true,
    },
    {
      key: 'downloads',
      renderTd: ({ likes }) => (typeof likes === 'number' ? formatNumber(likes, { decimals: 0 }) : '---'),
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Downloads',
      withSort: true,
    },
    {
      key: 'price',
      renderTd: (model) => (
        <Pricing model={model}>
          <span>
            $
            {(model?.pricing?.output_price && getPrice(model?.pricing?.output_price)) ||
              model?.price?.toFixed(3) ||
              0.001}
          </span>
        </Pricing>
      ),
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Price',
      withSort: true,
    },
    {
      key: 'latency',
      renderTd: (model) => <>{model.latency} sec</>,
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Latency',
      withSort: true,
    },

    // {
    //   key: 'timestamp',
    //   renderTd: (model) => (
    //     <>
    //       {(model.timestamp && DateTime.fromISO(model.timestamp).toLocaleString(DateTime.DATE_SHORT)) || '-'}
    //     </>
    //   ),
    //   thClassName: 'w-[12%] min-w-[9rem]',
    //   title: 'Date',
    //   withSort: false,
    // },
  ];

  if (!settings.size) {
    return columns;
  }

  return columns.filter((el) => settings.has(el.key) || el.key === '_id' || el.key === 'name');
};

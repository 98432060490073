import { useForm } from 'react-hook-form';

import { Bug } from 'lucide-react';

import { useReportCommentMutation } from 'shared/api/daiComments/useReportCommentMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

type Props = {
  commentId: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

type FormValues = {
  reason: string;
};

export const ReportModal = ({ commentId, isOpen, onOpenChange }: Props) => {
  const { isPending, mutateAsync: report } = useReportCommentMutation();
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { reason: '' },
    mode: 'onChange',
  });

  const handleReport = async (values: FormValues) => {
    try {
      await report({ commentId, reason: values.reason });

      toaster.success('Your report was sent successfully');
      onOpenChange(false);
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content>
        <Modal.CloseButton />

        <h1 className="mb-4 flex items-start gap-3">
          <Bug className="size-6 min-w-6 text-clay-900 sm:size-7" />

          <div className="mt-0 flex flex-col gap-2 sm:mt-1">
            <h1 className="text-xl font-light text-clay-900">Report a comment</h1>
            <div className="text-sm font-light text-clay-500">
              This will open a public discussion in the community tab and also ping the Nesa team about your
              report.
            </div>
          </div>
        </h1>

        <div className="relative mb-2 flex flex-col">
          <TextArea
            {...register('reason', { required: 'Reason is required' })}
            error={errors?.['reason']?.message?.toString() || undefined}
            placeholder="Report..."
            rows={10}
          />
        </div>

        <div className="flex flex-wrap items-center justify-between gap-2">
          <Button
            className="font-light"
            disabled={!isValid}
            isLoading={isPending}
            onClick={handleSubmit(handleReport)}
          >
            Send Report
          </Button>

          <a
            className="cursor-pointer text-sm/none text-clay-700 underline transition-colors hover:text-clay-900"
            href="https://nesa.ai/contact"
            rel="noreferrer"
            target="_blank"
          >
            Need help with reports?
          </a>
        </div>
      </Modal.Content>
    </Modal>
  );
};

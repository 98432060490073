import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';

import type { DatasetSort } from 'shared/api/datasets/types';

import { useGetDatasetsListInfiniteQuery } from 'shared/api/datasets/useGetDatasetsQuery';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';

import { useSettingTableKeysContext } from '../LeaderBoardTabs';
import { getColumns } from './config';
import { Skeleton } from './ui/Skeleton';

type Props = {
  short?: boolean;
};

export const DatasetTab = ({ short }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const sort = (searchParams.get('sort') || 'trending') as DatasetSort;

  const selectedTableKeys = useSettingTableKeysContext();

  const {
    data: datasetList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useGetDatasetsListInfiniteQuery({ sort: sort });

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const isLoading = status === 'pending';

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage && !short) {
      fetchNextPage();
    }
  }, [fetchNextPage, short, hasNextPage, intersection?.isIntersecting]);

  const columns = useMemo(() => {
    const page = datasetList?.pages[0];
    if (!page) return [];
    return getColumns(selectedTableKeys, page.numTotalItems, sort);
  }, [sort, datasetList, selectedTableKeys]);

  const tableData = useMemo(() => {
    if (!datasetList) return [];
    const list = datasetList?.pages.flatMap((el) => el.datasets);

    if (short) {
      return list.slice(0, 5);
    }
    return list;
  }, [datasetList, short]);

  return (
    <>
      <div className="w-full flex-1 overflow-auto">
        {isLoading ? (
          <Skeleton selectedTableKeys={short ? new Set() : selectedTableKeys} />
        ) : (
          <>
            <Table
              className="min-w-[58rem]"
              columns={columns}
              data={tableData}
              onRowClick={(row) => navigate(`/datasets/${row.datasetId.replace('/', '---')}`)}
            />
            <div ref={ref} />
          </>
        )}
        {isFetchingNextPage && (
          <div className=" flex justify-center py-5">
            <Spinner className=" size-8" />
          </div>
        )}
      </div>
    </>
  );
};

import type { WalletBalance } from 'pages/Wallet/types';

// import { sortWalletBalances } from 'pages/Wallet/helpers';
import { Table } from 'pages/Wallet/ui/Table';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';

type Props = { chainBalances: WalletBalance[] };

export const MyAssetsCosmos = ({ chainBalances }: Props) => {
  return (
    <Table
      fetchNextPage={() => void 0}
      hasNextPage={false}
      isFetchingNextPage={false}
      isPending={false}
      items={chainBalances
        .filter((coin) => fromUnesToNes(coin.amount, coin.decimals).toNumber() > 0.01)
        // ?.sort(sortWalletBalances)
        .map((coin) => ({
          avatar: coin.image,
          id: coin._id,
          rightValue:
            fromUnesToNes(coin.amount, coin.decimals).toNumber().toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) || '0',
          subtitle: coin.subtitle,
          title: coin.label,
        }))}
      label="Assets with Balance"
    />
  );
};

import type { AxiosError } from 'axios';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccount } from 'graz';

import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { WalletProviderModal } from 'features/WalletProviderModal';
import { FaucetRequestState } from 'shared/api/faucet/types';
import { useRequestTokensMutation } from 'shared/api/faucet/useRequestTokensMutation';
import { useVerifyDiscordMutation } from 'shared/api/verification/useVerifyDiscordMutation';
import { useVerifyTwitterMutation } from 'shared/api/verification/useVerifyTwitterMutation';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import DiscordIcon from './assets/discord-gray.svg?react';
import TwitterIcon from './assets/twitter-gray.svg?react';
import { Sidebar } from './ui/Sidebar';
import { AuthServiceInfo, Card, CardIcon, CardRow, CardTitle } from './ui/card-ui';

export const Faucet = () => {
  const navigate = useNavigate();

  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);

  const { isLoading: isLoadingUser, isUpdating: isUpdatingUser, updateUser, user } = useUser();
  const { data: account, isLoading: isLoadingAccount } = useAccount();

  const { isPending, mutateAsync: requestTokens } = useRequestTokensMutation();
  const { isPending: isDiscordPending, mutateAsync: verifyDiscord } = useVerifyDiscordMutation();
  const { isPending: isTwitterPending, mutateAsync: verifyTwitter } = useVerifyTwitterMutation();

  const twitterVerified = !!user?.verification?.twitter?.id;
  const discordVerified = !!user?.verification?.discord?.id;

  const hasToConnectWallet = !!account?.bech32Address;

  const isLoading = isLoadingUser || isLoadingAccount;

  const handleRequestToken = async () => {
    try {
      if (!user) return toaster.error('You are not logged in');
      if (!account?.bech32Address) return toaster.error('No wallet connected');

      const { error, state, tokenAmount } = await requestTokens({
        address: account.bech32Address,
        chain: 'Testnet',
      });

      if (error) {
        toaster.info(error);
      } else if (state === FaucetRequestState.complete) {
        const tokenAmountNumber = Number(tokenAmount) / Math.pow(10, nesaTestnet.currencies[0].coinDecimals);

        toaster.info(`${tokenAmountNumber} NES sent to your wallet!`);
      } else if (state === FaucetRequestState.queued) {
        const tokenAmountNumber = Number(tokenAmount) / Math.pow(10, nesaTestnet.currencies[0].coinDecimals);

        toaster.info(`${tokenAmountNumber} NES will be delivered soon!`);
      }
    } catch (e) {
      console.log('e', e);
      toaster.error(
        ((e as AxiosError)?.response?.data as { message?: string })?.message || 'Something went wrong',
      );
    }
  };

  return (
    <AnimateRoute className="mx-auto w-full max-w-5xl px-4 py-10 3xl:max-w-7xl">
      <BackButton
        className="mb-4 gap-4 text-2xl font-semibold text-clay-900 hover:text-clay-900"
        onClick={() => navigate(-1)}
      >
        Nesa Testnet Faucet
      </BackButton>
      <div className="ml-px pl-9 text-base font-medium">
        <div className="text-clay-900">Dear Nesa Explorers!</div>
        <div className="text-clay-500">
          Welcome to our testnet realm! Looking for tokens to launch your journey?
        </div>
      </div>

      <div className="mt-8 flex flex-col items-stretch gap-7 lg:flex-row lg:items-start">
        <Sidebar />
        {isLoading && (
          <div className="flex w-full flex-1 items-center justify-center py-12">
            <Spinner className="size-6" />
          </div>
        )}

        {!isLoading && (
          <div className="grid flex-1 grid-cols-1 gap-2.5">
            <Card>
              <CardIcon icon="wallet" />
              <CardTitle className="my-3">Connect your Wallet</CardTitle>

              <div className="text-sm text-clay-500">Walllet Address</div>
              <div className="mt-1.5 flex gap-4">
                <Input
                  className="flex-1"
                  disabled={!hasToConnectWallet}
                  placeholder="Input Nesa wallet address to receive token"
                  readOnly
                  value={account?.bech32Address || ''}
                />
                <div>
                  {hasToConnectWallet ? (
                    <Button
                      className="w-full"
                      isLoading={isPending}
                      onClick={() => {
                        handleRequestToken();
                      }}
                    >
                      Request{twitterVerified && ' (2X)'} Tokens
                    </Button>
                  ) : (
                    <Button className="w-full" onClick={() => setIsWalletProviderOpen(true)} variant="filled">
                      Connect Wallet
                    </Button>
                  )}
                </div>
              </div>
            </Card>
            <Card>
              <CardIcon icon="twitter" />
              <CardRow>
                <div>
                  <CardTitle>
                    Link your X Account <span className="text-sm font-normal">(Optional)</span>
                  </CardTitle>
                  <div className="text-sm text-clay-500">Link your x account to get 2X tokens</div>
                </div>
                {twitterVerified ? (
                  <AuthServiceInfo
                    icon={<TwitterIcon className="size-9" />}
                    isRemoveLoading={isUpdatingUser}
                    onRemoveClick={() =>
                      updateUser(
                        {
                          verification: {
                            ...user?.verification,
                            twitter: undefined,
                          },
                        },
                        { sendRequest: true },
                      )
                    }
                    username={user?.verification?.twitter?.username || ''}
                  />
                ) : (
                  <Button
                    isLoading={isTwitterPending}
                    onClick={async () => {
                      const { link } = await verifyTwitter();
                      window.open(link, '_self');
                    }}
                  >
                    Authenticate
                  </Button>
                )}
              </CardRow>

              <div className=" my-6 h-px bg-clay-20" />

              <CardIcon icon="discord" />
              <CardRow>
                <div>
                  <CardTitle>
                    Connect with Discord <span className="text-sm font-normal">(Optional)</span>
                  </CardTitle>
                  <div className="text-sm text-clay-500">Claim Rewards</div>
                </div>
                {discordVerified ? (
                  <AuthServiceInfo
                    icon={<DiscordIcon className="size-10" />}
                    isRemoveLoading={isUpdatingUser}
                    onRemoveClick={() =>
                      updateUser(
                        {
                          verification: {
                            ...user?.verification,
                            discord: undefined,
                          },
                        },
                        { sendRequest: true },
                      )
                    }
                    username={user?.verification?.discord?.username || ''}
                  />
                ) : (
                  <Button
                    isLoading={isDiscordPending}
                    onClick={async () => {
                      const { link } = await verifyDiscord();
                      window.open(link, '_self');
                    }}
                  >
                    Authenticate
                  </Button>
                )}
              </CardRow>
            </Card>
          </div>
        )}
      </div>
      <WalletProviderModal onOpenChange={setIsWalletProviderOpen} open={isWalletProviderOpen} />
    </AnimateRoute>
  );
};

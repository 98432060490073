import type { LucideProps } from 'lucide-react';

import type { ForwardRefExoticComponent, FunctionComponent, RefAttributes, SVGProps } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BriefcaseBusinessIcon, CircleUserIcon, LogsIcon, SettingsIcon, WalletIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { UserAvatar } from 'entities/UserAvatar';
import { useLogoutMutation } from 'shared/api/user/useLogoutMutation';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon } from 'shared/ui/Icon';
import { gpu as GpuIcon } from 'shared/ui/Icon/svg';
import { Popover } from 'shared/ui/Popover';
import { toaster } from 'shared/ui/Toast';

type Props = {
  className?: string;
};

export const UserLink = ({ className }: Props) => {
  const [open, setOpen] = useState(false);
  const { updateUser, user } = useUser();
  const navigate = useNavigate();
  const sm = useMinWidthMediaQuery('sm');
  const { mutateAsync: logout } = useLogoutMutation();

  const userItems: {
    disabled?: boolean;
    icon:
      | ForwardRefExoticComponent<Omit<LucideProps, 'ref'> & RefAttributes<SVGSVGElement>>
      | FunctionComponent<
          {
            title?: string | undefined;
          } & SVGProps<SVGSVGElement>
        >;
    id: string;
    label: string;
    path: string;
  }[] = [
    { icon: CircleUserIcon, id: 'profile', label: 'My Profile', path: `/account/${user?._id}` },
    { icon: WalletIcon, id: 'wallet', label: 'Wallet', path: '/wallet' },
    // { icon: 'database', id: 'nodes', label: 'My Nodes', path: '/nodes' },
    // { disabled: true, icon: 'trophy', id: 'rewards', label: 'Rewards', path: '/leader-board' },
    { icon: SettingsIcon, id: 'settings', label: 'Settings', path: '/settings' },
    { icon: BriefcaseBusinessIcon, id: 'collections', label: 'My Collections', path: '/model-collections' },
    { icon: LogsIcon, id: 'query-history', label: 'Query History', path: '/query-history' },
    {
      icon: (props: SVGProps<SVGSVGElement>) => (
        <GpuIcon {...props} className={twMerge(props.className, 'size-5')} />
      ),
      id: 'query-history',
      label: 'My Nodes',
      path: '/nodes',
    },
  ];

  const handleLogout = () => {
    logout();
    updateUser();
    setOpen(false);
    navigate('/auth/signin');
  };

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Popover.Trigger>
        <UserAvatar className={className} user={user} />
        {/* <div
          className={twMerge(
            'flex size-9 cursor-pointer items-center justify-center rounded-full bg-tusk-100 pb-1 font-klapt text-lg/none font-bold uppercase transition-colors hover:bg-tusk-300 hover:outline hover:outline-4 hover:outline-clay-10',
            user?.avatar && 'bg-white pb-0 hover:bg-white',
            className,
          )}
        >
          {user?.avatar ? (
            <img className="size-7 rounded-full" src={user?.avatar} />
          ) : (
            user?.first_name?.slice(0, 1) ?? (user?.email?.slice(0, 1) || '-')
          )}
        </div> */}
      </Popover.Trigger>
      <Popover.Content
        align="end"
        className="mt-4 flex w-48 flex-col rounded-lg p-1 pt-1.5 shadow-md sm:ml-4 sm:mt-0"
        side={sm ? 'right' : 'bottom'}
      >
        {userItems.map((item) => (
          <div
            className="flex cursor-pointer items-center gap-3 rounded px-3 py-2 hover:bg-clay-10"
            key={item.id}
            onClick={() => {
              if (item.disabled) {
                toaster.info('Coming soon');
                return;
              }
              navigate(item.path);
              setOpen(false);
            }}
          >
            <div className=" flex size-5 min-w-5 items-center justify-center">
              <item.icon className="size-[1.125rem] text-clay-350" />
            </div>
            <span className="text-sm text-clay-700">{item.label}</span>
          </div>
        ))}

        <div className="my-1.5 h-px bg-clay-20"></div>

        <div
          className="flex cursor-pointer items-center gap-3 rounded px-3 py-2 hover:bg-clay-10"
          onClick={handleLogout}
        >
          <Icon className="size-[1.125rem] text-red-900" name="logOut" />
          <span className="text-sm text-clay-700">Log Out</span>
        </div>
      </Popover.Content>
    </Popover>
  );
};

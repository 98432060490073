import { metaMask } from '@wagmi/connectors';
import { defineChain } from 'viem';
import { createConfig, http } from 'wagmi';

export const nesaEvmTestnet = defineChain({
  blockExplorers: {
    default: { name: 'Explorer', url: 'https://explorer-evm.test.nesa.ai' },
  },
  id: 111888,
  name: 'Nesa Chain',
  nativeCurrency: { decimals: 18, name: 'Ethereum', symbol: 'ETH' },
  rpcUrls: {
    default: { http: ['https://rpc-evm.test.nesa.ai'] },
  },
});

export const wagmiConfig = createConfig({
  chains: [nesaEvmTestnet],
  connectors: [metaMask()],
  transports: {
    [nesaEvmTestnet.id]: http(),
  },
});

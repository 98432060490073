import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';
3;
import type { EditCommentPostRequest, Post, PostComment } from './types';

export const useEditHuggingfaceCommentPostMutation = (
  options: UseMutationOptions<PostComment, Error, EditCommentPostRequest> = {},
) => {
  return useMutation({
    mutationFn: async (params: EditCommentPostRequest) => {
      const { data } = await axiosInstance.post<Post>(`/posts/edit-huggingface-comment`, params);

      return data;
    },
    ...options,
  });
};

import type { ReactNode } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  className?: string;
  container?: boolean;
};

export const AnimateRoute = ({ children, className, container }: Props) => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={twMerge(container && 'mx-auto size-full max-w-7xl px-4 py-10', className)}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.35 }}
    >
      {children}
    </motion.div>
  );
};

import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';

import { useGroupStakerListInfinityQuery } from 'shared/api/dai/useStakerListInfinityQuery';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';

import { useSettingTableKeysContext } from '../LeaderBoardTabs';
import { getColumns } from './config';
import { Skeleton } from './ui/Skeleton';

type Props = {
  short?: boolean;
};

export const StakersTab = ({ short }: Props) => {
  const [searchParams] = useSearchParams();

  const sort = (searchParams.get('sort') || 'totalCount') as 'stakesLength' | 'totalCount';

  const selectedTableKeys = useSettingTableKeysContext();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useGroupStakerListInfinityQuery({
    limit: short ? 5 : 40,
    sort: sort,
  });

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const isLoading = status === 'pending';

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage && !short) {
      fetchNextPage();
    }
  }, [fetchNextPage, short, hasNextPage, intersection?.isIntersecting]);

  const columns = useMemo(() => {
    const list = data?.pages;
    if (!list) return [];
    return getColumns(selectedTableKeys);
  }, [data, selectedTableKeys]);

  const tableData = useMemo(() => {
    if (!data) return [];
    return data?.pages.flatMap((el) => el.list);
  }, [data]);

  return (
    <>
      <div className="w-full flex-1 overflow-auto">
        {isLoading ? (
          <Skeleton selectedTableKeys={short ? new Set() : selectedTableKeys} />
        ) : (
          <>
            <Table
              className="min-w-[58rem]"
              columns={columns}
              data={tableData}
              // onRowClick={(row) => navigate(`/dai/${row}`)}
            />
            <div ref={ref} />
          </>
        )}
        {isFetchingNextPage && (
          <div className=" flex justify-center py-5">
            <Spinner className=" size-8" />
          </div>
        )}
      </div>
    </>
  );
};

import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

import type { User } from 'shared/api/user/types';

type Props = { user?: User } & ComponentProps<'div'>;

export const UserAvatar = ({ className, user, ...props }: Props) => {
  return (
    <div
      {...props}
      className={twMerge(
        'flex size-9 cursor-pointer items-center justify-center rounded-full bg-tusk-100 pb-1 font-klapt text-lg/none font-bold uppercase transition-colors hover:bg-tusk-300 hover:outline hover:outline-4 hover:outline-clay-10',
        user?.avatar && 'bg-white pb-0 hover:bg-white',
        className,
      )}
    >
      {user?.avatar ? (
        <img className="size-7 rounded-full" src={user?.avatar} />
      ) : (
        user?.first_name?.slice(0, 1) ?? (user?.email?.slice(0, 1) || '-')
      )}
    </div>
  );
};

import type { BarProps } from 'recharts';
import type { ActiveShape } from 'recharts/types/util/types';

import type { PointerEvent } from 'react';
import { memo, useEffect, useRef, useState } from 'react';

import { DateTime } from 'luxon';
import { Bar, ComposedChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { twMerge } from 'tailwind-merge';

import type { StakeAmountChart } from 'shared/api/dai/useGetStakeAmountChartQuery';

import { theme } from 'app/theme';
import { useGetStakeAmountChartQuery } from 'shared/api/dai/useGetStakeAmountChartQuery';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  daiId: string;
};

const xAxisHeight = 26;
const paddingBottom = 8;
const paddingTop = 20;
const paddingY = paddingBottom + paddingTop;

const getBarChartHeight = (height: number, lg: boolean) => {
  if (!lg) {
    // return height;
  }
  return height - (xAxisHeight + paddingY);
};

const getSlideBottom = (height: number, bottom: number, lg: boolean) => {
  if (!lg) {
    // let b = bottom;
    // if (b < 0) b = 0;
    // if (b > height) b = height;
    // return b;
  }

  const h = height + xAxisHeight + paddingY;

  let b = bottom + xAxisHeight + paddingBottom;
  if (b > h - paddingTop) b = h - paddingTop;
  if (b < xAxisHeight + paddingBottom) b = xAxisHeight + paddingBottom;
  return b;
};

export const StakeChart = memo(({ daiId }: Props) => {
  const { data: chartData, isPending } = useGetStakeAmountChartQuery({ daiId }, { enabled: !!daiId });

  const lg = useMinWidthMediaQuery('lg');
  const md = useMinWidthMediaQuery('md');

  const [referenceLineValue, setReferenceLineValue] = useState(0);
  const [slideTop, setReferenceTop] = useState(0);
  const [hoveredBarDate, setHoveredBarDate] = useState<string>();

  const slideRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const containerBoundsRef = useRef<DOMRect>(undefined);
  const slidePositionRef = useRef<DOMRect>(undefined);
  const offsetY = useRef<number>(undefined);

  const maxValue = Math.max(0, ...(chartData?.map((el) => el.count) || []));

  useEffect(() => {
    const containerBounds = containerRef.current?.getBoundingClientRect();
    containerBoundsRef.current = containerBounds;

    if (chartData) {
      const max = Math.max(0, ...(chartData.map((el) => el.count) || []));
      const chartHeight = containerBounds?.height ? getBarChartHeight(containerBounds.height, lg) : null;
      const prcByPx = chartHeight ? chartHeight / max : null;

      if (typeof prcByPx === 'number' && chartHeight) {
        setReferenceTop(getSlideBottom(chartHeight, (max / 2) * prcByPx, lg));
      }
      setReferenceLineValue(max / 2);
    }
  }, [chartData, lg]);

  const onPointerDown = (e: PointerEvent<HTMLDivElement>) => {
    e.currentTarget.setPointerCapture(e.pointerId);
    const containerBounds = containerRef.current?.getBoundingClientRect();
    const slideBounds = slideRef.current?.getBoundingClientRect();
    if (containerBounds) {
      containerBoundsRef.current = containerBounds;
    }
    if (slideBounds) {
      offsetY.current = e.clientY - slideBounds.top;
      slidePositionRef.current = slideBounds;
    }
  };
  const onPointerMove = (e: PointerEvent<HTMLDivElement>) => {
    const containerBounds = containerBoundsRef.current;
    const slideBounds = slidePositionRef.current;

    if (!containerBounds || !slideBounds) return;

    const chartHeight = getBarChartHeight(containerBounds.height, lg);
    const top = e.clientY - containerBounds.top - 13;
    const bottom = getSlideBottom(chartHeight, chartHeight - top, lg);
    if (slideRef.current) {
      slideRef.current.style.bottom = `${bottom}px`;
    }
    const prcByPx = chartHeight / maxValue;
    const value = (chartHeight - top) / prcByPx;
    setReferenceLineValue(value < 0 ? 0 : value > maxValue ? maxValue : value);
  };
  const onPointerUp = (e: PointerEvent<HTMLDivElement>) => {
    e.currentTarget.releasePointerCapture(e.pointerId);
    slidePositionRef.current = undefined;
  };

  if (isPending) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner className="size-6" />
      </div>
    );
  }

  const isEmptyChart = chartData?.every((el) => !el.count);

  return (
    <div
      className="relative size-full touch-none select-none"
      onPointerCancel={onPointerUp}
      onPointerMove={onPointerMove}
      onPointerOver={onPointerDown}
      onPointerUp={onPointerUp}
      ref={containerRef}
    >
      <ResponsiveContainer className="z-10" height="100%" width="100%">
        <ComposedChart
          barGap={4}
          data={chartData}
          margin={{
            bottom: paddingBottom,
            left: 26,
            right: 26,
            top: paddingTop,
          }}
          onMouseMove={(state) => {
            console.log(state);
            setHoveredBarDate(state.activeLabel || undefined);
          }}
        >
          <defs>
            <linearGradient id="colorPrimary" x1="0" x2="0" y1="0" y2="1">
              <stop offset="5%" stopColor="#8356E2" stopOpacity={0.6} />
              <stop offset="95%" stopColor="#8356E2" stopOpacity={0} />
            </linearGradient>
          </defs>

          {/* <Tooltip
            content={({ active, payload }) => {
              if (!active) return null;

              return (
                <div className="flex max-w-56 flex-col rounded-lg bg-clay-800 p-3 text-white">
                  <span className="text-lg">
                    ${payload?.[0]?.value?.toLocaleString(undefined, { maximumFractionDigits: 2 })} (
                    {payload?.[0]?.payload.stakesCount || 0} stakes)
                  </span>

                  <span className="text-sm text-[#B7C0CE]">
                    Staked on{' '}
                    {DateTime.fromISO(payload?.[0]?.payload?.date).toLocaleString(DateTime.DATE_MED)}
                  </span>
                </div>
              );
            }}
            cursor={false}
            wrapperStyle={{ outline: 'none', zIndex: 20 }}
          /> */}
          <Bar
            dataKey="count"
            radius={[10, 10, 10, 10]}
            shape={
              (({
                date,
                height,
                value,
                width,
                x,
                y,
              }: {
                date: string;
                height: number;
                payload: StakeAmountChart;
                value: number;
                width: number;
                x: number;
                y: number;
              }) => {
                const safeHeight = value && height < 12 ? 12 : height;
                const chartHeight = getBarChartHeight(containerBoundsRef.current?.height || 0, lg);
                return (
                  <g transform={`translate(${x},${y - (safeHeight - height)})`}>
                    <rect
                      className="transition-all duration-200"
                      fill={date === hoveredBarDate ? '#8356E2' : '#ECEDF1'}
                      height={value ? safeHeight : 0}
                      rx={safeHeight < 30 ? 6 : 10}
                      ry={safeHeight < 30 ? 6 : 10}
                      width={width}
                    />
                    <text
                      className={twMerge(
                        'opacity-0 transition-all duration-200',
                        hoveredBarDate === date && 'opacity-100',
                      )}
                      fill={theme.colors.clay[900]}
                      fontFamily="DM Sans"
                      fontSize={14}
                      fontWeight={600}
                      textAnchor="middle"
                      x={width / 2}
                      y={isEmptyChart ? chartHeight / 2 - 8 : -8}
                    >
                      $
                      {value && value < 1
                        ? 1
                        : value?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </text>
                  </g>
                );
              }) as ActiveShape<BarProps, SVGPathElement>
            }
          />

          <XAxis
            axisLine={false}
            dataKey="date"
            height={26}
            interval={lg ? 0 : md ? 1 : 2}
            tick={({ payload, x, y }) => (
              <g
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  setHoveredBarDate(undefined);
                }}
                onMouseMove={(e) => {
                  e.stopPropagation();
                  setHoveredBarDate(payload.value || undefined);
                }}
                transform={`translate(${x},${y})`}
              >
                {lg && (
                  <rect
                    fill={payload.value === hoveredBarDate ? '#F6F1FF' : '#F7F7F7'}
                    height={26}
                    rx={13}
                    ry={13}
                    textAnchor="middle"
                    transform="translate(-30,0)"
                    width={60}
                  />
                )}
                <text
                  dy={17}
                  fill={payload.value === hoveredBarDate ? '#8356E2' : '#707884'}
                  fontFamily="DM Sans"
                  fontSize={13}
                  fontWeight={500}
                  textAnchor="middle"
                  x={0}
                  y={0}
                >
                  {DateTime.fromISO(payload.value).toFormat('LLL d')}
                </text>
              </g>
            )}
            tickLine={false}
          />
          <YAxis domain={[0, maxValue]} width={0} />

          {typeof referenceLineValue === 'number' && maxValue > 0 && (
            <ReferenceLine stroke={theme.colors.clay[900]} strokeDasharray="4 4" y={referenceLineValue} />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      {typeof referenceLineValue === 'number' && maxValue > 0 && (
        <div
          className="pointer-events-none absolute left-[26px] z-10 flex translate-y-1/2"
          ref={slideRef}
          style={{
            bottom: `${slideTop}px`,
          }}
        >
          <div
            className="relative h-[30px] cursor-move rounded-full bg-clay-900 px-2.5 text-sm font-semibold leading-[30px] text-white"
            // onPointerCancel={onPointerUp}
            // onPointerDown={onPointerDown}
            // onPointerMove={onPointerMove}
            // onPointerUp={onPointerUp}
          >
            ${referenceLineValue.toLocaleString(undefined, { maximumFractionDigits: 2 })} Staked
          </div>
        </div>
      )}
    </div>
  );
});

import type { GroupStakerRes } from 'shared/api/dai/useStakerListInfinityQuery';
import type { TableColumn } from 'shared/ui/Table';

import { Icon } from 'shared/ui/Icon';

export const getColumns = (settings: Set<string>) => {
  const columns: TableColumn<string, GroupStakerRes>[] = [
    {
      key: '_id',
      renderTd: (_, index) => <div className="text-center font-semibold text-clay-900">{index + 1}</div>,
      thClassName: 'w-[5rem]',
      title: (
        <div className="flex justify-center">
          <Icon className="size-4" name="crown" />
        </div>
      ),
    },
    {
      key: 'user',
      renderTd: ({ user }) => {
        return (
          <div className="w-full text-sm font-normal text-clay-900">
            {user && (user.last_name || user.last_name) ? `${user.last_name} ${user.first_name}` : '-'}
          </div>
        );
      },
      thClassName: 'w-full',
      title: 'Staker',
    },
    {
      key: 'projects',
      renderTd: ({ stakes }) => {
        const projectIds = new Set(stakes.map((el) => el.company_id));
        return projectIds.size;
      },
      thClassName: 'w-[15%] min-w-[9rem]',
      title: 'Projects',
    },
    {
      key: 'TVL',
      renderTd: ({ totalAmount }) => `${totalAmount?.toLocaleString('en') || '-'}`,
      thClassName: 'w-[15%] min-w-[9rem]',
      title: 'TVL',
    },
    {
      key: 'stakes',
      renderTd: ({ stakes }) => `${stakes.length}`,
      thClassName: 'w-[15%] min-w-[9rem]',
      title: 'Stakes',
    },
    // {
    //   key: 'rewards',
    //   renderTd: ({ stakes }) => `-`,
    //   thClassName: 'w-[15%] min-w-[9rem]',
    //   title: 'Rewards',
    // },
  ];

  if (!settings.size) {
    return columns;
  }

  return columns.filter((el) => settings.has(el.key) || el.key === '_id' || el.key === 'user');
};

import { Link } from 'react-router-dom';

import type { WalletBalance } from 'pages/Wallet/types';

import { ClaimUsdButton } from 'features/ClaimUsdButton';
// import { sortWalletBalances } from 'pages/Wallet/helpers';
import { Table } from 'pages/Wallet/ui/Table';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { sleep } from 'shared/helpers/sleep';
// import { Icon } from 'shared/ui/Icon';

type Props = {
  evmBalance: WalletBalance[];
  fetchNextPage: VoidFunction;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isPending: boolean;
  refetch: VoidFunction;
};

export const MyAssetsEvm = ({
  evmBalance,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isPending,
  refetch,
}: Props) => {
  return (
    <Table
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isPending={isPending}
      items={evmBalance
        .filter(
          (coin) =>
            fromUnesToNes(coin.amount, coin.decimals).toNumber() > 0.01 ||
            coin.denom === 'ETH' ||
            coin.denom === 'USDN',
        )
        // ?.sort(sortWalletBalances)
        .map((coin) => {
          const amount = fromUnesToNes(coin.amount, coin.decimals).toNumber().toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });

          return {
            avatar: coin.image,
            id: coin._id,
            rightValue: (
              <div className="flex items-center gap-2">
                <div className="min-w-16 text-end text-base">{amount === '0.00' ? '<0.01' : amount}</div>
              </div>
            ),
            subtitle: coin.subtitle,
            title: coin.label,
            titleAcc:
              coin.label === 'ETH' ? (
                <Link
                  className="flex items-center gap-2 rounded-lg border-primary-100 bg-primary-100 px-3 py-2 text-xs font-medium text-primary-1000 transition-all hover:brightness-95 md:w-auto"
                  target={'_blank'}
                  to={'https://bridge-evm.test.nesa.ai/'}
                >
                  {/*<Icon className="-ml-0.5 size-3 text-white" name="link" />*/}
                  Bridge ETH
                </Link>
              ) : coin.label === 'USDN' ? (
                <ClaimUsdButton
                  buttonSize="extra-small"
                  onClaimSuccess={async () => {
                    await sleep(5000);
                    refetch();
                  }}
                />
              ) : null,
          };
        })}
      label="Assets with Balance"
    />
  );
};

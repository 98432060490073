import { useSlate } from 'slate-react';

import { Select } from 'shared/ui/Select';

import { type CustomElement, isBlockActive, toggleBlock } from '../libs';

const blockTypes: { title: string; type: CustomElement['type'] }[] = [
  { title: 'H1 Title', type: 'h1' },
  { title: 'H2 Title', type: 'h2' },
  { title: 'Paragraph', type: 'paragraph' },
  { title: 'Bulleted List', type: 'bulleted-list' },
  { title: 'Quote', type: 'quote' },
  // { title: 'Numbered List', type: 'numbered-list' },
];

export const BlockSelect = () => {
  const editor = useSlate();
  const activeType = blockTypes.find((el) => isBlockActive(editor, el.type));
  return (
    <Select
      className=" min-w-36"
      onValueChange={(el: CustomElement['type']) => toggleBlock(editor, el)}
      placeholder="Select Block"
      value={activeType?.type}
    >
      <Select.Content>
        {blockTypes.map((el) => (
          <Select.Item key={el.type} value={el.type}>
            {el.title}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};

import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Sparkles } from 'lucide-react';
import { DateTime } from 'luxon';
import { twJoin } from 'tailwind-merge';

import type { ProductStripeType } from 'app/stores/prices';

import { useProductStripePayment } from 'app/stores/prices';
import { useUser } from 'app/stores/user';
import { upgradeToProBulletList } from 'features/UpgradeToProModal';
import { stripeQueryKeys, useCancelSubscriptionMutation } from 'shared/api/stripe';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';

import { SettingLine } from './SettingLine';

export const Billing = () => {
  const { isPending: isPendingUser, proVersion } = useUser();

  const [loadingStripe, setLoadingStripe] = useState<ProductStripeType | null>(null);

  const queryClient = useQueryClient();
  const { mutateAsync: cancelSubscription } = useCancelSubscriptionMutation();

  const paymentSubscription = useProductStripePayment('/settings?type=billing');

  const upgradeToProByStripe = async (productType: ProductStripeType) => {
    setLoadingStripe(productType);

    try {
      await paymentSubscription(productType);
    } catch {
      setLoadingStripe(null);
    }
  };

  const handleCancelSubscription = async (subscriptionId: string) => {
    await cancelSubscription(subscriptionId);
    queryClient.invalidateQueries({ queryKey: stripeQueryKeys.checkSubscription() });
  };

  if (isPendingUser) {
    return (
      <Card className="flex flex-col items-center py-8 xs:px-6 xs:pb-2">
        <Spinner className="size-7" />
      </Card>
    );
  }

  const startDate = proVersion?.startDate
    ? DateTime.fromMillis(proVersion.startDate).toFormat('dd LLLL yyyy')
    : null;
  const endDate = proVersion?.endDate
    ? DateTime.fromMillis(proVersion.endDate).toFormat('dd LLLL yyyy')
    : null;

  return (
    <Card className="flex flex-col xs:px-6">
      <SettingLine
        middleSlot={
          <div className="flex w-full justify-end">
            {/* <div
              className="cursor-pointer rounded-lg border border-clay-40 px-2 py-1.5 text-xs text-clay-900 shadow-sm hover:bg-clay-10"
              onClick={() => toaster.info('Coming Soon')}
            >
              Change Plan
            </div> */}
            {proVersion?.isPro &&
              proVersion.subscriptionId &&
              !proVersion?.stripeSubscription?.cancel_at_period_end && (
                <Button
                  color="red"
                  disabled={proVersion?.stripeSubscription?.cancel_at_period_end}
                  onClick={() => handleCancelSubscription(proVersion.subscriptionId || '')}
                  size="extra-small"
                >
                  Unsubscribe
                </Button>
              )}
            {proVersion?.stripeSubscription?.cancel_at_period_end && (
              <div className="rounded-lg bg-red-100 px-2 py-1 text-xs font-semibold text-red-800">
                Canceled
              </div>
            )}
          </div>
        }
        startSlot={
          <div className="flex flex-col gap-3">
            <div className="flex items-center">
              {proVersion?.isPro ? (
                <div className="rounded-md bg-blue-600 px-2.5 py-2 text-lg/none font-semibold text-white">
                  PRO
                </div>
              ) : (
                <div className="rounded-md bg-clay-20 px-2.5 py-2 text-lg/none font-semibold text-clay-350">
                  FREE
                </div>
              )}

              <div className="ml-1.5 text-2xl font-semibold text-clay-900">Plan</div>

              {proVersion?.isPro ? (
                <div className="ml-3 rounded bg-clay-20 p-1.5 text-[0.5625rem]/none font-medium text-blue-600">
                  FULL ACCESS
                </div>
              ) : (
                <div className="ml-3 rounded bg-clay-20 p-1.5 text-[0.5625rem]/none font-medium text-blue-600">
                  LIMITED ACCESS
                </div>
              )}
            </div>

            {proVersion?.isPro ? (
              <></>
            ) : (
              <div className="text-sm font-light text-clay-500">
                The free plan limits your access to some of Nesa&apos;s features.
              </div>
            )}
          </div>
        }
      ></SettingLine>

      {proVersion?.isPro && (
        <>
          <div className="flex justify-between pb-2">
            <div>
              <div className="text-base font-medium text-clay-700">
                {proVersion?.stripeSubscription?.cancel_at_period_end ? '-' : endDate}
              </div>
              <div className="mt-0.5 text-xs text-clay-500 sm:text-sm">Next Payment</div>
            </div>

            {startDate && proVersion.stripeSubscription && (
              <div className="flex">
                <div>
                  {proVersion.stripeSubscription && (
                    <div className="text-xl font-medium text-clay-700">
                      ${(proVersion.stripeSubscription.plan.amount || 0) / 100}
                    </div>
                  )}
                  <div className="mt-0.5 text-xs text-clay-500 sm:text-sm">Last payment: {startDate}</div>
                </div>
                <div className="ml-8">
                  <div className="text-xl font-medium text-clay-700">Enabled</div>
                  <div className="mt-0.5 text-xs text-clay-500 sm:text-sm">Recurring payment</div>
                </div>
              </div>
            )}
          </div>

          {/* <div className="my-4 h-px bg-clay-20" />

          <div className="mb-2 flex justify-between">
            <div className="flex gap-4">
              <div>
                <div className="text-sm font-medium text-clay-700">Visa ending with 4556</div>
                <div className="mt-0.5 text-xs text-clay-500 sm:text-sm">Crypto Bank</div>
              </div>

              <div
                className="cursor-pointer self-center rounded-lg border border-clay-40 px-2 py-1.5 text-xs text-clay-900 shadow-sm hover:bg-clay-10"
                onClick={() => toaster.info('Coming soon')}
              >
                Change Payment Method
              </div>
            </div>

            <div
              className="flex cursor-pointer gap-2 self-end text-sm text-primary-800 hover:text-primary-1000"
              onClick={() => toaster.info('Coming soon')}
            >
              <Icon className="size-3.5" name="download" />
              Download Invoice
            </div>
          </div> */}
        </>
      )}

      {!proVersion?.isPro && (
        <div className="flex flex-col gap-6 rounded-2xl bg-blue-radial p-4 pt-6 md:flex-row md:justify-between md:p-2 md:pl-8">
          <div className="flex flex-1 flex-col justify-center gap-4 md:gap-8">
            <div className="flex items-center justify-center gap-2.5">
              <h2 className="text-3xl/none font-medium text-white sm:text-4xl/none">Upgrade to</h2>
              <div className="rounded-lg bg-[#173BDA] px-2.5 py-2 text-lg/none font-semibold text-white sm:text-xl/none">
                PRO
              </div>
            </div>

            <div className="w-full px-2 text-center text-sm font-light text-white">
              Get Unlimited access to thousands of models on Nesa.
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div
                className="flex h-12 cursor-pointer items-center justify-center rounded-lg bg-black text-white"
                onClick={() => !loadingStripe && upgradeToProByStripe('nesa.pro.month')}
              >
                {loadingStripe === 'nesa.pro.month' ? (
                  <Spinner className="size-4" />
                ) : (
                  <span className="text-xl font-semibold">
                    $9 <span className="text-base font-normal">/month</span>
                  </span>
                )}
              </div>

              <div
                className="flex h-12 cursor-pointer items-center justify-center rounded-lg bg-tusk-100 hover:bg-tusk-200"
                onClick={() => !loadingStripe && upgradeToProByStripe('nesa.pro.year')}
              >
                {loadingStripe === 'nesa.pro.year' ? (
                  <Spinner className="size-4" />
                ) : (
                  <span className="text-xl font-semibold">
                    $7 <span className="text-base font-normal">/month</span>
                  </span>
                )}
              </div>

              <div></div>

              <div className="text-center text-[0.625rem] font-light text-white">
                Save 25% with annual billing
              </div>
            </div>
          </div>

          <div className="rounded-lg bg-white p-4 md:w-96">
            <div className="mt-6 flex items-center gap-3 rounded-xl bg-blue-radial px-6 py-4 sm:mt-9">
              <div className="flex w-10 justify-center sm:w-16">
                <Sparkles className="size-6 text-white sm:size-10" strokeWidth={1.5} />
              </div>
              <div className="h-10 w-px bg-white/20"></div>
              <div className="flex-1 text-center text-lg/6 font-semibold text-white xs:text-2xl/7">
                Unlimited Inference Credits
              </div>
            </div>

            <div className="mt-4 grid grid-cols-1 gap-3.5">
              {upgradeToProBulletList.map((el, i) => (
                <div className="flex gap-2.5" key={i}>
                  <div className="mt-0.5 size-3.5 min-w-3.5">
                    <Icon className="size-3.5 text-green-600" name="check" safeArea="0" />
                  </div>
                  <div
                    className={twJoin('text-sm', el.bold ? 'font-semibold text-clay-900' : 'text-clay-500')}
                  >
                    {el.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

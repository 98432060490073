import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { queryClient } from 'app/queryClient';
import { useUser } from 'app/stores/user';
import { axiosInstance } from 'shared/config/axiosInstance';

import type { Dataset } from './types';
import type { DatasetReaction } from './useDatasetUserReactionsQuery';

import { queryKeys } from './queryKeys';

type Params = {
  datasetId: string;
};

type Response = {
  data: unknown;
  reaction: DatasetReaction;
};

export const useRemoveLikeDatasetMutation = (options: UseMutationOptions<Response, Error, Params> = {}) => {
  const { user } = useUser();
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<Response>(`/datasets/like/remove`, params);

      return data;
    },
    onError: (err, params, context) => {
      const queryKeyStr = queryKeys.reaction({ datasetId: params.datasetId, userId: user?._id || '' });

      queryClient.setQueryData(
        queryKeyStr,
        typeof context === 'object' && context && 'previous' in context ? context['previous'] : undefined,
      );

      const queryKeyDataset = queryKeys.metadata({ datasetId: params.datasetId });

      queryClient.setQueryData(
        queryKeyDataset,
        typeof context === 'object' && context && 'previousMetadata' in context
          ? context['previousMetadata']
          : undefined,
      );
    },
    onMutate: async (params) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const queryKeyStr = queryKeys.reaction({ datasetId: params.datasetId, userId: user?._id || '' });
      await queryClient.cancelQueries({ queryKey: queryKeyStr });

      // Snapshot the previous value
      const previous = queryClient.getQueryData(queryKeyStr);

      console.log('queryKeyStr', queryKeyStr);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKeyStr, { data: null });

      const queryKeyMetadata = queryKeys.metadata({ datasetId: params.datasetId });
      await queryClient.cancelQueries({ queryKey: queryKeyMetadata });

      // Snapshot the previous value
      const previousMetadata: Dataset | undefined = queryClient.getQueryData(queryKeyMetadata);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKeyMetadata, {
        ...(previousMetadata || {}),
        likes: (previousMetadata?.likes || 1) - 1,
      });

      // Return a context object with the snapshotted value
      return { previous, previousMetadata };
    },
    // Always refetch after error or success:
    onSettled: (data, other, variables) => {
      const queryKeyStr = queryKeys.reaction({ datasetId: variables.datasetId, userId: user?._id || '' });
      queryClient.invalidateQueries({ queryKey: queryKeyStr });
    },
    ...options,
  });
};

import { useNavigate } from 'react-router-dom';

import { BackButton } from 'app/ui/BackButton';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Tabs } from 'shared/ui/Tabs';

import { DaiTab } from './ui/DaiTab';
import { DatasetTab } from './ui/DatasetTab';
import { LeaderBoardTabs } from './ui/LeaderBoardTabs';
import { ModelsTab } from './ui/ModelsTab';
import { StakersTab } from './ui/StakersTab';
import { TopListTab } from './ui/TopListTab';

// import { Tabs } from 'shared/ui/Tabs';
// import { ExplorerTab } from './ui/ExplorerTab';
// import { FAQTab } from './ui/FAQTab';
// import { LeaderBoardTabs } from './ui/LeaderBoardTabs';

export const Page = () => {
  const navigate = useNavigate();

  return (
    <AnimateRoute className="mx-auto flex w-full max-w-7xl flex-col px-4 pb-10 pt-5 md:pt-10">
      <div className="mb-5 flex-none">
        <BackButton
          className="gap-4 text-2xl font-semibold text-clay-900 hover:text-clay-900"
          onClick={() => navigate('/')}
        >
          Leaderboard
        </BackButton>
      </div>

      <LeaderBoardTabs>
        <Tabs.Content value="top-list">
          <TopListTab />
        </Tabs.Content>
        <Tabs.Content value="models">
          <ModelsTab />
        </Tabs.Content>
        <Tabs.Content value="dai">
          <DaiTab />
        </Tabs.Content>
        <Tabs.Content value="datasets">
          <DatasetTab />
        </Tabs.Content>
        <Tabs.Content value="stakers">
          <StakersTab />
        </Tabs.Content>
      </LeaderBoardTabs>
    </AnimateRoute>
  );
};

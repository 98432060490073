import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateTime } from 'luxon';

import { Button } from 'shared/ui/Button';
import { DatePicker } from 'shared/ui/DatePicker';
import { FilterButton } from 'shared/ui/FilterButton';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { Select } from 'shared/ui/Select';

const statuses = [
  { label: 'All', value: 'all' },
  { label: 'Completed', value: 'completed' },
  { label: 'Progress', value: 'progress' },
];

export const Filter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [status, setStatus] = useState<'all' | 'completed' | 'progress'>('all');

  useEffect(() => {
    if (!isPopoverOpen) return;

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const statusParam = searchParams.get('status');

    if (statusParam) setStatus(statusParam as typeof status);

    if (startDate) {
      const date = DateTime.fromFormat(startDate, 'yyyy-LL-dd');
      if (date.isValid) {
        setStartDate(date.toJSDate());
      }
    }
    if (endDate) {
      const date = DateTime.fromFormat(endDate, 'yyyy-LL-dd');
      if (date.isValid) {
        setEndDate(date.toJSDate());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverOpen]);

  return (
    <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
      <Popover.Trigger>
        <FilterButton icon="filter">Filter</FilterButton>
      </Popover.Trigger>
      <Popover.Content className="rounded-lg p-2">
        <div className="flex flex-col gap-3">
          <div className="border-b border-clay-20 p-2.5 pt-1 text-sm font-medium text-clay-350">Filter</div>

          <div className="flex w-full flex-col">
            <div className="flex justify-between pb-1.5">
              <div className="text-xs text-clay-500 sm:text-sm">Data Range</div>
              <div
                className="cursor-pointer text-xs text-primary-800 hover:text-primary-1000 sm:text-sm"
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
              >
                Reset
              </div>
            </div>

            <div className="flex gap-2">
              <div className="flex flex-col gap-2">
                <div className="h-3.5 text-xs text-clay-300 sm:text-sm">From</div>
                <DatePicker
                  maxDate={endDate || new Date()}
                  onDateChange={setStartDate}
                  selectedDate={startDate}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="h-3.5 text-xs text-clay-300 sm:text-sm">To</div>
                <DatePicker
                  maxDate={new Date()}
                  minDate={startDate || undefined}
                  onDateChange={setEndDate}
                  selectedDate={endDate}
                />
              </div>
            </div>
          </div>

          <hr className="border-t border-clay-20" />

          <div className="flex w-full flex-col">
            <div className="flex justify-between pb-1.5">
              <div className="text-xs text-clay-500 sm:text-sm">Status</div>
              <div
                className="cursor-pointer text-xs text-primary-800 hover:text-primary-1000 sm:text-sm"
                onClick={() => setStatus('all')}
              >
                Reset
              </div>
            </div>

            <Select
              className="border border-clay-20 bg-white py-2.5 pl-3 pr-1.5 shadow-sm hover:bg-clay-10"
              onValueChange={(value) => {
                setStatus(value as typeof status);
              }}
              value={status}
            >
              <Select.Content className="z-50">
                {statuses?.map((status) => {
                  return (
                    <Select.Item key={status.value} value={status.value}>
                      <div className="flex gap-0.5 font-normal text-clay-600 lg:text-xs">{status.label}</div>
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
          </div>

          <hr className="border-t border-clay-20" />

          <div className="flex h-8 w-full justify-between">
            <Button
              className="pl-2.5 pr-3 outline-0"
              color="secondary"
              onClick={() => {
                setSearchParams((prev) => {
                  const newSearch = new URLSearchParams(prev);
                  newSearch.delete('status');
                  newSearch.delete('startDate');
                  newSearch.delete('endDate');
                  console.log(newSearch);
                  return newSearch;
                });
                setIsPopoverOpen(false);
              }}
              size="extra-small"
              variant="filled-light"
            >
              <Icon className="size-3 text-clay-350" name="refresh" />
              <div className="mt-px text-xs text-clay-350 sm:text-sm">Reset all</div>
            </Button>

            <Button
              className="flex h-8 items-center justify-center gap-2 rounded-lg bg-primary-800 pl-2.5 pr-3 outline-0"
              onClick={() => {
                setIsPopoverOpen(false);
                setSearchParams((prev) => {
                  const newSearch = new URLSearchParams(prev);
                  if (status === 'all') {
                    newSearch.delete('status');
                  } else {
                    newSearch.set('status', status);
                  }
                  if (startDate) {
                    newSearch.set('startDate', DateTime.fromJSDate(startDate).toFormat('yyyy-LL-dd'));
                  } else {
                    newSearch.delete('startDate');
                  }
                  if (endDate) {
                    newSearch.set('endDate', DateTime.fromJSDate(endDate).toFormat('yyyy-LL-dd'));
                  } else {
                    newSearch.delete('endDate');
                  }
                  return newSearch;
                });
              }}
              size="extra-small"
              variant="filled-light"
            >
              <Icon className="size-3 text-white" name="check" />
              <div className="text-xs font-medium text-white sm:text-sm">Apply</div>
            </Button>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};

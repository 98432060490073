import { useInfiniteQuery } from '@tanstack/react-query';

import type { DaiEvmTransfers } from 'shared/api/daiTransfers/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiTransfersKeys } from './queryKeys';

type Response = {
  limit: number;
  list: DaiEvmTransfers[];
  skip: number;
  totalCount: number;
};

type Params = {
  limit?: number;
  skip?: number;
  walletAddress: string;
};

export const useDaiTransfersInfiniteQuery = (
  { limit = 20, skip, walletAddress }: Params,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: Response, all: Response[]) => (last.list.length === limit ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<Response>(`/dai-transfers/${walletAddress}/history`, {
        params: {
          limit,
          skip: pageParam * limit,
        },
        signal,
      });

      return res.data;
    },
    queryKey: daiTransfersKeys.history({ limit, skip, walletAddress }),
    staleTime: 1000 * 60,
    ...(options || {}),
  });
};

import { useNavigate, useParams } from 'react-router-dom';

import { useAccount as cosmosAccount } from 'graz';
import { Layers2 } from 'lucide-react';
import { useAccount as wagmiAccount } from 'wagmi';

import { EVMWalletProviderContent } from 'features/WalletEvmProviderModal';
import { WalletProvider } from 'features/WalletProvider';
import { EvmTransactions } from 'pages/Wallet/EvmTransactions';
import { Header } from 'pages/Wallet/Header';
import { MyAssetsCosmos } from 'pages/Wallet/MyAssetsCosmos';
import { useBalancesQuery } from 'shared/api/chain/useBalancesQuery';
import { useGetAllUserStakesSummaryQuery } from 'shared/api/dai/useGetAllUserStakesSummaryQuery';
import { useGetEvmBalancesByAddressInfiniteQuery } from 'shared/api/daiStats';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { wrapQueryOptions } from 'shared/helpers/wrapQueryOptions';
import { Badge } from 'shared/ui/Badge';
import { Card } from 'shared/ui/Card';
import { Spinner } from 'shared/ui/Spinner';
import { Tabs } from 'shared/ui/Tabs';
import { StakingAssetsTable } from 'widgets/StakingAssetsTable';

import type { ActiveWallet, WalletBalance, WalletTab } from './types';

import { MyAssetsEvm } from './MyAssetsEvm';
import nesaSrc from './assets/nesa.svg';
import { ActionButton } from './ui/ActionButton';

export const Page = () => {
  const navigate = useNavigate();
  const { section = 'assets', walletType = 'cosmos' } = useParams<{
    section: WalletTab;
    walletType: ActiveWallet;
  }>();
  const { data: account, isConnected: isCosmosConnected } = cosmosAccount();

  const { address: evmAccountAddress, isConnected: isWagmiConnected } = wagmiAccount();

  const { data, isLoading: isBalancesLoading } = useBalancesQuery(
    { address: account?.bech32Address || '' },
    { enabled: !!account?.bech32Address },
  );

  const {
    data: evmBalancesData,

    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending: isEvmBalancesLoading,
    refetch: evmBalanceRefetch,
  } = useGetEvmBalancesByAddressInfiniteQuery(...wrapQueryOptions({ address: evmAccountAddress }));

  const { data: totalStakesSummary } = useGetAllUserStakesSummaryQuery();

  const chainBalances: WalletBalance[] = (data?.balances || []).map((b) => ({
    ...b,
    decimals: 6, // In cosmos all token have 6
    // In future there must be fields for that in the backend
    image: b.denom === 'unes' ? nesaSrc : undefined,
    label: b.denom === 'unes' ? 'NES' : b.denom,
    subtitle: b.denom === 'unes' ? 'Nesa AI' : undefined,
  }));
  // const chainBalances: WalletBalance[] = [
  //   {
  //     amount: '4984000',
  //     denom: 'ibc/8E27BA2D5493AF5636760E354E46004562C46AB7EC0CC4C1CA14E9E20E2545B5',
  //   },
  //   {
  //     amount: '2189698372',
  //     denom: 'unes',
  //   },
  // ].map((b) => ({
  //   ...b,
  //   decimals: 6,
  //   // In future there must be fields for that in the backend
  //   image: b.denom === 'unes' ? nesaSrc : undefined,
  //   label: b.denom === 'unes' ? 'NES' : b.denom,
  //   subtitle: b.denom === 'unes' ? 'Nesa AI' : undefined,
  // }));

  const evmBalance: WalletBalance[] =
    evmBalancesData?.pages.flatMap((p) => {
      return p.list.map((b) => ({
        address: b.token.token?.creation?.address || 'Unknown',
        amount: b.balance.toString(),
        decimals: b.token.token?.ticker === 'USDN' ? 6 : 18, // In evm only USDN will have 6 and other is 18
        denom: b.token.token?.ticker || 'Unknown',
        image: b.token.token?.ticker === 'USDN' ? nesaSrc : b.token.token?.image ? b.token.token.image : '',
        label: b.token.token?.ticker || 'Unknown',
        subtitle: b.token.projectName,
      }));
    }) || [];

  // const evmBalance: WalletBalance[] =
  //   mock?.list.map((b) => {
  //     return {
  //       _id: b.token?._id,
  //       address: b.token.token?.creation?.address || 'Unknown',
  //       amount: b.balance.toString(),
  //       decimals: b.token.token?.ticker === 'USDN' ? 6 : 18, // In evm only USDN will have 6 and other is 18
  //       denom: b.token.token?.ticker || 'Unknown',
  //       image: b.token.token?.ticker === 'USDN' ? nesaSrc : b.token.token?.image ? b.token.token.image : '',
  //       label: b.token.token?.ticker || 'Unknown',
  //       subtitle: b.token.projectName,
  //     };
  //   }) || [];

  const usdnBalance = evmBalance?.find((coin) => coin.denom === 'USDN');
  const nesBalance = chainBalances?.find((coin) => coin.denom === 'unes');

  if (walletType === 'cosmos' && !isCosmosConnected) {
    return (
      <Header>
        <WalletProvider className="m-auto max-w-[600px] -translate-y-10 rounded-2xl bg-white p-6 shadow-md md:max-w-lg" />
      </Header>
    );
  }

  if (walletType === 'evm' && !isWagmiConnected) {
    return (
      <Header>
        <div className="flex size-full items-center justify-center">
          <EVMWalletProviderContent className="m-auto max-w-[600px] -translate-y-10 rounded-2xl bg-white p-6 shadow-md md:max-w-lg" />
        </div>
      </Header>
    );
  }

  return (
    <Header>
      <header className="mb-8 flex flex-col">
        <div className="flex items-center gap-3 text-lg">
          <img alt="nesa" className="size-11 rounded-full" src={nesaSrc} />
          {walletType === 'cosmos' ? 'NES' : 'USDN'} Balance
        </div>
        <div className="flex flex-col items-start justify-between gap-4 xs:flex-row xs:items-center xs:gap-0 md:min-h-[calc(3.5rem+0.5rem+1.5rem)]">
          <div className="text-5xl font-bold">
            {isBalancesLoading || isEvmBalancesLoading ? (
              <div className="p-2">
                <Spinner className="size-4 md:size-6" />
              </div>
            ) : (
              (walletType === 'cosmos'
                ? `${fromUnesToNes(nesBalance?.amount ?? '0')
                    .toNumber()
                    .toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}`
                : `${fromUnesToNes(usdnBalance?.amount ?? '0')
                    .toNumber()
                    .toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}`) || '0.00'
            )}
          </div>
          {walletType === 'cosmos' && (
            <div className="flex gap-2">
              <div className="flex flex-col gap-1 border-r-2 border-r-clay-20 pr-6">
                <span className="whitespace-nowrap text-base font-medium text-clay-380">
                  My Staked Assets
                </span>
                <span className="text-4xl font-semibold text-blue-900">
                  $
                  {totalStakesSummary?.userStakesSum.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </span>
              </div>
              <div className="pl-6">
                <ActionButton
                  icon={Layers2}
                  iconClassName="size-5"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <span className="text-base">Stake</span>
                </ActionButton>
              </div>
            </div>
          )}
        </div>
      </header>

      <Tabs.Root
        onValueChange={(val) => navigate(`/wallet/${walletType}/${val}`, { replace: true })}
        tabs={
          <Tabs.List className="mb-4">
            <Tabs.Trigger value="assets">My Assets</Tabs.Trigger>
            {walletType === 'evm' && <Tabs.Trigger value="tx-history">Transaction History</Tabs.Trigger>}
            {walletType === 'cosmos' && <Tabs.Trigger value="staked">My Staked Assets</Tabs.Trigger>}
          </Tabs.List>
        }
        value={section}
      />

      {section === 'assets' && (
        <div className="grid grid-cols-1 gap-4 pb-4">
          <div className="flex items-center gap-3">
            <h2 className="text-2xl">My Assets</h2>
            <Badge className="w-fit translate-y-0.5 bg-yellow-500/10 px-2 py-1 font-medium text-yellow-500">
              Testnet
            </Badge>
          </div>
          {walletType === 'cosmos' ? (
            <MyAssetsCosmos chainBalances={chainBalances} />
          ) : (
            <MyAssetsEvm
              evmBalance={evmBalance}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isPending={isEvmBalancesLoading}
              refetch={evmBalanceRefetch}
            />
          )}
        </div>
      )}

      {section === 'tx-history' && walletType === 'evm' && (
        <div className="grid grid-cols-1 gap-4 pb-4">
          <h2 className="text-2xl">My Transactions</h2>
          <EvmTransactions />
        </div>
      )}
      {section === 'staked' && (
        <div>
          <h2 className="text-2xl">My Staked Assets</h2>
          <Card className="my-4 !p-4">
            {/*<div className="max-h-svh overflow-y-auto scrollbar-none">*/}
            <StakingAssetsTable />
            {/*</div>*/}
          </Card>
        </div>
      )}
    </Header>
  );
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { SendHorizonal } from 'lucide-react';

import type { DAIComment } from 'shared/api/daiComments/types';

import { useUser } from 'app/stores/user';
import { daiCommentsKeys } from 'shared/api/daiComments/queryKeys';
import { useCreateDaiCommentMutation } from 'shared/api/daiComments/useCreateDaiCommentMutation';
import { useDAICommentsInfiniteQuery } from 'shared/api/daiComments/useDAICommentsInfiniteQuery';
import { useDeleteDaiCommentMutation } from 'shared/api/daiComments/useDeleteDaiCommentMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Spinner } from 'shared/ui/Spinner';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

import { CommentCard } from './CommentCard';
import { CommentReplyModal } from './CommentReplyModal';
import { ReportModal } from './ReportModal';

type Props = {
  daiId: string;
};
export const DAIComments = ({ daiId }: Props) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();
  const queryClient = useQueryClient();

  const [content, setContent] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState<DAIComment>();

  const { isPending, mutateAsync: createComment } = useCreateDaiCommentMutation();
  const {
    isPending: isDeleting,
    mutateAsync: deleteComment,
    variables: deleteVars,
  } = useDeleteDaiCommentMutation();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending: isCommentsPending,
  } = useDAICommentsInfiniteQuery({ daiId, limit: 10 });
  const comments = data?.pages.flat() || [];

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const handleCreateComment = async () => {
    try {
      if (!user) {
        navigate('/auth/signin');
        return;
      }
      if (!content) return;

      const res = await createComment({ content, daiId });

      console.log('res', res);

      setContent('');

      toaster.success('Comment is saved');
    } catch (e) {
      catchError(e);
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    try {
      await deleteComment(
        { commentId },
        {
          onSuccess: () => {
            const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10 });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<{ pageParams: number[]; pages: DAIComment[][] }>(
              queryKey,
            );
            // Optimistically update to the new value
            queryClient.setQueryData(queryKey, {
              ...previousData,
              pages: previousData?.pages.map((page) => page.filter((item) => item._id !== commentId)),
            });

            queryClient.invalidateQueries({
              queryKey: daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo: commentId }),
            });
          },
        },
      );

      toaster.success('Comment was deleted');
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <>
      <div className="relative">
        <TextArea
          disabled={isPending}
          onChange={(e) => setContent(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.shiftKey == false) {
              handleCreateComment();
            }
          }}
          placeholder="Write Message here"
          rows={4}
          value={content}
        />
        <Button
          className="absolute bottom-6 right-2 px-2.5"
          disabled={!content}
          isLoading={isPending}
          onClick={handleCreateComment}
        >
          <SendHorizonal className="size-5" />
        </Button>
      </div>

      {isCommentsPending ? (
        <div className="flex items-center justify-center py-5">
          <Spinner className="size-5" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-4 py-4">
            {comments.map((comment) => {
              return (
                <CommentCard
                  author={`${comment.author.first_name || ''} ${comment.author.last_name || ''}`}
                  authorId={comment.author._id}
                  authorImage={comment.author.avatar}
                  className="border-b border-clay-20 pb-4"
                  commentId={comment._id}
                  commentsCount={comment.commentsCount || 0}
                  daiId={comment.daiId}
                  isDeleting={isDeleting && deleteVars.commentId === comment._id}
                  key={comment._id}
                  onDeleteComment={() => handleDeleteComment(comment._id)}
                  onReply={() => {
                    setSelectedComment(comment);
                    setIsOpen(true);
                  }}
                  onReport={() => {
                    setSelectedComment(comment);
                    setIsReportOpen(true);
                  }}
                  reactions={comment.reactions}
                  text={comment.content}
                  timestamp={comment.createdAt}
                />
              );
            })}
          </div>

          <div className="-mt-4" ref={ref} />

          {isFetchingNextPage && (
            <div className="flex items-center justify-center py-4">
              <Spinner className="size-5" />
            </div>
          )}
        </>
      )}

      {selectedComment && (
        <CommentReplyModal commentToReply={selectedComment._id} isOpen={isOpen} onOpenChange={setIsOpen} />
      )}

      {selectedComment && (
        <ReportModal commentId={selectedComment._id} isOpen={isReportOpen} onOpenChange={setIsReportOpen} />
      )}
    </>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

export const useCheckExistsDaiSlugQuery = (
  slug: string,
  options: UseQueryOptions<{ exists: boolean }> = {},
) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<{ exists: boolean }>(`/dai/check-exists-slug/${slug}`);

      return data;
    },
    queryKey: daiKeys.checkExistsDaiSlug(slug),
    ...options,
  });
};

import type { ComponentProps, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

type Props = ClassName & ComponentProps<'div'>;

export const Badge = ({ children, className, ...other }: PropsWithChildren<Props>) => {
  return (
    <div
      {...other}
      className={twMerge(
        'flex select-none items-center justify-center overflow-hidden rounded px-1.5 py-1 text-xs/none',
        className,
      )}
    >
      {children}
    </div>
  );
};

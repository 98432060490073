import { Navigate, useParams } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { useDAIBySlugQuery } from 'shared/api/dai/useDAIBySlugQuery';
import { useGetUserDAIsQuery } from 'shared/api/dai/useGetUserDAIsQuery';
import { useGetModelsByIdsQuery } from 'shared/api/models/useGetModelsByIdsQuery';
import { Spinner } from 'shared/ui/Spinner';

import type { Step } from './types';

import { CreateDAIContent } from './ui/CreateDAIContent';

export const CreateDAI = () => {
  const { slug: daiSlugToEdit = '' } = useParams<{ slug: string }>();
  const { user } = useUser();
  const userDais = useGetUserDAIsQuery(
    { limit: 100, skip: 0, userId: user?._id || '' },
    { enabled: !!user?._id },
  );

  const {
    data: daiToEditData,
    isError,
    isPending: isDaiToEditPending,
  } = useDAIBySlugQuery(daiSlugToEdit, { enabled: !!daiSlugToEdit });

  const daiToEdit = daiToEditData?.data;

  const modelsQuery = useGetModelsByIdsQuery(
    { ids: daiToEdit?.connectedModels || [] },
    { enabled: !!daiToEdit },
  );

  if (!!daiSlugToEdit && isError) {
    return <Navigate to="/dai/create" />;
  }

  if (userDais.isPending || (!!daiSlugToEdit && (isDaiToEditPending || modelsQuery.isPending))) {
    return (
      <div className="flex size-full items-center justify-center">
        <Spinner className="size-6" />
      </div>
    );
  }

  const isCreator = daiToEdit ? daiToEdit?.creator === user?._id : true;

  if (!isCreator || (!!daiSlugToEdit && isError)) {
    return <Navigate to="/dai/create" />;
  }

  const startStep: Step = (userDais.data?.totalCount || 0) > 0 && !daiToEdit ? 'initial' : 'start';

  return (
    <CreateDAIContent
      daiConnectedModels={modelsQuery.data}
      daiToEdit={daiToEdit}
      initialStep={startStep}
      key={daiSlugToEdit}
    />
  );
};

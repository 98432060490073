import { useState } from 'react';

import { twJoin } from 'tailwind-merge';

import type { HuggingfacePost } from 'shared/api/huggingface';

type Props = {
  onPostClick?: (post: HuggingfacePost) => void;
  post: HuggingfacePost;
  short?: boolean;
};

export const HuggingFaceContent = ({ post, short = false }: Props) => {
  const [showReadMore, setShowReadMore] = useState(false);

  return (
    <div className="relative">
      <div
        className={twJoin('relative text-base text-clay-500', short && 'max-h-[8lh] overflow-hidden')}
        ref={(el) => {
          if (el) {
            setShowReadMore(el.scrollHeight > el.clientHeight);
          }
        }}
      >
        {post.content.map((el, i) => {
          if (el.type === 'new_line') return <br key={i} />;
          if (el.type === 'link')
            return (
              <a
                className=" text-primary-800 underline hover:text-primary-1000"
                href={el.href}
                key={i}
                rel="noreferrer"
                target="_blank"
              >
                {el.value || el.raw}
              </a>
            );
          return <span key={i}>{el.value || el.raw}</span>;
        })}
      </div>
      {showReadMore && short && (
        <div className="absolute bottom-0 right-4 border-0 bg-white pl-1 text-base text-clay-350 underline">
          ...read more
        </div>
      )}
    </div>
  );
};

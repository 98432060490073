import { CodeBlock } from 'shared/ui/CodeBlock';
import { Modal } from 'shared/ui/Modal';

type Props = {
  configs: string[];
  datasetId: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const CroissantCodeModal = ({ configs, datasetId, isOpen, onOpenChange }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-screen-md">
        <Modal.CloseButton />
        <h1 className=" mb-3 border-b border-clay-20 pb-2 text-2xl font-semibold">
          How to use from the Croissant library
        </h1>

        {configs.map((config) => (
          <CodeBlock className="mb-4 border-b border-clay-20 pb-0" enableCopy key={config} language="python">
            {`from mlcroissant import Dataset

ds = Dataset(jsonld="https://umga.nesa.ai/api/datasets/${datasetId}/croissant")
records = ds.records("${config}")
          `}
          </CodeBlock>
        ))}
      </Modal.Content>
    </Modal>
  );
};

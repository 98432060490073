import { useForm } from 'react-hook-form';

import { useUser } from 'app/stores/user';
import { useCreateReportMutation } from 'shared/api/reviews/useCreateReportMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

type Props = {
  datasetId?: string;
  isOpen: boolean;
  modelId?: string;
  onOpenChange: (isOpen: boolean) => void;
  title: string;
};

type FormValues = {
  feedback: string;
};

export const ReportModal = ({ datasetId, isOpen, modelId, onOpenChange, title }: Props) => {
  const { user } = useUser();
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: { feedback: '' },
    mode: 'onChange',
  });

  const { isPending, mutateAsync: report } = useCreateReportMutation();

  const handleReport = async (values: FormValues) => {
    try {
      await report({ datasetId, feedback: values.feedback, modelId, userId: user?._id || '' });

      toaster.success('Your report was sent successfully');
      onOpenChange(false);
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-3xl">
        <Modal.CloseButton />

        <h1 className="mb-4 flex items-start gap-3">
          <Icon className="size-6 min-w-6 text-clay-900 sm:size-7" name="bug" />

          <div className="mt-0 flex flex-col gap-2 sm:mt-1">
            <h1 className="text-xl font-light text-clay-900">
              New report for <b>{title || ''}</b>
            </h1>
            <div className="text-sm font-light text-clay-500">
              This will open a public discussion in the community tab and also ping the Nesa team about your
              report.
            </div>
          </div>
        </h1>

        <div className="relative mb-2 flex flex-col">
          <TextArea
            {...register('feedback', { required: 'Feedback is required' })}
            error={errors?.['feedback']?.message?.toString() || undefined}
            placeholder="Report..."
            rows={10}
          />

          <Button
            className="absolute bottom-6 right-2 w-fit !pl-1.5 "
            color="white"
            onClick={() => {
              toaster.info('Coming soon');
            }}
            size="extra-small"
          >
            <Icon className="size-4 rotate-90 text-clay-600" name="arrowLeftLong" />
            <span className="text-sm/none">Attach File, Images, Video</span>
          </Button>
        </div>

        <div className="flex flex-wrap items-center justify-between gap-2">
          <Button
            className="font-light"
            disabled={!isValid}
            isLoading={isPending}
            onClick={handleSubmit(handleReport)}
          >
            Send Report
          </Button>

          <a
            className="cursor-pointer text-sm/none text-clay-700 underline transition-colors hover:text-clay-900"
            href="https://nesa.ai/contact"
            rel="noreferrer"
            target="_blank"
          >
            Need help with reports?
          </a>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import { useGetFileByCidQuery } from 'shared/api/ipfs/useGetFileByCidQuery';

import { Attachment } from './Attachments';

type Props = {
  cid: string;
};

export const NesaAttachment = ({ cid }: Props) => {
  const { data: fileData } = useGetFileByCidQuery(cid);

  if (fileData?.type === 'image') {
    return <Attachment attachment={{ type: 'image', url: fileData.data }} />;
  }
  return null;
};

import { useMutation } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import { waitForTransactionReceipt } from 'viem/actions';
import { useAccount, useChainId, useWalletClient, useWriteContract } from 'wagmi';

import { erc20Abi } from 'shared/config/abi/erc20Abi';
import { axiosInstance } from 'shared/config/axiosInstance';
import { evmCoins } from 'shared/config/coins';
import { catchError } from 'shared/helpers/parseAxiosError';
import { toaster } from 'shared/ui/Toast';

import type { FaucetResponse } from './useFaucetNonceQuery';

export const useClaimEvmUsd1Mutation = () => {
  const chainId = useChainId();
  const { address: accountAddress } = useAccount();
  const { data: wc } = useWalletClient({ chainId });

  const { writeContractAsync } = useWriteContract();

  return useMutation({
    mutationFn: async () => {
      invariant(accountAddress, 'useClaimEvmUsdMutation. accountAddress is undefined');
      invariant(wc, 'useClaimEvmUsdMutation. wc is undefined');

      const { data } = await axiosInstance.get<FaucetResponse>(`/evm-faucet/${accountAddress}/nonce`);

      invariant(data?.nonce, 'useClaimEvmUsdMutation. nonce is undefined');
      invariant(data?.signature, 'useClaimEvmUsdMutation. signature is undefined');

      const hash = await writeContractAsync({
        abi: erc20Abi,
        account: accountAddress,
        address: evmCoins.nesaUSD[chainId].address,
        args: [accountAddress, BigInt(data?.amount), data?.nonce, data?.signature],
        functionName: 'claimFaucet',
      });

      await waitForTransactionReceipt(wc, { hash });
    },
    onError: (e) => catchError(e),
    onSuccess: () => toaster.success('Success'),
  });
};

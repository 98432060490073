import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

type Response = {
  allTimeTxCount: number;
  stakedByUser?: number;
  stakers: number;
  yesterdayTxCount: number;
};

type Params = {
  daiId: string;
  userId?: string;
  walletAddresses: string[];
};

export const useGetDAISummaryQuery = (
  { daiId, ...params }: Params,
  options: UseQueryOptions<Response> = {},
) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/${daiId}/summary`, {
        params: params,
      });

      return data;
    },
    queryKey: daiKeys.daiSummary({ ...params, daiId }),
    ...options,
  });
};

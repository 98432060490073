import { twMerge } from 'tailwind-merge';

import type { User } from 'shared/api/user/types';

import { UserAvatar } from 'entities/UserAvatar';

type Props = {
  className?: string;
  user: User;
};

export const NesaAvatar = ({ className, user }: Props) => {
  return <UserAvatar className={twMerge('size-9 min-w-9 text-[70%]', className)} user={user} />;
};

import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';

import type { Model } from 'shared/api/models/types';

import { useModelListOriginQuery } from 'shared/api/models/useGetModelListQuery';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';

import { useSettingTableKeysContext } from '../LeaderBoardTabs';
import { getColumns } from './config';
import { Skeleton } from './ui/Skeleton';

type Props = {
  short?: boolean;
};

export const ModelsTab = ({ short }: Props) => {
  const navigate = useNavigate();

  const selectedTableKeys = useSettingTableKeysContext();

  const [searchParams, setSearchparams] = useSearchParams();

  const types = searchParams.getAll('type');
  const sort = (searchParams.get('sort') || 'likes') as keyof Model;
  const sortType = (searchParams.get('sortType') || 'desc') as 'asc' | 'desc';

  const [ref, intersection] = useIntersectionObserver({ rootMargin: '30px' });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } = useModelListOriginQuery({
    direction: sortType,
    limit: short ? 5 : 40,
    sort: sort || undefined,
    type: types,
  });

  const onSortChange = (sortKey: string, sortType: string) => {
    setSearchparams((prev) => {
      const newSearch = new URLSearchParams(prev);
      newSearch.set('sort', sortKey);
      newSearch.set('sortType', sortType);
      return newSearch;
    });
  };

  const isLoading = status === 'pending';

  const columns = useMemo(() => {
    const page = data?.pages[0];
    if (!page) return [];
    return getColumns(short ? new Set() : selectedTableKeys, page.total_count, sortType);
  }, [selectedTableKeys, short, sortType, data]);

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage && !short) {
      fetchNextPage();
    }
  }, [intersection?.isIntersecting, hasNextPage, short, fetchNextPage]);

  const tableData = useMemo(() => {
    if (!data) return [];
    return data?.pages.flatMap((el) => el.data);
  }, [data]);

  tableData.at(0);

  return (
    <>
      <div className="w-full flex-1 overflow-auto">
        {isLoading ? (
          <Skeleton selectedTableKeys={short ? new Set() : selectedTableKeys} />
        ) : (
          <>
            <Table
              className="min-w-[58rem]"
              columns={columns}
              data={tableData}
              onRowClick={(row) => navigate(`/models/${row._id}`)}
              onSortChange={({ key, type }) => onSortChange(key, type)}
              sort={{
                key: sort,
                type: sortType,
              }}
            />
            <div ref={ref} />
          </>
        )}
        {isFetchingNextPage && (
          <div className=" flex justify-center py-5">
            <Spinner className=" size-8" />
          </div>
        )}
      </div>
    </>
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import { getContract } from 'viem';
import { waitForTransactionReceipt } from 'viem/actions';
import { useAccount, useChainId, useWalletClient } from 'wagmi';

import { erc20Abi } from 'shared/config/abi/erc20Abi';
import { catchError } from 'shared/helpers/parseAxiosError';
import { toaster } from 'shared/ui/Toast';

import { getAllowanceQueryKey } from './useAllowanceQuery';

type Params = {
  contractAddress: `0x${string}`;
  currencyAddress: `0x${string}`;
};

export const useApproveMutation = () => {
  const chainId = useChainId();
  const { data: wc } = useWalletClient({ chainId });
  const { address: accountAddress } = useAccount();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Params) => {
      invariant(wc, 'useERC20ApproveMutation. wc is undefined');

      const contract = getContract({
        abi: erc20Abi,
        address: params.currencyAddress,
        client: wc,
      });

      const totalSupply = await contract.read.totalSupply();

      const defaultAllowance = 10000n * 10n ** 18n;

      const {
        request: { args, ...options },
      } = await contract.simulate.approve([
        params.contractAddress,
        defaultAllowance > totalSupply ? defaultAllowance : totalSupply,
      ]);

      const hash = await contract.write.approve(args, options);

      const receiptPromise = waitForTransactionReceipt(wc, { hash });

      return await receiptPromise;
    },
    onError: (e) => catchError(e),
    onSuccess: async (_, variables) => {
      toaster.success('Spending has been approved');
      // notifySuccess(`Spending ${variables.currency.symbol} has been approved`);
      const { contractAddress, currencyAddress } = variables;
      const key = getAllowanceQueryKey({
        accountAddress: accountAddress!,
        contractAddress,
        currencyAddress,
      });

      const result = await queryClient.invalidateQueries({ queryKey: key });
      return result;
    },
  });
};

import type { DatasetSplit } from 'shared/api/datasets/useGetDatasetSplitsQuery';

export const getConfigsFromSplits = (splits: DatasetSplit[]) => {
  return splits.reduce<string[]>((acc, item) => {
    if (acc.includes(item.config)) {
      return acc;
    }

    acc.push(item.config);

    return acc;
  }, []);
};

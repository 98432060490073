import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiStatsKeys } from '.';

type Balances = {
  balance: number;
  token: Token;
};

type Token = {
  projectName: string;
  token: {
    creation: {
      address: string;
    };
    image: string;
    ticker?: string;
  };
};

type Response = { count: number; limit: number; list: Balances[]; skip: number };

type Props = {
  address: string;
  limit?: number;
  skip?: number;
};

export const useGetEvmBalancesByAddressInfiniteQuery = (
  { limit = 20, ...props }: Props,
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: Response, all: Response[]) => (last.list.length === limit ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const { data } = await axiosInstance.get<Response>(`/dai-stats/${props.address}/balances`, {
        params: {
          limit: limit,
          skip: pageParam * limit,
        },
        signal,

        timeout: 20000,
      });

      return data;
    },
    queryKey: daiStatsKeys.getEvmBalancesByAddress({ limit, ...props }),
    ...options,
  });
};

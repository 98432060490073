import { useNavigate } from 'react-router-dom';

import { Image } from 'lucide-react';

import { BackButton } from 'app/ui/BackButton';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Badge } from 'shared/ui/Badge';
import { Button } from 'shared/ui/Button';

import { GeneratedResult } from './ui/GeneratedResult';
import { Header } from './ui/Header';

export const Function = () => {
  const navigate = useNavigate();
  return (
    <AnimateRoute className=" flex h-full flex-col overflow-hidden rounded-2xl bg-clay-20 p-2">
      {/* <AnimateRoute className="mx-4 mb-4 flex h-full flex-col overflow-hidden rounded-2xl bg-clay-20 p-2"> */}{' '}
      <div className="relative flex grow flex-col overflow-y-scroll rounded-2xl bg-white py-3  sm:py-3.5">
        <div className="flex w-full flex-col border-b border-clay-20 px-4 pb-3.5">
          <BackButton className="text-[12px]" onClick={() => navigate('/functions')}>
            Back to functions
          </BackButton>
        </div>
        <Header />

        <div className="grid grow grid-cols-3 px-4 pt-4">
          <div className="col-span-2 grid grid-cols-2 gap-4 border-r border-clay-20 pr-4">
            <div className="relative flex items-center justify-center rounded-2xl border border-dashed border-clay-20 p-2 text-sm text-clay-300">
              <Badge className="absolute left-2 top-2 flex items-center gap-1 rounded-lg bg-clay-10 px-2 py-1.5 text-clay-700">
                <Image className="size-3" />
                Upload Image Input
              </Badge>
              <div className=" absolute right-2 top-2 flex size-5 items-center justify-center rounded-full bg-clay-20 text-xs font-medium text-clay-700">
                1
              </div>
              Drop Image Here or Click to Upload
            </div>
            <div className="relative flex items-center justify-center rounded-2xl border border-dashed border-clay-20 p-2 text-sm text-clay-300">
              <Badge className="absolute left-2 top-2 flex items-center gap-1 rounded-lg bg-clay-10 px-2 py-1.5 text-clay-700">
                <Image className="size-3" />
                Upload Image Input
              </Badge>
              <div className=" absolute right-2 top-2 flex size-5 items-center justify-center rounded-full bg-clay-20 text-xs font-medium text-clay-700">
                2
              </div>
              Drop Image Here or Click to Upload
            </div>
            <div className="col-span-full mt-auto flex items-center justify-between">
              <Button color="secondary">Reset</Button>
              <Button>Generate Function</Button>
            </div>
          </div>
          <GeneratedResult />
        </div>
      </div>
    </AnimateRoute>
  );
};

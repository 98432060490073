import type { ReactNode } from 'react';

import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { CATEGORY_LABEL_MAP } from 'shared/const';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { timeAgo } from 'shared/helpers/timeAgo';
import { Badge } from 'shared/ui/Badge';

type Props = {
  category: string;
  description: string;
  iconSlot: ReactNode;
  onClick?: () => void;
  publishedAt: string;
  title: string;
} & ClassName;

export const FunctionCard = ({
  category,
  className,
  description,
  iconSlot,
  onClick,
  publishedAt,
  title,
}: Props) => {
  const badgeColor = CATEGORY_LABEL_MAP[category as keyof typeof CATEGORY_LABEL_MAP]?.color;
  return (
    <div
      className={twMerge(
        'flex gap-4 rounded-xl border border-clay-20 bg-white p-4 transition',
        !!onClick && 'cursor-pointer hover:border-clay-40',
        className,
      )}
      onClick={onClick}
    >
      <div className="flex size-9 min-w-9 items-center justify-center rounded-lg bg-clay-10">{iconSlot}</div>
      <div className="flex grow flex-col">
        <h2 className="mb-2 text-[16px] font-semibold">{title}</h2>
        <div className="mb-2.5 line-clamp-2 text-sm/4 font-light text-clay-500">{description}</div>

        <Badge
          className="max-w-fit font-medium uppercase"
          style={{
            backgroundColor: badgeColor,
          }}
        >
          {getCategoryLabel(category)}
        </Badge>

        <div className="ml-auto text-[10px] font-medium uppercase leading-none text-clay-300">
          {timeAgo(DateTime.fromISO(publishedAt))}
        </div>
      </div>
    </div>
  );
};

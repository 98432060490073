import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Icon, isIconName } from 'shared/ui/Icon';

import type { SidebarItem } from '../../types';

type Props = {
  iconClassName?: string;
  iconWrapperClassName?: string;
  isExpanded: boolean;
} & ClassName &
  SidebarItem;

export const SidebarLink = ({
  className,
  disabled,
  icon,
  iconClassName,
  iconWrapperClassName,
  isExpanded,
  name,
  onClick,
  path,
  strokeWidth = 1.5,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const LucideIcon = icon;

  const isActive =
    path === '/'
      ? location.pathname === path || location.pathname.startsWith('/dai')
      : location.pathname.startsWith(path);

  return (
    <AnimatePresence>
      <NavLink
        className={twMerge(className, disabled && 'pointer-events-none')}
        key={path}
        onClick={onClick}
        onPointerDown={(e) => {
          e.preventDefault();
        }}
        state={{ sidebar: true }}
        to={path}
      >
        <motion.div
          aria-disabled={disabled || undefined}
          className={twMerge(
            'flex h-9 w-fit items-center gap-2 rounded-xl p-2 text-clay-400 transition-colors hover:bg-clay-20',
            isActive && 'bg-tusk-100 text-blue-900 hover:bg-tusk-100',
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isIconName(LucideIcon) ? (
            <Icon className={twMerge('size-5', iconClassName)} name={LucideIcon} safeArea="0" />
          ) : (
            <LucideIcon className={twMerge('size-5', iconClassName)} strokeWidth={strokeWidth} />
          )}

          {(isHovered || isExpanded) && (
            <motion.div
              animate={{ opacity: 1, width: 'auto' }}
              className={twMerge(
                'overflow-hidden whitespace-nowrap text-sm font-medium text-clay-800',
                iconWrapperClassName,
              )}
              exit={{ opacity: 0, width: 0 }}
              initial={{ opacity: 0, width: 0 }}
              key={name}
            >
              {name}
            </motion.div>
          )}
        </motion.div>
      </NavLink>
    </AnimatePresence>
  );
};

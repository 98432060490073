import { useSlate } from 'slate-react';

import { Icon, type IconName } from 'shared/ui/Icon';

import { type CustomTextLeaf, isMarkActive, toggleMark } from '../libs';

type Props = {
  icon: IconName;
  type: CustomTextLeaf;
};

export const InlineButton = ({ icon, type }: Props) => {
  const editor = useSlate();

  const isActive = isMarkActive(editor, type);

  return (
    <div
      aria-selected={isActive}
      className="cursor-pointer rounded text-clay-500 duration-200 aria-selected:bg-clay-20 aria-selected:text-clay-900 hover:text-clay-900"
      onClick={(e) => {
        e.preventDefault();
        toggleMark(editor, type);
      }}
    >
      <Icon className="size-5" name={icon} />
    </div>
  );
};

import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import type { LiveEvent } from 'shared/api/dai/types';

import { useGetActivityLatestQuery } from 'shared/api/dai/useGetActivityLatestQuery';
import { sleep } from 'shared/helpers/sleep';
import { uuidv4 } from 'shared/helpers/uuid';

export type EventType = 'createStake' | 'swap'; // swap is not existing on backend

export type EventProps = {
  // addItem: (num?: number) => void;
  events: LiveEvent[];
  isNewEventAppeared: boolean;
  makeAllEventsAnimationDisabled: VoidFunction;
  popPendingEvent: (num?: number) => void;
};

export const EventsContext = createContext<EventProps>({
  // addItem: () => {},
  events: [],
  isNewEventAppeared: false,
  makeAllEventsAnimationDisabled: () => void 0,
  popPendingEvent: () => {},
});

type Props = {
  initialData: LiveEvent[];
};

// const item: LiveEvent = {
//   __v: 0,
//   _id: '67d1437f8ab251237a94b219',
//   account: 'nesa1rs4kg6n6mmz55x5u69vzqjz66waltvu0a0dhy8',
//   amount: '1000000',
//   company_id: {
//     _id: '679a8ea26bb5a237248c1eb9',
//     profileImage: 'https://cdn-beta.nesa.ai/dai/6636aa70a8721c20698234d4-1738347357578.png',
//     projectName: 'TEST',
//   },
//   isInitial: false,
//   lockup_seconds: 7776000,
//   timestamp: 1741767551378,
//   type: 'createStake',
//   user_id: '66484b6513eb8daa35c75b24',
// };

const Provider = ({ children, initialData }: PropsWithChildren<Props>) => {
  const [eventData, setData] = useState<LiveEvent[]>(initialData);
  const [isNewEventAppeared, setNewEventAppeared] = useState<boolean>(false);
  const [pendingEvents, setPendingEvents] = useState<LiveEvent[]>([]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleQueue = useCallback(() => {
    setNewEventAppeared(true);
    sleep(350)
      .then(() => setNewEventAppeared(false))
      .then(() =>
        sleep(350).then(() => {
          if (pendingEvents.length > 0) {
            const event = pendingEvents[0];
            setData((prev) => {
              return prev.length > 15 ? [event, ...prev.slice(0, 14)] : [event, ...prev];
            });
            setPendingEvents((prev) => prev.slice(1));
          } else {
            setData((prev) => {
              const prevWithoutLast = prev.slice(0, -1);
              return [{ ...prev.slice(-1)[0], uniqueKey: uuidv4() }, ...prevWithoutLast];
            });
          }
        }),
      );
  }, [pendingEvents]);

  useEffect(() => {
    const eventSource = new EventSource(`${import.meta.env.VITE_API_ENDPOINT}/dai/chain-activity`, {
      withCredentials: true,
    });

    eventSource.onmessage = (event) => {
      const data: LiveEvent = JSON.parse(event.data);

      setPendingEvents((prev) => [...prev, data]);
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  const makeAllEventsAnimationDisabled = () => {
    setData((prev) => [...prev.map((p) => ({ ...p, isInitial: true }))]);
  };

  return (
    <EventsContext.Provider
      value={{
        // addItem: async (index = 0) => {
        //   const mockItem: LiveEvent = {
        //     ...item,
        //     _id: uuidv4(),
        //     amount: `${Math.random() * 1000000}`,
        //     company_id: {
        //       _id: '679a8ea26bb5a237248c1eb9',
        //       profileImage: 'https://cdn-beta.nesa.ai/dai/6636aa70a8721c20698234d4-1738347357578.png',
        //       projectName: 'TEST',
        //     },
        //   };
        //   setNewEventAppeared(true);
        //   sleep(500)
        //     .then(() => setNewEventAppeared(false))
        //     .then(() => setIsAdding(true))
        //     .then(() =>
        //       sleep(500).then(() => {
        //         // if (pendingEvents.length > 0) {
        //         const event = mockItem;
        //         setData((prev) => {
        //           return prev.length > 15 ? [event, ...prev.slice(0, 14)] : [event, ...prev];
        //         });
        //         setPendingEvents((prev) => prev.slice(1));
        //         // } else {
        //         //   setData((prev) => {
        //         //     const prevWithoutLast = prev.slice(0, -1);
        //         //     return [prev.slice(-1)[0], ...prevWithoutLast];
        //         //   });
        //         // }
        //         setIsAdding(false);
        //       }),
        //     );
        // },
        events: eventData,
        isNewEventAppeared,
        makeAllEventsAnimationDisabled,
        popPendingEvent: handleQueue,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const LiveEventsProvider = ({ children }: PropsWithChildren) => {
  const { data } = useGetActivityLatestQuery({ limit: 10 }, { staleTime: Infinity });

  console.log('data', data);

  return (
    <Provider initialData={data?.list?.map((l) => ({ ...l, isInitial: true })) || []}>{children}</Provider>
  );
};

export const useEvents = () => useContext(EventsContext);

import { useFormContext, useWatch } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';

import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';

import type { UploadModelValues } from '../UploadModelModal';

import { StepHeader } from './StepHeader';

type Props = {
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onTitleClick: () => void;
};

export const Encryption = ({ isExpanded, onPrevClick, onTitleClick }: Props) => {
  const { control, setValue } = useFormContext<UploadModelValues>();
  const encryptionEnabled = useWatch({ control, name: 'encryptionEnabled' });

  return (
    <>
      <StepHeader
        icon="lock"
        isActive={isExpanded}
        isCompleted={!!encryptionEnabled}
        onClick={onTitleClick}
        title="Encryption"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="flex shrink-0 flex-col gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <div className="mx-4 mt-4 h-full px-0">
              <Checkbox.CheckboxWrapper>
                <Checkbox.Control
                  checked={encryptionEnabled}
                  onChange={(e) => setValue('encryptionEnabled', e.target.checked)}
                  value="enabled"
                />
                <Checkbox.CheckboxLabel>Enable Equivariant Encryption (Optional)</Checkbox.CheckboxLabel>
              </Checkbox.CheckboxWrapper>
            </div>

            <span className="mx-4 flex text-sm font-normal text-clay-500">
              Equivariant Encryption (EE) is a special privacy-preserving technology developed by Nesa that
              can provide end-to-end encryption on inference for your model when people use it. If enabled,
              Nesa will reach out to explain the process for one-time encryption of your model.
            </span>

            <div className="mx-4 mb-4 flex justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={onPrevClick}
                variant="filled-light"
              >
                Back
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

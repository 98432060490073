import { type ComponentProps, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { json } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { motion } from 'framer-motion';
import { Wrench } from 'lucide-react';
import { twJoin } from 'tailwind-merge';

import { BackButton } from 'app/ui/BackButton';
import { PageIsInAlphaModal } from 'features/PageIsInAlphaModal';
import { UpgradeToProModal } from 'features/UpgradeToProModal';
import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { useGetTrainingByIdQuery } from 'shared/api/training';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { FilePicker } from 'shared/ui/FilePicker';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';
import { Switch } from 'shared/ui/Switch';
import { TextArea } from 'shared/ui/TextArea';

import { categoryTypes, dataSetSourceOptions, fullParameters, parameters } from './const';

const mockLogs = [
  `10.16.12.226:41404 - "GET /ui/is_model_training HTTP/1.1" 200 OK|`,
  `10.16.16.234:1562 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.16.234:49744 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.12.226:38567 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.12.226:34977 - "GET /ui/accelerators HTTP/1.1" 200 OK`,
  `10.16.3.13:37659 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.16.234:4156 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.12.226:7983 - "GET /ui/accelerators HTTP/1.1" 200 OK`,
  `10.16.3.13:39731 - "GET /ui/is_model_training HTTP/1.1" 200 OK |`,
  `10.16.12.226:14483 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.16.234:5686 - "GET /ui/is_model_training HTTP/1.1" 200 OK |`,
  `10.16.38.196:19069 - "GET /ui/accelerators HTTP/1.1" 200 OK`,
  `10.16.3.13:4160 - "GE| /uI/is_model_training HI1P/1.1 200 OK`,
  `10.16.3.13:15352 - "GET /ui/accelerators HTTP/1.1" 200 OK|`,
  `10.16.38.196:57564 - "GET /ui/is_model_training HTTP/1.1" 200 OK |`,
  `10.16.16.234:15012 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.16.234:28530 - "GET /ui/is_model_training HTTP/1.1" 200 OK`,
  `10.16.38.196:61525 - "GET /ui/accelerators HTTP/1.1" 200 OK |`,
  `10.16.3.13:9385 - "GET /ui/is_model_training HTTP/1.1" 200 OK|`,
  `10.16.16.234:3284 - "GET /ui/is_ model_ training HTTP/1.1" 200 OK|`,
  `10.16.16.234:33580 - "GET /ui/accelerators HTTP/1.1" 200 OK`,
];

type FormValues = {
  custom: boolean;
  datasetSource: string;
  modelId: string;
  parameters: Partial<Record<string, unknown>>;
  projectName: string;
};

export const TrainingNoCode = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isLora = location.pathname.startsWith('/training/lora');

  const [isShowBlocker, setIsShowBlocker] = useState(false);
  const [openUpgradeToPro, setOpenUpgradeToPro] = useState(false);

  const [isExpandedLogs, setIsExpandedLogs] = useState(false);
  const [taskType, setTaskType] = useState<string>();
  const [parameterMode, setParameterMode] = useState('basic');
  const [isJsonMode, setIsJsonMode] = useState(false);

  const { trainId } = useParams();

  const { data: train, isError: isErrorTrain, isPending: isPendingTrain } = useGetTrainingByIdQuery(trainId!);

  const { data: dataModels, isPending: isPendingModels } = useModelListQuery(
    {
      limit: 1000,
      type: taskType ? [taskType] : undefined,
    },
    { enabled: !!taskType },
  );

  const models = useMemo(() => dataModels?.pages.flat(), [dataModels]);

  const {
    // formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      custom: false,
      datasetSource: '',
      modelId: '',

      parameters: {},
    },
    mode: 'onChange',
  });

  const values = watch();

  const selectedModelId = values.modelId;

  const onTaskTypeChange = (task: string) => {
    setTaskType(task);
    setValue(
      'parameters',
      Object.entries((parameterMode === 'full' ? fullParameters : parameters)[task]).reduce<
        Record<string, unknown>
      >((acc, [key, option]) => {
        acc[key] = `${option.default}`;
        return acc;
      }, {}),
    );
  };
  const onParameterModeChange = (mode: string) => {
    setParameterMode(mode);
    if (taskType) {
      setValue(
        'parameters',
        Object.entries((mode === 'full' ? fullParameters : parameters)[taskType]).reduce<
          Record<string, unknown>
        >((acc, [key, option]) => {
          acc[key] = `${option.default}`;
          return acc;
        }, {}),
      );
    }
  };

  useEffect(() => {
    if (!train) return;

    setTaskType(train.model.type);
    reset({
      datasetSource: train.datasetSource || 'local',
      modelId: train.model._id,
      parameters:
        train.parameters ||
        Object.entries((parameterMode === 'full' ? fullParameters : parameters)[train.model.type]).reduce<
          Record<string, unknown>
        >((acc, [key, option]) => {
          acc[key] = `${option.default}`;
          return acc;
        }, {}),
      projectName: train.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [train]);

  useEffect(() => {
    if (models && models.length && !models.find((el) => el._id === selectedModelId)) {
      setValue('modelId', models[0]._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModels, selectedModelId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsShowBlocker(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLora]);

  const isPending = isPendingTrain || isPendingModels;

  const onSend = (values: FormValues) => {
    console.log(values);
  };

  const options = taskType ? (parameterMode === 'full' ? fullParameters : parameters)[taskType] : null;

  return (
    <AnimateRoute className="mx-auto flex size-full max-w-7xl flex-col overflow-hidden px-4 pb-10 pt-4">
      <div className="mb-4 flex flex-none">
        <BackButton
          className="gap-4 text-2xl font-semibold text-clay-900 hover:text-clay-900"
          onClick={() => navigate(-1)}
        >
          Training
        </BackButton>
      </div>

      {isErrorTrain && (
        <div className="flex items-center justify-center rounded-2xl border border-clay-20 bg-white p-7">
          {!isErrorTrain && isPending && <Spinner className="size-7" />}
          {isErrorTrain && <span className="text-lg font-medium">Something went wrong</span>}
        </div>
      )}

      {!isErrorTrain && (
        <div className="flex flex-1 flex-col overflow-hidden rounded-2xl border border-clay-20 bg-white shadow-card-medium">
          <header className="flex items-center gap-5 border-b border-clay-20 bg-clay-10 px-3.5 py-4">
            <div className="flex items-center gap-3 text-clay-900">
              <Icon className="size-5" name="brain" safeArea="0" />
              <span className="text-lg font-medium">Train</span>
            </div>
            <div className="line-clamp-1 flex items-center gap-2 whitespace-nowrap rounded-xl border border-clay-20 bg-white px-2.5 py-2 text-clay-900">
              <Icon className="size-4" name="box" />
              <span className="text-sm font-medium">{train?.name || '--'}</span>
            </div>
            <div className="flex flex-1 justify-end">
              <Button onClick={handleSubmit(onSend)}>Start Training</Button>
            </div>
          </header>
          <div className="flex items-center gap-7 border-b border-clay-20 px-3.5 py-4">
            <div className="flex items-center gap-2.5">
              <div className="text-base font-medium text-clay-900">Task</div>
              <StyledSelect onValueChange={onTaskTypeChange} value={taskType}>
                <Select.Content>
                  {categoryTypes.map((task) => (
                    <Select.Item key={task} value={task}>
                      {getCategoryLabel(task)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </StyledSelect>
            </div>

            <div className="flex items-center gap-2.5">
              <div className="text-base font-medium text-clay-900">Parameter Mode</div>
              <StyledSelect onValueChange={onParameterModeChange} value={parameterMode}>
                <Select.Content>
                  <Select.Item value="basic">Basic</Select.Item>
                  <Select.Item value="full">Full</Select.Item>
                </Select.Content>
              </StyledSelect>
            </div>

            {isPending && <Spinner className="size-5" />}

            {/* {models && (
              <div className="flex items-center gap-2.5">
                <div className="text-base font-medium text-clay-900">Base model</div>
                <StyledSelect disabled value={train.model._id}>
                  <Select.Content>
                    {models.map((model) => (
                      <Select.Item key={model._id} value={model._id}>
                        {model.modelName || model.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </StyledSelect>
              </div>
            )} */}
          </div>

          <div className="flex w-full flex-1 overflow-y-auto overflow-x-hidden">
            <div className="flex-[55] p-7">
              <h2 className="mb-5 border-b border-clay-20 pb-3 text-xl font-medium text-clay-900">Project</h2>

              <div className="flex flex-col gap-2.5">
                <Input
                  classNameInputWrapper="h-11"
                  classNameLabel="text-sm mb-2"
                  label="Project name"
                  placeholder="Project name"
                  {...register('projectName', {
                    required: true,
                    validate: (val) => !!val.trim(),
                  })}
                />

                <div>
                  <div className="mb-2 text-sm text-clay-500">Base Model</div>
                  <div className="flex items-center gap-4">
                    <StyledSelect
                      disabled={values.custom}
                      onValueChange={(val) => setValue('modelId', val)}
                      value={values.modelId}
                      wrapperClassName="flex-1"
                    >
                      <Select.Content>
                        {models?.map((model) => (
                          <Select.Item key={model._id} value={model._id}>
                            {model.modelName || model.name}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </StyledSelect>
                    <Checkbox.CheckboxWrapper>
                      <Checkbox.Control
                        checked={values.custom}
                        onChange={(e) => setValue('custom', e.target.checked)}
                      />
                      <Checkbox.CheckboxLabel>Custom</Checkbox.CheckboxLabel>
                    </Checkbox.CheckboxWrapper>
                  </div>
                </div>

                <StyledSelect
                  label="Dataset Source"
                  onValueChange={(val) => setValue('datasetSource', val)}
                  value={values.datasetSource}
                  wrapperClassName="flex-1"
                >
                  <Select.Content>
                    {dataSetSourceOptions.map((option) => (
                      <Select.Item key={option.value} value={option.value}>
                        {option.label}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </StyledSelect>

                {values.datasetSource === 'local' && (
                  <FilePicker
                    className="mt-5 min-h-52 rounded-3xl border-solid border-clay-10 bg-clay-10"
                    icon="file"
                    iconClassName="size-4"
                    iconWrapperClassName="size-14 bg-white"
                    onFileChange={() => null}
                  >
                    <div className="mt-2 text-lg/none font-semibold text-clay-900">Upload Training Files</div>
                    <div className="mt-1 text-sm/none font-semibold text-clay-350">
                      Drag files here or click to <span className="text-primary-800">browse</span>
                    </div>
                  </FilePicker>
                )}
                {values.datasetSource === 'nesa-hub' && (
                  <>
                    <div className="flex items-end gap-4">
                      <div className="flex-1">
                        <Input
                          classNameInputWrapper="h-11"
                          classNameLabel="text-sm mb-2"
                          label="Hub dataset path"
                          placeholder="Hub dataset path"
                        />
                      </div>
                      <Button asIcon className="size-11" color="secondary" size="medium">
                        <Icon className="size-5" name="scanEye" safeArea="0" />
                      </Button>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        classNameInputWrapper="h-11"
                        classNameLabel="text-sm mb-2"
                        label="Train split"
                        placeholder="Train split"
                      />
                      <Input
                        classNameInputWrapper="h-11"
                        classNameLabel="text-sm mb-2"
                        label="Valid split (optional)"
                        placeholder="Valid split (optional)"
                      />
                    </div>
                  </>
                )}
              </div>
              <h2 className="my-5 border-b border-clay-20 pb-3 text-xl font-medium text-clay-900">
                Column Mapping
              </h2>
              <TextArea label="Text" labelClassName="text-sm mb-2" placeholder="text" />
            </div>
            <div className="flex-[45] overflow-hidden border-l border-clay-20 p-7">
              <h2 className="mb-5 border-b border-clay-20 pb-3 text-xl font-medium text-clay-900">
                Parameters
              </h2>

              <div className="mb-5">
                <label className="flex items-center gap-3">
                  <Switch checked={isJsonMode} onCheckedChange={(checked) => setIsJsonMode(checked)} />
                  <span className="text-sm font-medium text-clay-900">Json</span>
                </label>
              </div>

              {isJsonMode && (
                <div className="w-full overflow-hidden">
                  <CodeMirror
                    className="outline-none"
                    extensions={[json()]}
                    height="100%"
                    value={JSON.stringify(values.parameters, null, '  ')}
                  />
                </div>
              )}

              {!isJsonMode && (
                <div className="grid grid-cols-2 gap-2.5">
                  {options &&
                    Object.entries(options).map(([key, option]) => {
                      if (option.type === 'number') {
                        return (
                          <Input
                            classNameInputWrapper="h-11"
                            classNameLabel="text-sm mb-2"
                            key={key}
                            label={option.label}
                            type="number"
                            {...register(`parameters.${key}`)}
                          />
                        );
                      }
                      if (option.type === 'string') {
                        return (
                          <Input
                            classNameInputWrapper="h-11"
                            classNameLabel="text-sm mb-2"
                            key={key}
                            label={option.label}
                            {...register(`parameters.${key}`)}
                          />
                        );
                      }
                      if (option.type === 'dropdown' && option.options) {
                        return (
                          <StyledSelect
                            key={key}
                            label={option.label}
                            onValueChange={(value) => setValue(`parameters.${key}`, value)}
                            value={(values.parameters?.[key] as string) || undefined}
                            wrapperClassName="flex-1"
                          >
                            <Select.Content>
                              {option.options.map((value) => (
                                <Select.Item key={`${value}`} value={`${value}`}>
                                  {`${value}`}
                                </Select.Item>
                              ))}
                            </Select.Content>
                          </StyledSelect>
                        );
                      }

                      return null;
                    })}
                </div>
              )}
            </div>
          </div>
          <motion.div
            animate={{ height: isExpandedLogs ? '256px' : '0px' }}
            className="flex flex-none flex-col overflow-hidden bg-clay-10"
            initial={{ height: '0px' }}
          >
            <header className="flex items-center gap-2.5 border-y border-clay-20 px-4 py-3.5 text-clay-900">
              <Icon className="size-4" name="list" />
              <span className="text-lg font-semibold">Logs</span>
            </header>
            <div className="flex-1 overflow-y-auto p-4">
              {mockLogs.map((row, i) => (
                <div className="text-sm text-clay-500" key={i}>
                  {row}
                </div>
              ))}
            </div>
          </motion.div>
          <div className="flex-none border-t border-clay-20">
            <div className="px-4 py-3.5">
              <Button color="secondary" onClick={() => setIsExpandedLogs(!isExpandedLogs)}>
                Logs
                <Icon
                  className={twJoin('size-5 duration-200', isExpandedLogs && 'rotate-180')}
                  name="arrowDownSm"
                  safeArea="0"
                />
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className="pointer-events-none absolute inset-0" />

      <PageIsInAlphaModal
        description={
          <>
            <span className="text-clay-900">
              Access to <span className="font-semibold">Training</span> is being rolled out in phases to users
              on the early access list.
            </span>
            <p className="mt-2.5 text-sm text-clay-500">To join the access list, upgrade to Nesa Pro.</p>
          </>
        }
        icon={<Wrench className="size-5 text-clay-900" />}
        onBackClick={() => {
          setIsShowBlocker(false);
          navigate('/training');
        }}
        onOpenChange={() => {
          setIsShowBlocker(false);
          navigate('/training');
        }}
        onUpgradeToProClick={() => {
          setOpenUpgradeToPro(true);
        }}
        open={isShowBlocker}
        subtitle="Nesa Train facilitates a seamless training experience with subsidized costs on Nesa compute. You can train your model with code or without code."
        title="Nesa Train is in Alpha"
      />

      <UpgradeToProModal
        onOpenChange={() => {
          setOpenUpgradeToPro(false);
          navigate('/training');
        }}
        open={openUpgradeToPro}
        redirectPath="/training"
      />
    </AnimateRoute>
  );
};

const StyledSelect = (props: ComponentProps<typeof Select>) => {
  return (
    <Select
      {...props}
      className="h-11 min-w-40 border border-clay-20 bg-white shadow-checkbox"
      classNameLabel="mb-2 text-sm text-clay-500"
    />
  );
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { SendHorizonal } from 'lucide-react';

import type { DAIComment } from 'shared/api/daiComments/types';

import { useUser } from 'app/stores/user';
import { daiCommentsKeys } from 'shared/api/daiComments/queryKeys';
import { useCommentByIdQuery } from 'shared/api/daiComments/useCommentByIdQuery';
import { useCreateDaiCommentMutation } from 'shared/api/daiComments/useCreateDaiCommentMutation';
import { useDAICommentsInfiniteQuery } from 'shared/api/daiComments/useDAICommentsInfiniteQuery';
import { useDeleteDaiCommentMutation } from 'shared/api/daiComments/useDeleteDaiCommentMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

import { CommentCard } from './CommentCard';
import { ReportModal } from './ReportModal';

type Props = {
  commentToReply: string;
  isOpen: boolean;
  onOpenChange: (val: boolean) => void;
};

export const CommentReplyModal = ({ commentToReply: commentToReplyId, isOpen, onOpenChange }: Props) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [content, setContent] = useState('');
  const [selectedCommentId, setSelectedCommentId] = useState<string>();
  const [isReportOpen, setIsReportOpen] = useState(false);

  const queryClient = useQueryClient();

  const { data: commentToReplyData, isPending: isLoadingComment } = useCommentByIdQuery(
    { commentId: commentToReplyId },
    { enabled: !!commentToReplyId },
  );

  const {
    isPending: isDeleting,
    mutateAsync: deleteComment,
    variables: deleleteVars,
  } = useDeleteDaiCommentMutation();

  const commentToReply = commentToReplyData?.data;

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const { isPending, mutateAsync: createComment } = useCreateDaiCommentMutation();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending: isCommentsPending,
  } = useDAICommentsInfiniteQuery(
    {
      daiId: commentToReply?.daiId || '',
      limit: 10,
      repliedTo: commentToReply?._id || '',
    },
    { enabled: isOpen && !!commentToReply },
  );

  const comments = data?.pages.flat() || [];

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const handleCreateComment = async () => {
    try {
      if (!user) {
        navigate('/auth/signin');
        return;
      }
      if (!content || !commentToReply) return;

      const res = await createComment({
        content,
        daiId: commentToReply.daiId,
        repliedTo: commentToReply._id,
      });

      console.log('res', res);

      setContent('');

      toaster.success('Comment is saved');
    } catch (e) {
      catchError(e);
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    try {
      await deleteComment(
        { commentId },
        {
          onSuccess: () => {
            const queryKey = daiCommentsKeys.list({ daiId: commentToReply?.daiId || '', limit: 10 });

            // Snapshot the previous value
            const previousData = queryClient.getQueryData<{ pageParams: number[]; pages: DAIComment[][] }>(
              queryKey,
            );
            // Optimistically update to the new value
            queryClient.setQueryData(queryKey, {
              ...previousData,
              pages: previousData?.pages.map((page) => page.filter((item) => item._id !== commentId)),
            });

            queryClient.invalidateQueries({
              queryKey: daiCommentsKeys.list({
                daiId: commentToReply?.daiId || '',
                limit: 10,
                repliedTo: commentId,
              }),
            });
          },
        },
      );

      if (commentId === commentToReply?._id) {
        onOpenChange(false);
      }

      toaster.success('Comment was deleted');
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-2xl">
        <Modal.CloseButton />

        <div className="flex max-w-full grow flex-col">
          <h1 className="mb-4 text-2xl font-medium">Reply to comment</h1>

          <div className="flex flex-col">
            {isLoadingComment ? (
              <StretchedSkeleton
                className="relative mb-4 max-h-[96px] overflow-hidden rounded-xl"
                enable
                rx={12}
                ry={12}
              />
            ) : (
              commentToReply && (
                <CommentCard
                  author={`${commentToReply?.author?.first_name || ''} ${commentToReply?.author?.last_name || ''}`}
                  authorId={commentToReply?.author?._id}
                  authorImage={commentToReply?.author?.avatar}
                  className="py-4"
                  commentId={commentToReply?._id}
                  daiId={commentToReply?.daiId}
                  isDeleting={isDeleting && deleleteVars.commentId === commentToReply?._id}
                  key={commentToReply?._id}
                  onDeleteComment={() => handleDeleteComment(commentToReply._id)}
                  onReport={() => {
                    setSelectedCommentId(commentToReply._id);
                    setIsReportOpen(true);
                  }}
                  reactions={commentToReply?.reactions}
                  text={commentToReply?.content}
                  timestamp={commentToReply?.createdAt}
                />
              )
            )}

            <div className="mb-2 mt-4 text-sm">Replies:</div>

            <div className="relative">
              <TextArea
                className="text-sm placeholder:text-sm"
                disabled={isPending}
                innerClassName="text-sm placeholder:text-sm"
                onChange={(e) => setContent(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.shiftKey == false) {
                    handleCreateComment();
                  }
                }}
                placeholder="Write Message here"
                rows={4}
                value={content}
              />
              <Button
                className="absolute bottom-6 right-2 px-2.5"
                disabled={!content}
                isLoading={isPending}
                onClick={handleCreateComment}
              >
                <SendHorizonal className="size-5" />
              </Button>
            </div>

            {isCommentsPending ? (
              <div className="flex items-center justify-center py-5">
                <Spinner className="size-5" />
              </div>
            ) : (
              <div className="flex max-h-[200px] flex-col gap-4 overflow-y-scroll py-4 scrollbar-none md:max-h-[400px]">
                {comments.length > 0 ? (
                  comments.map((comment) => {
                    return (
                      <CommentCard
                        author={`${comment.author.first_name || ''} ${comment.author.last_name || ''}`}
                        authorId={comment.author._id}
                        authorImage={comment.author.avatar}
                        className="border-b border-clay-20 pb-4"
                        commentId={comment._id}
                        daiId={comment.daiId}
                        isDeleting={isDeleting && deleleteVars.commentId === comment._id}
                        key={comment._id}
                        onDeleteComment={() => handleDeleteComment(comment._id)}
                        onReport={() => {
                          setSelectedCommentId(comment._id);
                          setIsReportOpen(true);
                        }}
                        reactions={comment.reactions}
                        repliedTo={commentToReplyId}
                        text={comment.content}
                        timestamp={comment.createdAt}
                      />
                    );
                  })
                ) : (
                  <div className="py-5 text-center text-sm text-clay-350">No comments yet</div>
                )}

                <div className="-mt-4" ref={ref} />

                {isFetchingNextPage && (
                  <div className="flex items-center justify-center py-4">
                    <Spinner className="size-5" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="mt-2 flex justify-end">
          <Button className="ml-auto" color="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
        </div>

        {selectedCommentId && (
          <ReportModal commentId={selectedCommentId} isOpen={isReportOpen} onOpenChange={setIsReportOpen} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export const evmCoins: Record<
  'nesaUSD',
  Record<number, { address: `0x${string}`; chainId: number; decimals: number; logo: string; symbol: string }>
> = {
  nesaUSD: {
    111888: {
      address: '0xCd18Bde1F3C201f3E82e34b4FBE8933b6AA3C219',
      chainId: 111888,
      decimals: 6,
      logo: '',
      symbol: 'USDN',
    } as const,
  },
};

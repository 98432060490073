import type { Address, Hash } from 'viem';

import { queryOptions, useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

import type { QueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

type Params = {
  accountAddress: Address | undefined;
};
export const getFaucetNonceQueryKey = ({ accountAddress }: Params) => {
  return ['erc20', 'faucet-nonce', accountAddress];
};

export type FaucetResponse = {
  amount: string;
  nonce: string;
  signature: Hash;
};
const getQueryOptions = <TData = FaucetResponse>(
  params: Params,
  options?: QueryOptions<FaucetResponse, unknown, TData>,
) => {
  const { accountAddress } = params;
  return queryOptions<FaucetResponse, unknown, TData>({
    enabled: Boolean(accountAddress),
    queryFn: async () => {
      const { data } = await axiosInstance.get<FaucetResponse>(`/evm-faucet/${accountAddress}/nonce`);

      return data;
    },
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getFaucetNonceQueryKey({
      accountAddress,
    }),
    ...options,
  });
};

export const useFaucetNonceQuery = <TData = FaucetResponse>(
  options?: QueryOptions<FaucetResponse, unknown, TData>,
) => {
  const { address: accountAddress } = useAccount();

  return useQuery(getQueryOptions<TData>({ accountAddress }, options));
};

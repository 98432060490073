import { Box } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { filterItems } from 'shared/const';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { Icon } from 'shared/ui/Icon';

type Props = {
  onChange: (value: string) => void;
  value: string;
} & ClassName;

export const CategoryCarousel = ({ className, onChange, value: category }: Props) => {
  return (
    <div
      className={twMerge(
        'relative mb-8 flex flex-nowrap items-center gap-1 overflow-x-scroll scrollbar-none',
        className,
      )}
    >
      {filterItems.map((item) => {
        const isActive = category === item.value;
        return (
          <div
            className={twMerge(
              'group flex min-w-fit cursor-pointer flex-col items-center justify-center gap-2 rounded-xl border border-clay-20 bg-white px-2 pb-2 pt-3 transition hover:border-primary-800',
              item.value === 'all' && 'min-w-20',
              isActive && 'border-primary-800',
            )}
            key={item.value}
            onClick={() => onChange(item.value)}
          >
            {item.value === 'all' ? (
              <Box
                className={twMerge(
                  'size-6 stroke-[1.2] p-0 text-clay-350 group-hover:text-primary-800',
                  isActive && 'text-primary-800',
                )}
              />
            ) : (
              <Icon
                className={twMerge(
                  'size-6 p-0 text-clay-350 group-hover:text-primary-800',
                  isActive && 'text-primary-800',
                  item.isStroke &&
                    'stroke-clay-350 text-transparent group-hover:stroke-primary-800 group-hover:text-transparent',
                  item.isStroke && isActive && 'stroke-primary-800 text-transparent',
                )}
                name={item.icon}
              />
            )}
            <span
              className={twMerge(
                'text-xs text-clay-700 group-hover:text-primary-800',
                isActive && 'text-primary-800',
              )}
            >
              {item.value === 'all' ? 'All' : getCategoryLabel(item.value)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

import type { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import type { ActiveWallet, WalletTab } from 'pages/Wallet/types';

export const WalletRouteGuard = ({ children }: PropsWithChildren) => {
  const { section, walletType } = useParams<{
    section: WalletTab;
    walletType: ActiveWallet;
  }>();

  if (!isSection(section) || !isWallet(walletType)) {
    return <Navigate replace to="/not-found" />;
  }

  if (walletType === 'evm' && section === 'staked') {
    return <Navigate replace to="/not-found" />;
  }

  return children;
};

const isSection = (section: unknown): section is WalletTab | undefined => {
  return section === 'assets' || section === 'tx-history' || section === 'staked' || !section;
};

const isWallet = (walletType: unknown): walletType is ActiveWallet | undefined => {
  return walletType === 'evm' || walletType === 'cosmos' || !walletType;
};

import type { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Card } from 'shared/ui/Card';

type Props = {
  label: string;
  value: ReactNode;
} & ClassName;

export const StatCard = ({ className, label, value }: Props) => {
  return (
    <Card className={twMerge('flex flex-col gap-3 !p-6', className)}>
      <div className="text-4xl font-semibold">{value}</div>
      <div className="font-light text-clay-500">{label}</div>
    </Card>
  );
};

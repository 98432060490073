import type { DAISort } from './useDAIListInfiniteQuery';

export const daiKeys = {
  base: ['dai'],
  byId: (params: { id: string }) => [...daiKeys.base, 'byId', params],
  bySlug: (slug: string) => [...daiKeys.base, 'by-slug', slug],
  checkExistsDaiSlug: (slug: string) => [...daiKeys.base, 'check-exists-slug', slug],
  connectedModels: (params: { walletAddresses: string[] }) => [...daiKeys.base, 'connectedModels', params],
  daiSummary: (params: { daiId: string; userId?: string; walletAddresses: string[] }) => [
    ...daiKeys.base,
    'daiSummary',
    params,
  ],
  daiWallets: (params: { daiId: string }) => [...daiKeys.base, 'daiWallets', params],
  getAllUserStakesSummary: () => [...daiKeys.base, 'total-stakes-summary-by-user'],
  getLatestDAI: (params?: { limit?: number; skip?: number }) => [...daiKeys.base, 'latest-dai', params],
  groupStakerListInfinityQuery: (params: Record<string, unknown>) => [
    ...daiKeys.base,
    'stakers-list',
    params,
  ],
  infiniteList: (params: {
    category?: string;
    includeDev?: boolean;
    limit: number;
    sort?: DAISort;
    tags?: string[];
  }) => [...daiKeys.base, 'infiniteList', params],
  infiniteListFull: (params: {
    category?: string;
    includeDev?: boolean;
    limit: number;
    sort?: DAISort;
    tags?: string[];
  }) => [...daiKeys.base, 'infiniteListFull', params],
  latestActivity: (params: { limit: number; skip?: number }) => [...daiKeys.base, 'latestActivity', params],
  list: (params: { category?: string; limit: number; skip: number; sort?: DAISort; tags?: string[] }) => [
    ...daiKeys.base,
    'list',
    params,
  ],
  listByUserId: (params?: { userId: string }) => [...daiKeys.base, 'list-by-user-id', params],
  requestChart: (params: { walletAddresses: string[] }) => [...daiKeys.base, 'requestChart', params],
  sessionOverview: (params: { walletAddresses: string[] }) => [...daiKeys.base, 'sessionOverview', params],
  stakeAmountChart: (params: { daiId: string }) => [...daiKeys.base, 'stakeAmountChart', params],
  stakeSummary: (params: { daiId?: string } = {}) => [...daiKeys.base, 'stake-summary', params],
  summary: (params: { ids: string[] }) => [...daiKeys.base, 'summary', params],
  topTvlDAIs: (params: { limit?: number; skip?: number }) => [...daiKeys.base, 'topTvlDais', params],
  userDAIs: (params: { limit: number; skip: number; userId: string }) => [
    ...daiKeys.base,
    'user-dais',
    params,
  ],
  userWallets: (params: { daiId?: string; userId: string }) => [...daiKeys.base, 'userWallets', params],
};

import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useGetUserDAIsQuery } from 'shared/api/dai/useGetUserDAIsQuery';
import { Badge } from 'shared/ui/Badge';
import { Button } from 'shared/ui/Button';
import { HotDaiCard } from 'shared/ui/HotDaiCard';

import emptyImgSrc from './assets/emptyDAIs.svg';

export const UserDAIList = () => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const { user } = useUser();
  const { data: userDai } = useGetUserDAIsQuery(
    { limit: 100, skip: 0, userId: user?._id || '' },
    { enabled: !!user?._id },
  );

  if (!userDai?.list.length) {
    return (
      <div className="my-auto flex flex-col items-center justify-center gap-1.5 py-10 ">
        <img alt="img" className="mb-2 h-20 w-auto" src={emptyImgSrc} />

        <h1 className="text-center text-2xl ">No DAI created yet</h1>
        <div className="mb-4 text-center text-base font-light text-clay-500">
          DAI are decentralized AI projects and protocols
        </div>

        <Button onClick={() => navigate('/dai/create')}>Create a DAI</Button>
      </div>
    );
  }

  return (
    <div
      className="grow overflow-y-auto scrollbar-none"
      ref={(el) => {
        scrollContainerRef.current = el;
      }}
    >
      <div className="grid w-full grid-flow-dense grid-cols-1 justify-between gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {userDai.list.map((dai, index) => {
          const founder = dai.members[0];

          return (
            <div key={index}>
              <HotDaiCard
                chipSlot={
                  dai.isNesaExclusive ? (
                    <Badge className="bg-yellow-500/10 px-2 py-1.5 font-medium uppercase text-yellow-500">
                      exclusive on nesa
                    </Badge>
                  ) : undefined
                }
                className="h-full"
                coinImageSrc={dai.token?.image}
                coinName={dai.token?.ticker}
                description={dai.shortDescription}
                key={dai._id}
                mediaSrc={dai.profileImage}
                onManageClick={() => navigate(`/dai/${dai._id}/edit`)}
                participants={(dai.largestStakers || []).map(({ avatar, first_name, last_name }) => ({
                  avatar,
                  firstName: first_name,
                  lastName: last_name,
                  previewLetter: first_name?.slice(0, 1) || last_name?.slice(0, 1) || '',
                }))}
                showMediaNearTitle
                title={dai.projectName}
                tokenType={dai.tokenType}
                tvl={getTvl({ tvlNes: dai.tvl })}
                userImageSrc={founder?.avatar}
                userName={founder?.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { useMemo, useState } from 'react';

import { Bar, BarChart, Cell } from 'recharts';
import { twMerge } from 'tailwind-merge';

import type { StatResponse } from 'shared/api/datasets/useDatasetStatsQuery';
import type { DatasetSplit } from 'shared/api/datasets/useGetDatasetSplitsQuery';
import type { ClassName } from 'shared/types';

import { Pagination } from 'pages/Datasets/ui/Pagination';
import { useDatasetStatsQuery } from 'shared/api/datasets/useDatasetStatsQuery';
import { type DatasetRow, useGetDatasetRowsQuery } from 'shared/api/datasets/useGetDatasetRowsQuery';
import { useSearchDatasetRowsQuery } from 'shared/api/datasets/useSearchDatasetRowsQuery';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';

import { getErrorMessage } from '../helpers/getErrorMessage';

type Props = {
  dataset: string;
  onSearchChange?: (value: string) => void;
  pageLimit?: number;
  search?: string;
  splits: DatasetSplit[];
} & ClassName;

const defaultPageLimit = 10;

const TableTitle = ({
  item,
  stats,
}: {
  item: {
    feature_idx: number;
    name: string;
    type: { _type: 'Value'; dtype: 'string' };
  };
  stats?: StatResponse;
}) => {
  const [overItemIdx, setOverItemIdx] = useState<number>();
  const currentStat = stats?.statistics?.find((stat) => stat.column_name === item.name);
  const chatData = currentStat?.column_statistics?.histogram?.hist?.map((value) => ({ label: value, value }));

  return (
    <div className="flex flex-col gap-0.5">
      <div>{item.name}</div>
      <div className="text-sm font-light text-clay-350">{item.type.dtype}</div>

      {currentStat && (
        <BarChart data={chatData} height={40} width={150}>
          <Bar
            dataKey="value"
            onMouseEnter={(_, idx) => {
              setOverItemIdx(idx);
            }}
            onMouseLeave={() => {
              setOverItemIdx(undefined);
            }}
          >
            {chatData?.map((_, index) => (
              <Cell
                cursor="pointer"
                fill={index === overItemIdx ? '#939BA8' : '#E3E4E7'}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      )}

      <div className="-mt-1 h-2 max-w-[150px]">
        {currentStat?.column_statistics &&
          stats &&
          (overItemIdx !== undefined ? (
            <div className="flex items-center justify-between pl-2">
              <div className="flex items-center gap-0.5 text-xs font-medium text-clay-300">
                <div>{currentStat?.column_statistics.histogram.bin_edges[overItemIdx]?.toFixed()}</div> -
                <div>{currentStat?.column_statistics.histogram.bin_edges[overItemIdx + 1]?.toFixed()}</div>
              </div>

              <div className="text-xs font-medium text-clay-300">
                {(
                  (currentStat.column_statistics.histogram.hist[overItemIdx] / stats?.num_examples) *
                  100
                ).toFixed()}
                %
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-between px-2">
              <div className="flex items-center gap-0.5 text-xs font-medium text-clay-300">
                {currentStat?.column_statistics.min?.toFixed()}
              </div>
              <div className="flex items-center gap-0.5 text-xs font-medium text-clay-300">
                {currentStat?.column_statistics.max?.toFixed()}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export const DatasetViewer = ({
  className,
  dataset,
  onSearchChange,
  pageLimit = defaultPageLimit,
  search = '',
  splits,
}: Props) => {
  const [expandedRow, setExpandedRow] = useState<number>();
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState(search);

  const configs = splits.reduce<string[]>((acc, item) => {
    if (acc.includes(item.config)) {
      return acc;
    }

    acc.push(item.config);

    return acc;
  }, []);
  const splitList = splits.reduce<string[]>((acc, item) => {
    if (acc.includes(item.split)) {
      return acc;
    }

    acc.push(item.split);

    return acc;
  }, []);

  const [selectedSplit, setSelectedSplit] = useState(splitList[0]);
  const [selectedConfig, setSelectedConfig] = useState(configs[0]);

  const {
    data: rowsData,
    error: rowsError,
    isPending: isRowsPending,
  } = useGetDatasetRowsQuery(
    {
      config: selectedConfig,
      dataset: dataset,
      length: pageLimit,
      offset: page * pageLimit,
      split: selectedSplit,
    },
    { enabled: !!dataset, retry: 0 },
  );
  const {
    data: searchRowsData,
    error: searchRowsError,
    isPending: isSearchRowsPending,
  } = useSearchDatasetRowsQuery(
    {
      config: selectedConfig,
      dataset: dataset,
      length: pageLimit,
      offset: page * pageLimit,
      query: search || '',
      split: selectedSplit,
    },
    { enabled: !!dataset && !!search, retry: 0 },
  );

  const { data: stats } = useDatasetStatsQuery(
    { config: selectedConfig, dataset: dataset, split: selectedSplit },
    { enabled: !!dataset, retry: 0 },
  );

  const generatedColumns = useMemo(() => {
    return (
      rowsData?.features.map((item) => {
        return {
          key: item.name,
          renderTd: (row: DatasetRow) => {
            if (item.type.dtype !== 'string') return <div>unsupported</div>;

            const value = row.row[item.name as keyof DatasetRow] as string;

            return (
              <div className="flex cursor-pointer items-center ">
                <span
                  className={twMerge(
                    'line-clamp-2 font-fira font-light text-clay-400',
                    expandedRow === row.row_idx && 'line-clamp-none',
                  )}
                >
                  {value}
                </span>
              </div>
            );
          },
          tdClassName: 'w-1/2 max-w-1/2 group-hover:bg-clay-10 transition-colors',
          thClassName: 'w-1/2 max-w-1/2 border-r',
          title: <TableTitle item={item} stats={stats} />,
        };
      }) || []
    );
  }, [rowsData, expandedRow, stats]);

  const { empty, error } = getErrorMessage(search ? searchRowsError : rowsError);

  const isPending = search ? isSearchRowsPending : isRowsPending;

  const noData = search ? !(searchRowsData?.rows || []).length : !(rowsData?.rows || []).length;

  return (
    <>
      <header className="mb-4 flex items-center justify-between border-b border-clay-20 pb-2 pl-4 pr-2 ">
        <h1 className="flex items-center gap-2  text-lg font-medium">
          <Icon className="text-clay-700" name="grid" />
          Viewer
        </h1>

        <div>
          <Input
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearchChange?.(searchValue);
                setPage(0);
              }
            }}
            placeholder="Search this dataset"
            startSlot={<Icon className="size-4 text-clay-600" name="search" />}
            value={searchValue}
          />
        </div>
      </header>
      <div className={twMerge('flex flex-col', className)}>
        <div className="mb-4 flex w-full flex-col items-center gap-4 sm:flex-row">
          <Select
            label="Splits"
            onValueChange={setSelectedSplit}
            value={selectedSplit}
            wrapperClassName="w-full flex-1"
          >
            <Select.Content>
              {splitList.map((item) => {
                return (
                  <Select.Item key={item} value={item}>
                    {item}
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
          <Select
            label="Config"
            onValueChange={setSelectedConfig}
            value={selectedConfig}
            wrapperClassName="w-full flex-1"
          >
            <Select.Content>
              {configs?.map((item) => {
                return (
                  <Select.Item key={item} value={item}>
                    {item}
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
        </div>

        {!isPending && (empty || noData) && (
          <div className="my-auto flex size-full flex-col items-center justify-center text-sm font-light text-clay-300">
            No data available
          </div>
        )}

        {!empty && error && (
          <div className="my-auto flex size-full flex-col items-center justify-center rounded-xl bg-red-1000/10 px-4 py-3 text-sm font-light text-red-1100">
            Error: {error}
          </div>
        )}

        {isPending ? (
          <div className="flex size-full flex-col items-center justify-center py-12">
            <Spinner className="size-5" />
          </div>
        ) : (
          <div className="flex-1 overflow-x-scroll">
            <Table
              className="max-w-full  rounded-lg"
              columns={generatedColumns}
              data={search ? searchRowsData?.rows || [] : rowsData?.rows || []}
              onRowClick={(row: DatasetRow) => setExpandedRow(row.row_idx)}
            />
          </div>
        )}

        {!search && rowsData && rowsData?.num_rows_per_page !== undefined && rowsData.num_rows_total > 0 && (
          <Pagination
            onPageChange={setPage}
            page={page}
            perPage={pageLimit}
            totalItemsCount={rowsData.num_rows_total}
          />
        )}

        {search &&
          searchRowsData &&
          searchRowsData?.num_rows_per_page !== undefined &&
          searchRowsData.num_rows_total > 0 && (
            <Pagination
              onPageChange={setPage}
              page={page}
              perPage={pageLimit}
              totalItemsCount={searchRowsData.num_rows_total}
            />
          )}
      </div>
    </>
  );
};

import type { VariantProps } from 'class-variance-authority';
import type { NumberFormatValues, NumericFormatProps } from 'react-number-format';

import type { ComponentProps } from 'react';
import { NumericFormat as ReactNumericFormat } from 'react-number-format';

import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const variants = cva(['inline-flex bg-transparent outline-hidden'], {
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      currency: ['text-[32px] leading-[44px] font-bold'],
      default: ['text-white rounded-md border border-dark-jungle-green', 'px-3 py-3 text-sm'],
    },
  },
});

const isAllowed = (values: NumberFormatValues) => {
  const { value } = values;
  if (value[0] && value[1] && value[0] === '0' && value[1] !== '.') return false;

  return true;
};

export type NumericInputProps = {
  ref?: ComponentProps<'input'>['ref'];
} & NumericFormatProps &
  VariantProps<typeof variants>;

export const NumericInput = ({ className, onValueChange, ref, variant, ...props }: NumericInputProps) => {
  return (
    <ReactNumericFormat
      allowNegative={false}
      autoComplete="off"
      className={twMerge(variants({ className, variant }))}
      getInputRef={() => ref}
      isAllowed={isAllowed}
      onValueChange={(values, sourceInfo) => {
        if (sourceInfo.source !== 'event') return;
        onValueChange?.(values, sourceInfo);
      }}
      {...props}
    />
  );
};

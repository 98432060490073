import { useState } from 'react';

import { Croissant } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

import { CroissantCodeModal } from './CroissantCodeModal';

type Props = {
  configs?: string[];
  datasetId: string;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

export const UseDatasetPopover = ({ configs = ['default'], datasetId, isOpen, onOpenChange }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Popover onOpenChange={onOpenChange} open={isOpen}>
        <Popover.Trigger asChild>
          <div
            className={twMerge(
              'group flex h-9 cursor-pointer items-center gap-0 rounded-lg bg-clay-800 pl-3 pr-2 text-sm text-white',
              isOpen && 'shadow-sm',
            )}
          >
            <Icon
              className="mr-2 size-3.5 text-white transition-colors group-hover:text-primary-800"
              name="rocket"
            />{' '}
            Use this dataset
            <Icon
              className={twMerge('ml-0.5 size-5 text-white transition-transform', isOpen && 'rotate-180')}
              name="arrowDownSm"
            />
          </div>
        </Popover.Trigger>
        <Popover.Content
          align="start"
          className="min-w-48 rounded-lg p-1"
          collisionPadding={4}
          side="bottom"
          sideOffset={4}
        >
          <div
            className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 transition-colors hover:bg-clay-10"
            onClick={() => setIsModalOpen(true)}
          >
            <Croissant className="size-4" strokeWidth={1.25} />
            <span className="text-sm font-light">Croissant</span>
          </div>
        </Popover.Content>
      </Popover>
      <CroissantCodeModal
        configs={configs}
        datasetId={datasetId}
        isOpen={isModalOpen}
        onOpenChange={setIsModalOpen}
      />
    </>
  );
};

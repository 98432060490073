import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { Card as CardUI } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';

import DiscordIcon from '../assets/discord.svg?react';
import TwitterIcon from '../assets/twitter.svg?react';
import WalletIcon from '../assets/wallet.svg?react';

export const Card = ({ children }: PropsWithChildren) => {
  return <CardUI className="sm:p-6">{children}</CardUI>;
};

export const CardTitle = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div className={twMerge('text-xl/6 font-semibold text-clay-900', className)} {...props} />;
};

const icons = {
  discord: DiscordIcon,
  twitter: TwitterIcon,
  wallet: WalletIcon,
};

export const CardIcon = ({ icon }: { icon: keyof typeof icons }) => {
  const Icon = icons[icon];

  return <Icon className="size-11" />;
};

export const RemoverButton = ({ children = 'Remove', className, ...props }: ComponentProps<'button'>) => {
  return (
    <button
      {...props}
      className={twMerge(
        'cursor-pointer text-sm font-semibold text-red-900 duration-200 hover:text-red-800 disabled:text-clay-300',
        className,
      )}
    >
      {children}
    </button>
  );
};

export const CardRow = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge(
        'mt-3 flex flex-col flex-wrap justify-between gap-5 md:flex-row md:items-end',
        className,
      )}
    />
  );
};

type AuthServiceInfoProps = {
  icon: ReactNode;
  isRemoveLoading: boolean;
  onRemoveClick: () => void;
  username: string;
};

export const AuthServiceInfo = ({ icon, isRemoveLoading, onRemoveClick, username }: AuthServiceInfoProps) => {
  return (
    <div className="flex items-center justify-between gap-12">
      <div className="flex items-center gap-5">
        <div className="flex items-center gap-3">
          {icon}
          <span className="text-base font-medium text-clay-900">{username}</span>
        </div>
        <div className="flex items-center gap-1.5 rounded-full bg-green-600/10 py-1.5 pl-2 pr-2.5 text-green-600">
          <Icon className="size-3.5" name="check" />
          <span className="text-sm">Authenticated</span>
        </div>
      </div>
      <RemoverButton disabled={isRemoveLoading} onClick={onRemoveClick} />
    </div>
  );
};

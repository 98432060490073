import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAIStake } from './types';

type Params = {
  account: string;
  amount: string;
  company_id: string;
  lockup_seconds: number;
  owner?: string;
  // stake_id: number;
  start_time?: number;
};

export const useCreateDAIStakeMutation = (options: UseMutationOptions<DAIStake, Error, Params> = {}) => {
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<DAIStake>(
        `/dai/${typeof params.company_id === 'string' ? params.company_id : params.company_id['_id']}/stake`,
        params,
      );

      return data;
    },

    ...options,
  });
};

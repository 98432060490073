import { useState } from 'react';

import { ChevronDown, Codesandbox, Rocket } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import { Popover } from 'shared/ui/Popover';

export const FunctionPopover = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover onOpenChange={setIsOpen} open={isOpen}>
      <Popover.Trigger asChild>
        <div
          className={twMerge(
            'group flex h-9 cursor-pointer items-center gap-0 rounded-lg bg-clay-800 pl-3 pr-2 text-sm text-white',
            isOpen && 'shadow-sm',
          )}
        >
          <Rocket className="mr-2 size-3.5 text-white transition-colors group-hover:text-primary-800" /> Use
          this function
          <ChevronDown
            className={twMerge('ml-1 size-4 text-white transition-transform', isOpen && 'rotate-180')}
          />
        </div>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        className="min-w-48 rounded-lg p-1"
        collisionPadding={4}
        side="bottom"
        sideOffset={4}
      >
        <div className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 text-sm font-light transition-colors hover:bg-clay-10">
          <div className=" flex size-6 items-center justify-center">
            <Codesandbox className="size-[1.125rem] stroke-[1.5] text-clay-400" />
          </div>{' '}
          Nesa SDK
        </div>
      </Popover.Content>
    </Popover>
  );
};

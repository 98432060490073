import { type ElementType, type PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName, PolymorphicProps } from 'shared/types';

import { Spinner } from '../Spinner';

export type ButtonVariant = 'filled' | 'filled-light';
export type ButtonColor = 'blue' | 'dark' | 'primary' | 'red' | 'secondary' | 'tusk' | 'white';
export type ButtonSize = 'extra-small' | 'medium' | 'small';

export type Props = {
  asIcon?: boolean;
  color?: ButtonColor;
  isLoading?: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
} & ClassName;

export const Button = <E extends ElementType = 'button'>(
  props: PropsWithChildren<PolymorphicProps<E, Props>>,
) => {
  const {
    as: Component = 'button',
    asIcon,
    children,
    className,
    color = 'primary',
    disabled,
    isLoading,
    size = 'small',
    variant = 'filled',
    ...restProps
  } = props;

  const isLocked = disabled || isLoading;

  const isSecondaryLightFilled = color === 'secondary' && variant === 'filled-light';
  const isPrimaryLightFilled = color === 'primary' && variant === 'filled-light';
  const isPrimaryFilled = color === 'primary' && variant === 'filled';
  const isSecondaryFilled = color === 'secondary' && variant === 'filled';
  const isWhite = color === 'white' && variant === 'filled';
  const isDarkFilled = color === 'dark' && variant === 'filled';
  const isTuskFilled = color === 'tusk' && variant === 'filled';

  const isRed = color === 'red' && variant === 'filled';
  const isBlue = color === 'blue' && variant === 'filled';

  const iconButtonSize = {
    'extra-small': 'size-8',
    medium: 'size-12',
    small: 'size-10',
  }[size];
  const buttonSize = {
    'extra-small': 'h-8 px-3 py-1 text-xs',
    medium: 'h-12 px-5 py-4 text-sm font-light',
    small: 'h-10 px-4 py-2 text-xs lg:text-sm',
  }[size];

  return (
    <Component
      className={twMerge(
        'relative inline-flex cursor-pointer items-center justify-center gap-1 rounded-lg text-center font-medium transition-all',
        isLocked && 'pointer-events-none cursor-not-allowed',
        isLocked && 'opacity-70',

        asIcon ? iconButtonSize : buttonSize,

        isSecondaryLightFilled && 'bg-clay-10 text-clay-350 hover:brightness-95',
        isPrimaryFilled && 'bg-primary-800 text-white hover:bg-primary-1000',
        isTuskFilled && 'bg-tusk-100 text-clay-900 hover:bg-tusk-200',
        isPrimaryLightFilled && 'border-primary-100 bg-primary-100 text-primary-1000 hover:brightness-95',
        isSecondaryFilled && 'bg-clay-20 text-clay-900 hover:bg-clay-40',
        isWhite &&
          'border border-clay-40 bg-white text-clay-800 shadow-sm hover:bg-white hover:text-primary-800',
        isRed && 'border border-[#FDEDED] bg-[#FDEDED] text-red-900 hover:brightness-95',
        isBlue && 'bg-blue-600 text-white hover:brightness-95',

        isDarkFilled && 'bg-clay-900 text-white hover:brightness-95',

        className,
      )}
      disabled={disabled || isLoading}
      {...restProps}
    >
      {isLoading ? (
        <div>
          <div className="pointer-events-none inline-flex gap-1 opacity-0">{children}</div>
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner className="size-4" />
          </div>
        </div>
      ) : (
        children
      )}
    </Component>
  );
};

import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Plus } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { DAIComment } from 'shared/api/daiComments/types';
import type { ClassName } from 'shared/types';

import { useUser } from 'app/stores/user';
import { daiCommentsKeys } from 'shared/api/daiComments/queryKeys';
import { useAddDaiCommentReactionMutation } from 'shared/api/daiComments/useAddDaiCommentReactionMutation';
import { catchError } from 'shared/helpers/parseAxiosError';
import { uuidv4 } from 'shared/helpers/uuid';
import { Popover } from 'shared/ui/Popover';

const reactionList = ['🔥', '🚀', '👀', '❤️', '🤗', '😎', '➕', '🧠', '👍', '🤝', '😔', '🤯'];

type Props = {
  commentId: string;
  daiId: string;
  repliedTo?: string;
} & ClassName;
export const AddReactionButton = ({ className, commentId, daiId, repliedTo }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useUser();
  const queryClient = useQueryClient();

  const { mutateAsync: addReaction } = useAddDaiCommentReactionMutation({
    onError: (_err, _newData, context) => {
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });

      queryClient.setQueryData(
        queryKey,
        typeof context === 'object' && context && 'previous' in context ? context['previous'] : undefined,
      );

      const commentQueryKey = daiCommentsKeys.comment({ commentId });

      queryClient.setQueryData(
        commentQueryKey,
        typeof context === 'object' && context && 'previousComment' in context
          ? context['previousComment']
          : undefined,
      );
    },
    onMutate: async (params) => {
      const newReactionId = `custom-${uuidv4()}`;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });
      await queryClient.cancelQueries({ queryKey });
      // Snapshot the previous value
      const previousData = queryClient.getQueryData<{ pageParams: number[]; pages: DAIComment[][] }>(
        queryKey,
      );
      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, {
        ...previousData,
        pages: previousData?.pages.map((page) =>
          page.map((item) =>
            item._id === params.commentId
              ? {
                  ...item,
                  reactions: [
                    ...(item.reactions || []),
                    { _id: newReactionId, author: user?._id, reaction: params.reaction },
                  ],
                }
              : item,
          ),
        ),
      });

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      const commentQueryKey = daiCommentsKeys.comment({ commentId });
      await queryClient.cancelQueries({ queryKey: commentQueryKey });
      // Snapshot the previous value
      const previousComment = queryClient.getQueryData<{ data: DAIComment }>(commentQueryKey);
      // Optimistically update to the new value

      if (previousComment) {
        queryClient.setQueryData(commentQueryKey, {
          data: {
            ...(previousComment?.data || {}),
            reactions: [
              ...(previousComment?.data?.reactions || []),
              { _id: newReactionId, author: user?._id, reaction: params.reaction },
            ],
          },
        });
      }

      // // Return a context object with the snapshotted value
      return { previous: previousData, previousComment };
    },
    // Always refetch after error or success:
    onSettled: () => {
      const queryKey = daiCommentsKeys.list({ daiId: daiId, limit: 10, repliedTo });
      queryClient.invalidateQueries({ queryKey });
      const commentQueryKey = daiCommentsKeys.comment({ commentId });
      queryClient.invalidateQueries({ queryKey: commentQueryKey });
    },
  });

  const handleAddReaction = async (reaction: string) => {
    try {
      await addReaction({ commentId, reaction });
    } catch (e) {
      catchError(e);
    }
  };

  return (
    <Popover onOpenChange={setIsOpen} open={isOpen}>
      <Popover.Trigger>
        <div
          className={twMerge(
            'flex size-7 items-center justify-center rounded-full border border-clay-20 bg-white',
            className,
          )}
        >
          <Plus className="size-4 text-clay-300" />
        </div>
      </Popover.Trigger>
      <Popover.Content className="px-2">
        <div className="grid grid-cols-4 gap-1.5">
          {reactionList.map((reaction) => (
            <button
              className="size-8 rounded-lg bg-white pt-0.5 text-center text-lg leading-8 duration-200 hover:bg-clay-20"
              key={reaction}
              onClick={() => {
                handleAddReaction(reaction);
                setIsOpen(false);
              }}
            >
              {reaction}
            </button>
          ))}
        </div>
      </Popover.Content>
    </Popover>
  );
};

import { CopyToClipboard } from 'react-copy-to-clipboard';

import logoSrc from 'app/assets/images/logo.svg';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

import imgSrc from './assets/img.png';

type Props = {
  address: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const SuccessCreationDAIModal = ({ address, isOpen, onOpenChange }: Props) => {
  return (
    <Modal onOpenChange={() => null} open={isOpen}>
      <Modal.Content className="w-full !max-w-[38rem]" innerClassName="sm:p-7">
        <div className="mb-7 flex justify-center">
          <img alt="" className="w-14" src={logoSrc} />
        </div>
        <h2 className="mb-2 text-center text-3xl font-semibold text-clay-900 md:text-4xl">
          Congrats you successfully created a DAI!
        </h2>

        <div className="my-7 h-px bg-clay-20"></div>

        <div className="flex items-start gap-7">
          <div className="flex size-11 min-w-11 items-center justify-center rounded-full bg-tusk-100">
            <img alt="" className=" size-6" src={logoSrc} />
          </div>
          <div className="flex flex-1 flex-col overflow-hidden">
            <h4 className="text-2xl font-semibold text-clay-900">You get 1,000 $NES for inference!</h4>
            <p className="mt-2.5 max-w-full text-lg/6 font-normal text-clay-500">
              Sent to your connected address:
              <CopyToClipboard onCopy={() => toaster.success('Copied')} text={address || ''}>
                <div className="cursor-pointer truncate font-semibold">{address}</div>
              </CopyToClipboard>
              Now when people stake you get subsidized AI inference!
            </p>
          </div>
        </div>

        <div className="my-7 h-px bg-clay-20"></div>

        <div className="mb-3.5 text-base text-clay-900">
          You can connect more models to your DAI in the Edit Creation Flow.
        </div>
        <img alt="" className="w-full" src={imgSrc} />

        <div className="mb-7 mt-5 flex items-start gap-9">
          <div className="text-sm font-semibold text-clay-900">Remember</div>
          <div className="flex-1 text-base font-normal text-clay-500">
            The more on-chain transaction activity that your DAI has from AI model inference on Nesa, the more
            community interest and TVL it will typically attract.
          </div>
        </div>

        <Button className="w-full" onClick={() => onOpenChange(false)}>
          Awesome! See my DAI
        </Button>
      </Modal.Content>
    </Modal>
  );
};

import type { ComponentProps, ReactElement } from 'react';
import { createContext, useContext, useEffect, useId, useRef, useState } from 'react';

import * as RTabs from '@radix-ui/react-tabs';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const LayoutContext = createContext<null | string>(null);
const ActiveTabContext = createContext<null | string>(null);
const TabVariantContext = createContext<'button' | 'rounded-white' | 'underline'>('underline');

const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (context == null) throw new Error('Use context without provider');

  return context;
};

const useActiveTab = () => {
  const context = useContext(ActiveTabContext);

  return context;
};

const useTabVariant = () => {
  const context = useContext(TabVariantContext);

  return context;
};

const List = ({ children, className, ref, ...props }: ComponentProps<typeof RTabs.List>) => {
  const layoutId = useId();
  const variant = useTabVariant();

  return (
    <LayoutContext.Provider value={layoutId}>
      <RTabs.List
        {...props}
        className={twMerge(
          'relative flex border-b border-clay-20',
          variant === 'button' && 'gap-2 border-none',
          variant === 'rounded-white' &&
            'flex w-fit items-center overflow-hidden rounded-full border-none bg-clay-10 p-1 text-clay-350',
          className,
        )}
        ref={ref}
      >
        {children}
      </RTabs.List>
    </LayoutContext.Provider>
  );
};

const Trigger = ({ children, className, ref, value, ...props }: ComponentProps<typeof RTabs.Trigger>) => {
  const layoutId = useLayoutContext();
  const activeTab = useActiveTab();
  const variant = useTabVariant();

  return (
    <RTabs.Trigger
      {...props}
      className={twMerge(
        'relative flex items-center gap-2 px-4 pb-2.5 pt-1.5 text-sm font-medium text-clay-900',
        variant === 'button' &&
          'rounded-lg border border-transparent px-3 py-2 transition-all hover:bg-clay-40',
        variant === 'button' &&
          activeTab === value &&
          'border  border-tusk-200 bg-tusk-100 hover:bg-tusk-100 hover:brightness-95',
        variant === 'rounded-white' &&
          'rounded-full bg-transparent py-1 text-clay-350 transition-colors hover:text-clay-800',
        variant === 'rounded-white' && activeTab === value && ' bg-white text-clay-800',
        'disabled:cursor-not-allowed disabled:text-clay-350',
        className,
      )}
      ref={ref}
      value={value}
    >
      {children}
      {activeTab === value && variant === 'underline' && (
        <motion.div
          className="absolute inset-x-0 -bottom-px h-0.5 bg-primary-800"
          layoutId={layoutId}
          style={{ originY: '0px' }}
        />
      )}
    </RTabs.Trigger>
  );
};

const Content = RTabs.Content;

type RootProps<Tab extends string> = {
  defaultValue?: Tab;
  onValueChange?: (tab: Tab) => void;
  tabs: ReactElement;
  value?: Tab;
};

const Root = <Tab extends string>({
  children,
  tabs,
  variant = 'underline',
  ...props
}: Omit<
  { variant?: 'button' | 'rounded-white' | 'underline' } & ComponentProps<typeof RTabs.Root>,
  keyof RootProps<Tab>
> &
  RootProps<Tab>) => {
  const [activeTab, setActiveTab] = useState(props.defaultValue);
  const [height, setHeight] = useState<'auto' | number>('auto');
  const [isHidden, setIsHidden] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const isControlled = typeof props.onValueChange === 'function' && typeof props.value === 'string';

  const value = isControlled ? props.value : activeTab;
  const onTabChange = isControlled ? props.onValueChange : setActiveTab;

  useEffect(() => {
    if (!containerRef.current) return;
    let timeout: NodeJS.Timeout | number;

    const resizeObserver = new ResizeObserver((entries) => {
      const observedHeight = entries[0].contentRect.height;
      setHeight(observedHeight);
      setIsHidden(true);
      timeout = setTimeout(() => {
        setIsHidden(false);
      }, 100);
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      clearTimeout(timeout);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <ActiveTabContext.Provider value={value || null}>
      <TabVariantContext.Provider value={variant}>
        <AnimatePresence>
          <RTabs.Root {...props} onValueChange={(tab) => onTabChange?.(tab as Tab)} value={value}>
            {tabs}
            <motion.div
              animate={{ height }}
              style={{ height, overflow: isHidden ? 'hidden' : 'visible' }}
              transition={{ duration: 0.1 }}
            >
              <div ref={containerRef}>{children}</div>
            </motion.div>
          </RTabs.Root>
        </AnimatePresence>
      </TabVariantContext.Provider>
    </ActiveTabContext.Provider>
  );
};

export const Tabs = {
  Content,
  List,
  Root,
  Trigger,
};

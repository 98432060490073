//TODO: after feat/DAI-stuff move to entities/models
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { UpgradeToProModal } from 'features/UpgradeToProModal';
import { Button } from 'shared/ui/Button';

type Props = {
  isProPlanModalVisible: boolean;
};

export const UpgradeToProPopup = ({ isProPlanModalVisible }: Props) => {
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);
  return (
    isProPlanModalVisible && (
      <>
        <div className="absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 flex-col gap-4 rounded-lg bg-white px-4 py-6 shadow-lg">
          <span className="text-center">
            This model is currently only <br />
            available to{' '}
            <Link
              className="cursor-pointer font-medium text-blue-600 underline underline-offset-2"
              target="_blank"
              to="/pricing"
            >
              Pro users
            </Link>{' '}
            in Nesa V1.
          </span>
          <Button
            className="w-fit self-center rounded-[48px] px-7"
            color="blue"
            onClick={() => setIsUpgradeOpen(true)}
            variant="filled"
          >
            Upgrade to Pro
          </Button>
        </div>
        <UpgradeToProModal onOpenChange={setIsUpgradeOpen} open={isUpgradeOpen} />
      </>
    )
  );
};

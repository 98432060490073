import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiKeys } from './queryKeys';

type Response = {
  stakersCount: number;
  totalStaked: string;
};

export const useGetStakeSummaryByDAIQuery = (daiId: string, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/stake/summary/${daiId}`);

      return data;
    },
    queryKey: daiKeys.stakeSummary({ daiId: daiId }),
    ...options,
  });
};

import type { Control, FieldValues, Path, UseControllerProps } from 'react-hook-form';
// import type { Prettify } from 'viem';

import { useController } from 'react-hook-form';

import { Input, type InputProps } from './InputCurrency';

type CustomProps<T extends FieldValues, N extends Path<T>> = {
  control: Control<T>;
  name: N;
  onChange?: (value: string) => void;
  rules?: UseControllerProps<T, N>['rules'];
};

export type Props<T extends FieldValues, N extends Path<T>> = CustomProps<T, N> &
  Omit<InputProps, 'value' | keyof CustomProps<T, N>>;

export const RHFInput = <T extends FieldValues, N extends Path<T>>(props: Props<T, N>) => {
  const { control, disabled, errorMessage, name, onChange, rules } = props;

  const { field, fieldState } = useController({
    control,
    disabled,
    name,
    rules,
  });

  const error = errorMessage ?? fieldState.error?.message;

  return (
    <Input
      {...props}
      {...field}
      errorMessage={error}
      onChange={(value) => {
        field.onChange(value);
        onChange?.(value);
      }}
    />
  );
};

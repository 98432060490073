import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { DAI } from './types';

import { daiKeys } from './queryKeys';

type Response = {
  list: DAI[];
};

type Params = {
  includeDev?: boolean;
  limit?: number;
  skip?: number;
};

export const useGetLatestDAIQuery = (params: Params = {}, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>(`/dai/latest-dai`, {
        params: { ...params, includeDev: import.meta.env.MODE === 'development' },
      });

      return data;
    },
    queryKey: daiKeys.getLatestDAI(params),
    ...options,
  });
};

import { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import { BackButton } from 'app/ui/BackButton';
import { useDAIBySlugQuery } from 'shared/api/dai/useDAIBySlugQuery';
import { useNavigateToTop } from 'shared/hooks/navigate';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Spinner } from 'shared/ui/Spinner';
// import { TradingView } from 'widgets/TradingView';

import type { Time } from 'lightweight-charts';

import { Expand } from 'lucide-react';
import { DateTime } from 'luxon';

import { SuccessCreationDAIModal } from 'features/SuccessCreationDAIModal';
import { useGetDAIPriceQuery } from 'shared/api/daiStatistics/useGetDAIPriceQuery';
import { PriceChartInterval, useGetDAIPriceChartQuery } from 'shared/api/daiStats/useGetDAIPriceChartQuery';
import { TradingBarView } from 'widgets/TradingBarView';

import { TradeForm } from '../../features/TradeForm';
import { DAIComments } from './ui/DAIComments';
import { DAIContent } from './ui/DAIContent';
import { TradeModal } from './ui/TradeModal';

const date = DateTime.now().minus({ days: 14 }).toISO();

export const DAI = () => {
  const navigate = useNavigateToTop();
  const location = useLocation();

  const { slug = '' } = useParams<{ slug: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const creationDAIWalletAddress = searchParams.get('creation-dai-address');

  const { data, isPending, isSuccess } = useDAIBySlugQuery(slug, { enabled: !!slug });
  const daiPricesQuery = useGetDAIPriceQuery(
    { daiId: data?.data._id || '', limit: 100, skip: 0 },
    { enabled: !!data?.data._id },
  );

  const daiPriceChartQuery = useGetDAIPriceChartQuery(
    {
      daiId: data?.data._id || '',
      interval: PriceChartInterval.d1,
      startDate: date,
    },
    { enabled: !!data?.data._id },
  );

  const dai = data?.data;

  const [isTradeExpanded, setIsTradeExpanded] = useState(location.state?.isModalOpen || false);
  // const [isStakingOpen, setIsStakingOpen] = useState(false);
  // const [isTeamMemberOpen, setIsTeamMemberOpen] = useState(false);

  return (
    <AnimateRoute className="flex grow flex-col rounded-xl bg-clay-20 p-2">
      <div className="grow overflow-hidden rounded-xl bg-white">
        <div className="grid h-full gap-4 xl:grid-cols-4">
          <div className="relative col-span-1 flex max-w-full flex-col overflow-hidden border-r border-clay-20 xl:col-span-3">
            <BackButton className="absolute left-4 top-4 z-30 font-medium" onClick={() => navigate('/')}>
              Back to DAI
            </BackButton>

            {/* <AnimatePresence> */}
            {isPending && (
              <motion.div
                animate={{ opacity: 1 }}
                className="flex size-full min-h-[300px] grow items-center justify-center"
                initial={{ opacity: 0 }}
              >
                <Spinner className="size-7" />
              </motion.div>
            )}
            <AnimatePresence>
              {isSuccess && dai && (
                <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
                  <DAIContent dai={dai} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* right side */}
          <div className="col-span-1 h-full px-4 py-6 xl:pl-0">
            {isSuccess && dai && (
              <div className="relative mb-4 flex min-h-80 flex-col">
                <header className="mb-4 flex items-center justify-between">
                  <div className="text-2xl font-medium">
                    Trade {dai?.token?.ticker ? `$${dai?.token?.ticker.toUpperCase()}` : ''}
                  </div>

                  <motion.button
                    animate="rest"
                    className=" flex h-7 min-w-7 items-center justify-center gap-1.5 rounded-md bg-clay-20 pl-2 pr-3 text-sm text-clay-400 outline-none"
                    initial="rest"
                    onClick={() => setIsTradeExpanded(true)}
                    whileHover="hover"
                  >
                    <Expand className="size-3.5 min-w-3.5" />
                    Trade view
                  </motion.button>
                </header>

                <div className="-mr-3 h-[200px]">
                  {daiPricesQuery.isPending ? (
                    <div className="flex items-center justify-center">
                      <Spinner className="size-4" />
                    </div>
                  ) : daiPriceChartQuery?.data?.length ? (
                    <TradingBarView
                      daiId={dai._id}
                      data={daiPriceChartQuery.data?.map((item) => ({
                        ...item,
                        time: DateTime.fromMillis(item.time).toUnixInteger() as Time,
                      }))}
                    />
                  ) : (
                    <div className="flex h-full items-center justify-center text-sm text-clay-300">
                      No chart data available
                    </div>
                  )}
                </div>

                {dai?.token?.creation?.address ? (
                  <TradeForm daiToken={dai?.token} />
                ) : (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/20 backdrop-blur-[1.2px]">
                    <div className="flex flex-col items-center justify-center gap-1 rounded-xl bg-clay-10 px-3 py-3.5 text-center">
                      <h1 className="text-center text-lg/none font-medium">Swap disabled</h1>
                      <p className="text-center text-sm/none font-light text-clay-400">
                        This project has no coin to trade yet
                      </p>
                    </div>
                  </div>
                )}

                {/* <div className="mb-8 mt-4 h-80 overflow-hidden">
                  <TradingView allowSymbolChange={false} hideLegend hideTopToolbar hideVolume />
                </div> */}

                {/* {!dai?.token?.creation?.address && (
                  
                )} */}
              </div>
            )}

            {dai?._id && <DAIComments daiId={dai?._id} />}
          </div>
        </div>
      </div>
      {!!dai && <TradeModal dai={dai} isOpen={isTradeExpanded} onOpenChange={setIsTradeExpanded} />}

      {creationDAIWalletAddress && (
        <SuccessCreationDAIModal
          address={creationDAIWalletAddress}
          isOpen={!!creationDAIWalletAddress}
          onOpenChange={() =>
            setSearchParams((prev) => {
              const newSearch = new URLSearchParams(prev);
              newSearch.delete('creation-dai-address');
              return newSearch;
            })
          }
        />
      )}
    </AnimateRoute>
  );
};

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import type { UseQueryOptions } from 'shared/types';

import { queryKeys } from './queryKeys';

type Params = {
  config: string;
  dataset: string;
  length: number;
  offset: number;
  query: string;
  split: string;
};

export type DatasetRow = { row: Record<string, unknown>; row_idx: number; truncated_cells: unknown[] };

type Response = {
  config: string;
  dataset: string;
  features: {
    feature_idx: number;
    name: string;
    type: {
      _type: 'Value';
      dtype: 'string';
    };
  }[];
  num_rows_per_page: number;

  num_rows_total: number;
  partial?: boolean;
  rows: DatasetRow[];
};

export const useSearchDatasetRowsQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<Response>(`https://datasets.nesa.ai/search`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          // 'X-NS-AUTH': 'AB7345GDDJMNU7T',
        },
        params,
        paramsSerializer: {
          indexes: null, // no brackets at all
        },
        signal,
      });

      return data;
    },
    queryKey: queryKeys.rows(params),
    ...options,
  });
};

import type { Descendant } from 'slate';
import type { ReactEditor } from 'slate-react';

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { HuggingfacePost } from 'shared/api/huggingface';

import { queryClient } from 'app/queryClient';
import { huggingfaceKeys } from 'shared/api/huggingface';
import { type Post, useCreateCommentPostMutation, useCreateHuggingfaceCommentPost } from 'shared/api/posts';
import { postsKeys } from 'shared/api/posts/queryKeys';
import { CommentEditor } from 'shared/ui/TextEditor';

type Props = {
  onComplete?: () => void;
  parentCommentId?: string;
  postId: string;
  type: 'huggingface' | 'nesa';
};

export const CreationComment = ({ onComplete, parentCommentId, postId, type }: Props) => {
  const [searchParams] = useSearchParams();

  const sort = searchParams.get('sort') || 'trending';

  const [content, setContent] = useState<Descendant[]>([{ children: [{ text: '' }], type: 'paragraph' }]);
  const [, setFiles] = useState<File[]>([]);

  const { isPending: isLoadingCreateComment, mutateAsync: createComment } = useCreateCommentPostMutation();
  const { isPending: isLoadingCreateHuggingfaceComment, mutateAsync: createHuggingfaceComment } =
    useCreateHuggingfaceCommentPost();

  const isLoading = isLoadingCreateComment || isLoadingCreateHuggingfaceComment;

  const handleSend = async (editor: ReactEditor) => {
    if (type === 'nesa') {
      const newPost = await createComment({
        content: JSON.stringify(content),
        parentId: parentCommentId,
        postId: postId,
      });
      editor.children = [{ children: [{ text: '' }], type: 'paragraph' }];
      setContent([{ children: [{ text: '' }], type: 'paragraph' }]);

      const queryKey = postsKeys.postList();
      const data: { pages: Post[][] } | undefined = queryClient.getQueryData(queryKey);
      if (data) {
        queryClient.setQueryData(queryKey, {
          ...data,
          pages: data.pages.map((page) => page.map((post) => (post._id === newPost._id ? newPost : post))),
        });
      }
      onComplete?.();
      return;
    }

    const newComment = await createHuggingfaceComment({
      content: JSON.stringify(content),
      parentId: parentCommentId,
      postId: postId,
    });

    editor.children = [{ children: [{ text: '' }], type: 'paragraph' }];
    setContent([{ children: [{ text: '' }], type: 'paragraph' }]);

    const queryKey = huggingfaceKeys.postList({ sort });
    const data: { pages: HuggingfacePost[][] } | undefined = queryClient.getQueryData(queryKey);
    if (data) {
      queryClient.setQueryData(queryKey, {
        ...data,
        pages: data.pages.map((page) =>
          page.map((post) =>
            post.slug === postId
              ? {
                  ...post,
                  comments: [...post.comments, newComment],
                }
              : post,
          ),
        ),
      });
    }
    onComplete?.();
  };

  return (
    <CommentEditor
      isLoading={isLoading}
      onChange={setContent}
      onFileChange={(files) => setFiles((prev) => [...prev, ...files])}
      onSend={handleSend}
      value={content}
    />
  );
};

import { useEffect, useRef } from 'react';

import { Terminal } from '@xterm/xterm';
import { DateTime } from 'luxon';

import '@xterm/xterm/css/xterm.css';

export const TerminalUI = () => {
  const terminal = useRef<Terminal>(undefined);
  const terminalElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (terminal.current || !terminalElementRef.current) return;
    terminal.current = new Terminal({ theme: { background: 'white' } });
    terminal.current.open(terminalElementRef.current);

    terminal.current.write(
      `\x1b[36mLast login: ${DateTime.now().toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}\x1b[36m`,
    );
  }, []);

  return <div className="p-5" ref={terminalElementRef}></div>;
};

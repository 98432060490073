import type {
  GetDatasetsParams,
  GetDatasetsTagsByTypeParams,
  GetMatchingDatasetsTagsParams,
  MetadataParams,
  StatParams,
} from './types';
import type { GetReactionParams } from './useDatasetUserReactionsQuery';
import type { SplitsParams } from './useGetDatasetSplitsQuery';

export const queryKeys = {
  additional: (params: { ids: string[] }) => [...queryKeys.base, 'additional', params],
  base: ['datasets'],
  byUser: (params: { userId: string }) => [...queryKeys.base, 'byUser', params],
  custom: (params: { datasetId: string }) => [...queryKeys.base, 'custom', params],
  customList: () => [...queryKeys.base, 'custom', 'full-list'],
  datasetsByType: (params: GetDatasetsTagsByTypeParams) => [...queryKeys.base, 'dataset-tags', params],
  infinity: (params: GetDatasetsParams) => [...queryKeys.base, 'infinity', params],
  json: (params: GetDatasetsParams) => [...queryKeys.base, 'json', params],
  matchingTags: (params: GetMatchingDatasetsTagsParams) => [...queryKeys.base, 'matchingTags', params],
  metadata: (params: MetadataParams) => [...queryKeys.base, 'metadata', params],
  reaction: (params: GetReactionParams) => [...queryKeys.base, 'reactions', params],
  rows: (params: unknown) => [...queryKeys.base, 'rows', params],
  splits: (params: SplitsParams) => [...queryKeys.base, 'splits', params],
  stats: (params: StatParams) => [...queryKeys.base, 'stats', params],
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { NodeChartResponse, NodeChatPeriod } from './types';

import { nodesKeys } from './queryKeys';

export const useNodeChartQuery = (
  { nodeId, period }: { nodeId: string; period: NodeChatPeriod },
  options: UseQueryOptions<NodeChartResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get<NodeChartResponse>(`/nodes/${nodeId}/response-count`, {
        params: { period },
        signal,
      });

      return res.data?.reverse();
    },
    queryKey: nodesKeys.chart(nodeId, period),
    ...(options || {}),
  });
};

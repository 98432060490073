import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';
3;
import type { HuggingfacePostComment } from '../huggingface';
import type { CreateCommentPostRequest } from './types';

export const useCreateHuggingfaceCommentPost = (
  options: UseMutationOptions<HuggingfacePostComment, Error, CreateCommentPostRequest> = {},
) => {
  return useMutation({
    mutationFn: async (params: CreateCommentPostRequest) => {
      const { data } = await axiosInstance.post<HuggingfacePostComment>(
        `/posts/create-huggingface-comment`,
        params,
      );

      return data;
    },
    ...options,
  });
};

import CodeMirror from '@uiw/react-codemirror';

import type { RepoFile } from '../../mock';

type Props = {
  file?: RepoFile;
};

export const FileEditor = ({ file }: Props) => {
  if (!file) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <div className="text-2xl text-clay-300">Select File</div>
      </div>
    );
  }

  return (
    <div className="flex-1">
      <CodeMirror className="outline-none" height="100%" value={file.content} />
    </div>
  );
};

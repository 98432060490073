import type { HTMLAttributeAnchorTarget } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { useUser } from 'app/stores/user';
import { useCreateNesaDatasetMutation } from 'shared/api/datasets/useCreateNesaDatasetMutation';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { Select } from 'shared/ui/Select';
import { toaster } from 'shared/ui/Toast';

type Props = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
};

const links: {
  icon: IconName;
  iconClassName?: string;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  url: string;
}[] = [
  {
    icon: 'bolt',
    iconClassName: 'stroke-clay-400 text-transparent',
    label: 'Getting Started',
    target: '_blank',
    url: 'https://docs.nesa.ai/nesa/using-nesa/getting-started',
  },
  { icon: 'book', iconClassName: '', label: 'Documentation', target: '_blank', url: 'https://docs.nesa.ai' },
  // {
  //   icon: 'huggingface',
  //   iconClassName: 'text-transparent',
  //   label: 'Huggingface',
  //   target: '_blank',
  //   url: 'https://huggingface.co/docs/hub/en/models-uploading',
  // },
];

const visibilityOptions = [
  {
    description: 'Anyone on Nesa can see this dataset. Only you or members of your organization can commit.',
    iconSrc: 'earth',
    label: 'Public',
    value: false,
  },
  {
    description: 'Only you or members of your organization can see and commit to this dataset.',
    iconSrc: 'lock',
    label: 'Private',
    value: true,
  },
];

const licenseOptions = [
  { label: 'Apache License 2.0', value: 'apache-2.0' },
  { label: 'MIT', value: 'mit' },
  { label: 'OpenRAIL License family', value: 'openrail' },
  { label: 'bigscience-openrail-m', value: 'bigscience-openrail-m' },
  { label: 'creativeml-openrail-m', value: 'creativeml-openrail-m' },
  { label: 'bigscience-bloom-rail-1.0', value: 'bigscience-bloom-rail-1.0' },
  { label: 'bigcode-openrail-m', value: 'bigcode-openrail-m' },
  { label: 'afl-3.0', value: 'afl-3.0' },
  { label: 'artistic-2.0', value: 'artistic-2.0' },
  { label: 'bsl-1.0', value: 'bsl-1.0' },
  { label: 'bsd', value: 'bsd' },
  { label: 'bsd-2-clause', value: 'bsd-2-clause' },
  { label: 'bsd-3-clause', value: 'bsd-3-clause' },
  { label: 'bsd-3-clause-clear', value: 'bsd-3-clause-clear' },
  { label: 'c-uda', value: 'c-uda' },
  { label: 'cc', value: 'cc' },
  { label: 'cc0-1.0', value: 'cc0-1.0' },
  { label: 'cc-by-2.0', value: 'cc-by-2.0' },
  { label: 'cc-by-2.5', value: 'cc-by-2.5' },
  { label: 'cc-by-3.0', value: 'cc-by-3.0' },
  { label: 'cc-by-4.0', value: 'cc-by-4.0' },
  { label: 'cc-by-sa-3.0', value: 'cc-by-sa-3.0' },
  { label: 'cc-by-sa-4.0', value: 'cc-by-sa-4.0' },
  { label: 'cc-by-nc-2.0', value: 'cc-by-nc-2.0' },
  { label: 'cc-by-nc-3.0', value: 'cc-by-nc-3.0' },
  { label: 'cc-by-nc-4.0', value: 'cc-by-nc-4.0' },
  { label: 'cc-by-nd-4.0', value: 'cc-by-nd-4.0' },
  { label: 'cc-by-nc-nd-3.0', value: 'cc-by-nc-nd-3.0' },
  { label: 'cc-by-nc-nd-4.0', value: 'cc-by-nc-nd-4.0' },
];

type FormValues = {
  datasetName: string;
  license: string;
  owner: string;
  private: boolean;
};

export const CreateDatasetModal = ({ isOpen, onOpenChange }: Props) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const { isPending: isCreating, mutateAsync: createNesaDataset } = useCreateNesaDatasetMutation();

  const methods = useForm<FormValues>({
    defaultValues: {
      datasetName: '',
      license: 'mit',
      owner: `${user?.first_name || ''}${user?.last_name || ''}`,
      private: true,
    },
    mode: 'onChange',
  });

  const {
    control,
    formState: { isValid },
    handleSubmit,
    register,
    reset,
  } = methods;

  const { field: licenseField } = useController({
    control,
    name: 'license',
    rules: { required: true },
  });

  const { field: privateField } = useController({
    control,
    name: 'private',
  });

  const handleCreateDataset = async (values: FormValues) => {
    console.log('handleCreateDataset', values);

    try {
      const datasetId = `${values.owner}/${values.datasetName}`;
      const res = await createNesaDataset({
        datasetId: `${values.owner}/${values.datasetName}`,
        license: values.license,
        ownerName: values.owner,
        private: values.private,
      });

      toaster.success('Your dataset is created');

      navigate(`/datasets/${datasetId.replace('/', '---')}`);

      console.log('created dta', res);

      reset({
        datasetName: '',
        license: 'mit',
        owner: `${user?.first_name || ''}${user?.last_name || ''}`,
        private: true,
      });
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-6xl" innerClassName="p-8">
        <div className="grid w-full grid-cols-[17rem_1fr_1fr] gap-8">
          <div className="col-span-1 flex flex-col items-stretch">
            <div className="flex flex-col">
              <Icon className="mb-6 size-11 stroke-clay-900 text-transparent" name="fileBox" />
              <h1 className="mb-4 text-3xl font-semibold">Upload a Dataset</h1>
              <div className="text-balance text-base font-light text-clay-500">
                Earn when your dataset{' '}is{' '}used for training or by a DAI.
              </div>
            </div>

            <div className="mt-auto">
              <div className="mb-3 text-xs font-normal text-clay-500">Guides & Tips</div>

              <div className="flex flex-col gap-3">
                {links.map(({ icon, iconClassName, label, target, url }) => {
                  return (
                    <Link
                      className="group flex items-center gap-1.5"
                      key={label}
                      onClick={() => {
                        if (!url) toaster.info('Coming soon');
                      }}
                      target={target}
                      to={url}
                    >
                      <Icon className={twMerge('size-3.5 text-clay-400', iconClassName)} name={icon} />
                      <span className="text-sm font-normal text-clay-800 transition-colors group-hover:text-primary-800">
                        {label}
                      </span>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-span-2 grid grid-cols-2 gap-4 rounded-xl bg-white px-4 py-3 drop-shadow-mdAll">
            <Input
              disabled
              label={
                <span>
                  Owner <span className="text-red-900">*</span>
                </span>
              }
              placeholder="Owner"
              required
              {...register('owner', { required: true })}
            />
            <Input
              label={
                <span>
                  Dataset name <span className="text-red-900">*</span>
                </span>
              }
              placeholder="Type name here"
              required
              {...register('datasetName', { required: true })}
            />

            <div className="col-span-2">
              <Select
                className="w-full border border-clay-20 bg-white pl-3"
                disabled={isCreating}
                label="License"
                onValueChange={licenseField.onChange}
                placeholder="Select License"
                value={licenseField.value}
              >
                <Select.Content>
                  {licenseOptions.map((item) => {
                    return (
                      <Select.Item key={item.value} value={item.value}>
                        {item.value}
                      </Select.Item>
                    );
                  })}
                </Select.Content>
              </Select>
            </div>

            <div className="col-span-2 flex gap-4">
              {visibilityOptions.map(({ description, iconSrc, label, value }) => {
                return (
                  <div
                    className={twMerge(
                      'flex flex-1 cursor-pointer gap-2 rounded-lg border border-clay-20 bg-white px-6 py-4 transition-colors hover:border-primary-800',
                      value === privateField.value && 'border-primary-150 bg-primary-30',
                      isCreating && 'pointer-events-none opacity-60',
                    )}
                    key={label + value}
                    onClick={() => privateField.onChange(value)}
                  >
                    <Icon className="size-4 shrink-0 text-primary-800" name={iconSrc as IconName} />
                    <div>
                      <div className="text-base/5 font-semibold">{label}</div>
                      <span className="text-sm/4 font-light text-clay-500">{description}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="col-span-2 rounded-lg bg-clay-10 px-6 py-4 text-sm font-light">
              Once your dataset is created, you can upload your files using the web interface or git.
            </div>

            <div className="col-span-2 flex w-full items-center justify-between">
              <Button color="secondary" onClick={() => onOpenChange(false)}>
                Cancel
              </Button>
              <Button disabled={!isValid} isLoading={isCreating} onClick={handleSubmit(handleCreateDataset)}>
                Create Dataset
              </Button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

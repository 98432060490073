import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { CreateReportParams, CreateReviewResponse } from './types';

export const useCreateReportMutation = (
  options: UseMutationOptions<CreateReviewResponse, Error, CreateReportParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ ...params }: CreateReportParams) => {
      const { data } = await axiosInstance.post<CreateReviewResponse>(`/reviews/report`, params);

      return data;
    },
    ...options,
  });
};

import { Area, AreaChart, ResponsiveContainer } from 'recharts';

export type ChartData = {
  count?: number;
  date?: string;
};

type Props = {
  data: ChartData[];
  total: string;
};

export const Chart = ({ data, total }: Props) => {
  return (
    <div className="flex flex-wrap items-center gap-2 xl:flex-nowrap">
      <p className="text-3xl font-semibold text-clay-900">${total}</p>
      <div className="size-full grow">
        <ResponsiveContainer className="size-auto max-h-10">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorPrimary" x1="0" x2="0" y1="0" y2="1">
                <stop offset="5%" stopColor="#8356E2" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#8356E2" stopOpacity={0} />
              </linearGradient>
            </defs>

            <Area
              dataKey="count"
              dot={false}
              fill="url(#colorPrimary)"
              fillOpacity={1}
              stroke="#7A4AE1"
              strokeWidth={1.5}
              type="monotone"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

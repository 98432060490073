import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';
3;
import type { Post, PostComment } from './types';

export const useDeleteCommentPostMutation = (
  options: UseMutationOptions<PostComment, Error, string> = {},
) => {
  return useMutation({
    mutationFn: async (id: string) => {
      const { data } = await axiosInstance.post<Post>(`/posts/delete-comment/${id}`);

      return data;
    },
    ...options,
  });
};

import { getFormattedNumber } from 'pages/Datasets/helpers/getFormattedNumber';
import { useGetTopTotalByMCAPStatsQuery, useGetTopTotalByTVLStatsQuery } from 'shared/api/daiStats';

import { NetworkStatsRow } from './Row';

export const NetworkStats = () => {
  const { data: mcap } = useGetTopTotalByMCAPStatsQuery();
  const { data: tvl } = useGetTopTotalByTVLStatsQuery();

  return (
    <div className="flex basis-[36%] flex-col gap-5 overflow-hidden rounded-xl border border-clay-20 p-4">
      <p className="text-xl font-semibold">Network Stats</p>
      <div className="flex h-full flex-col justify-between">
        <NetworkStatsRow
          changePrc={mcap?.diff || 0}
          chartData={mcap?.chartData || []}
          title="Market cap"
          total={getFormattedNumber(mcap?.total || 0, 0)}
        />
        <div className="border border-clay-20"></div>
        <NetworkStatsRow
          changePrc={tvl?.diff || 0}
          chartData={tvl?.chartData || []}
          title="TVL"
          total={tvl?.total ? getFormattedNumber(tvl.total, 0) : ''}
        />
      </div>
    </div>
  );
};

import { useMemo, useState } from 'react';

import { DateTime } from 'luxon';
import { zeroAddress } from 'viem';
import { useAccount } from 'wagmi';

import { WalletEvmProviderModal } from 'features/WalletEvmProviderModal';
import { useGetUserPortfolioBalanceQuery } from 'shared/api/daiStats/useGetUserPortfolioBalanceQuery';
import { fixPow } from 'shared/helpers/pow';
import { Spinner } from 'shared/ui/Spinner';

import { UserTotalStakes } from './UserTotalStakes';

export const RewardsAirdrop = () => {
  const { address } = useAccount();

  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);

  const { data: balance, isPending } = useGetUserPortfolioBalanceQuery(
    { address: address || zeroAddress },
    { enabled: !!address },
  );

  const rewards = useMemo(() => {
    const days =
      (DateTime.now().toMillis() - DateTime.fromISO('2025-03-30T00:00:00Z').toMillis()) /
      (24 * 60 * 60 * 1000);

    const fixNegative = fixPow(days, 0.8);

    return Math.floor(500_000 * (40 / (1 + Math.exp(6 - days / 10))) ** 0.421 + 1000 * fixNegative + 811_220);
  }, []);

  return (
    <div className="flex flex-col rounded-lg bg-gradient-to-tr from-transparent via-transparent via-60% to-[#DABDFF]/50 p-4 shadow ">
      <div className="flex flex-nowrap items-center justify-between gap-x-2">
        <div className="flex flex-col flex-nowrap items-start gap-1.5 text-lg/none font-semibold lg:text-sm/none min-[1220px]:text-base/none">
          <span>Rewards</span>
          <span>& Airdrop</span>
        </div>
        <span className="min-w-fit text-3xl font-bold text-black 2xs:text-4xl lg:text-xl min-[1220px]:text-3xl 2xl:text-5xl">
          {rewards ? `$${rewards.toLocaleString(undefined)}` : '---'}
        </span>
      </div>

      <div className="mt-4 flex flex-wrap gap-4 border-t border-clay-20 pt-4">
        <div className="flex min-w-[140px] flex-col gap-1.5">
          <div className="text-sm/none text-clay-400">My portfolio value</div>
          <div className="text-xl/none font-semibold">
            {address ? (
              isPending ? (
                <Spinner className="size-4" />
              ) : (
                `$${balance?.portfolioBalance?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || '-'}`
              )
            ) : (
              '--'
            )}
          </div>
        </div>
        <UserTotalStakes />
        <div></div>
      </div>

      <WalletEvmProviderModal onOpenChange={setIsWalletProviderOpen} open={isWalletProviderOpen} />
    </div>
  );
};

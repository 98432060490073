import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { CircleDotIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';

import { useGetLatestDAIQuery } from 'shared/api/dai/useGetLatestDAIQuery';
import { ContentLoader } from 'shared/ui/ContentLoader';

export const LatestDAI = () => {
  const [skip, setSkip] = useState(1);
  const { data, isError, isPending, isSuccess } = useGetLatestDAIQuery(
    { limit: 6, skip },
    { refetchInterval: 10 * 1000 },
  );

  const list = useRef(data?.list);
  if (data?.list) {
    list.current = data.list;
  }

  useEffect(() => {
    if (isSuccess && skip === 1) {
      const timeout = setTimeout(() => setSkip(0), 4000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isSuccess, skip]);

  if (isError) return null;

  return (
    <div className={twMerge('flex flex-col gap-4')}>
      <header className="flex items-center gap-3">
        <div className="flex size-8 items-center justify-center rounded-lg bg-yellow-500/10">
          <CircleDotIcon className="size-4 stroke-yellow-500 text-transparent" />
        </div>

        <div className="text-2xl font-medium tracking-tighter">Latest DAI</div>
      </header>
      <motion.div className="flex flex-col gap-1.5">
        {!list.current &&
          isPending &&
          Array.from({ length: 6 })
            .fill(null)
            .map((_, i) => <Item isLoading key={i} />)}
        {list.current && (
          <AnimatePresence initial={false} mode="popLayout">
            {list.current.map((dai) => (
              <motion.div
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 1 }}
                initial={{ opacity: 0, scale: 0.7 }}
                key={dai?._id}
                layout
                transition={{ duration: 0.4 }}
              >
                <Item dai={dai} key={dai._id} />
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </motion.div>
    </div>
  );
};

type ItemProps = {
  dai?: DAI;
  isLoading?: boolean;
};

const Item = ({ dai, isLoading }: ItemProps) => {
  return (
    <Link
      className={twMerge(
        'flex cursor-pointer items-center gap-3 rounded-lg bg-clay-10 px-2 py-1.5',
        !dai && 'pointer-events-none',
      )}
      to={`/dai/${dai?.slug}`}
    >
      <div className="flex flex-1 items-center gap-3">
        <div className="relative size-8 min-w-8 overflow-hidden rounded-lg">
          {dai?.profileImage && (
            <img className="size-full rounded-lg object-cover object-center" src={dai?.profileImage} />
          )}
          {isLoading && (
            <ContentLoader className="absolute inset-0" height="100%" width="100%">
              <rect height="100%" rx={8} ry={8} width="100%" x="0" y="0" />
            </ContentLoader>
          )}
        </div>
        <div className="relative h-5 flex-1 truncate to-clay-900 text-sm font-semibold">
          {isLoading ? (
            <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="12" width="100%">
              <rect height="12" rx={4} ry={4} width="100%" x="0" y="0" />
            </ContentLoader>
          ) : (
            dai?.projectName
          )}
        </div>
      </div>
      <div className="flex h-5 items-center rounded-md border border-clay-10 bg-white px-2 text-[10px] font-semibold uppercase leading-3 text-clay-380 shadow-checkbox">
        {dai?.projectCategory}
      </div>
    </Link>
  );
};

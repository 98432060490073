import { useState } from 'react';

import { ExpandModal } from 'features/ModelQuery/ui/ExpandModal';
import { Icon } from 'shared/ui/Icon';

import { ExpandVideo } from './ExpandedVideo';

type AttachmentType = {
  type: 'image' | 'video';
  url: string;
};

type AttachmentsProps = {
  attachments: AttachmentType[];
};
export const Attachments = ({ attachments }: AttachmentsProps) => {
  return (
    <div
      className="mt-5 flex gap-2 overflow-x-auto scrollbar-none"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {attachments.map((el, i) => (
        <Attachment attachment={el} key={i} />
      ))}
    </div>
  );
};

type AttachmentProps = {
  attachment: AttachmentType;
};

export const Attachment = ({ attachment }: AttachmentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {attachment.type === 'image' && (
        <>
          <img
            alt=""
            className="h-28 min-h-28 cursor-pointer rounded-lg"
            onClick={() => setIsExpanded(true)}
            src={attachment.url}
          />
          <ExpandModal imageSrc={attachment.url} isOpen={isExpanded} onOpenChange={setIsExpanded} />
        </>
      )}
      {attachment.type === 'video' && (
        <div className="relative cursor-pointer">
          <video className="h-28 min-h-28 max-w-none rounded-lg" src={attachment.url} />
          <div
            className="absolute inset-0 flex items-center justify-center rounded-lg bg-clay-900/50 duration-200 hover:bg-clay-900/30"
            onClick={() => setIsExpanded(true)}
          >
            <Icon className=" size-8 text-white" name="play" />
          </div>
          <ExpandVideo isOpen={isExpanded} onOpenChange={setIsExpanded} videoSrc={attachment.url} />
        </div>
      )}
    </>
  );
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';
3;
import type { CreatePostRequest } from './types';

export const useCreatePostMutation = (
  options: UseMutationOptions<unknown, Error, CreatePostRequest> = {},
) => {
  return useMutation({
    mutationFn: async (params: CreatePostRequest) => {
      const { data } = await axiosInstance.post<unknown>(`/posts/create`, params);

      return data;
    },
    ...options,
  });
};

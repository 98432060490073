import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { DateTime } from 'luxon';

import type { ModelType } from 'shared/ui/Gallery/PreviewBanner/PreviewBanner';

import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { CategoryModelsDropdown } from 'features/CategoryModelsDropdown';
import { QueryHistoryCard } from 'features/QueryHistory';
import { useGetQueryHistoryByUserInfiniteQuery } from 'shared/api/queryHistory/useGetQueryHistoryByUserInfiniteQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Select } from 'shared/ui/Select';

import emptyHistorySrc from './assets/empty-history.svg';
import { Filter } from './ui/Filter';
import { LoadingCard } from './ui/LoadingCard';

const sortOptions = [
  { label: 'Newest', value: 'newest' },
  { label: 'Oldest', value: 'oldest' },
];

export const Page = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [searchParams, setSearchParams] = useSearchParams();

  const sort = (searchParams.get('sort') || undefined) as 'newest' | 'oldest' | undefined;
  const modelTypes = searchParams.getAll('modelType') as ModelType[];
  const startDateParam = searchParams.get('startDate');
  const endDateParam = searchParams.get('endDate');
  const status = searchParams.get('status');

  const startDate = startDateParam ? DateTime.fromFormat(startDateParam, 'yyyy-LL-dd').startOf('day') : null;
  const endDate = endDateParam ? DateTime.fromFormat(endDateParam, 'yyyy-LL-dd').endOf('day') : null;

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
    useGetQueryHistoryByUserInfiniteQuery(
      {
        endDate: endDate && endDate.isValid ? endDate.toISO() : undefined,
        limit: 10,
        modelType: modelTypes.length ? modelTypes : undefined,
        sort,
        startDate: startDate && startDate.isValid ? startDate.toISO() : undefined,
        status: status || undefined,
        userId: user?._id || '',
      },
      { enabled: !!user?._id },
    );

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const historyList = data?.pages.flat() || [];

  return (
    <AnimateRoute className="flex flex-col overflow-hidden  !pb-0" container>
      <div className="mb-6">
        <BackButton
          className="gap-3 text-2xl font-semibold text-clay-900 hover:text-clay-900"
          onClick={() => navigate(-1)}
        >
          Query history
        </BackButton>
      </div>

      <div className="mb-2 flex justify-between">
        <div className="flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <CategoryModelsDropdown
            currentTypes={modelTypes}
            onApply={(values) => {
              setSearchParams((prev) => {
                const newSearch = new URLSearchParams(prev);
                newSearch.delete('modelType');
                if (!values.includes('all')) values.forEach((el) => newSearch.append('modelType', el));
                return newSearch;
              });
            }}
          />
        </div>
        <div className="box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
          <Select
            className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            iconClassName="mr-1"
            iconName="arrowDownUp"
            onValueChange={(value) =>
              setSearchParams((prev) => {
                const newSearch = new URLSearchParams(prev);
                newSearch.set('sort', value);
                return newSearch;
              })
            }
            placeholder="Sort by"
            placeholderClassName="font-medium text-clay-600"
            value={sort}
          >
            <Select.Content className="z-10 p-1">
              <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Sort by
              </div>

              {sortOptions.map((item) => {
                return (
                  <Select.Item
                    className="text-sm text-clay-700 hover:bg-blue-50"
                    indicatorClassName="size-3 text-clay-700"
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
          <Filter />
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-4 overflow-scroll pb-10">
        {isPending ? (
          <>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </>
        ) : historyList.length > 0 ? (
          <>
            {historyList.map((history) => {
              return <QueryHistoryCard data={history} key={history._id} />;
            })}
            <div className="-mt-4" ref={ref} />
          </>
        ) : (
          <Card className="flex flex-col items-center justify-center !p-16">
            <img className="mb-6 w-[72px]" src={emptyHistorySrc} />
            <div className="mb-2 text-2xl font-semibold">Your history is empty</div>
            <div className="mb-6 text-base font-light text-clay-500">No history to be shown</div>

            <Button onClick={() => navigate('/')}>Browse Models</Button>
          </Card>
        )}

        {isFetchingNextPage && (
          <>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </>
        )}
      </div>
    </AnimateRoute>
  );
};

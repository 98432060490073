import type { IChartApi, Time } from 'lightweight-charts';

import { useEffect, useRef } from 'react';

import { CandlestickSeries, ColorType, createChart } from 'lightweight-charts';

import { PriceChartInterval } from 'shared/api/daiStats/useGetDAIPriceChartQuery';

import type { TWSeries } from './hooks/useRealtimeUpdates';

import { useRealtimeUpdates } from './hooks/useRealtimeUpdates';

type Props = {
  daiId: string;
  data: { close: number; high: number; low: number; open: number; time: Time }[];
};

export const TradingBarView = ({ daiId, data }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi>(undefined);
  const seriesRef = useRef<TWSeries>(undefined);

  useRealtimeUpdates({ daiId, period: PriceChartInterval.d1, series: seriesRef.current || null });

  useEffect(() => {
    if (!ref.current) return;

    chartRef.current = createChart(ref.current, {
      autoSize: true,

      crosshair: {
        horzLine: {
          labelBackgroundColor: '#303742',
        },
        vertLine: {
          labelBackgroundColor: '#303742',
        },
      },
      grid: {
        horzLines: {
          color: '#ECEDF1',
        },
        vertLines: {
          color: '#ECEDF1',
        },
      },
      layout: {
        background: { color: 'white', type: ColorType.Solid },
        fontFamily: "'DM Sans', sans-serif",
        // grid: {
        //   ve
        // }

        textColor: '#303742',
      },
      rightPriceScale: {
        borderColor: '#818996',
        entireTextOnly: true,
      },
      timeScale: {
        allowBoldLabels: false,
        borderColor: '#818996',
      },
    });

    // make lines lighter, replace black with another color
    seriesRef.current = chartRef.current.addSeries(CandlestickSeries, {
      borderVisible: false,
      downColor: '#ef5350',
      priceFormat: {
        // formatter: (value: number) => {
        //   return value.toFixed(2);
        // },
        minMove: 0.0001,
        precision: 4,
        // type: 'custom',
        type: 'price',
      }, // color: '#2962FF',
      upColor: '#26a69a',
      wickDownColor: '#ef5350',
      wickUpColor: '#26a69a',
    });

    // const data = [
    //   { time: 1642425322 as Time, value: 0 },
    //   { time: 1642511722 as Time, value: 8 },
    //   { time: 1642598122 as Time, value: 10 },
    //   { time: 1642684522 as Time, value: 20 },
    //   { time: 1642770922 as Time, value: 3 },
    //   { time: 1642857322 as Time, value: 43 },
    //   { time: 1642943722 as Time, value: 41 },
    //   { time: 1643030122 as Time, value: 43 },
    //   { time: 1643116522 as Time, value: 56 },
    //   { time: 1643202922 as Time, value: 46 },
    // ];

    seriesRef.current.setData(data);

    chartRef.current.timeScale().fitContent();

    return () => {
      chartRef.current?.remove();
    };
  }, [data]);
  return <div className="size-full" ref={ref}></div>;
};

import type { ComponentProps, FunctionComponent, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { Icon, isIconName } from 'shared/ui/Icon';

type Props = {
  icon:
    | FunctionComponent<
        {
          title?: string | undefined;
        } & React.SVGProps<SVGSVGElement>
      >
    | IconName;
  iconClassName?: string;
} & ComponentProps<'div'>;

export const ActionButton = ({
  children,
  className,
  icon,
  iconClassName,
  ...props
}: PropsWithChildren<Props>) => {
  const LucideIcon = icon;

  return (
    <div
      className={twMerge('group flex cursor-pointer flex-col items-center gap-2 font-normal', className)}
      {...props}
    >
      <div className="flex size-14 items-center justify-center rounded-full bg-tusk-100 transition-colors group-hover:bg-tusk-200">
        {/*<Icon className={twMerge('size-4', iconClassName)} name={icon} />*/}
        {isIconName(LucideIcon) ? (
          <Icon className={twMerge('size-4', iconClassName)} name={LucideIcon} />
        ) : (
          <LucideIcon className={twMerge('size-4', iconClassName)} />
        )}
      </div>

      {children}
    </div>
  );
};

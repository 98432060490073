import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { NodeStatResponse } from './types';

import { nodesKeys } from './queryKeys';

export const useNodeStatQuery = (
  { nodeId }: { nodeId: string },
  options: UseQueryOptions<NodeStatResponse> = {},
) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const res = await axiosInstance.get<NodeStatResponse>(`/nodes/${nodeId}/stat`, {
        signal,
      });

      return res.data;
    },
    queryKey: nodesKeys.nodeStat(nodeId),
    ...(options || {}),
  });
};

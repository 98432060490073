import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { useEvents } from 'app/providers/LiveEvents';
import { useUser } from 'app/stores/user';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
// import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import { TokenEvent } from './Event';

export const TopBar = () => {
  const { user } = useUser();
  const { events, isNewEventAppeared, makeAllEventsAnimationDisabled, popPendingEvent } = useEvents();
  const navigate = useNavigate();
  const [isTabActive, setIsTabActive] = useState<boolean>(true);
  const [daiPopupVisible, setDaiPopupVisibility] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isTabActive || daiPopupVisible || isHovered) return;
    const id = setInterval(() => {
      popPendingEvent();
    }, 4200);
    return () => {
      clearInterval(id);
    };
  }, [daiPopupVisible, isHovered, isTabActive, popPendingEvent]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };
    makeAllEventsAnimationDisabled();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimateRoute className="rounded-xl bg-clay-20 p-2">
      {/*<Button onClick={() => addItem()}>Add</Button>*/}
      <div className="relative flex h-14 w-full items-center gap-4 rounded-t-xl bg-[#F2ECFF] pl-4">
        <div
          className={twMerge(
            'absolute inset-x-0 bottom-0 h-12 bg-gradient-to-t from-primary-300 to-transparent transition-opacity duration-500',
            isNewEventAppeared ? 'opacity-100' : 'opacity-0',
          )}
        />

        {!user && (
          <div
            className="relative top-4 z-30 mr-3 flex size-16 min-w-16 cursor-pointer items-center justify-center rounded-2xl border border-clay-20 bg-white shadow-lg"
            onClick={() => {
              navigate('/auth/signin');
            }}
          >
            <Icon className="size-8" name="logo" />
          </div>
        )}
        <span className="text-base font-semibold text-[#6F48C1]">Live</span>

        <div
          className="group flex grow overflow-hidden pr-4"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className={twMerge('flex items-center')}>
            {events.map((e, index) => (
              <TokenEvent
                className="px-6"
                event={e}
                index={index}
                key={e.uniqueKey || e._id}
                onOpenChange={setDaiPopupVisibility}
              />
            ))}
          </div>
        </div>
      </div>
      <Outlet />
    </AnimateRoute>
  );
};

import type { RenderElementProps, RenderLeafProps } from 'slate-react';

export const renderElement = ({ attributes, children, element }: RenderElementProps) => {
  if (element.type === 'h1') {
    return (
      <h1 {...attributes} className="mb-5 mt-6 text-4xl font-semibold text-clay-900 first:mt-0">
        {children}
      </h1>
    );
  }

  if (element.type === 'h2') {
    return (
      <h2 {...attributes} className="mb-4 mt-5 text-2xl font-semibold text-clay-900">
        {children}
      </h2>
    );
  }

  if (element.type === 'bulleted-list') {
    return (
      <ul {...attributes} className="my-4 list-disc pl-6">
        {children}
      </ul>
    );
  }

  if (element.type === 'quote') {
    return (
      <blockquote
        {...attributes}
        className="border-l-2 border-clay-40 px-4 first-of-type:mt-4 last-of-type:mb-4"
      >
        {children}
      </blockquote>
    );
  }

  // if (element.type === 'numbered-list') {
  //   return <ol className="my-2 list-decimal pl-6">{children}</ol>;
  // }

  if (element.type === 'list-item') {
    return <li className="text-base font-medium text-clay-900 marker:!text-center">{children}</li>;
  }

  return <p className="text-base text-clay-500">{children}</p>;
};

export const renderLeaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf['bold']) {
    children = <b className="font-semibold">{children}</b>;
  }
  if (leaf['italic']) {
    children = <i className="italic">{children}</i>;
  }
  if (leaf['underline']) {
    children = <u className="underline">{children}</u>;
  }
  if (leaf['code']) {
    children = <code className="rounded-sm bg-clay-20 p-0.5">{children}</code>;
  }

  return <span {...attributes}>{children}</span>;
};

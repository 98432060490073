import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { GrazProvider } from 'graz';
import { WagmiProvider } from 'wagmi';

import { LiveEventsProvider } from 'app/providers/LiveEvents';
import { TopBar } from 'app/ui/TopBar/TopBar';
import { StripeCheckPaymentHandler } from 'features/useStripePaymentHandler';
import { Agents } from 'pages/Agents';
import { Auth } from 'pages/Auth/Auth';
import { GoogleCallback } from 'pages/Auth/GoogleCallback';
import { PhoneNumber } from 'pages/Auth/PhoneNumber';
import { RecoveryPassword } from 'pages/Auth/RecoveryPassword';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { SignIn } from 'pages/Auth/SignIn';
import { SignUp } from 'pages/Auth/SignUp';
import { VerifyEmail } from 'pages/Auth/VerifyEmail';
import { BuildOnNesa } from 'pages/BuildOnNesa';
import { CreateDAI } from 'pages/CreateDAI';
import { DAI } from 'pages/DAI';
import { DAIOverview } from 'pages/DAIOverview';
import { DatasetDetails } from 'pages/DatasetDetails';
import { Datasets } from 'pages/Datasets';
import { Ecosystem } from 'pages/Ecosystem';
import { EmailVerification } from 'pages/EmailVerification';
import { Enterprise } from 'pages/Enterprise';
import { ExploreDAI } from 'pages/ExploreDAI';
import { Faucet } from 'pages/Faucet';
import { Function } from 'pages/Function';
import { Functions } from 'pages/Functions';
import { GalleryHome } from 'pages/GalleryHome';
import { LeaderBoard } from 'pages/LeaderBoard2';
// import { Faucet } from 'pages/Faucet';
import { Component } from 'pages/Map';
import { ModelCollectionId } from 'pages/ModelCollectionId';
import { ModelCollections } from 'pages/ModelCollections';
import { ModelPage } from 'pages/ModelPage';
import { Models } from 'pages/Models';
import { NodePage } from 'pages/NodePage';
import { Nodes } from 'pages/Nodes';
import { NotFound } from 'pages/NotFound';
import { PasswordRecovery } from 'pages/PasswordRecovery';
import { Posts } from 'pages/Posts';
import { Preview } from 'pages/Preview';
import { PricingPage } from 'pages/PricingPage';
import { PrivateTx } from 'pages/PrivateTx';
import { Profile } from 'pages/Profile';
import { QueryHistory } from 'pages/QueryHistory';
import { QueryPage } from 'pages/QueryPage';
import { ResetPassword as OldResetPassword } from 'pages/ResetPassword';
import { Settings } from 'pages/Settings';
import { SignUp as OldSignUp } from 'pages/SignUp';
import { Training, TrainingCode, TrainingNoCode } from 'pages/Training';
import { UnsubscribeEmail } from 'pages/UnsubscribeEmail';
import { VerifyEmail as OldVerifyEmail } from 'pages/VerifyEmail';
import { Wallet } from 'pages/Wallet';
import { WalletRouteGuard } from 'pages/Wallet/WalletGuard';
import { Whitelist } from 'pages/Whitelist';
import { nesaTestnet } from 'shared/config/networks/nesaTestnet';
import { ScrollToTop } from 'shared/ui/ScrollToTop';
// import { RestrictedAccess } from 'shared/provider/RestrictedAccess';
import { Tooltip } from 'shared/ui/Tooltip';

import { wagmiConfig } from './config/wagmiConfig';
import { AuthGuard } from './providers/AuthGuard';
// import { PublicAndAuthGuard } from './providers/PublicAndAuthGuard';
import { queryClient } from './queryClient';
import { ProductPricesProvider } from './stores/prices';
import { UserContextProvider } from './stores/user';
import { Loading } from './ui/Loading';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
  const [isLoading, setIsLoading] = useState(import.meta.env.MODE !== 'development');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
      clearTimeout(timeout);
    }, 1500);
  }, []);

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <ProductPricesProvider>
            <GrazProvider
              grazOptions={{
                autoReconnect: false,
                chains: [nesaTestnet],
              }}
            >
              <LiveEventsProvider>
                <StripeCheckPaymentHandler>
                  <Tooltip.Provider>
                    <AnimatePresence>{isLoading && <Loading duration={1500} />}</AnimatePresence>
                    <AnimatePresence>
                      <Routes>
                        <Route element={<Preview />} path="/preview" />
                        <Route element={<OldSignUp />} path="/signup" />
                        <Route element={<UnsubscribeEmail />} path="/unsubscribe-email/:token" />
                        <Route element={<PrivateTx />} path="/private-tx/:id" />

                        <Route element={<Auth />} path="/auth">
                          <Route element={<Navigate replace to="/auth/signin" />} index />
                          <Route element={<SignIn />} path="signin" />
                          <Route element={<SignUp />} path="signup" />
                          <Route element={<VerifyEmail />} path="verify-email" />
                          <Route element={<PhoneNumber />} path="phone" />
                          <Route element={<RecoveryPassword />} path="recovery-password" />
                          <Route element={<ResetPassword />} path="reset-password/:userId/:token" />

                          <Route element={<GoogleCallback />} path="google/callback" />
                        </Route>

                        <Route element={<OldVerifyEmail />} path="/signup/verify" />
                        {/* <Route element={<PublicAndAuthGuard />}>
                          <Route element={<TopBar />}>
                            <Route element={<DAIOverview />} path="/" />
                            <Route element={<ExploreDAI />} path="/dai/explore" />
                          </Route>
                          <Route element={<DAI />} path="/dai/:slug" />
                        </Route> */}
                        <Route element={<AuthGuard />}>
                          <Route element={<TopBar />}>
                            <Route element={<DAIOverview />} path="/" />
                            <Route element={<ExploreDAI />} path="/dai/explore" />
                          </Route>
                          <Route element={<DAI />} path="/dai/:slug" />
                          <Route element={<CreateDAI />} path="/dai/create" />
                          <Route element={<Functions />} path="/functions" />
                          <Route element={<Function />} path="/functions/:id" />
                          <Route element={<CreateDAI />} path="/dai/:slug/edit" />

                          <Route element={<GalleryHome />} path="/models" />
                          <Route element={<ModelPage />} path="/models/:id/details" />
                          <Route element={<QueryPage />} path="/models/:id/:section?" />

                          <Route element={<Agents />} path="/agents" />

                          <Route element={<NodePage />} path="/nodes/:id" />
                          <Route element={<Component />} path="/map" />
                          <Route
                            element={
                              <WalletRouteGuard>
                                <Wallet />
                              </WalletRouteGuard>
                            }
                            path="/wallet/:walletType?/:section?"
                          />
                          <Route element={<Profile />} path="/account/:id" />
                          <Route element={<Settings />} path="/settings" />
                          <Route element={<QueryHistory />} path="/query-history" />
                          <Route element={<LeaderBoard />} path="/leader-board" />
                          <Route element={<BuildOnNesa />} path="/build-on-nesa" />
                          <Route element={<Faucet />} path="/privilege/faucet" />
                          <Route element={<Ecosystem />} path="/ecosystem" />
                          <Route element={<Nodes />} path="/nodes" />
                          <Route element={<Models />} path="/models" />
                          <Route element={<ModelCollections />} path="/model-collections" />
                          <Route element={<ModelCollectionId />} path="/model-collections/:id" />
                          <Route element={<PricingPage />} path="/pricing" />
                          <Route element={<Enterprise />} path="/enterprise" />
                          <Route element={<Datasets />} path="/datasets" />
                          <Route element={<DatasetDetails />} path="/datasets/:id/:section?" />
                          <Route element={<Posts />} path="/posts" />
                          <Route element={<Training />} path="/training" />
                          <Route element={<TrainingNoCode />} path="/training/no-code/:trainId" />
                          <Route element={<TrainingCode />} path="/training/code/:trainId" />
                          <Route element={<TrainingNoCode />} path="/training/lora/:trainId" />
                        </Route>

                        <Route element={<PasswordRecovery />} path="/recovery/password" />

                        <Route
                          element={<EmailVerification />}
                          path="/users/:userId/verification/email/:token"
                        />
                        <Route element={<OldResetPassword />} path="/users/:userId/reset/password/:token" />

                        <Route element={<Whitelist />} path="/whitelist" />
                        <Route element={<NotFound />} path="*" />
                      </Routes>
                    </AnimatePresence>
                  </Tooltip.Provider>
                </StripeCheckPaymentHandler>
              </LiveEventsProvider>
            </GrazProvider>
          </ProductPricesProvider>
        </UserContextProvider>
        <ToastContainer />

        <ScrollToTop />
      </QueryClientProvider>
    </WagmiProvider>
  );
};

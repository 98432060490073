import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

import accountSrc from '../assets/account.svg';

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const DeactivateModal = ({ isOpen, onOpenChange }: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-xs">
        <Modal.CloseButton />

        <div className="flex !max-w-fit flex-col items-center justify-center gap-4">
          <img className="w-24" src={accountSrc} />
          <div className="text-2xl font-medium text-clay-900">Account Deactivation</div>
          <div className="-mt-2 text-center text-sm font-light text-clay-500">
            Are you sure to deactivate your account? Your account can’t be recovered.
          </div>
          <div
            className="cursor-pointer rounded-lg bg-red-900 px-3 text-sm/9 text-white hover:bg-red-1000"
            onClick={() => toaster.info('Coming soon')}
          >
            Deactivate
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

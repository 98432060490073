import { useState } from 'react';

import { useDebounce } from '@uidotdev/usehooks';
import { Box, Search, X } from 'lucide-react';

import type { Model } from 'shared/api/models/types';

import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { useGetModelsByNamesQuery } from 'shared/api/models/useGetModelsByNamesQuery';
import { trendingTextModelNames } from 'shared/const';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { Popover } from 'shared/ui/Popover';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  isOpen: boolean;
  onAddModels: (models: Model[]) => void;
  onOpenChange: (isOpen: boolean) => void;
};

export const AddModelModal = ({ isOpen, onAddModels, onOpenChange }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [selectedModels, setSelectedModels] = useState<Model[]>([]);

  const debouncedValue = useDebounce(searchValue, 300);

  const { data, isPending } = useModelListQuery(
    { limit: 5, search: debouncedValue },
    { enabled: debouncedValue.length > 2 },
  );

  const { data: trendingModels = [], isPending: isTrendingPending } = useGetModelsByNamesQuery({
    names: trendingTextModelNames,
  });
  const modelsResult = searchValue.length <= 2 ? trendingModels : data?.pages.flat() || [];

  const resetValue = () => {
    setSearchValue('');
    setIsPopoverOpen(false);
  };

  const hasResults = modelsResult.length > 0 && (searchValue.length <= 2 ? !isTrendingPending : !isPending);

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!max-w-xl">
        <Modal.CloseButton />

        <h1 className="mb-4 text-lg">Add Model</h1>

        <div className="flex flex-col">
          <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
            <Popover.Trigger
              asChild
              className="flex w-full shrink-0 grow-0 cursor-pointer items-center gap-0 text-xs font-medium outline-none transition-colors hover:text-primary-900"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <div>
                <Input
                  classNameInputWrapper="h-14 rounded-xl px-3.5 shadow-card-large"
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSearchValue(e.target.value);
                  }}
                  onFocusCapture={() => {
                    setIsPopoverOpen(true);
                  }}
                  placeholder="Search for Model"
                  size="medium"
                  startSlot={
                    <Search className="mr-1 size-4 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300" />
                  }
                  value={searchValue}
                />
              </div>
            </Popover.Trigger>
            <Popover.Content
              alignOffset={0}
              className="w-[var(--radix-popover-trigger-width)] p-3"
              onOpenAutoFocus={(e) => e.preventDefault()}
              sideOffset={10}
            >
              {!isPending && !modelsResult.length ? (
                <div className="flex flex-col items-center py-3 ">
                  <div className="mx-auto mb-2 flex size-11 items-center justify-center rounded-full border border-clay-20 shadow-boxy">
                    <Search className="size-4 text-clay-350" />
                  </div>
                  <h4 className="mb-2 text-lg">No Result Found</h4>

                  <div className="mb-3 text-center text-sm font-light text-clay-400">
                    <span className="font-normal text-clay-900">“{searchValue}”</span> did not match any
                    models or commands. Please try again.
                  </div>

                  <Button color="secondary" onClick={resetValue} variant="filled-light">
                    Clear search
                  </Button>
                </div>
              ) : (
                !hasResults && (
                  <div className="flex min-h-48 items-center justify-center py-4">
                    <Spinner className="size-5" />
                  </div>
                )
              )}
              {hasResults && (
                <div className="flex flex-col ">
                  <div className="mb-3 border-b border-clay-20 pb-2 text-sm font-light text-clay-350">
                    {searchValue.length <= 2 ? 'Trending Results' : 'Search Results'}
                  </div>

                  {modelsResult.map((model) => {
                    return (
                      <div
                        className="flex cursor-pointer items-center gap-3 rounded-lg px-2.5 py-1 text-sm font-normal text-clay-700 transition-colors hover:bg-clay-10"
                        key={model._id}
                        onClick={() => {
                          // const newSearch = new URLSearchParams(searchParams);
                          // newSearch.set('modelId', model._id);
                          setIsPopoverOpen(false);
                          // setSearchParams(newSearch);

                          setSelectedModels((prev) => {
                            const isSelected = prev.find((item) => item._id === model._id);

                            if (isSelected) return prev;

                            return [...prev, model];
                          });
                        }}
                      >
                        <div className="flex flex-col overflow-hidden">
                          <div className="flex items-center gap-1.5 ">
                            <span className="flex items-center gap-2.5 truncate">
                              <Box className="size-5 shrink-0 text-clay-350" />

                              <span className="truncate text-base">{model.modelName}</span>
                            </span>

                            <span className="size-[2px] rounded-full bg-clay-100"></span>

                            <span className="min-w-fit text-sm font-light text-clay-300">{model.org}</span>
                          </div>

                          <div className="ml-7 line-clamp-2 max-w-[80%] text-xs font-light text-clay-300">
                            {model.description}
                          </div>
                        </div>

                        <div className="ml-auto shrink-0 truncate rounded-[4px] border border-clay-20 bg-white px-1 py-0.5 text-xs uppercase text-clay-300">
                          {getCategoryLabel(model.type)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Popover.Content>
          </Popover>
        </div>

        <div className="my-6 flex flex-col gap-4">
          {selectedModels.map((model) => {
            return (
              <div
                className="flex items-center justify-between gap-2 text-sm/none font-light"
                key={model._id}
              >
                <div className="flex items-center gap-2">
                  <Box className="size-4 text-clay-350" />
                  {model.modelName}
                </div>

                <X
                  className="size-4 cursor-pointer text-clay-350 transition-colors hover:text-red-900"
                  onClick={() => {
                    setSelectedModels((prev) => prev.filter(({ _id }) => _id !== model._id));
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className="flex items-center justify-between gap-3">
          <Button color="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onAddModels(selectedModels);
              setSelectedModels([]);
              setSearchValue('');
              onOpenChange(false);
            }}
          >
            Add Models
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import { Fragment, useMemo, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from 'shared/ui/Icon';

import type { Repo } from './mock';

import { findFileByPath } from './libs';
import { mockRepo } from './mock';
import { RepoFile } from './ui/File';
import { FileEditor } from './ui/FileEditor';
import { Folder } from './ui/Folder';
import { TerminalUI } from './ui/Terminal';

type Props = {
  repo?: Repo;
};

const asideLinks = [
  {
    icon: <Icon className="size-4 text-clay-500" name="boltStroke" />,
    label: 'Getting Started',
    to: '',
  },
  {
    icon: <Icon className="size-4 text-clay-500" name="book" />,
    label: 'How to train',
    to: '',
  },
];

export const IDE = ({ repo = mockRepo }: Props) => {
  const [selectedFilePath, setSelectedFilePath] = useState<string | undefined>(() => {
    const find = (part: Pick<Repo, 'files' | 'folders'>): string | undefined => {
      const fileName = part.files.at(0);
      if (fileName) {
        return fileName.name;
      }
      for (let i = 0; i < part.folders.length; i++) {
        const filePath = find(part.folders[i]);
        if (filePath) {
          return `${part.folders[i].name}/${filePath}`;
        }
      }

      return undefined;
    };
    return find(mockRepo);
  });

  const selectedFile = useMemo(() => {
    if (selectedFilePath) {
      return findFileByPath(repo, selectedFilePath);
    }
  }, [selectedFilePath, repo]);

  return (
    <div className="flex flex-1 overflow-hidden bg-white">
      <aside className="flex h-full min-w-72 flex-col overflow-y-auto border-r border-clay-20 px-3 py-2">
        <header className="mb-3.5 flex items-center">
          <h3 className="line-clamp-1 flex-1 text-lg font-semibold">{repo.name}</h3>
        </header>

        <div className="flex flex-1 flex-col">
          {mockRepo.folders.map((folder, i) => (
            <Folder
              folder={folder}
              initialOpen={i === 0}
              key={folder.name}
              onFileChange={setSelectedFilePath}
              path=""
              selectedFilePath={selectedFilePath}
            />
          ))}
          {mockRepo.files.map((file) => (
            <RepoFile
              file={file}
              key={file.name}
              onFileChange={setSelectedFilePath}
              path=""
              selectedFilePath={selectedFilePath}
            />
          ))}
        </div>
        <div className="rounded-xl bg-clay-10 p-4">
          <h5 className=" mb-2.5 text-sm font-medium text-clay-500">How to Train</h5>
          {asideLinks.map((item, i) => (
            <div
              className="flex cursor-pointer items-center gap-2 rounded-md p-2 duration-200 hover:bg-clay-20"
              key={i}
            >
              {item.icon}
              <span className="text-sm font-medium text-clay-900">{item.label}</span>
            </div>
          ))}
        </div>
      </aside>
      <div className="flex flex-1 flex-col border-r border-clay-20">
        <header className="flex items-center gap-3 border-b border-clay-20 px-3.5 py-4">
          <Icon className=" size-5" name="code" safeArea="0" />
          <div className="text-lg font-semibold text-clay-900">Code Editor</div>
        </header>
        {selectedFilePath && (
          <div className="flex items-center px-3.5 py-2">
            {selectedFilePath.split('/').map((name, i, arr) => (
              <Fragment key={`${i}${name}`}>
                <span
                  className={twMerge(
                    'text-sm font-medium text-clay-350',
                    i === arr.length - 1 && 'text-clay-900',
                  )}
                >
                  {name}
                </span>
                {i < arr.length - 1 && (
                  <Icon className="size-5 -rotate-90 text-clay-350" name="arrowDownSm" safeArea="0" />
                )}
              </Fragment>
            ))}
          </div>
        )}
        <FileEditor file={selectedFile} />
      </div>
      <div className="flex w-full max-w-lg flex-col">
        <header className="flex items-center gap-3 border-b border-clay-20 px-3.5 py-4">
          <Icon className=" size-5" name="wandSparkles" safeArea="0" />
          <div className="text-lg font-semibold text-clay-900">Terminal</div>
        </header>
        <TerminalUI />
      </div>
    </div>
  );
};

import { useNavigate } from 'react-router-dom';

type Props = {
  tags: string[];
  title: string;
};
export const Tags = ({ tags, title }: Props) => {
  const navigate = useNavigate();

  if (tags.length === 0) return null;
  return (
    <>
      <div className="text-base text-clay-400">{title}</div>
      {/* <div className="flex flex-wrap items-center gap-2"> */}
      {tags.map((tag) => {
        return (
          <div
            className="cursor-pointer select-none rounded-lg bg-white px-2 py-1 text-sm text-clay-700 shadow-md"
            key={tag}
            onClick={() => {
              navigate(`/datasets?keywords=${tag}`);
            }}
          >
            {tag}
          </div>
        );
      })}
      {/* </div> */}
    </>
  );
};

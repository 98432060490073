import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { Dataset } from './types';

type Params = {
  datasetId: string;
  pathMap: Record<string, string>;
};

type Response = {
  data: Dataset;
};

export const useUpdateDatasetFileStructureMutation = (
  options: UseMutationOptions<Response, Error, Params> = {},
) => {
  return useMutation({
    mutationFn: async (params: Params) => {
      const { data } = await axiosInstance.post<Response>(`/datasets/update/fileMetadata`, params);

      return data;
    },

    ...options,
  });
};

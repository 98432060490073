import { useState } from 'react';

import { Card } from 'shared/ui/Card';

import { DeactivateModal } from './DeactivateModal';
import { SettingLine } from './SettingLine';

export const Account = () => {
  const [isDeactivateOpen, setIsDeactivateOpen] = useState(false);

  return (
    <>
      <Card className="flex flex-col xs:px-6 xs:pb-2">
        <SettingLine
          description="You are deactivating or Delete your account"
          middleSlot={
            <div className="flex w-full justify-start md:justify-end">
              <div
                className="cursor-pointer rounded-lg border border-red-900/15 bg-red-900/10 px-2 py-1.5 text-xs text-red-900 shadow-sm hover:bg-red-900/20"
                onClick={() => setIsDeactivateOpen(true)}
              >
                Deactivate
              </div>
            </div>
          }
          title="Deactivate Account"
        ></SettingLine>
      </Card>

      <DeactivateModal isOpen={isDeactivateOpen} onOpenChange={setIsDeactivateOpen} />
    </>
  );
};

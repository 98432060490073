import type { ProjectCategory } from 'pages/CreateDAI/types';

export const categories: { label: string; value: ProjectCategory }[] = [
  { label: 'DeSci', value: 'desci' },
  { label: 'DeFi', value: 'defi' },
  { label: 'Entertainment', value: 'entertainment' },
  { label: 'Gaming', value: 'gaming' },
  { label: 'Infrastructure', value: 'infrastructure' },
  { label: 'Productivity', value: 'productivity' },
  { label: 'Social', value: 'social' },
  { label: 'Commercial', value: 'commercial' },
];

type CategoriesMap = Record<ProjectCategory, { label: string; value: ProjectCategory }>;

export const categoriesMap = categories.reduce<CategoriesMap>((acc, item) => {
  acc[item.value] = item;
  return acc;
}, {} as CategoriesMap);

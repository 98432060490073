import agentImg1 from './assets/1.png';
import agentImg2 from './assets/2.png';
import agentImg3 from './assets/3.png';
import agentImg4 from './assets/4.png';
import agentImg5 from './assets/5.png';
import agentImg6 from './assets/6.png';
import agentImg7 from './assets/7.png';
import agentImg8 from './assets/8.png';
import agentImg9 from './assets/9.png';
import agentImg10 from './assets/10.png';
import agentImg11 from './assets/11.png';
import agentImg12 from './assets/12.png';

export const topGrayCards = [
  {
    button: 'Private keys hidden',
    description: 'Nesa EE gives AI agents total blanket privacy',
    title: 'Nesa EE',
  },
  {
    button: 'Encrypted fingerprinting',
    description: 'Encrypted tracking and orchestration of task-agents',
    title: 'EE Orchestrator',
  },
];

export const mainCards = [
  {
    bg: '#A783F4',
    buttons: ['Price Tracking', 'DAO Decisions', 'Audit Executor', 'Web Building'],
    description: 'Goal based\nAgents',
    title: 'Most Used',
  },
  {
    bg: '#179E6C',
    buttons: ['Auto Gameplay', 'Digital Librarian', 'Data Prediction', 'Web3 Wiki'],
    description: 'Learning\nbased Agents',
    title: 'Favorites',
  },
  {
    bg: '#5496FF',
    buttons: ['Virtual Team', 'Code Protection', 'IoT Connection', 'Chain Surveillance'],
    description: 'Multi-agent\nSystems',
    title: 'New Noteworthy',
  },
];

export const agentsCards = [
  {
    description: 'An agent that streamlines voting, proposals, and consensus for DAO decision-making...',
    img: agentImg1,
    rating: 4.9,
    reviewCount: 124,
    title: 'DAO Decisions',
    type: 'GOAL BASED',
  },
  {
    description:
      'An agent that executes predictive modeling, data optimization, and analytics for on-chain...',
    img: agentImg2,
    rating: 4.8,
    reviewCount: 68,
    title: 'Data Prediction',
    type: 'LEARNING BASED',
  },
  {
    description: 'An agent that builds, deploys, and manages web applications that are robust, scalable...',
    img: agentImg3,
    rating: 4.6,
    reviewCount: 82,
    title: 'Web Building',
    type: 'GOAL BASED',
  },
  {
    description: 'An agent that detects on-chain anomalies through real-time surveillance to prevent...',
    img: agentImg5,
    rating: 4.8,
    reviewCount: 132,
    title: 'Chain Surveillance',
    type: 'MULTI-AGENT',
  },
  {
    description: 'An agent that learns advanced gameplay and plays games on autopilot, transparently...',
    img: agentImg4,
    rating: 4.6,
    reviewCount: 193,
    title: 'Auto Gameplay',
    type: 'LEARNING BASED',
  },
  {
    description: 'An agent that instantiates virtual team members to execute team-based roles...',
    img: agentImg6,
    rating: 4.4,
    reviewCount: 104,
    title: 'Virtual Team',
    type: 'MULTI-AGENT',
  },
  {
    description: 'An agent that tracks cryptocurrency prices and makes predictions, leveraging real-time...',
    img: agentImg7,
    rating: 4.7,
    reviewCount: 376,
    title: 'Price Tracking',
    type: 'GOAL BASED',
  },
  {
    description: 'An agent that connects and secures IoT devices on any shared network, achieving...',
    img: agentImg8,
    rating: 4.4,
    reviewCount: 21,
    title: 'IoT Connection',
    type: 'MULTI-AGENT',
  },
  {
    description:
      'An agent that organizes, parses, searches, and delivers online historical data with insights...',
    img: agentImg9,
    rating: 4.2,
    reviewCount: 52,
    title: 'Digital Librarian',
    type: 'LEARNING BASED',
  },
  {
    description: 'An agent that protects smart contracts, identifies vulnerabilities, and ensures...',
    img: agentImg10,
    rating: 3.8,
    reviewCount: 231,
    title: 'Code Protection',
    type: 'MULTI-AGENT',
  },
  {
    description: 'An agent serving data from a comprehensive knowledge base with of web3 information...',
    img: agentImg11,
    rating: 4.1,
    reviewCount: 212,
    title: 'Web3 Wiki',
    type: 'LEARNING BASED',
  },
  {
    description: 'An agent with intelligent assessment tools to automate compliance checks, analyze...',
    img: agentImg12,
    rating: 4.2,
    reviewCount: 74,
    title: 'Audit Executor',
    type: 'GOAL BASED',
  },
];

import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { TradeType } from '@uniswap/sdk-core';
import { ArrowDownUp } from 'lucide-react';
import { type Address } from 'viem';
import { useAccount, useBalance, useChainId, useDisconnect, useSwitchChain } from 'wagmi';

import type { DAI } from 'shared/api/dai/types';

import { nesaEvmTestnet } from 'app/config/wagmiConfig';
import { useUser } from 'app/stores/user';
import { RHFInput } from 'features/InputCurrency/RHFInputCurrency';
import { LoginModal } from 'features/LoginModal';
import { SignupModal } from 'features/SignupModal';
import { WalletEvmProviderModal } from 'features/WalletEvmProviderModal';
import { useAllowanceQuery } from 'shared/api/wallet/useAllowanceQuery';
import { useApproveMutation } from 'shared/api/wallet/useApproveMutation';
import { useSwapMutation } from 'shared/api/wallet/useSwapMutation';
import { evmCoins } from 'shared/config/coins';
import { swapContract } from 'shared/config/contracts';
import { fromNesToUnes } from 'shared/helpers/fromNesToUnes';
import { fromUnesToNes } from 'shared/helpers/fromUnesToNes';
import { catchError } from 'shared/helpers/parseAxiosError';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import { useSwapQuote } from './hooks/useSwapQuote';
import { TradeHeader } from './ui/TradeHeader';

type Token = { address: `0x${string}`; decimals: number; symbol: string };
type FormValues = {
  inputAmount: string;
  inputToken: Token;
  outputAmount: string;
  outputToken: Token;
};

export const TradeForm = ({ daiToken }: { daiToken: DAI['token'] }) => {
  const { address, chainId: accountChainId } = useAccount();
  const chainId = useChainId();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const { slug = '' } = useParams();
  const { user } = useUser();

  const nativeBalanceQuery = useBalance({ address });
  const { isPending: isSwapping, mutate } = useSwapMutation();

  const { isPending: isSwitchingChain, switchChainAsync } = useSwitchChain();

  const [latestChangedToken, setLatestChangedToken] = useState<'tokenIn' | 'tokenOut'>('tokenIn');
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const isZeroEth = nativeBalanceQuery.data?.value === 0n;

  const swapContractAddress = swapContract[chainId as keyof typeof swapContract].address;

  const form = useForm<FormValues>({
    defaultValues: {
      inputToken: {
        address: evmCoins.nesaUSD[chainId].address,
        decimals: evmCoins.nesaUSD[chainId].decimals,
        symbol: evmCoins.nesaUSD[chainId].symbol,
      },
      outputToken: { address: daiToken?.creation?.address, decimals: 18, symbol: daiToken?.ticker },
    },
    mode: 'onChange',
  });

  const { control, getValues, setValue } = form;

  const inputToken = useWatch({ control, name: 'inputToken' });
  const inputAmount = useWatch({ control, name: 'inputAmount' });
  const outputToken = useWatch({ control, name: 'outputToken' });

  const inputBalanceQuery = useBalance({ address, chainId, token: inputToken.address });
  const outputBalanceQuery = useBalance({ address, chainId, token: outputToken.address });

  const { isPending: isApproving, mutateAsync: approve } = useApproveMutation();
  const { data: allowance } = useAllowanceQuery({
    contractAddress: swapContractAddress,
    currencyAddress: inputToken.address,
  });

  const { getQuote, isLoading } = useSwapQuote({
    poolAddress: daiToken?.creation?.lpAddress as Address,
    tokenIn: inputToken,
    tokenOut: outputToken,
  });

  const [isWalletProviderOpen, setIsWalletProviderOpen] = useState(false);

  const inputBalanceValue = inputBalanceQuery.data
    ? fromUnesToNes(inputBalanceQuery.data?.value.toString(), inputBalanceQuery.data.decimals).toNumber()
    : 0;

  const handleSwitch = () => {
    const currentValues = getValues();

    setValue('inputToken', currentValues.outputToken);
    setValue('inputAmount', currentValues.outputAmount);

    setValue('outputToken', currentValues.inputToken);
    setValue('outputAmount', currentValues.inputAmount);
  };

  const isChainInvalid = accountChainId !== nesaEvmTestnet.id;

  const handleSwap = () => {
    mutate(
      {
        amount: fromNesToUnes(inputAmount, inputToken.decimals).toFixed(),
        poolAddress: daiToken?.creation?.lpAddress as Address,
        tokenIn: inputToken,
        tokenOut: outputToken,
      },
      {
        onSuccess: () => {
          toaster.success('Swap was successful');
          setValue('inputAmount', '');
          setValue('outputAmount', '');

          inputBalanceQuery.refetch();
          outputBalanceQuery.refetch();
        },
      },
    );
  };

  const handleApprove = async () => {
    try {
      approve({
        contractAddress: swapContractAddress,
        currencyAddress: inputToken?.address,
      });
    } catch (e) {
      catchError(e);
    }
  };

  const handleOutputChange = async (value: string) => {
    if (!inputBalanceQuery.data?.decimals) return;

    if (!value) {
      setValue('outputAmount', '');

      return;
    }

    setLatestChangedToken('tokenOut');

    try {
      const res = await getQuote(
        fromNesToUnes(value, outputToken?.decimals).toFixed(),
        TradeType.EXACT_OUTPUT,
      );

      if (!res) {
        setValue('outputAmount', '');
        return;
      }

      const formatted = fromUnesToNes(res.toString(), inputToken.decimals);

      setValue('inputAmount', formatted.toString() || '');
    } catch (e) {
      setValue('inputAmount', '');
    }
  };

  const handleInputChange = async (value: string) => {
    if (!value) {
      setValue('outputAmount', '');

      return;
    }

    setLatestChangedToken('tokenIn');

    try {
      const res = await getQuote(fromNesToUnes(value, inputToken.decimals).toFixed());

      if (!res) {
        setValue('outputAmount', '');
        return;
      }

      setValue('outputAmount', res ? fromUnesToNes(res.toString(), outputToken.decimals).toString() : '');
    } catch (e) {
      setValue('outputAmount', '');
    }
  };

  return (
    <>
      <TradeHeader
        onClaimSuccess={() => {
          inputBalanceQuery.refetch();
          outputBalanceQuery.refetch();
        }}
      />
      <FormProvider {...form}>
        <div className="relative flex flex-col gap-1">
          <div className="rounded-lg bg-clay-10">
            <div className="flex items-center justify-between px-5 pt-4">
              <div className="flex items-center gap-2 text-xs font-normal text-clay-380">
                From:{' '}
                <div className="flex items-center gap-1 font-semibold text-clay-800">
                  <div className="box-border flex size-4 items-center justify-center rounded-full bg-primary-800">
                    <Icon className="box-border size-2 p-0 text-white" name="logo" />
                  </div>
                  Nesa EVM
                </div>
              </div>
              {address && (
                <div className="text-xs uppercase text-clay-380">
                  Balance:{' '}
                  {inputBalanceQuery.data?.value !== undefined
                    ? fromUnesToNes(
                        inputBalanceQuery.data?.value?.toString(),
                        inputBalanceQuery.data?.decimals,
                      )
                        .toNumber()
                        .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                    : '-'}
                </div>
              )}
            </div>
            <RHFInput
              control={control}
              decimals={6}
              disabled={isApproving || isSwapping || !address}
              name="inputAmount"
              onChange={handleInputChange}
              rules={{
                required: true,
              }}
              slot={
                <span className="flex items-center gap-3 text-2xl font-semibold">
                  {inputToken?.symbol}{' '}
                  {isLoading && latestChangedToken === 'tokenOut' && <Spinner className="size-4" />}
                </span>
              }
            />
          </div>
          <div className="rounded-lg bg-clay-10">
            <div className="flex items-center justify-between px-5 pt-4">
              <div className="flex items-center gap-2 text-xs font-normal text-clay-380">
                To:{' '}
                <div className="flex items-center gap-1 font-semibold text-clay-800">
                  <div className="box-border flex size-4 items-center justify-center rounded-full bg-primary-800">
                    <Icon className="box-border size-2 p-0 text-white" name="logo" />
                  </div>
                  Nesa EVM
                </div>
              </div>
              {address && (
                <div className="text-xs uppercase text-clay-380">
                  Balance:{' '}
                  {outputBalanceQuery.data?.value !== undefined
                    ? fromUnesToNes(
                        outputBalanceQuery.data?.value.toString(),
                        outputBalanceQuery.data.decimals,
                      )
                        .toNumber()
                        .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                    : '-'}
                </div>
              )}
            </div>
            <RHFInput
              control={control}
              decimals={6}
              disabled={isApproving || isSwapping || !address}
              name="outputAmount"
              onChange={handleOutputChange}
              rules={{ required: true }}
              slot={
                <span className="flex items-center gap-3 text-2xl font-semibold uppercase">
                  {outputToken?.symbol}{' '}
                  {isLoading && latestChangedToken === 'tokenIn' && <Spinner className="size-4" />}
                </span>
              }
            />
          </div>

          <div
            className="absolute left-1/2 top-1/2 flex size-8 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white transition-colors hover:bg-clay-20"
            onClick={handleSwitch}
          >
            <ArrowDownUp className="size-4 text-clay-800" />
          </div>
        </div>
      </FormProvider>
      {address ? (
        <>
          {isChainInvalid ? (
            <Button
              className="mt-4 w-full text-base font-medium"
              isLoading={isSwitchingChain}
              onClick={() => {
                switchChainAsync({ chainId: nesaEvmTestnet.id });
              }}
              size="medium"
            >
              Switch chain
            </Button>
          ) : (
            <Button
              className="mt-4 w-full text-base font-medium"
              disabled={inputBalanceValue < Number(inputAmount) || (!!allowance && !inputAmount) || isZeroEth}
              isLoading={isApproving || isSwapping}
              onClick={!allowance ? handleApprove : handleSwap}
              size="medium"
            >
              {isZeroEth ? 'You need testnet ETH' : !allowance ? 'Approve' : 'Swap'}
            </Button>
          )}
          <Button
            className="mb-4 mt-2 w-full text-base font-medium"
            color="secondary"
            onClick={() => disconnect()}
            size="medium"
            variant="filled-light"
          >
            Disconnect EVM wallet
          </Button>
        </>
      ) : (
        <Button
          className="my-4 w-full text-base font-medium"
          onClick={() => {
            if (!user) {
              setIsLoginModalOpen(true);
              return;
            }

            setIsWalletProviderOpen(true);
          }}
          size="medium"
          variant="filled-light"
        >
          {!user ? 'Swap' : 'Connect EVM wallet'}
        </Button>
      )}

      <WalletEvmProviderModal onOpenChange={setIsWalletProviderOpen} open={isWalletProviderOpen} />

      <SignupModal
        isOpen={isSignupModalOpen}
        onLoginClick={() => {
          setIsSignupModalOpen(false);
          setIsLoginModalOpen(true);
        }}
        onOpenChange={setIsSignupModalOpen}
      />
      <LoginModal
        isOpen={isLoginModalOpen}
        onLogin={() => {
          navigate(`/dai/${slug}`);
        }}
        onOpenChange={setIsLoginModalOpen}
        onSignupClick={() => {
          setIsLoginModalOpen(false);
          setIsSignupModalOpen(true);
        }}
      />
    </>
  );
};

import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { User } from './types';

type UploadImageParams = {
  file: File;
  userId: string;
};

type Response = {
  data: User;
  imageUrl: string;
};
export const useAvatarUploadMutation = (
  options: UseMutationOptions<Response, Error, UploadImageParams> = {},
) => {
  return useMutation({
    mutationFn: async ({ file, userId }: UploadImageParams) => {
      const formData = new FormData();

      formData.append('file', file);

      const { data } = await axiosInstance.post<Response>(`/users/${userId}/upload-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 5 * 60 * 1000,
      });

      return data;
    },
    ...options,
  });
};

import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { DateTime } from 'luxon';

import type { HuggingfacePost as HuggingfacePostType } from 'shared/api/huggingface';

import { BackButton } from 'app/ui/BackButton';
import { useHuggingfacePostsQuery } from 'shared/api/huggingface';
import { type Post as PostType, useActiveAuthorsQuery } from 'shared/api/posts';
import { usePostListQuery } from 'shared/api/posts/usePostListQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import huggingFaceIconSrc from 'shared/ui/Icon/svg/huggingface.svg';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';

import { CreatePostModal } from './ui/CreatePostModal';
import { HuggingfacePost } from './ui/HuggingfacePost';
import { NesaAvatar } from './ui/NesaAvatar';
import { NesaPost } from './ui/NesaPost';

export const Posts = () => {
  const [isOpenCreatePostModal, setIsCreatePostModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const sort = searchParams.get('sort') || 'trending';

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const { data: activeAuthors } = useActiveAuthorsQuery();

  const {
    data: huggingfacePostsData,
    fetchNextPage: fetchNextPageHuggingfacePosts,
    hasNextPage: hasNextPageHuggingfacePosts,
    isFetchingNextPage: isFetchingNextPageHuggingfacePosts,
    isPending: isPendingHuggingfacePosts,
  } = useHuggingfacePostsQuery({
    sort,
  });

  const {
    data: nesaPostsData,
    fetchNextPage: fetchNextPageNesaPosts,
    hasNextPage: hasNextPageNesaPosts,
    isFetchingNextPage: isFetchingNextPageNesaPosts,
    isPending: isPendingNesaPosts,
  } = usePostListQuery();

  const isPending = isPendingHuggingfacePosts || isPendingNesaPosts;
  const isFetchingNextPage = isFetchingNextPageHuggingfacePosts || isFetchingNextPageNesaPosts;

  const huggingfaceAuthors = useMemo(() => {
    if (!huggingfacePostsData || !activeAuthors || !huggingfacePostsData?.pages[0].length) return [];
    return huggingfacePostsData.pages[0].map((el) => el.author).slice(0, 10 - activeAuthors.length);
  }, [huggingfacePostsData, activeAuthors]);

  const posts = useMemo(() => {
    const huggingfacePosts = huggingfacePostsData?.pages.flat();
    const nesaPosts = nesaPostsData?.pages.flat();
    const max = Math.max(huggingfacePosts?.length || 0, nesaPosts?.length || 0);

    const posts: ({ post: HuggingfacePostType; type: 'huggingface' } | { post: PostType; type: 'nesa' })[] =
      [];

    for (let i = 0; i < max; i++) {
      if (nesaPosts?.[i]) {
        posts.push({ post: nesaPosts[i], type: 'nesa' });
      }
      if (huggingfacePosts?.[i]) {
        posts.push({ post: huggingfacePosts[i], type: 'huggingface' });
      }
    }
    posts.sort((a, b) => {
      const ad =
        typeof a.post.publishedAt === 'string'
          ? DateTime.fromISO(a.post.publishedAt).toMillis()
          : a.post.publishedAt;
      const bd =
        typeof b.post.publishedAt === 'string'
          ? DateTime.fromISO(b.post.publishedAt).toMillis()
          : b.post.publishedAt;

      return bd - ad;
    });
    return posts;
  }, [huggingfacePostsData, nesaPostsData]);

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPageHuggingfacePosts) {
      fetchNextPageHuggingfacePosts();
    }
  }, [fetchNextPageHuggingfacePosts, hasNextPageHuggingfacePosts, intersection?.isIntersecting]);

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPageNesaPosts) {
      fetchNextPageNesaPosts();
    }
  }, [fetchNextPageNesaPosts, hasNextPageNesaPosts, intersection?.isIntersecting]);

  return (
    <AnimateRoute className="mt-9 flex h-full overflow-hidden">
      <div className="hidden flex-[4] px-6 lg:block xl:px-9">
        <h4 className="text-xl/6 font-semibold text-clay-900">Active Users</h4>
        {activeAuthors && (
          <div className="mt-5 flex flex-col gap-4">
            {activeAuthors.map((user) => (
              <div className="flex items-center gap-3" key={user._id}>
                <NesaAvatar className="size-7 min-w-7" user={user} />
                <div className="text-base font-medium text-clay-500">
                  {user.first_name} {user.last_name}
                </div>
              </div>
            ))}
            {huggingfaceAuthors.map((el) => (
              <div className="flex items-center gap-3" key={el._id}>
                <img
                  alt=""
                  className="size-7 min-w-7 rounded-full"
                  src={el.avatarUrl.startsWith('http') ? el.avatarUrl : huggingFaceIconSrc}
                />
                <div className="text-base font-medium text-clay-500">{el.fullname || el.name}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex h-full flex-1 flex-col overflow-hidden px-6 lg:flex-[16] lg:pl-0 xl:pr-0 3xl:flex-[12]">
        <div className="mb-6 flex flex-wrap justify-between gap-4">
          <BackButton
            className="gap-3 text-balance text-2xl font-semibold text-clay-900 hover:text-clay-900"
            onClick={() => navigate(-1)}
          >
            Latest posts in the world of AI
          </BackButton>
          <div className="box-border flex h-12 items-center gap-1 rounded-xl bg-blue-50 p-1">
            <Select
              className="h-10 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
              iconClassName="mr-1"
              iconName="arrowDownUp"
              onValueChange={(value) =>
                setSearchParams((prev) => {
                  const newSearch = new URLSearchParams(prev);
                  newSearch.set('sort', value);
                  return newSearch;
                })
              }
              placeholder="Sort by"
              placeholderClassName="font-medium text-clay-600"
              value={sort}
            >
              <Select.Content className="z-10 p-1">
                <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                  Sort by
                </div>

                {[
                  { label: 'Trending', value: 'trending' },
                  { label: 'Newest', value: 'recent' },
                ].map((item) => {
                  return (
                    <Select.Item
                      className="text-sm text-clay-700 hover:bg-blue-50"
                      indicatorClassName="size-3 text-clay-700"
                      key={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
            <Button onClick={() => setIsCreatePostModal(true)}>
              <Icon className="size-3.5" name="plus" />
              New Post
            </Button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {posts.length > 0 && (
            <>
              <div className="flex flex-col gap-2.5">
                {posts.map((data) =>
                  data.type === 'huggingface' ? (
                    <HuggingfacePost key={data.post.slug} post={data.post} />
                  ) : (
                    <NesaPost key={data.post._id} post={data.post} />
                  ),
                )}
              </div>
              <div className="-mt-4" ref={ref} />
            </>
          )}
          {(isPending || isFetchingNextPage) && (
            <div className="flex justify-center pb-8 pt-12">
              <Spinner className="size-6" />
            </div>
          )}
        </div>
      </div>

      <div className="hidden flex-[4] px-6 xl:block xl:px-9 3xl:flex-[6]"></div>

      <CreatePostModal isOpen={isOpenCreatePostModal} onOpenChange={setIsCreatePostModal} />
    </AnimateRoute>
  );
};

import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Boxes, FileBox } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { UserDAIList } from 'features/UserDAIList';
import { UserDatasetsList } from 'features/UserDatasetsList';
import { UserModelList } from 'features/UserModelList/UserModelList';
import { UserNodeList } from 'features/UserNodeList';
import { useGetQueryHistoryByUserQuery } from 'shared/api/queryHistory/useGetQueryHistoryByUserQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Icon, isIconName } from 'shared/ui/Icon';

type ProfileTabs = 'dai' | 'datasets' | 'models' | 'nodes';
const tabs: {
  icon:
    | FunctionComponent<
        {
          title?: string | undefined;
        } & React.SVGProps<SVGSVGElement>
      >
    | IconName;
  iconClassName?: string;
  label: string;
  value: ProfileTabs;
}[] = [
  { icon: 'customDai', iconClassName: 'size-[1.125rem]', label: 'My DAI', value: 'dai' },
  { icon: Boxes, iconClassName: 'size-4', label: 'My Models', value: 'models' },
  { icon: 'database', label: 'My Nodes', value: 'nodes' },
  {
    icon: FileBox,
    label: 'My Datasets',
    value: 'datasets',
  },
];

export const Profile = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const { data: queryHistoryData } = useGetQueryHistoryByUserQuery(
    { limit: 1, userId: user?._id || '' },
    { enabled: !!user?._id },
  );

  const achievements: { icon: IconName; label: string; value: string }[] = [
    { icon: 'crown', label: 'Rank', value: '--' },
    { icon: 'currency', label: 'No. of Queries', value: queryHistoryData?.total_count?.toString() || '-' },
    { icon: 'box', label: 'Models', value: '1' },
  ];

  const [selectedSection, setSelectedSection] = useState<ProfileTabs>('dai');

  const hasName = !!user?.first_name || !!user?.last_name;

  return (
    <AnimateRoute className="relative mx-auto flex w-full max-w-7xl flex-1 flex-col gap-4 px-4 py-10">
      <BackButton
        className="gap-3 text-2xl font-semibold text-clay-900 hover:text-clay-900"
        onClick={() => navigate(-1)}
      >
        Account
      </BackButton>
      <Card className="flex w-full flex-col items-start justify-between gap-4 !p-4 lg:flex-row lg:items-center lg:!p-6">
        <div className="flex gap-3">
          <div className="flex size-16 items-center justify-center rounded-full bg-primary-30 lg:size-24 lg:min-h-24 lg:min-w-24">
            <span className="pb-0.5 font-klapt text-3xl/none font-bold capitalize text-primary-800">
              {user?.first_name?.slice(0, 1) ?? (user?.email?.slice(0, 1) || '-')}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-xl font-normal text-clay-1000 lg:text-2xl 2xl:text-2xl">
              {hasName ? `${user?.first_name || ''} ${user?.last_name || ''}` : user?.email || ''}
            </h3>

            <div className="mb-3 flex items-center gap-2 text-sm font-light text-clay-500">
              <Icon className="size-4" name="envelope" />
              {user?.email}
            </div>

            <Button className="w-fit" color="white" onClick={() => navigate('/settings')} size="extra-small">
              Edit
            </Button>
          </div>
        </div>

        <div className="flex w-full gap-3 lg:w-auto">
          {achievements.map((el) => (
            <div
              className="flex min-w-20 flex-1 flex-col items-start rounded-xl bg-clay-10 p-3 sm:min-w-32 lg:flex-auto 2xl:py-2"
              key={el.label}
            >
              <Icon className="mb-4 size-5 text-primary-800" name={el.icon} />
              <h4 className="mb-1 text-xl font-bold leading-none text-clay-800 lg:text-2xl">
                {el.value.padStart(2, '0')}
              </h4>
              <p className="text-xs font-light leading-none text-clay-400 sm:text-sm">{el.label}</p>
            </div>
          ))}
        </div>
      </Card>

      <div className="flex w-fit items-center gap-1 rounded-xl bg-clay-20 p-1">
        {tabs.map(({ icon: ProfileIcon, iconClassName, label, value }) => {
          return (
            <div
              className={twMerge(
                'flex cursor-pointer items-center gap-1.5 rounded-lg bg-clay-10 px-3 py-2 text-sm font-normal text-clay-600 transition-colors hover:text-primary-800',
                selectedSection == value && 'bg-white font-medium text-clay-800 drop-shadow-smAll',
              )}
              key={value}
              onClick={() => setSelectedSection(value)}
            >
              {isIconName(ProfileIcon) ? (
                <Icon className={twMerge('size-3.5', iconClassName)} name={ProfileIcon} safeArea="0" />
              ) : (
                <ProfileIcon className={twMerge('size-3.5', iconClassName)} strokeWidth={1.5} />
              )}
              {label}
            </div>
          );
        })}
      </div>

      <div className="flex w-full flex-1 flex-col">
        {selectedSection === 'dai' && <UserDAIList />}
        {selectedSection === 'models' && <UserModelList />}
        {selectedSection === 'nodes' && <UserNodeList />}
        {selectedSection === 'datasets' && <UserDatasetsList />}
      </div>
      {/* </div> */}
    </AnimateRoute>
  );
};

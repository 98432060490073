import { useState } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ModelType } from 'shared/ui/Gallery/PreviewBanner/PreviewBanner';

import { filterItems } from 'shared/const';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

type Props = {
  currentTypes: ModelType[];
  onApply: (types: ModelType[]) => void;
};

export const CategoryModelsDropdown = ({ currentTypes, onApply }: Props) => {
  const [isMultiselect, setIsMultiselect] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<ModelType[]>([]);

  const shouldShowNumber = (currentTypes?.length || 0) >= 1;

  return (
    <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
      <Popover.Trigger asChild>
        <div
          className={twMerge(
            'flex h-9 w-fit cursor-pointer items-center rounded-lg bg-white pl-4 pr-3 text-sm font-semibold text-clay-600 inner-border-0 hover:bg-clay-10',
            isPopoverOpen && shouldShowNumber && 'bg-white text-primary-800 hover:bg-white',
            !isPopoverOpen && shouldShowNumber && 'bg-primary-800 text-white hover:bg-primary-900',
          )}
          onClick={() => setSelectedValues(currentTypes || [])}
        >
          {shouldShowNumber ? `${currentTypes.length} Selected` : 'All Models'}
          <motion.div
            animate={{ rotate: isPopoverOpen ? -180 : 0 }}
            initial={{ rotate: 0 }}
            transition={{ duration: 0.25 }}
          >
            <Icon
              className={twMerge(
                'size-6 p-0 text-clay-500',
                isPopoverOpen && shouldShowNumber && 'text-primary-800',
                !isPopoverOpen && shouldShowNumber && 'text-white',
              )}
              name="arrowDownSm"
            />
          </motion.div>
        </div>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        alignOffset={0}
        className="z-10 w-fit overflow-y-auto rounded-lg px-2 pb-2 pt-1 shadow-sm"
        sideOffset={10}
      >
        <div className="border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-clay-300">
          Select Multiple Model Types
        </div>
        <div className="mt-2 grid h-80 grid-cols-2 gap-1 overflow-x-auto lg:h-auto lg:grid-cols-3 xl:grid-cols-4">
          {filterItems.map((item) => {
            const isSelected = selectedValues?.includes(item.value);
            return (
              <div
                className={twMerge(
                  'group flex h-10 w-48 cursor-pointer flex-row items-center justify-start gap-1 rounded-md border border-clay-20 p-2.5 text-xs font-medium text-clay-700 transition-colors aria-selected:bg-clay-20 hover:bg-clay-20 xs:h-14 xs:w-64 xs:gap-2 xs:p-3 xs:text-sm',
                  isSelected && 'border-primary-50 bg-primary-30 hover:bg-primary-30',
                  item.isStroke && 'strokeOnHover',
                )}
                key={item.value}
                onClick={() => {
                  if (!isMultiselect) {
                    console.log('apply');
                    onApply(isSelected ? [] : [item.value]);
                    setIsPopoverOpen(false);
                    return;
                  }

                  const valuesToSet = isSelected
                    ? selectedValues.filter((value) => value !== item.value)
                    : [...selectedValues, item.value];

                  setSelectedValues(valuesToSet);
                }}
              >
                <div
                  className={twMerge(
                    'flex size-6 items-center justify-center rounded-lg bg-clay-10 p-0.5 group-hover:bg-white xs:size-9 xs:p-2',
                    isSelected && 'bg-white',
                  )}
                >
                  <Icon
                    className={twMerge(
                      'size-4 p-0 text-clay-350 group-hover:text-primary-800 xs:size-5',
                      isSelected && 'text-primary-800',
                      item.value === 'all' && 'size-3 xs:size-4',
                    )}
                    name={item.icon}
                    svgProps={{ fill: item.iconFill }}
                  />
                </div>
                {getCategoryLabel(item.value)}
              </div>
            );
          })}
        </div>

        <div className="mt-2 flex h-8 w-full justify-between">
          <Button
            className="pl-4 outline-0"
            color="secondary"
            onClick={() => {
              onApply([]);
              setIsMultiselect(false);
              // onFilterChange({ ...filters, type: [] });
              setIsPopoverOpen(false);
            }}
            size="extra-small"
            variant="filled-light"
          >
            <Icon className="size-3 text-blue-800" name="refresh" />
            <div className="mt-px text-xs font-medium text-blue-800">Reset all</div>
          </Button>

          <Button
            className={twMerge(
              'flex h-8 items-center justify-center gap-2 rounded-lg bg-primary-800 px-3 outline-0',
              !isMultiselect && 'gap-1 pl-2',
            )}
            onClick={() => {
              if (!isMultiselect) {
                setIsMultiselect(true);
                return;
              }

              onApply(selectedValues);

              if (selectedValues.includes('all') || !selectedValues.length) {
                setIsMultiselect(false);
              }
              setIsPopoverOpen(false);
            }}
            size="extra-small"
            variant="filled-light"
          >
            {isMultiselect ? (
              <>
                <Icon className="size-3 text-white" name="check" />
                <div className="text-xs font-medium text-white">Apply</div>
              </>
            ) : (
              <>
                <Icon className="size-4 text-white" name="grid" />
                <span className="text-white">Multiselect</span>
              </>
            )}
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  );
};

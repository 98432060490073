import { useState } from 'react';

import { useGetTopDAIsByMCAPStatsQuery, useGetTopDAIsByTVLStatsQuery } from 'shared/api/daiStats';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Spinner } from 'shared/ui/Spinner';

import { TopPerformingDAIRow } from './Row';
import { Title } from './Title';

export const TopPerformingDAI = () => {
  const lg = useMinWidthMediaQuery('xl');
  const xs = useMinWidthMediaQuery('xs');

  const [activeTab, setActiveTab] = useState<'MARKETCAP' | 'TVL'>('TVL');

  const { data: tvl, isPending: isPendingTVL } = useGetTopDAIsByTVLStatsQuery();
  const { data: mcap, isPending: isPendingMCAP } = useGetTopDAIsByMCAPStatsQuery();

  const list = ((activeTab === 'TVL' ? tvl : mcap) || []).slice(0, lg ? 5 : xs ? 7 : 5);
  const isLoading = isPendingTVL || isPendingMCAP;

  return (
    <div className="xs:max-w-screen-xl:max-h-[25rem] flex flex-1 grow flex-col gap-3 overflow-hidden rounded-xl border border-clay-20 p-4">
      <Title onTabChange={setActiveTab} tab={activeTab} />
      {!!list.length && !isLoading && (
        <div className="grid flex-1 grid-cols-[1fr_auto_auto] flex-col gap-3">
          {list?.map((item, index) => (
            <TopPerformingDAIRow
              imgSrc={item.dai.profileImage}
              index={index + 1}
              key={item.dai._id}
              showDecimals={activeTab === 'TVL'}
              slug={item.dai.slug}
              title={item.dai.projectName}
              value={item.total}
              volumeChangePrc={item.diff}
            />
          ))}
        </div>
      )}
      {!isLoading && !list.length && (
        <div className="flex flex-1 items-center justify-center py-5">
          <div className="text-base font-semibold text-clay-500">No Data</div>
        </div>
      )}
      {isLoading && (
        <div className="flex flex-1 items-center justify-center py-5">
          <Spinner className="size-5" />
        </div>
      )}
    </div>
  );
};

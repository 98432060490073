import { Editor, Node, Element as SlateElement, Transforms } from 'slate';

export type CustomText = {
  bold?: true;
  code?: boolean;
  italic?: boolean;
  text: string;
  underline?: boolean;
};

export type CustomTextLeaf = 'bold' | 'code' | 'italic' | 'underline';

type ListItem = {
  children: CustomText[];
  type: 'list-item';
};

export type CustomElement = {
  children: CustomText[] | ListItem[];
  type: 'bulleted-list' | 'h1' | 'h2' | 'numbered-list' | 'paragraph' | 'quote' | ListItem['type'];
};

export const LIST_TYPES = ['bulleted-list', 'numbered-list'];
export const BLOCK_TYPES = ['bulleted-list', 'h1', 'h2', 'numbered-list', 'paragraph'];

export const serialize = (nodes: Node[]) => {
  return nodes.map((n) => Node.string(n)).join('\n');
};

export const isBlockActive = (editor: Editor, format: CustomElement['type']) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    }),
  );

  return !!match;
};

export const toggleBlock = (editor: Editor, format: CustomElement['type']) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { children: [], type: format };
    Transforms.wrapNodes(editor, block);
  }
};

export const isMarkActive = (editor: Editor, format: CustomTextLeaf) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor: Editor, format: CustomTextLeaf) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

import { Link, useNavigate } from 'react-router-dom';

import { BackButton } from 'app/ui/BackButton';
import { UserNodeList } from 'features/UserNodeList';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';

import CpuIcon from './assets/cpu.svg?react';
import GpuIcon from './assets/gpu.svg?react';

const cards = [
  {
    docsUrl: 'https://docs.nesa.ai/nesa/run-a-nesa-node/prerequisites',
    icon: <GpuIcon />,
    id: 'run-gpu-node',
    label: 'Run a GPU node on Nesa',
  },
  {
    docsUrl: 'https://docs.nesa.ai/nesa/run-a-nesa-node/prerequisites',
    icon: <CpuIcon />,
    id: 'run-cpu-node',
    label: 'Run a CPU node on Nesa',
  },
];

export const Page = () => {
  const navigate = useNavigate();
  return (
    <AnimateRoute className="mx-auto flex w-full max-w-7xl flex-1 flex-col px-4 py-10">
      <BackButton
        className="mb-4 gap-3 text-2xl font-semibold text-clay-900 hover:text-clay-900"
        onClick={() => navigate('/')}
      >
        {' '}
        My Nodes
      </BackButton>

      <div className="mb-4 grid grid-cols-1 gap-2 md:grid-cols-2">
        {cards.map(({ docsUrl, icon, id, label }) => (
          <Card className="flex flex-col gap-3 !p-6 md:gap-4" key={id}>
            {icon}
            <h1 className="text-xl md:text-2xl">{label}</h1>

            <Button as={Link} className="w-fit" target="_blank" to={docsUrl}>
              Documentation
            </Button>
          </Card>
        ))}
      </div>

      <UserNodeList />
    </AnimateRoute>
  );
};

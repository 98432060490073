import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { catchError } from 'shared/helpers/parseAxiosError';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { type IconName } from 'shared/ui/Icon';
import { Sidebar } from 'shared/ui/Sidebar';
import { toaster } from 'shared/ui/Toast';

import { Account } from './ui/Account';
import { Billing } from './ui/Billing';
import { General } from './ui/General';
import { Security } from './ui/Security';

type ComponentProps = {
  handleSaveSetting: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

const settings: {
  component: React.ComponentType<ComponentProps>;
  icon: IconName;
  id: string;
  isStrokeIcon?: boolean;
  title: string;
}[] = [
  { component: General, icon: 'settings2', id: 'general', title: 'General' },
  { component: Security, icon: 'lock', id: 'security', title: 'Security' },
  { component: Billing, icon: 'enterprise', id: 'billing', isStrokeIcon: true, title: 'Billing & Plans' },
  { component: Account, icon: 'userCircle', id: 'account', title: 'Account' },
];

type FormValues = {
  discord: string;
  email: string;
  firstName: string;
  lastName: string;
};

export const Page = () => {
  const [searchParams] = useSearchParams();

  const settingType = searchParams.get('type') || 'general';

  const { updateUser, user } = useUser();

  const navigate = useNavigate();

  const methods = useForm<FormValues>({
    defaultValues: {
      email: user?.email || '',
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
    },
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    handleSubmit,
  } = methods;

  const handleSaveSetting = async (values: FormValues) => {
    try {
      if (!isValid) return;

      await updateUser({ first_name: values.firstName, last_name: values.lastName }, { sendRequest: true });

      toaster.success('Changes are saved');
    } catch (e) {
      catchError(e);
    }
  };

  const activeSection = settings.find((item) => item.id === settingType);

  return (
    <AnimateRoute className="mx-auto w-full max-w-6xl px-4 py-5 lg:py-10">
      <FormProvider {...methods}>
        <div className="flex w-full flex-col">
          <div>
            <BackButton
              className="gap-3 text-2xl font-semibold text-clay-900 hover:text-clay-900"
              onClick={() => navigate(-1)}
            >
              Settings
            </BackButton>
          </div>

          <div className="mt-6 flex flex-col items-stretch gap-3 lg:flex-row lg:items-start lg:gap-7">
            <Sidebar
              menuItems={settings.map((el) => ({
                icon: el.icon,
                isActive: el.id === settingType,
                isStrokeIcon: el.isStrokeIcon,
                label: el.title,
                to: `/settings?type=${el.id}`,
              }))}
            />

            <div className="flex w-full flex-1 flex-col gap-2">
              {activeSection && (
                <activeSection.component handleSaveSetting={handleSubmit(handleSaveSetting)} />
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    </AnimateRoute>
  );
};

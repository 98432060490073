import type { Id } from 'react-toastify';

import { type FormEvent, useRef } from 'react';
import { useController, useForm } from 'react-hook-form';

import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { twMerge } from 'tailwind-merge';

import { modelCollectionsQueryKeys, useCreateModelCollectionsQuery } from 'shared/api/model-collections';
import { filterItems } from 'shared/const';
import { getCategoryLabel } from 'shared/helpers/getCategoryLabel';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Modal } from 'shared/ui/Modal';
import { Select } from 'shared/ui/Select';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

type Props = {
  onCancel?: () => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

type FormValues = {
  description: string;
  icon: string;
  name: string;
};

export const CreateModelCollections = ({ onCancel, onOpenChange, open }: Props) => {
  const { isPending, mutateAsync } = useCreateModelCollectionsQuery();

  const queryClient = useQueryClient();

  const toastId = useRef<Id | undefined>(undefined);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: { description: '', icon: '', name: '' },
    mode: 'onChange',
  });

  const { field: iconFiled } = useController({ control, name: 'icon', rules: { required: true } });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) {
      toastId.current = toaster.error(
        'Please fill in the required fields',
        {},
        { toastId: toastId.current || undefined, updateId: toastId.current || undefined },
      );
      return;
    }
    try {
      const values = getValues();
      await mutateAsync({ ...values, models: [] });
      queryClient.invalidateQueries({
        queryKey: modelCollectionsQueryKeys.myCollections(),
      });
      reset();
      onOpenChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full !max-w-[460px]" innerClassName="sm:p-7" withAnimation={false}>
        <Modal.CloseButton className=" right-4 top-4" />
        <div className="mb-2 text-3xl font-semibold text-clay-900">Create a Collection</div>
        <p className="mb-4 text-base text-clay-500">Create your first collection, or create multiple ones.</p>

        <form className="flex flex-col gap-1" onSubmit={handleSubmit}>
          <Input
            errorSpacing
            {...register('name', { validate: (val) => (!val.trim() ? 'Required field' : true) })}
            disabled={isPending}
            error={errors.name?.message}
            label={
              <span>
                Collection Name <span className="text-red-900">*</span>
              </span>
            }
            placeholder="-"
          />
          <TextArea
            {...register('description', { validate: (val) => (!val.trim() ? 'Required field' : true) })}
            disabled={isPending}
            error={errors.description?.message}
            innerClassName="text-sm"
            label={
              <span className="text-xs/none font-normal text-clay-500 2xl:text-sm">
                Description <span className="text-red-900">*</span>
              </span>
            }
            placeholder="-"
          />
          <Select
            className="border border-clay-20 bg-white"
            label="Collection Icon"
            onValueChange={iconFiled.onChange}
            placeholder="-"
            value={iconFiled.value}
          >
            <Select.Content>
              {filterItems.map((item) => {
                if (item.value === 'all') return null;
                return (
                  <Select.Item key={item.value} value={item.value}>
                    <div className="flex items-center gap-2">
                      <Icon
                        className={twMerge('size-4 p-0 text-clay-350 group-hover:text-primary-800 xs:size-5')}
                        name={item.icon}
                        svgProps={{ fill: item.iconFill }}
                      />
                      <span>{getCategoryLabel(item.value)}</span>
                    </div>
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
          <p className="mt-1 text-xs text-clay-380">
            Apply an icon based on the models it cointains. Or Choose from model-category icons.
          </p>

          <div className=" mt-4 flex justify-start gap-2">
            <Button color="secondary" onClick={onCancel || (() => onOpenChange(false))}>
              Cancel
            </Button>
            <Button type="submit">Create</Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

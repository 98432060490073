import { Rotate3D } from 'lucide-react';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import { getFormattedNumber } from 'pages/Datasets/helpers/getFormattedNumber'; // after feat/DAI-stuff move to entities/?
import { modalities } from 'pages/Datasets/ui/MainFiltersSection';
import { ContentLoader } from 'shared/ui/ContentLoader';
import { Icon } from 'shared/ui/Icon'; // after feat/DAI-stuff move to entities/datasets
import { useMemo } from 'react';

import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  className?: string;
  downloads?: number;
  isLoading?: boolean;
  likes?: number;
  modalities?: string[];
  onClick?: () => void;
  rows?: number;
  tags?: string[];
  timestamp?: number;
  title?: string;
};
export const DatasetCard = ({
  className,
  downloads,
  isLoading,
  likes,
  modalities: itemModalities = [],
  onClick,
  rows,
  tags,
  timestamp,
  title,
}: Props) => {
  const modalityInfo = useMemo(() => {
    const set = new Set(itemModalities.map((el) => el.toLocaleLowerCase()));
    return modalities.find(({ id }) => set.has(id.toLocaleLowerCase()));
  }, [itemModalities]);

  let replacedTitle = title?.replace(/huggingface/i, '');
  if (replacedTitle?.startsWith('/')) {
    replacedTitle = replacedTitle.slice(1);
  }
  return (
    <div
      className={twMerge(
        'flex cursor-pointer gap-4 overflow-hidden rounded-xl border border-clay-20 p-4 transition-colors hover:border-clay-40',
        className,
      )}
      onClick={onClick}
    >
      <div className="relative flex size-8 min-w-8 items-center justify-center overflow-hidden rounded-lg bg-clay-20">
        {isLoading ? (
          <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
            <rect height="100%" width="100%" x="0" y="0" />
          </ContentLoader>
        ) : (
          <>
            {modalityInfo?.icon ? (
              <Icon
                className={twMerge(
                  'size-3.5 text-clay-400',
                  modalityInfo?.isStroke && 'stroke-clay-400 stroke-[1.6px] text-transparent',
                )}
                name={modalityInfo?.icon || 'rotate'}
              />
            ) : (
              <Rotate3D className="size-4 text-clay-400" />
            )}
          </>
        )}
      </div>
      <div className="flex flex-1 flex-col gap-1 overflow-hidden">
        <div className="relative flex h-7 items-center justify-between gap-1 truncate text-lg font-semibold">
          {replacedTitle && <span className="w-full flex-1 truncate">{replacedTitle}</span>}
          {isLoading && (
            <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="16" width="100%">
              <rect height="16" rx={6} ry={6} width="100%" x="0" y="0" />
            </ContentLoader>
          )}
          {!!tags?.length && (
            <Tooltip
              content={
                tags.includes("nesa's pick") ? "Nesa's Pick" : tags.includes('featured') ? 'Featured' : null
              }
              side="top"
            >
              <div
                className={twMerge(
                  'flex size-7 min-w-7 items-center justify-center rounded-md',
                  tags.includes("nesa's pick") && 'border-primary-150 bg-primary-30 text-primary-800',
                  tags.includes('featured') && 'border-tusk-200 bg-green-100/40 text-green-500',
                )}
              >
                {tags.includes("nesa's pick") && <span className="text-xs font-semibold">N</span>}
                {tags.includes('featured') && <Icon className="-mt-px size-4" name="star" />}
              </div>
            </Tooltip>
          )}
        </div>
        {isLoading ? (
          <div className="relative h-4">
            <ContentLoader className="absolute left-0 top-1/2 -translate-y-1/2" height="12" width="100%">
              <rect height="12" rx={4} ry={4} width="100%" x="0" y="0" />
            </ContentLoader>
          </div>
        ) : (
          <div className="flex flex-wrap items-center gap-2.5">
            <div className="flex items-center gap-1.5">
              <Icon className="size-3.5 text-clay-350" name="calendar" safeArea="0" />
              <span className="mt-0.5 inline-block h-3 text-xs/none text-clay-700">
                Updated {timestamp ? DateTime.fromMillis(timestamp).toFormat('MMM dd') : ''}
              </span>
            </div>
            <div className="text-xs text-clay-30">|</div>
            {rows !== undefined && (
              <>
                <div className="flex items-center gap-1.5 text-xs">
                  <Icon className="size-3.5 text-clay-350" name="layerDouble" safeArea="0" />
                  <span className="mt-0.5 text-xs/none text-clay-700">
                    {rows && getFormattedNumber(rows)}
                  </span>
                </div>
                <div className="text-xs text-clay-30">|</div>
              </>
            )}
            {downloads !== undefined && (
              <>
                {' '}
                <div className="flex items-center gap-1.5 text-xs">
                  <Icon className="size-3.5 text-clay-350" name="download" />
                  <span className="mt-0.5 text-xs/none text-clay-700">
                    {downloads && getFormattedNumber(downloads)}
                  </span>
                </div>
                {likes !== undefined && <div className="text-xs text-clay-30">|</div>}{' '}
              </>
            )}
            {likes !== undefined && (
              <div className="flex items-center gap-1.5 text-xs">
                <Icon
                  className="size-3.5 stroke-clay-350 stroke-[1.25px] text-transparent"
                  name="heart"
                  safeArea="0"
                />
                <span className="mt-0.5 text-xs/none text-clay-700">
                  {likes && getFormattedNumber(likes)}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

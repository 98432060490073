import type { Descendant } from 'slate';
import type { ReactEditor } from 'slate-react';

import type { ChangeEvent } from 'react';
import { useMemo, useRef } from 'react';

import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { serialize } from './libs';
import { BlockSelect } from './ui/BlockSelect';
import { InlineButton } from './ui/InlineButton';
import { renderElement, renderLeaf } from './ui/render';

type Props = {
  onChange: (val: Descendant[]) => void;
  onFileChange: (files: File[]) => void;
  value?: Descendant[];
};
export const TextEditor = ({ onChange, onFileChange, value = [] }: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const files = fileList ? [...fileList] : [];

    if (files.length) {
      onFileChange(files);
    }
  };

  return (
    <div className=" overflow-hidden rounded-xl border border-clay-20">
      <Slate editor={editor} initialValue={value} onChange={onChange}>
        <div className="flex items-center gap-6 px-3.5 py-2.5">
          <BlockSelect />
          <div className="flex gap-2">
            <InlineButton icon="underline" type="underline" />
            <InlineButton icon="bold" type="bold" />
            <InlineButton icon="italic" type="italic" />
            <InlineButton icon="code" type="code" />
          </div>
          <div className="flex flex-1 justify-end">
            <div
              className="size-5 cursor-pointer text-clay-500 duration-200 hover:text-clay-900"
              onClick={() => fileRef.current?.click()}
            >
              <Icon className="size-5" name="imagePlus" />
              <input
                accept=".jpeg,.png,.svg,.jpg"
                className="hidden"
                multiple
                onChange={handleFileChange}
                ref={fileRef}
                type="file"
              />
            </div>
          </div>
        </div>
        <div className="px-6 pb-5">
          <Editable className="outline-none" renderElement={renderElement} renderLeaf={renderLeaf} />
        </div>
      </Slate>
    </div>
  );
};

type CommentEditorProps = {
  isLoading: boolean;
  onSend: (editor: ReactEditor) => void;
} & Props;

export const CommentEditor = ({ isLoading, onChange, onSend, value = [] }: CommentEditorProps) => {
  // const fileRef = useRef<HTMLInputElement>(null);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  // const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const fileList = e.target.files;
  //   const files = fileList ? [...fileList] : [];

  //   if (files.length) {
  //     onFileChange(files);
  //   }
  // };

  return (
    <div className="overflow-hidden rounded-xl bg-clay-10 px-5 py-4">
      <Slate editor={editor} initialValue={value} onChange={onChange}>
        <Editable
          className="outline-none"
          placeholder="Add Comment"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
        <div className="mt-5 flex items-center gap-6">
          <InlineButton icon="underline" type="underline" />
          <InlineButton icon="bold" type="bold" />
          <InlineButton icon="italic" type="italic" />
          <InlineButton icon="code" type="code" />
          {/* <div
            className="size-5 cursor-pointer text-clay-500 duration-200 hover:text-clay-900"
            onClick={() => fileRef.current?.click()}
          >
            <Icon className="size-5" name="imagePlus" />
            <input
              accept=".jpeg,.png,.svg,.jpg"
              className="hidden"
              multiple
              onChange={handleFileChange}
              ref={fileRef}
              type="file"
            />
          </div> */}
          <div className="flex flex-1 justify-end">
            <Button
              disabled={!serialize(value).trim() || isLoading}
              isLoading={isLoading}
              onClick={() => onSend(editor)}
              size="small"
            >
              Send
            </Button>
          </div>
        </div>
      </Slate>
    </div>
  );
};

type PreviewSlateProps = {
  value: Descendant[];
};

export const PreviewSlate = ({ value }: PreviewSlateProps) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <Slate editor={editor} initialValue={value}>
      <Editable className="outline-none" readOnly renderElement={renderElement} renderLeaf={renderLeaf} />
    </Slate>
  );
};

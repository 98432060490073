export const getFormattedNumber = (balance: number, maximumFractionDigits?: number) => {
  if (balance >= 1000000000) {
    return `${(balance / 1000000000).toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 0 })}B`;
  }
  if (balance >= 1000000) {
    return `${(balance / 1000000).toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 0 })}M`;
  }
  if (balance >= 1000) {
    return `${(balance / 1000).toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 0 })}K`;
  }

  return balance.toLocaleString(undefined, { maximumFractionDigits });
};

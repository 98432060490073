import { DaiTab } from '../DaiTab';
import { DatasetTab } from '../DatasetTab';
import { ModelsTab } from '../ModelsTab';
import { StakersTab } from '../StakersTab';

export const TopListTab = () => {
  return (
    <div className="grid grid-cols-1 gap-2">
      <ModelsTab short />
      <DaiTab short />
      <DatasetTab short />
      <StakersTab short />
    </div>
  );
};

type Props = {
  locales?: Intl.LocalesArgument;
  tvlNes?: number | string;
};

export const getTvl = ({ locales = undefined, tvlNes }: Props) => {
  if (!tvlNes) return '0';
  const tvlNumber = Number(tvlNes);

  if (Number.isNaN(tvlNumber)) return '0';

  return (tvlNumber / 0.72).toLocaleString(locales, { maximumFractionDigits: 2, minimumFractionDigits: 0 });
};

import type { Country, Props as ReactPhoneInputProps, Value } from 'react-phone-number-input';

import type { ComponentProps } from 'react';
import { useState } from 'react';
import ReactPhoneInput from 'react-phone-number-input';

import { twMerge } from 'tailwind-merge';

import { renderInputClassName } from '../Input';
import { CountrySelect } from './ui/CountrySelect';

interface Props {
  className?: string;
  classNameInputWrapper?: string;
  error?: string;
  errorSpacing?: boolean;
  onBlur?: ReactPhoneInputProps<HTMLInputElement>['onBlur'];
  onChange: (value?: Value) => void;
  placeholder?: string;
  value?: string;
}

export const PhoneInput = ({
  className,
  classNameInputWrapper,
  error,
  errorSpacing,
  placeholder = 'Enter your mobile number',
  ...props
}: Props) => {
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>('US');

  return (
    <div className={twMerge('flex flex-col gap-1', errorSpacing && 'pb-4', className)}>
      <ReactPhoneInput
        className={twMerge(
          renderInputClassName({ classNameInputWrapper, error }),
          'items-baseline overflow-hidden pl-0',
          className,
        )}
        countrySelectComponent={CountrySelect}
        defaultCountry={selectedCountry}
        inputComponent={CustomInput}
        onCountryChange={setSelectedCountry}
        placeholder={placeholder}
        {...props}
      />
      {error && <div className="text-[10px] leading-none text-pink-500">{error}</div>}
    </div>
  );
};

const CustomInput = (props: ComponentProps<'input'>) => {
  return (
    <input
      {...props}
      className="h-full w-inherit bg-white/0 text-clay-900 placeholder:font-light placeholder:leading-none placeholder:text-clay-350 focus:outline-none"
    />
  );
};

import type { MouseEvent } from 'react';

type Props = {
  count: number;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  reaction: string;
  selected: boolean;
};

export const Reaction = ({ count, onClick, reaction, selected }: Props) => {
  return (
    <div
      aria-selected={selected}
      className="flex items-center gap-2 rounded-full border border-clay-20 bg-white px-2.5 py-1.5 duration-200 aria-selected:bg-clay-20 hover:border-clay-40"
      onClick={onClick}
    >
      <div className="pt-0.5 text-xs/none">{reaction}</div>
      <div className="text-sm font-medium text-clay-500">{count}</div>
    </div>
  );
};

import { Card } from 'shared/ui/Card';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';

export const LoadingCard = () => {
  return (
    <Card className="relative flex h-52 w-full flex-col gap-2 bg-white">
      <div className="relative flex h-10 w-full items-center justify-between border-b border-clay-20">
        <StretchedSkeleton className="relative h-5 max-w-32" enable />
        <div className="relative flex items-center gap-4">
          <StretchedSkeleton className="relative h-5 max-w-32" enable />
          <StretchedSkeleton className="relative h-5 max-w-32" enable />
          <StretchedSkeleton className="relative h-5 max-w-12" enable />
        </div>
      </div>

      <StretchedSkeleton className="relative h-20" enable />
    </Card>
  );
};

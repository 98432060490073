import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { LiveEvent } from './types';

import { daiKeys } from './queryKeys';

type Response = {
  list: LiveEvent[];
};

type Params = {
  includeDev?: boolean;
  limit: number;
  skip?: number;
};

export const useGetActivityLatestQuery = (params: Params, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<Response>('/dai/activity/latest', {
        params: {
          ...params,
          includeDev: params.includeDev ?? import.meta.env.MODE === 'development',
        },
      });

      return data;
    },
    queryKey: daiKeys.latestActivity(params),
    ...options,
  });
};

import { AnimateRoute } from 'shared/ui/AnimateRoute';

import { FindModel } from './ui/FindModel';
import { TrainedModels } from './ui/TrainedModels';

export const Training = () => {
  return (
    <AnimateRoute className="mx-auto w-full max-w-7xl px-4 pb-10 pt-8">
      <div className="flex flex-col gap-5">
        <FindModel />
        <TrainedModels />
      </div>
    </AnimateRoute>
  );
};

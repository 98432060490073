import type { MouseEvent } from 'react';

import type { SidebarMenuItem } from 'shared/ui/Sidebar';

import { Sidebar as SidebarUI } from 'shared/ui/Sidebar';
import { toaster } from 'shared/ui/Toast';

const menuItems: SidebarMenuItem[] = [
  {
    icon: 'unplug',
    label: 'Connections',
    to: '/privilege/faucet',
  },
  {
    icon: 'coins',
    label: 'Claim Token',
    onClick: (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      toaster.info('Coming Soon');
    },
    to: '/privilege/claim-tokens',
  },
  {
    icon: 'gift',
    label: 'Giveway',
    onClick: (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      toaster.info('Coming Soon');
    },
    to: '/privilege/giveway',
  },
] as const;

export const Sidebar = () => {
  return <SidebarUI menuItems={menuItems} />;
};

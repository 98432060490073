import { type ComponentProps, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Wrench } from 'lucide-react';

import { PageIsInAlphaModal } from 'features/PageIsInAlphaModal';
import { UpgradeToProModal } from 'features/UpgradeToProModal';
import { useGetTrainingByIdQuery } from 'shared/api/training';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';

import { IDE } from './ui/IDE';

export const TrainingCode = () => {
  const navigate = useNavigate();
  const { trainId } = useParams();

  const { data: train, isError, isPending } = useGetTrainingByIdQuery(trainId!);

  const [isShowBlocker, setIsShowBlocker] = useState(false);
  const [openUpgradeToPro, setOpenUpgradeToPro] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsShowBlocker(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <AnimateRoute className="relative size-full p-4">
      <div className="flex size-full flex-col overflow-hidden rounded-[1.125rem] bg-clay-20 p-2.5">
        {(isError || isPending) && (
          <div className="flex items-center justify-center rounded-2xl border border-clay-20 bg-white p-7">
            {isPending && <Spinner className="size-7" />}
            {isError && <span className="text-lg font-medium">Something went wrong</span>}
          </div>
        )}

        {train && (
          <>
            <header className="flex items-center gap-2">
              <Button asIcon color="secondary" onClick={() => navigate(-1)}>
                <Icon className="size-4" name="arrowLeftLong" />
              </Button>
              <div className="flex items-center gap-3 whitespace-nowrap rounded-lg bg-white px-3.5 py-2">
                <span className="text-base text-clay-900">{train.name}</span>
                <div className="h-5 w-px bg-clay-20" />
                <Icon className="size-4 text-clay-900" name="penLineStroke" safeArea="0" />
              </div>
              <Button color="dark">Save Project</Button>
              <div className="flex flex-1 justify-end">
                <Button color="secondary">How to Train ?</Button>
              </div>
            </header>

            <div className="mt-2 flex flex-1 flex-col rounded-xl bg-clay-10">
              <header className="flex items-center gap-7 border-b border-clay-20 px-3.5 py-2">
                <div className="flex items-center gap-2.5">
                  <div className="text-base font-medium text-clay-900">Train (IDE)</div>
                  <StyledSelect value="IDE">
                    <Select.Content>
                      <Select.Item value="IDE">Train (IDE) task</Select.Item>
                    </Select.Content>
                  </StyledSelect>
                </div>
                <div className="flex items-center gap-2.5">
                  <div className="text-base font-medium text-clay-900">Base model</div>
                  <StyledSelect value="gpt-245.6475">
                    <Select.Content>
                      <Select.Item value="gpt-245.6475">gpt-245.6475</Select.Item>
                    </Select.Content>
                  </StyledSelect>
                </div>
              </header>
              <IDE />
            </div>
          </>
        )}
      </div>

      <div className="pointer-events-none absolute inset-0" />

      <PageIsInAlphaModal
        description={
          <>
            <span className="text-clay-900">
              Access to <span className="font-semibold">Training</span> is being rolled out in phases to users
              on the early access list.
            </span>
            <p className="mt-2.5 text-sm text-clay-500">To join the access list, upgrade to Nesa Pro.</p>
          </>
        }
        icon={<Wrench className="size-5 text-clay-900" />}
        onBackClick={() => {
          setIsShowBlocker(false);
          navigate('/training');
        }}
        onOpenChange={() => {
          setIsShowBlocker(false);
          navigate('/training');
        }}
        onUpgradeToProClick={() => {
          setOpenUpgradeToPro(true);
        }}
        open={isShowBlocker}
        subtitle="Nesa Train facilitates a seamless training experience with subsidized costs on Nesa compute. You can train your model with code or without code."
        title="Nesa Train is in Alpha"
      />

      <UpgradeToProModal
        onOpenChange={() => {
          setOpenUpgradeToPro(false);
          navigate('/training');
        }}
        open={openUpgradeToPro}
        redirectPath="/training"
      />
    </AnimateRoute>
  );
};

const StyledSelect = (props: ComponentProps<typeof Select>) => {
  return (
    <Select
      {...props}
      className="h-11 min-w-40 border border-clay-20 bg-white shadow-checkbox"
      classNameLabel="mb-2 text-sm text-clay-500"
    />
  );
};

import { Star } from 'lucide-react';

import { Button } from 'shared/ui/Button';
import { toaster } from 'shared/ui/Toast';

export const Rating = () => {
  return (
    <div className="inline-flex items-center gap-1.5 rounded-lg bg-clay-10 py-1 pl-3 pr-1 text-sm">
      <span className="inline-flex items-center gap-0.5 font-semibold leading-none text-gold-600">
        <Star className="mr-1.5 size-4 fill-gold-600" /> 4.4
      </span>
      <span className="leading-none text-clay-40 ">/</span>
      <span className="leading-none ">376</span>

      <Button
        className="ml-1.5 h-auto px-2 py-1"
        color="white"
        onClick={() => toaster.info('Coming soon')}
        size="extra-small"
      >
        {' '}
        Rate
      </Button>
    </div>
  );
};

import type { DatasetSplit } from 'shared/api/datasets/useGetDatasetSplitsQuery';

import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';

import { DatasetViewer } from './DatasetViewer';

type Props = {
  datasetName: string;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onSearchChange?: (value: string) => void;
  search?: string;
  splits: DatasetSplit[];
};
export const DatasetModal = ({
  datasetName,
  isOpen,
  onOpenChange,
  onSearchChange,
  search,
  splits,
}: Props) => {
  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="h-full !max-w-full !px-0">
        <div className="relative flex h-full flex-col overflow-hidden bg-white py-0 pb-12 md:pb-0">
          <DatasetViewer
            className="flex-1 overflow-scroll px-4"
            dataset={datasetName}
            onSearchChange={onSearchChange}
            pageLimit={16}
            search={search}
            splits={splits}
          />

          <Button
            className="absolute bottom-0 right-0 rounded-lg text-xs"
            color="dark"
            onClick={() => {
              onOpenChange(false);
            }}
            size="extra-small"
            variant="filled"
          >
            <Icon className="size-4 text-white" name="shrink" />
            Exit Fullscreen
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

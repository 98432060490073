import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { twMerge } from 'tailwind-merge';

import { queryClient } from 'app/queryClient';
import { queryKeys } from 'shared/api/datasets/queryKeys';
import { useUpdateDatasetFileStructureMutation } from 'shared/api/datasets/useUpdateDatasetFileStructureMutation';
import { useUploadDatasetMutation } from 'shared/api/datasets/useUploadDatasetMutation';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { toaster } from 'shared/ui/Toast';

import type { RecursiveFile } from './FileExplorer';

type Props = {
  datasetId: string;
  onCancel: () => void;
  onCommit?: (updatesStucture: RecursiveFile | undefined) => void;
};

const getFilePath = (file: File) => {
  return 'path' in file && typeof file.path === 'string'
    ? file.path.startsWith('./')
      ? file.path.slice(2)
      : file.path
    : '';
};

export const FileUpload = ({ datasetId, onCancel, onCommit }: Props) => {
  const { mutateAsync: uploadDatasetFile } = useUploadDatasetMutation();
  const { mutateAsync: updateFileStructure } = useUpdateDatasetFileStructureMutation();

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadedFiles(acceptedFiles);
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop });

  const handleCommitChanges = async () => {
    try {
      setIsLoading(true);
      const promises = uploadedFiles.map((fileToUpload) => {
        return uploadDatasetFile({
          datasetId,
          file: fileToUpload,
          path: getFilePath(fileToUpload),
        });
      });

      const res = await Promise.all(promises);

      const updMetadata = await updateFileStructure({
        datasetId,
        pathMap: uploadedFiles.reduce<Record<string, string>>((acc, file, idx) => {
          acc[getFilePath(file)] = res[idx]?.data;

          return acc;
        }, {}),
      });

      const queryKey = queryKeys.metadata({ datasetId });

      queryClient.setQueryData(queryKey, updMetadata.data);

      onCommit?.(updMetadata.data?.completeMetadata);
    } catch (e) {
      toaster.error('Something went wrong...');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col py-4">
      <header className="mb-6 flex items-center gap-2 px-4 text-lg font-medium">
        <Icon className="size-[16px] rotate-180" name="arrowDown" /> Upload File(s)
      </header>

      <div className="flex flex-col gap-4 border-b border-clay-20 px-4 pb-6">
        <div
          {...getRootProps()}
          className={twMerge(
            'flex min-h-36 cursor-pointer flex-col items-center justify-center rounded-lg border-[1.2px] border-dashed border-clay-40 p-6',
            'font-normal text-clay-400 transition-all duration-200',
            isDragActive && 'border-primary-800',
          )}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>{`Drag files/folders here or click to browse from your computer`}</p>
          )}
        </div>

        {uploadedFiles.length > 0 && (
          <div>
            <div className="mb-3">Accepted files:</div>

            <div className="flex flex-wrap gap-1">
              {uploadedFiles.map((file) => (
                <div
                  className="flex w-fit items-center gap-1 rounded-lg bg-clay-10 px-2 py-1 text-sm text-clay-500"
                  key={file.name}
                >
                  <Icon className="size-[16px] stroke-clay-350 text-transparent" name="fileBox" />
                  {'path' in file ? (file.path as string) : file.name}

                  <Icon
                    className="size-4 cursor-pointer transition-colors hover:text-red-1000"
                    name="close"
                    onClick={() => {
                      setUploadedFiles((prev) =>
                        prev.filter((item) => {
                          if ('path' in item && 'path' in file) {
                            return item.path !== file.path;
                          }

                          return item.name !== file.name;
                        }),
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="mt-auto flex items-center gap-4 px-4">
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={uploadedFiles.length === 0} isLoading={isLoading} onClick={handleCommitChanges}>
          Commit changes
        </Button>
      </div>
    </div>
  );
};

import { DateTime } from 'luxon';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';

import { useGetRequestsChartQuery } from 'shared/api/dai/useGetRequestsChartQuery';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  isLaunched?: boolean;
  walletAddresses: string[];
};

const mockData = [
  { amt: 2400, requests: 0 },
  { amt: 2210, requests: 0 },
  { amt: 2290, requests: 0 },
  { amt: 2000, requests: 600 },
  { amt: 2181, requests: 700 },
  { amt: 2500, requests: 1308 },
  { amt: 2100, requests: 800 },
  { amt: 2000, requests: 1005 },
  { amt: 2181, requests: 1800 },
  { amt: 2500, requests: 1700 },
  { amt: 2100, requests: 2000 },
  { amt: 2181, requests: 2500 },
  { amt: 2500, requests: 3000 },
  { amt: 2100, requests: 2900 },
  { amt: 2000, requests: 5100 },
  { amt: 2181, requests: 5150 },
  { amt: 2500, requests: 4600 },
  { amt: 2100, requests: 5000 },
  { amt: 2000, requests: 5508 },
  { amt: 2181, requests: 5100 },
  { amt: 2100, requests: 5900 },
  { amt: 2000, requests: 6008 },
  { amt: 2181, requests: 6100 },
  { amt: 2500, requests: 6900 },
  { amt: 2100, requests: 7400 },
  { amt: 2181, requests: 7300 },
  { amt: 2500, requests: 7500 },
];
export const ChainChart = ({ isLaunched, walletAddresses = [] }: Props) => {
  const { data: chartData, isPending } = useGetRequestsChartQuery(
    { walletAddresses },
    { enabled: walletAddresses.length > 0 },
  );

  if (isPending) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner className="size-6" />
      </div>
    );
  }

  const data = isLaunched === false ? mockData : chartData?.list || [];

  return (
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart
        data={data}
        margin={{
          bottom: 5,
          left: 5,
          right: 5,
          top: 5,
        }}
      >
        <Tooltip
          content={({ active, payload }) => {
            if (!active) return null;

            return (
              <div className="flex max-w-56 flex-col rounded-lg bg-clay-800 p-3 text-white">
                <span className="text-lg">
                  {payload?.[0]?.value?.toLocaleString(undefined, { maximumFractionDigits: 2 })} requests
                </span>

                <span className="text-sm text-[#B7C0CE]">
                  {DateTime.fromISO(payload?.[0]?.payload?.date).toLocaleString(DateTime.DATE_MED)}
                </span>
              </div>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: 'none', zIndex: 9999 }}
        />
        <defs>
          <linearGradient id="colorPrimary" x1="0" x2="0" y1="0" y2="1">
            <stop offset="5%" stopColor="rgba(122, 74, 225, 0.3)" stopOpacity={0.4} />
            <stop offset="100%" stopColor="rgba(122, 74, 225, 0.3)" stopOpacity={0} />
          </linearGradient>
        </defs>

        <Area
          dataKey="requests"
          dot={false}
          fill="url(#colorPrimary)"
          fillOpacity={1}
          stroke={isLaunched ? '#7A4AE1' : 'rgba(122, 74, 225, 0.3)'}
          strokeWidth={1.5}
          type="linear"
        />

        {/*
        <Line dataKey="requests" dot={false} stroke="#7A4AE1" strokeWidth={1} type="linear" /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

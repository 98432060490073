import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { daiStatsKeys } from '.';

type Props = {
  address: string;
};

type Response = { portfolioBalance: number };
export const useGetUserPortfolioBalanceQuery = (params: Props, options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/dai-stats/${params.address}/portfolio`, {
        signal,
        timeout: 20000,
      });

      return data;
    },
    queryKey: daiStatsKeys.getUserPortfolioBalance(params),
    ...options,
  });
};

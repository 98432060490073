import type { Repo } from './mock';

export const findFileByPath = (repo: Repo, path: string) => {
  const names = path.split('/').reverse();

  let folders = repo.folders;
  let files = repo.files;

  while (names.length) {
    const name = names.pop();

    if (names.length) {
      const folder = folders.find((folder) => folder.name === name);

      if (folder) {
        folders = folder.folders;
        files = folder.files;
      } else {
        return;
      }
    } else {
      const file = files.find((file) => file.name === name);

      return file;
    }
  }
};

import { twMerge } from 'tailwind-merge';
import { useAccount } from 'wagmi';

import type { ButtonSize } from 'shared/ui/Button';

import { nesaEvmTestnet } from 'app/config/wagmiConfig';
import { useClaimEvmUsd1Mutation } from 'shared/api/wallet/useClaimEvmUsd1Mutation';
import { useFaucetNonceQuery } from 'shared/api/wallet/useFaucetNonceQuery';
import { Button } from 'shared/ui/Button';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  buttonSize?: ButtonSize;
  className?: string;
  onClaimSuccess?: () => void;
};

export const ClaimUsdButton = ({ buttonSize = 'extra-small', className, onClaimSuccess }: Props) => {
  const { address, chainId } = useAccount();
  const nonceQuery = useFaucetNonceQuery();
  const claimUsdMutation = useClaimEvmUsd1Mutation();

  const isChainInvalid = chainId !== nesaEvmTestnet.id;

  if (!address) return null;

  return (
    <Tooltip
      content={
        isChainInvalid
          ? 'You need to switch chain'
          : !nonceQuery.isPending && !nonceQuery.data?.nonce
            ? `You've used up your daily USD faucet quota`
            : ''
      }
      side="top"
    >
      <div className={twMerge('ml-auto min-w-fit', className)}>
        <Button
          color="primary"
          disabled={!nonceQuery.data?.nonce || isChainInvalid}
          isLoading={claimUsdMutation.isPending || nonceQuery.isPending}
          onClick={() => {
            claimUsdMutation.mutateAsync(undefined, {
              onSuccess: () => {
                onClaimSuccess?.();
              },
            });
          }}
          size={buttonSize}
          variant="filled-light"
        >
          Claim USD
        </Button>
      </div>
    </Tooltip>
  );
};

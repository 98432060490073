import type { Review, ReviewFilter } from 'shared/api/reviews/types';

import { queryClient } from 'app/queryClient';
import { useUser } from 'app/stores/user';
import { theme } from 'app/theme';
import { ConfirmationModal } from 'features/Reviews/ui/ConfirmationModal';
import { ReviewItem } from 'features/Reviews/ui/ReviewItem';
import { ReviewList } from 'features/Reviews/ui/ReviewList';
import { StarRow } from 'features/Reviews/ui/StarRow';
import { ReviewModal } from 'pages/QueryPage/ui/ReviewModal';
import { useGetModelByIdQuery } from 'shared/api/models/useGetModelByIdQuery';
import { reviewsKeys } from 'shared/api/reviews/queryKeys';
import { useDeleteReviewMutation } from 'shared/api/reviews/useDeleteReviewMutation';
import { useGetReviewSummaryQuery } from 'shared/api/reviews/useGetReviewSummaryQuery';
import { useGetUserReviewByModelQuery } from 'shared/api/reviews/useGetUserReviewByModelQuery';
import { useStateX } from 'shared/hooks/useStateX';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';
import { StarRating } from 'shared/ui/StarRating';
import { toaster } from 'shared/ui/Toast';

type Props = {
  modelId: string;
};

const FILTERS: { id: ReviewFilter; title: string }[] = [
  { id: 'newest', title: 'Newest' },
  { id: 'highest', title: 'Highest' },
  { id: 'lowest', title: 'Lowest' },
];

export const ModelReviews = ({ modelId }: Props) => {
  const [state, setState] = useStateX<{
    filter: ReviewFilter;
    isDeleteConfirmOpen: boolean;
    isReviewOpen: boolean;
    selectedReview?: Review;
  }>({
    filter: 'newest',
    isDeleteConfirmOpen: false,
    isReviewOpen: false,
    selectedReview: undefined,
  });

  const { user } = useUser();
  const { data: userReviewData } = useGetUserReviewByModelQuery({
    modelId,
    userId: user?._id || '',
  });
  const { data: reviewSummary } = useGetReviewSummaryQuery({
    modelId,
  });
  const { data: model } = useGetModelByIdQuery(modelId!, { enabled: !!modelId });

  const { isPending: isDeleting, mutateAsync: deleteReview } = useDeleteReviewMutation();

  const handleDeleteConfirmation = async () => {
    try {
      if (!state.selectedReview?._id) {
        toaster.error('No review selected');
        return;
      }

      await deleteReview({ id: state.selectedReview?._id });

      const key = reviewsKeys.list({ modelId, userId: user?._id });
      queryClient.removeQueries({ queryKey: key });

      setState({ isDeleteConfirmOpen: false, selectedReview: undefined });
    } catch (e) {
      toaster.error('Something went wrong');
    }
  };

  return (
    <>
      <div className="mb-4 grid grid-cols-1 gap-4 rounded-lg bg-clay-10 p-2 md:grid-cols-[11rem_1fr]">
        <div className="flex h-full flex-col items-center justify-center gap-3 rounded-lg bg-white px-4 py-3 md:py-0">
          <div className="text-4xl/none font-bold">{reviewSummary?.avg?.toFixed(1)}</div>
          <StarRating
            className="gap-0.5"
            rating={reviewSummary?.avg || 0}
            starColor={theme.colors.primary[800]}
            svgProps={{ height: 18, width: 18 }}
          />
          <div className="text-sm font-light text-clay-400">{reviewSummary?.totalCount} ratings</div>
        </div>

        <div className="flex w-full flex-col gap-2 py-2">
          {reviewSummary?.stars &&
            Object.entries(reviewSummary?.stars)
              .reverse()
              .map(([rating, count]) => {
                return (
                  <StarRow
                    currentReviewsCount={count || 0}
                    key={rating}
                    rating={Number(rating)}
                    reviewsCount={reviewSummary?.totalCount || 0}
                  />
                );
              })}
        </div>
      </div>

      <div className="mb-3 flex w-full flex-col items-center justify-between gap-2 md:flex-row">
        <Button
          className="flex w-full items-center gap-2 px-3 md:w-fit"
          onClick={() => setState({ isReviewOpen: true })}
        >
          <Icon className="size-4 stroke-white" name="penLine" /> Write a Review{' '}
          <span className="rounded-md bg-white/20 px-2 text-[9px] uppercase text-tusk-100">Get points</span>
        </Button>

        <Select
          className="h-9 p-3 text-sm "
          iconClassName="mr-1"
          iconName="arrowDownUp"
          onValueChange={(id: ReviewFilter) => setState({ filter: id })}
          value={state.filter}
          wrapperClassName="w-full md:w-fit"
        >
          <Select.Content className="z-10">
            <div className="mb-0.5 w-48 border-b border-clay-20 px-3 py-2 text-sm font-normal text-clay-350">
              Sort by
            </div>

            {FILTERS.map(({ id, title }) => {
              return (
                <Select.Item
                  className="text-sm text-clay-900 hover:bg-clay-10"
                  key={id}
                  onClick={() => setState({ filter: id })}
                  value={id}
                >
                  {title}
                </Select.Item>
              );
            })}
          </Select.Content>
        </Select>
      </div>

      <div className="flex grow flex-col overflow-scroll">
        {userReviewData?.data && (
          <ReviewItem
            isOwner
            onDelete={() => {
              setState({ isDeleteConfirmOpen: true, selectedReview: userReviewData.data });
            }}
            onEdit={() => {
              setState({ isReviewOpen: true, selectedReview: userReviewData.data });
            }}
            review={userReviewData?.data}
          />
        )}

        <ReviewList
          modelId={modelId}
          onReviewDelete={(review) => {
            setState({ isDeleteConfirmOpen: true, selectedReview: review });
          }}
          onReviewEdit={(review) => {
            setState({ isReviewOpen: true, selectedReview: review });
          }}
          sort={state.filter}
          userId={user?._id || ''}
        />
      </div>

      {model && (
        <ReviewModal
          isOpen={state.isReviewOpen}
          model={model}
          onOpenChange={(isReviewOpen) => setState({ isReviewOpen })}
          review={state.selectedReview}
        />
      )}

      <ConfirmationModal
        content="Are you sure you want to delete this review?"
        isLoading={isDeleting}
        isOpen={state.isDeleteConfirmOpen}
        onConfirm={handleDeleteConfirmation}
        onOpenChange={(isOpen) => setState({ isDeleteConfirmOpen: isOpen })}
      />
    </>
  );
};

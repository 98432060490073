import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';

import { useDAIListInfiniteFullQuery } from 'shared/api/dai/useDAIListInfiniteQuery';
import { Spinner } from 'shared/ui/Spinner';
import { Table } from 'shared/ui/Table';

import { useSettingTableKeysContext } from '../LeaderBoardTabs';
import { getColumns } from './config';
import { Skeleton } from './ui/Skeleton';

type Props = {
  short?: boolean;
};

export const DaiTab = ({ short }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const sort = (searchParams.get('sort') || 'newest') as 'newest' | 'oldest';

  const selectedTableKeys = useSettingTableKeysContext();

  const {
    data: daiList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useDAIListInfiniteFullQuery({ limit: short ? 5 : 40, sort: sort });

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const isLoading = status === 'pending';

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage && !short) {
      fetchNextPage();
    }
  }, [fetchNextPage, short, hasNextPage, intersection?.isIntersecting]);

  const columns = useMemo(() => {
    const page = daiList?.pages[0];
    if (!page) return [];
    return getColumns(selectedTableKeys, page.totalCount, sort);
  }, [sort, daiList, selectedTableKeys]);

  const tableData = useMemo(() => {
    if (!daiList) return [];
    return daiList?.pages.flatMap((el) => el.list);
  }, [daiList]);

  return (
    <>
      <div className="w-full flex-1 overflow-auto">
        {isLoading ? (
          <Skeleton selectedTableKeys={short ? new Set() : selectedTableKeys} />
        ) : (
          <>
            <Table
              className="min-w-[58rem]"
              columns={columns}
              data={tableData}
              onRowClick={(row) => navigate(`/dai/${row.slug}`)}
              // onSortChange={({ key, type }) => onSortChange(key, type)}
              // sort={{
              //   key: sort,
              //   type: sortType,
              // }}
            />
            <div ref={ref} />
          </>
        )}
        {isFetchingNextPage && (
          <div className=" flex justify-center py-5">
            <Spinner className=" size-8" />
          </div>
        )}
      </div>
    </>
  );
};

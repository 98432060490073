import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackButton } from 'app/ui/BackButton';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import { anchorCards, applications, developerResourcesCards } from './config';
import shapeSrc from './ui/assets/shape.svg';

export const Page = () => {
  const navigate = useNavigate();

  const applicationsRef = useRef<HTMLDivElement>(null);

  return (
    <AnimateRoute className="relative mx-2 flex-1 scroll-smooth pb-8 md:mr-4 md:mt-0">
      <div className="relative flex flex-col overflow-hidden rounded-2xl bg-[#E3E4E7]">
        <img className="absolute -right-5 z-0 max-w-[600px] -translate-y-1/4" src={shapeSrc} />
        <div className="relative mx-auto flex w-full flex-col gap-5 px-2 pb-2 pt-7 sm:px-6 sm:pb-6 xl:max-w-screen-lg xl:px-0 2xl:max-w-screen-xl">
          <div className="flex flex-wrap items-center justify-between gap-1">
            <BackButton
              className="gap-3 text-2xl font-semibold text-clay-900 hover:text-clay-900"
              onClick={() => navigate('/')}
            >
              {' '}
              Nesa Ecosystem
            </BackButton>

            <div className="text-sm font-light text-clay-900">Discover the decentralized world of Nesa.</div>
          </div>

          <div className="grid grid-cols-1 gap-3 md:grid-cols-3 lg:gap-5">
            {anchorCards.map((el) => (
              <a
                className="group flex flex-col gap-1 rounded-lg border-none bg-white p-4 transition-colors sm:!p-6"
                href={el.link}
                key={el.link}
                rel={el.link.startsWith('http') ? 'noreferrer' : undefined}
                target={el.link.startsWith('http') ? '_blank' : undefined}
              >
                <h1 className="text-xl sm:text-2xl">{el.title}</h1>
                <sub className="mb-6 text-sm font-light text-clay-500 sm:text-base">{el.description}</sub>

                <Button
                  className="w-fit !border !border-clay-40 bg-white text-sm drop-shadow-sm group-hover:text-primary-800 hover:bg-white hover:text-primary-800"
                  color="secondary"
                  onClick={(e) => {
                    if (el.title === 'Applications') {
                      e.preventDefault();
                      e.stopPropagation();
                      applicationsRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  size="extra-small"
                >
                  {el.linkText}
                </Button>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div
        className="relative mx-auto mb-8 grid w-full grid-cols-1 gap-8 px-2 pt-8 sm:px-6 lg:grid-cols-3 xl:max-w-screen-lg xl:px-0 2xl:max-w-screen-xl"
        ref={applicationsRef}
        // id="applications"
      >
        <div>
          <h2 className="mb-3 text-xl font-bold">The Ecosystem of Nesa</h2>
          <p className="mb-6 font-light text-clay-500">
            Explore select applications building with Nesa or running AI inference on the network.
          </p>

          <Button as="a" className="font-light" href="http://docs.nesa.ai/" rel="noreferrer" target="_blank">
            <Icon className="mr-1 size-4" name="combine" />
            Integrate with Nesa
          </Button>
        </div>
        <div className="col-span-2 grid grid-cols-1 gap-1 sm:grid-cols-2">
          {applications.map((app) => {
            return (
              <div className="flex flex-col rounded-lg border border-clay-20 bg-white p-6" key={app.title}>
                <header className="mb-6 flex justify-between">
                  <img alt="" className="size-11" src={app.icon} />

                  <Button
                    as="a"
                    className="!size-7 w-fit !border !border-clay-40 bg-white p-1 text-sm drop-shadow-sm group-hover:text-primary-800 hover:bg-white hover:text-primary-800"
                    color="secondary"
                    href={app.link}
                    onClick={(e) => {
                      if (!app.link) {
                        e.preventDefault();
                      }
                    }}
                    rel="noreferrer"
                    size="extra-small"
                    target="_blank"
                  >
                    <Icon className="size-4 rotate-[135deg]" name="arrowLeftLong" />
                  </Button>
                </header>
                <h2 className="mb-2 text-xl font-semibold">{app.title}</h2>
                <p className="text-sm font-light text-clay-500">{app.description}</p>
              </div>
            );
          })}
          <div className="flex flex-col items-center justify-center py-12 text-base">
            <div>
              <div className="font-semibold text-clay-900">and many others.</div>
              <a
                className="text-primary-800 duration-200 hover:text-primary-1000"
                href="https://nesa.ai/"
                rel="noreferrer"
                target="_blank"
              >
                See All
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mx-auto grid w-full grid-cols-1 gap-8 px-2 sm:px-6 lg:grid-cols-3 xl:max-w-screen-lg xl:px-0 2xl:max-w-screen-xl">
        <div>
          <h2 className="mb-3 text-xl font-bold">Developer resources</h2>
          {/* <p className="mb-6 font-light text-clay-500">
            Explore select applications building on Nesa or running AI inference on the network.
          </p> */}

          <Button as="a" className="font-light" href="http://docs.nesa.ai/" rel="noreferrer" target="_blank">
            <Icon className="mr-1 size-4" name="combine" />
            Integrate with Nesa
          </Button>
        </div>
        <div className="col-span-2 grid grid-cols-1 gap-1 sm:grid-cols-2">
          {developerResourcesCards.map((card) => {
            return (
              <div className="flex flex-col rounded-lg border border-clay-20 bg-white p-6" key={card.title}>
                <header className="mb-6 flex justify-between">
                  <div className="size-11">{card.icon}</div>

                  <Button
                    as="a"
                    className="!size-7 w-fit !border !border-clay-40 bg-white p-1 text-sm drop-shadow-sm group-hover:text-primary-800 hover:bg-white hover:text-primary-800"
                    color="secondary"
                    href={card.link}
                    rel="noreferrer"
                    size="extra-small"
                    target="_blank"
                  >
                    <Icon className="size-4 rotate-[135deg]" name="arrowLeftLong" />
                  </Button>
                </header>
                <h2 className="mb-2 text-xl font-semibold">{card.title}</h2>
                <p className="text-sm font-light text-clay-500">{card.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </AnimateRoute>
  );
};

import { Fragment, memo, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDebounce, useIntersectionObserver } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { SearchIcon, XIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

import type { DAISort } from 'shared/api/dai/useDAIListInfiniteQuery';

import { categoriesMap } from 'entities/DAI/const';
import { getTvl } from 'pages/DAI/helpers/getTvl';
import { useDAIListInfiniteQuery } from 'shared/api/dai/useDAIListInfiniteQuery';
import { Badge } from 'shared/ui/Badge';
import { DaiRow } from 'shared/ui/DaiRow/DaiRow';
import { HotDaiCard } from 'shared/ui/HotDaiCard/HotDaiCard';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';

type ViewType = 'grid' | 'row';

type Props = {
  defaultViewType?: ViewType;
  withSearchInput?: boolean;
};

export const ExploreDAI = memo(({ defaultViewType = 'grid', withSearchInput }: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewType, setViewType] = useState<ViewType>(defaultViewType);

  const sort = (searchParams.get('sort') || 'noteworthy') as DAISort;
  const searchValue = searchParams.get('search') || '';
  const category = searchParams.get('category') || undefined;

  const debounceValue = useDebounce(searchValue, 200);

  const onSearchParamsChange = (name: string, value?: string) => {
    setSearchParams((prev) => {
      const newSearch = new URLSearchParams(prev);
      if (value) {
        newSearch.set(name, value);
      } else {
        newSearch.delete(name);
      }
      return newSearch;
    });
  };

  const {
    data: daiList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending: isListLoading,
    isSuccess,
  } = useDAIListInfiniteQuery({ category: category, limit: 12, search: debounceValue, sort });

  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const list = daiList?.pages.flat() || [];

  const selectedCategory = categoriesMap[category as keyof typeof categoriesMap];
  // const selectedCategory = categories.find((el) => el.value === category);

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  return (
    <div className="flex flex-col overflow-hidden">
      <header className="mb-4 flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
        {withSearchInput ? (
          <Input
            className="max-w-2xl"
            classNameInputWrapper="bg-clay-10"
            onChange={(e) => onSearchParamsChange('search', e.target.value)}
            placeholder="Search for a DAI"
            size="medium"
            startSlot={
              <Icon
                className="size-4 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                name="search"
              />
            }
            value={searchValue}
          />
        ) : (
          <div className="text-3xl font-medium tracking-tighter">More DAI</div>
        )}

        <div className="right-4 box-border flex h-12 max-w-fit items-center gap-1 rounded-xl bg-blue-50 p-1">
          <Select
            className="h-10 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
            iconName="arrowDownUp"
            onValueChange={(val) => onSearchParamsChange('sort', val)}
            placeholder="Sort by"
            placeholderClassName="font-medium text-clay-600"
            value={sort}
          >
            <Select.Content className="z-10 rounded-lg p-1 shadow-sm">
              <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                Sort by
              </div>

              <Select.Item className="text-sm text-clay-900 hover:bg-clay-10" value="noteworthy">
                Noteworthy
              </Select.Item>

              <Select.Item className="text-sm text-clay-900 hover:bg-clay-10" value="newest">
                Newest
              </Select.Item>
            </Select.Content>
          </Select>

          <div
            className={twMerge(
              'flex size-10 cursor-pointer items-center justify-center rounded-lg text-clay-500 transition-colors hover:bg-white',
              viewType === 'grid' && 'bg-white text-clay-800',
            )}
            onClick={() => setViewType('grid')}
          >
            <Icon className="size-4" name="grid" />
          </div>
          <div
            className={twMerge(
              'flex size-10 cursor-pointer items-center justify-center rounded-lg text-clay-500 transition-colors hover:bg-white',
              viewType === 'row' && 'bg-white text-clay-800',
            )}
            onClick={() => setViewType('row')}
          >
            <Icon className="size-4" name="list" />
          </div>
        </div>
      </header>

      {selectedCategory && (
        <div className="mb-6 flex items-center gap-4">
          <div className="text-lg font-semibold text-clay-900">Filters: </div>

          <div
            className="flex cursor-pointer items-center gap-2 rounded-lg bg-clay-20 px-2.5 py-1.5 duration-200 hover:bg-clay-30"
            onClick={() => onSearchParamsChange('category')}
          >
            <span className="text-sm font-medium text-clay-500">{selectedCategory.label}</span>
            <XIcon className="size-3.5 text-clay-500" />
          </div>
        </div>
      )}

      <AnimatePresence>
        {isSuccess && list.length > 0 && (
          <Fragment key="list-success">
            <motion.div
              animate={{ opacity: 1 }}
              className={twMerge(
                'relative grid gap-0',
                viewType === 'grid' && 'gap-2 sm:grid-cols-2 lg:grid-cols-3',
                viewType === 'row' && 'w-full grid-cols-[4fr_2fr_2fr_1fr_2fr] overflow-x-scroll',
              )}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              {list.map((dai) => {
                const founder = dai.members[0];

                if (viewType === 'row') {
                  return (
                    <DaiRow
                      className="min-w-[512px] cursor-pointer border-b border-clay-20 py-4 transition-colors last:border-none hover:bg-clay-10/50"
                      coinImageSrc={dai.token?.image}
                      coinName={dai.token?.ticker}
                      description={dai.shortDescription}
                      key={`row-${dai._id}`}
                      mediaSrc={dai.profileImage}
                      onClick={() => navigate(`/dai/${dai.slug}`)}
                      // participants={dai.largestStakers || []}
                      participants={(dai.largestStakers || []).map(({ avatar, first_name, last_name }) => ({
                        avatar,
                        firstName: first_name,
                        lastName: last_name,
                        name: first_name?.slice(0, 1) || last_name?.slice(0, 1) || '',
                      }))}
                      tags={[dai.projectCategory]}
                      title={dai.projectName}
                      tokenType={dai.tokenType}
                      tvl={getTvl({ tvlNes: dai.tvl })}
                      userImageSrc={founder?.avatar}
                      userName={founder?.name}
                    />
                  );
                }
                return (
                  <HotDaiCard
                    chipSlot={
                      dai.isNesaExclusive ? (
                        <Badge className="bg-yellow-500/10 px-2 py-1.5 font-medium uppercase text-yellow-500">
                          exclusive on nesa
                        </Badge>
                      ) : undefined
                    }
                    className="h-full"
                    coinImageSrc={dai.token?.image}
                    coinName={dai.token?.ticker}
                    description={dai.shortDescription}
                    key={dai._id}
                    mediaSrc={dai.profileImage}
                    onClick={() => navigate(`/dai/${dai.slug}`)}
                    // participants={dai.largestStakers || []}
                    participants={(dai.largestStakers || []).map(({ avatar, first_name, last_name }) => ({
                      avatar,
                      firstName: first_name,
                      lastName: last_name,
                      previewLetter: first_name?.slice(0, 1) || last_name?.slice(0, 1) || '',
                    }))}
                    showMediaNearTitle={false}
                    tags={[dai.projectCategory]}
                    title={dai.projectName}
                    tokenType={dai.tokenType}
                    tvl={getTvl({ tvlNes: dai.tvl })}
                    userImageSrc={founder?.avatar}
                    userName={founder?.name || ''}
                  ></HotDaiCard>
                );
              })}
            </motion.div>

            <div key="next" ref={ref} />
          </Fragment>
        )}
      </AnimatePresence>
      {isSuccess && list.length === 0 && (
        <div className="flex flex-col items-center gap-5 py-10">
          <div className="flex size-12 items-center justify-center rounded-full border border-clay-20 bg-white">
            <SearchIcon className="size-4 text-clay-350" />
          </div>
          <div className="text-lg">
            <span className=" font-semibold text-clay-900">No DAI matching.</span>{' '}
            <span className="font-medium text-clay-500">Search again.</span>
          </div>
        </div>
      )}

      {(isListLoading || isFetchingNextPage) && (
        <div
          className={twMerge(
            'relative mt-2 grid grid-cols-1 gap-0',
            viewType && 'grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3',
          )}
        >
          {Array.from({ length: 6 }).map((_, idx) => (
            <HotDaiCard isLoading key={idx} />
          ))}
        </div>
      )}
    </div>
  );
});

import { useState } from 'react';

import axios from 'axios';
import { saveAs } from 'file-saver';

import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { toaster } from 'shared/ui/Toast';

import type { RecursiveFile } from './FileExplorer';

type Props = {
  baseName?: string;
  data: Record<string, RecursiveFile> | Record<string, string>;
  lvlName: string;
  onFolderClick: () => void;
};
export const FileExplorerRow = ({ baseName, data, lvlName, onFolderClick }: Props) => {
  const [loadingPaths, setLoadingPaths] = useState<Set<string>>(new Set());

  const isPath = lvlName === 'files';

  const setLoadingPath = (path: string, isDelete: boolean) => {
    setLoadingPaths((prev) => {
      const newPaths = new Set(prev);
      if (isDelete) {
        newPaths.delete(path);
      } else {
        newPaths.add(path);
      }
      return newPaths;
    });
  };
  const reqFile = async (path: string, name: string) => {
    try {
      setLoadingPath(path, false);
      const { data: blobData } = await axios.get<Blob>(path, { responseType: 'blob', timeout: 60 * 1000 });
      saveAs(blobData, name);
      setLoadingPath(path, true);
    } catch {
      toaster.error('Something went wrong');
      setLoadingPath(path, true);
    }
  };
  if (isPath) {
    return Object.entries(data).map(([name, path]) => {
      return (
        <div
          className="flex cursor-auto items-center justify-between gap-3 border-b border-clay-20 px-4 py-3 text-clay-700 transition-colors last-of-type:border-none hover:bg-clay-10"
          key={baseName + name}
        >
          <div className="flex items-center gap-3">
            <Icon className="stroke-clay-400 text-transparent" name="fileBox" /> {name}
          </div>

          <Button
            className="h-9 px-2 py-1"
            color="secondary"
            isLoading={loadingPaths.has(path)}
            onClick={() => reqFile(path, name)}
            variant="filled-light"
          >
            <Icon className="size-4 text-clay-400" name="download" />
          </Button>
        </div>
      );
    });
  }

  return (
    <div
      className="flex cursor-pointer items-center gap-3 border-b border-clay-20 px-4 py-3 text-clay-700 transition-colors last-of-type:border-none hover:bg-clay-10"
      key={lvlName}
      onClick={onFolderClick}
    >
      <Icon className="stroke-clay-400 text-transparent" name="folderOpen" /> {lvlName}
    </div>
  );
};

import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import type { Dataset } from 'shared/api/datasets/types';

import { useUser } from 'app/stores/user';
import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';

import { FileExplorerRow } from './FileExplorerRow';
import { FileUpload } from './FileUpload';

export type RecursiveFile = {
  data?: Record<string, RecursiveFile>;
  files: Record<string, string>;
};

type Props = {
  dataset: Dataset;
};

export const FileExplorer = ({ dataset }: Props) => {
  const { user } = useUser();

  const fileStructure = dataset.completeMetadata;

  const [folderToRender, setFolderToRender] = useState<RecursiveFile | undefined>(fileStructure);
  const [history, setHistory] = useState<RecursiveFile[]>([]);
  const [isUploadView, setIsUploadView] = useState(false);

  const isEditAvailable = dataset.nesaUserId === user?._id;

  if (isUploadView) {
    return (
      <FileUpload
        datasetId={dataset.datasetId}
        onCancel={() => setIsUploadView(false)}
        onCommit={(updatedStructure: RecursiveFile | undefined) => {
          setIsUploadView(false);
          setFolderToRender(updatedStructure);
        }}
      />
    );
  }

  return (
    <div className="flex flex-1 flex-col rounded-lg bg-white p-4">
      <header className="mb-6 flex justify-between text-lg font-medium">
        File viewer{' '}
        {isEditAvailable && (
          <Popover>
            <Popover.Trigger asChild>
              <div
                className={twMerge(
                  'group flex h-9 cursor-pointer items-center gap-0 rounded-lg bg-clay-800 pl-3 pr-2 text-sm text-white',
                )}
              >
                <Icon className={twMerge('mr-1.5 size-3 text-white transition-transform')} name="plus" />
                Add Files
                <Icon
                  className={twMerge('ml-0.5 size-5 text-white transition-transform')}
                  name="arrowDownSm"
                />
              </div>
            </Popover.Trigger>
            <Popover.Content
              align="end"
              className="min-w-48 rounded-lg p-1"
              collisionPadding={4}
              side="bottom"
              sideOffset={4}
            >
              <div
                className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-2 text-sm font-light transition-colors hover:bg-clay-10"
                onClick={() => setIsUploadView(true)}
              >
                Upload files
              </div>
            </Popover.Content>
          </Popover>
        )}
      </header>

      {(!folderToRender || !Object.entries(folderToRender).length) && (
        <div className="flex items-center justify-center py-10 text-center text-sm font-light text-clay-400">
          No data available
        </div>
      )}

      {folderToRender && (
        <div className="rounded-lg border border-clay-20">
          {history.length > 0 && (
            <div
              className="flex w-full cursor-pointer select-none items-center justify-between gap-3 border-b border-clay-20 px-4 pb-4 pt-3 text-clay-700 transition-colors last-of-type:border-none hover:bg-clay-10"
              onClick={() => {
                const lastItem = history[history.length - 1];
                setHistory((prev) => prev.slice(0, prev.length - 1));
                setFolderToRender(lastItem);
              }}
            >
              ...
            </div>
          )}
          {Object.entries(folderToRender).map(([lvlName, innerData]) => {
            return (
              <FileExplorerRow
                baseName={lvlName}
                data={innerData}
                key={lvlName}
                lvlName={lvlName}
                onFolderClick={() => {
                  setHistory((prev) => [...prev, folderToRender]);
                  setFolderToRender(innerData as unknown as RecursiveFile);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

import { metaMask } from '@wagmi/connectors';
import { twMerge } from 'tailwind-merge';
import { useConnect } from 'wagmi';

import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import metamaskSrc from 'shared/ui/assets/metamask.svg';

type Props = {
  className?: string;
  onConnected?: () => void;
  onOpenChange?: (open: boolean) => void;
};

export const EVMWalletProviderContent = ({ className, onConnected, onOpenChange }: Props) => {
  const { connectAsync, isPending: isLoading } = useConnect();
  return (
    <div className={twMerge('flex w-full flex-col', className)}>
      <div className="mb-4 flex flex-col items-center justify-center pt-2">
        <Icon className="mb-4 size-20 text-clay-500" name="dashedWallet" />

        <h1 className="mb-2 text-center text-2xl font-semibold">Select your wallet provider</h1>
        <div className="text-center text-base font-light text-clay-500">
          Connect to Nesa EVM Chain now. Choose the wallet provider below.
        </div>
      </div>
      <div className="relative grid w-full grid-cols-1 gap-2">
        {isLoading && (
          <div className="absolute inset-0 z-20 col-span-2 flex flex-col items-center justify-center gap-3 bg-white/50 text-clay-500">
            <Spinner className="size-6" /> <span>Connecting...</span>
          </div>
        )}

        <div
          className={twMerge(
            'flex cursor-pointer items-center justify-center gap-4 overflow-hidden rounded-lg bg-clay-10 px-4 py-5 transition-colors hover:bg-clay-20',
            isLoading && 'pointer-events-none select-none opacity-50',
          )}
          key="metamask"
          onClick={async () => {
            await connectAsync({ connector: metaMask() });

            onConnected?.();

            onOpenChange?.(false);
          }}
        >
          <img className="size-7" src={metamaskSrc} />
          MetaMask
        </div>
      </div>
    </div>
  );
};

import { useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';

import { useAccount } from 'graz';
import { ArrowUpRight, Check, Link } from 'lucide-react';

import type { DAI } from 'shared/api/dai/types';

import { useUser } from 'app/stores/user';
import { Wallet } from 'app/ui/GalleryHeader/ui/Wallet';
import { categories } from 'entities/DAI/const';
import { useGetConnectedModelsQuery } from 'shared/api/dai/useGetConnectedModelsQuery';
import { useGetDAIConnectedWalletsQuery } from 'shared/api/dai/useGetDAIConnectedWalletsQuery';
import { useGetDAISummaryQuery } from 'shared/api/dai/useGetDAISummaryQuery';
import { getShortenedAddress } from 'shared/helpers/getShortenedAddress';
import { Badge } from 'shared/ui/Badge';
import { Button } from 'shared/ui/Button';
import { Chip } from 'shared/ui/Chip';
import { Icon } from 'shared/ui/Icon';
import { Image } from 'shared/ui/Image';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';
import { Tooltip } from 'shared/ui/Tooltip';
import { TruncateMiddle } from 'shared/ui/TruncateMiddle';

import { getTvl } from '../helpers/getTvl';
import { ConnectedModels } from './ConnectedModels';
import { DAIChart } from './DAIChart';
import { InteractionLogs } from './InteractionLogs';
import { StakeButton } from './StakeButton';
import { TeamMember } from './TeamMember';
import { TeamMemberModel } from './TeamMemberModel';

type Props = {
  dai: DAI;
};
export const DAIContent = ({ dai }: Props) => {
  const { user } = useUser();
  const location = useLocation();

  const [isTeamMemberOpen, setIsTeamMemberOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<DAI['members'][number]>();

  const { data: account, isConnected } = useAccount();

  const { data } = useGetDAIConnectedWalletsQuery({ daiId: dai._id });
  const wallets = data?.list;
  const walletAddresses = (wallets || [])?.map((w) => w.walletAddress);

  const activeConnectedModels = useGetConnectedModelsQuery(
    { walletAddresses: walletAddresses },
    { enabled: walletAddresses.length > 0 },
  );

  const connectedModelsList = useMemo(() => {
    const sortedActiveModels =
      activeConnectedModels.data?.list
        .sort((a, b) => b.sessionCount - a.sessionCount)
        .slice(0, 15)
        .map((item) => item.model._id) || [];

    if (!sortedActiveModels.length) {
      return dai.connectedModels?.slice(0, 15) || [];
    }

    return [
      sortedActiveModels[0],
      ...(dai.connectedModels || []).slice(0, 6),
      ...sortedActiveModels.slice(1),
      ...(dai.connectedModels || []).slice(6),
    ]
      .slice(0, 14)
      .reduce<string[]>((acc, address) => {
        if (acc.includes(address)) return acc;

        acc.push(address);
        return acc;
      }, []);
  }, [activeConnectedModels, dai.connectedModels]);

  const { data: daiSummary, isPending: isSummaryPending } = useGetDAISummaryQuery(
    { daiId: dai._id, userId: user?._id, walletAddresses },
    { enabled: !!dai._id },
  );

  return (
    <>
      <div className="relative">
        {/* <div className="absolute bottom-[40%] left-0 right-[65%] top-0 bg-gradient-to-br from-black/60 via-transparent via-30% to-transparent"></div> */}
        {dai.cover.endsWith('.mp4') ? (
          <video autoPlay className="h-96 w-full object-cover" loop muted playsInline src={dai.cover} />
        ) : (
          <img className="h-96 w-full object-cover" src={dai.cover} />
        )}
      </div>

      <div className="relative -top-12">
        <div className="relative m-4 mb-14 grid grid-cols-1 gap-3 rounded-xl bg-white bg-gradient-to-t from-transparent to-clay-20 p-6 md:gap-x-10 lg:grid-cols-5 2xl:grid-cols-7">
          <div className="col-span-1 flex flex-col gap-4 lg:col-span-3 2xl:col-span-5">
            <div className="flex flex-col gap-2 md:flex-row md:gap-4">
              {dai.profileImage && (
                <Image
                  className="size-20  overflow-hidden rounded-full object-cover sm:size-24 lg:size-32"
                  src={dai.profileImage}
                />
              )}

              <div className="flex flex-col gap-2 md:gap-4">
                {dai.tokenType !== 'no-token' ? (
                  <div className="flex items-center gap-4">
                    <div className="size-6 overflow-hidden rounded-full bg-clay-800">
                      {dai.token?.image && (
                        <Image className="size-full object-cover" src={dai.token?.image} />
                      )}
                    </div>
                    <span className="font-light">${dai.token?.ticker || '--'}</span>

                    <div>
                      {dai.isVerified && (
                        <Chip className="flex items-center gap-1.5 bg-blue-600/5 py-0.5 text-[12px] text-blue-600">
                          <Check className="size-3.5" /> Verified
                        </Chip>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="text-sm text-clay-100">No token available</div>
                )}
                <div className="flex items-center gap-3 text-2xl font-semibold tracking-tighter lg:text-4xl">
                  {dai.projectName}{' '}
                </div>

                <div className="flex flex-wrap items-center gap-1.5 md:gap-3">
                  {dai.isNesaExclusive && (
                    <Badge className="bg-yellow-500/10 px-2 py-1.5 font-medium uppercase text-yellow-500">
                      exclusive on nesa
                    </Badge>
                  )}
                  {[dai.projectCategory].map((tag, idx) => {
                    const category = categories.find((cat) => cat.value === tag);
                    return (
                      <Badge className="bg-clay-20 px-2 py-1.5 font-medium uppercase text-clay-350" key={idx}>
                        {category?.label || ''}
                      </Badge>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="text-base font-light text-clay-350 md:text-lg 2xl:mr-12">
              {dai.shortDescription}
            </div>

            {!!user && (
              <div className="flex items-start gap-3 py-4 ">
                <div className="flex flex-col gap-1">
                  <div className="text-sm text-clay-400">My Staked Amount</div>
                  <div className="text-3xl font-semibold tracking-tighter">
                    {isSummaryPending ? (
                      <Spinner className="my-1 size-5" />
                    ) : daiSummary?.stakedByUser ? (
                      `$${getTvl({ tvlNes: daiSummary.stakedByUser })}`
                    ) : (
                      `$0`
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-1 flex flex-col gap-10 lg:col-span-2 2xl:col-span-2">
            <div className="flex items-center gap-2">
              {dai.productLink && (
                <Button
                  className="flex flex-1 justify-between overflow-hidden pr-2 text-start text-base"
                  color="white"
                  onClick={() =>
                    window.open(
                      dai.productLink.startsWith('https://') ? dai.productLink : `https://${dai.productLink}`,
                    )
                  }
                >
                  <div className="flex-1 truncate">Try {dai.projectName || ''} now</div>
                  <ArrowUpRight className="size-[1.125rem] min-w-[1.125rem]" />
                </Button>
              )}

              <CopyToClipboard
                onCopy={() => toaster.success('Copied')}
                text={`${window.origin}${location.pathname}`}
              >
                <Button
                  className="ml-auto flex justify-between gap-2 bg-clay-20 pl-2.5 pr-2 text-base text-clay-1000"
                  color="white"
                >
                  Share
                  <Link className="size-3.5" />
                </Button>
              </CopyToClipboard>
            </div>

            <div className="flex flex-col gap-5">
              <div className="text-sm text-clay-400 md:text-base">Total Value Locked</div>
              <div className="flex items-center gap-3 text-2xl font-semibold tracking-tighter sm:text-3xl lg:text-5xl">
                ${getTvl({ tvlNes: dai.tvl })}{' '}
                {/* <div className="flex items-center gap-1.5">
                <div
                  className={twMerge(
                    'flex size-4 items-center justify-center rounded-full bg-green-600/20 text-green-600',
                    priceChangePrc < 0 && 'bg-red-900/20 text-red-900',
                  )}
                >
                  <Icon className={twMerge('size-3', priceChangePrc > 0 && 'rotate-180')} name="arrowDown" />
                </div>

                <div
                  className={twMerge(
                    'text-base/none font-light tracking-normal text-green-600',
                    priceChangePrc < 0 && ' text-red-900',
                  )}
                >
                  {priceChangePrc > 0 ? '+' : ''}
                  {priceChangePrc}%
                </div>
              </div> */}
              </div>
              <StakeButton dai={dai} />

              {!user && (
                <Wallet
                  address={
                    <TruncateMiddle approximateCharWidth={6} largeString={account?.bech32Address || ''} />
                  }
                  addressText={account?.bech32Address}
                  alignPopup="start"
                  isConnected={isConnected}
                />
              )}
            </div>
          </div>

          <DAIChart
            className="col-span-1 lg:col-span-5 2xl:col-span-7"
            daiId={dai._id}
            walletAddresses={(wallets || [])?.map((w) => w.walletAddress)}
          />
        </div>
        <div className="relative col-span-1 grid grid-cols-1 md:grid-cols-2 lg:col-span-4">
          <div className="flex flex-col gap-1.5 border-b border-r border-clay-20 px-7 py-3">
            <div className="text-base font-normal text-clay-350">Address</div>
            <div className="flex flex-col">
              {wallets?.map((wallet) => {
                return (
                  <div className="flex items-center gap-2 text-base font-medium" key={wallet._id}>
                    {getShortenedAddress(wallet.walletAddress)}{' '}
                    <CopyToClipboard
                      onCopy={() => toaster.success('Copied')}
                      text={wallet.walletAddress || ''}
                    >
                      <Icon
                        className="size-4 cursor-pointer text-clay-350 transition-colors hover:text-clay-800"
                        name="copy"
                      />
                    </CopyToClipboard>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-1.5 border-b border-clay-20 px-7 py-3">
            <div className="text-base font-normal text-clay-350">Stakers</div>
            <div className="flex items-center gap-2 text-base font-medium">
              {isSummaryPending ? <Spinner className="size-4" /> : daiSummary?.stakers ?? '--'}
            </div>
          </div>{' '}
          <div className="flex flex-col gap-1.5 border-b border-r border-clay-20 px-7 py-3">
            <div className="text-base font-normal text-clay-350">Total Supply</div>
            <div className="flex items-center gap-2 text-base font-medium">
              {(dai.token?.totalSupply && Number(dai.token?.totalSupply)?.toLocaleString()) || '--'}
            </div>
          </div>{' '}
          <div className="flex flex-col gap-1.5 border-b border-clay-20 px-7 py-3 ">
            <div className="flex items-center gap-1 text-base font-normal text-clay-350">
              Airdrop to Stakers{' '}
              <Tooltip
                content={
                  <div className="max-w-52">
                    Nesa does not control any DAI token rewards or airdrops and does not vet DAI claims or
                    information. Before staking and trading, always do your own research.
                  </div>
                }
                delayDuration={100}
                side="top"
              >
                <Icon className="size-4 text-clay-350" name="infoCircle" />
              </Tooltip>
            </div>
            <div className="flex items-center gap-2 text-base font-medium">
              {dai.token?.totalSupply && dai.token?.reservedForStakers
                ? `${((dai.token?.totalSupply * dai.token.reservedForStakers) / 100).toLocaleString()} (${dai.token.reservedForStakers}%)`
                : '--'}
            </div>
          </div>
        </div>
        <ConnectedModels
          connectedModelIds={connectedModelsList || []}
          modelDescriptions={dai.modelDescriptions}
        />

        <div className="grid grid-cols-1 gap-4 p-6 md:grid-cols-2 md:gap-x-12">
          <div className="flex flex-col gap-4">
            <h2 className="text-xl md:text-2xl">{dai.tagline}</h2>
            <p className="whitespace-pre-line text-base font-light text-clay-350">{dai.description}</p>
          </div>
          <div className="flex flex-col">
            <h2 className="text-2xl font-semibold md:text-3xl">{dai.projectName} Team</h2>
            {dai.isTeamDoxxed && (
              <div className="mt-3 flex w-fit items-center gap-1.5 rounded-full bg-tusk-100/80 px-2 py-1 text-xs font-medium">
                <Icon className="size-2.5" name="check" /> Doxxed
              </div>
            )}

            <div className="mt-10 grid grid-cols-2 justify-start gap-x-3 gap-y-6 md:grid-cols-3">
              {dai.members.map((member) => {
                return (
                  <TeamMember
                    avatar={member.avatar}
                    key={member.id}
                    name={member.name}
                    onClick={() => {
                      setSelectedMember(member);
                      setIsTeamMemberOpen(true);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="col-span-3 flex flex-col gap-2 border-b border-clay-20 p-6 pb-8 md:pb-12">
          <div className="text-base text-clay-350">Official Links</div>
          <div className="flex flex-wrap items-center gap-1.5 md:gap-3">
            {dai.socials.map(({ href, type }) => {
              return (
                <div
                  className="cursor-pointer rounded-full bg-clay-10 px-2.5 py-1 text-sm font-medium capitalize transition-colors hover:bg-clay-20"
                  key={href}
                  onClick={() =>
                    window.open(href.startsWith('https://') ? href : `https://${href}`, '_blank')
                  }
                >
                  {type}
                </div>
              );
            })}
          </div>
        </div>

        <InteractionLogs
          createdAt={dai.createdAt}
          daiId={dai._id}
          daiName={dai.projectName}
          tokenData={dai.token}
        />
      </div>

      {selectedMember && (
        <TeamMemberModel
          isOpen={isTeamMemberOpen}
          member={selectedMember}
          onOpenChange={setIsTeamMemberOpen}
        />
      )}
    </>
  );
};

import type { DAI } from 'shared/api/dai/types';
import type { TableColumn } from 'shared/ui/Table';

import { Icon } from 'shared/ui/Icon';

export const getColumns = (settings: Set<string>, total = 0, sortType: string) => {
  const columns: TableColumn<keyof DAI, DAI>[] = [
    {
      key: '_id',
      renderTd: (_, index) => (
        <div className="text-center font-semibold text-clay-900">
          {sortType === 'oldest' ? total - index : index + 1}
        </div>
      ),
      thClassName: 'w-[5rem]',
      title: (
        <div className="flex justify-center">
          <Icon className="size-4" name="crown" />
        </div>
      ),
    },
    {
      key: 'projectName',
      renderTd: ({ projectName }) => {
        return <div className="w-full text-sm font-normal text-clay-900">{projectName}</div>;
      },
      thClassName: 'w-full',
      title: 'DAI',
    },
    {
      key: 'projectCategory',
      renderTd: ({ projectCategory }) => projectCategory,
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Category',
    },
    {
      key: 'tvl',
      renderTd: ({ tvl }) => (tvl && Number(Number(tvl).toFixed(4)).toLocaleString('en')) || '-',
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'TVL',
    },
    {
      key: 'token',
      renderTd: ({ token }) => token?.reservedForStakers || '-',
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Starkers',
    },
    {
      key: 'cover',
      renderTd: () => '0%',
      thClassName: 'w-[12%] min-w-[9rem]',
      title: 'Airdrop',
    },
  ];

  if (!settings.size) {
    return columns;
  }

  return columns.filter((el) => settings.has(el.key) || el.key === '_id' || el.key === 'projectName');
};

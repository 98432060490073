import Countdown from 'react-countdown';

import { InfoIcon } from 'lucide-react';
import { DateTime } from 'luxon';

import { useUser } from 'app/stores/user';
import { UpgradeToProTriggerModal } from 'features/UpgradeToProModal';
import { useLimitReached } from 'shared/hooks/useLimitReached';
import { StretchedSkeleton } from 'shared/ui/StretchedSkeleton';
import { Tooltip } from 'shared/ui/Tooltip';

type Props = {
  modelId: string;
  section?: string;
};

// export const QueryLimitOverview = ({ className, isMinimized, modelId }: PropsWithChildren<Props>) => {
//   const { section } = useParams<{ id: string; section?: Section }>();
//   const { isLimitReached, isPending, limits } = useLimitReached(modelId);

//   const lg = useMinWidthMediaQuery('lg');

//   const { proVersion } = useUser();
//   const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);

//   if (isPending) {
//     return (
//       <div className="absolute inset-0 z-10 flex flex-col items-center bg-white py-3">
//         <StretchedSkeleton
//           className="relative mx-auto max-h-6 w-full max-w-44 rounded-full"
//           enable
//           rx={12}
//           ry={12}
//         />
//       </div>
//     );
//   }

//   if (proVersion?.isPro) return;

//   const isTotalLimitReached = limits && limits.totalLimitLeft <= 0;

//   if (!limits) return;

//   if (!isLimitReached) {
//     return (
//       <motion.div
//         animate={{ opacity: 1 }}
//         className={twMerge(
//           'flex items-center justify-center gap-3 whitespace-nowrap rounded-full bg-clay-20 px-4 py-0.5 text-xs/none font-light text-clay-500',
//           className,
//         )}
//         exit={{ opacity: 0 }}
//         initial={{ opacity: 0 }}
//       >
//         {isMinimized || !lg ? (
//           <Tooltip content="Number of free uses remaining" side="top">
//             <div className="flex cursor-default gap-1">
//               {limits && (
//                 <span className="font-normal text-clay-800">
//                   {limits.modelLimit - limits.modelLimitUsed}/{limits.modelLimit}
//                 </span>
//               )}{' '}
//             </div>
//           </Tooltip>
//         ) : (
//           <div className="flex min-w-fit gap-1">
//             Number of free uses remaining:{' '}
//             {limits && (
//               <span className="font-normal text-clay-800">
//                 {limits.modelLimit - limits.modelLimitUsed}/{limits.modelLimit}
//               </span>
//             )}{' '}
//           </div>
//         )}
//         <Button
//           className="min-w-fit bg-transparent p-0"
//           onClick={() => setIsUpgradeOpen(true)}
//           size="extra-small"
//           variant="filled-light"
//         >
//           Get Pro
//         </Button>

//         <UpgradeToProModal
//           onOpenChange={setIsUpgradeOpen}
//           open={isUpgradeOpen}
//           redirectPath={`/models/${modelId}${section ? `/${section}` : ''}`}
//         />
//       </motion.div>
//     );
//   }

//   return (
//     <motion.div
//       animate={{ opacity: 1 }}
//       className={twMerge(
//         'mt-1 flex size-fit items-center justify-center gap-1 rounded-full bg-clay-20 px-4 py-2 text-xs/none font-light text-clay-500',
//         className,
//       )}
//       exit={{ opacity: 0 }}
//       initial={{ opacity: 0 }}
//     >
//       <span className="text-xs/none text-clay-500">
//         {isTotalLimitReached ? 'All models reset in' : 'This model resets in'}{' '}
//       </span>
//       <span className="font-semibold">
//         <Countdown date={DateTime.now().plus({ days: 1 }).startOf('day').toISO()} daysInHours />
//       </span>
//     </motion.div>
//   );
// };

export const QueryLimitOverview = ({ modelId, section }: Props) => {
  const { isLimitReached, isPending, limits } = useLimitReached(modelId);

  const { proVersion } = useUser();

  let content;
  const isTotalLimitReached = limits && limits.totalLimitLeft <= 0;

  if (proVersion?.isPro) return;

  if (isPending) {
    content = (
      <div className="relative h-3.5 w-9">
        <StretchedSkeleton className="absolute inset-0" enable rx={2} ry={2} />
      </div>
    );
  }

  if (!isLimitReached && limits) {
    content = (
      <div className="flex items-center gap-2 text-clay-900">
        <span className="text-sm font-semibold">
          {limits.modelLimit - limits.modelLimitUsed}/{limits.modelLimit}
        </span>
        <Tooltip content="Number of free uses remaining" side="top">
          <InfoIcon className="size-3.5" />
        </Tooltip>
      </div>
    );
  }
  if (isLimitReached && limits) {
    content = (
      <div className="flex items-center gap-2 text-clay-900">
        <span className="text-sm font-semibold">
          <Countdown date={DateTime.now().plus({ days: 1 }).startOf('day').toISO()} daysInHours />
        </span>
        <Tooltip content={isTotalLimitReached ? 'All models reset in' : 'This model resets in'} side="top">
          <InfoIcon className="size-3.5" />
        </Tooltip>
      </div>
    );
  }

  if (!content) return;

  return (
    <div className="flex h-10 items-center gap-3 rounded-lg bg-blue-600/10 px-3 empty:hidden">
      {content}
      <div className=" h-[1.125rem] w-px bg-[#C3C9E2]"></div>
      <UpgradeToProTriggerModal
        classNameText="text-blue-600 font-semibold"
        redirectPath={`/models/${modelId}${section ? `/${section}` : ''}`}
      />
    </div>
  );
};

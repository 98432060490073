import type { Dataset } from 'shared/api/datasets/types';
import type { TableColumn } from 'shared/ui/Table';

import { getFormattedNumber } from 'pages/Datasets/helpers/getFormattedNumber';
import { Icon } from 'shared/ui/Icon';

export const getColumns = (settings: Set<string>, total = 0, sortType: string) => {
  const columns: TableColumn<keyof Dataset, Dataset>[] = [
    {
      key: '_id',
      renderTd: (_, index) => (
        <div className="text-center font-semibold text-clay-900">
          {sortType !== 'trending' ? total - index : index + 1}
        </div>
      ),
      thClassName: 'w-[5rem]',
      title: (
        <div className="flex justify-center">
          <Icon className="size-4" name="crown" />
        </div>
      ),
    },
    {
      key: 'datasetId',
      renderTd: ({ datasetId }) => {
        let replacedTitle = datasetId?.replace(/huggingface/i, '');
        if (replacedTitle?.startsWith('/')) {
          replacedTitle = replacedTitle.slice(1);
        }
        const [title, des] = replacedTitle.split('/');
        return (
          <div className="">
            {des && title && <div className="text-xs font-semibold capitalize text-clay-300">{title}</div>}
            <div className="line-clamp-3 w-full text-sm font-normal capitalize text-clay-900">
              {(des || title).split('_').join(' ')}
            </div>
          </div>
        );
      },
      thClassName: 'w-full',
      title: 'Datasets',
    },
    {
      key: 'downloads',
      renderTd: ({ downloads }) => (downloads && getFormattedNumber(downloads)) || 0,
      thClassName: 'w-[15%] min-w-[9rem]',
      title: 'Downloads',
    },
    {
      key: 'nRows',
      renderTd: ({ nRows }) => (nRows && getFormattedNumber(nRows)) || 0,
      thClassName: 'w-[15%] min-w-[9rem]',
      title: 'Rows',
    },
    {
      key: 'likes',
      renderTd: ({ likes }) => (likes && getFormattedNumber(likes)) || 0,
      thClassName: 'w-[15%] min-w-[9rem]',
      title: 'Likes',
    },
    {
      key: 'nBytesFiles',
      renderTd: ({ nBytesFiles }) => `${nBytesFiles?.toLocaleString('en')} bytes`,
      thClassName: 'w-[15%] min-w-[9rem]',
      title: 'Size',
    },
  ];

  if (!settings.size) {
    return columns;
  }

  return columns.filter((el) => settings.has(el.key) || el.key === '_id' || el.key === 'datasetId');
};

import type { Time } from 'lightweight-charts';

import { ChartNoAxesColumn } from 'lucide-react';
import { DateTime } from 'luxon';

import type { DAI } from 'shared/api/dai/types';

import { TradeForm } from 'features/TradeForm';
import { PriceChartInterval, useGetDAIPriceChartQuery } from 'shared/api/daiStats/useGetDAIPriceChartQuery';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';
import { Spinner } from 'shared/ui/Spinner';
import { TradingBarView } from 'widgets/TradingBarView';

type Props = {
  dai: DAI;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};
const date = DateTime.now().startOf('hour').minus({ hours: 100 }).toISO();
export const TradeModal = ({ dai, isOpen, onOpenChange }: Props) => {
  const daiPriceChartQuery = useGetDAIPriceChartQuery(
    {
      daiId: dai._id,
      interval: PriceChartInterval.h1,
      startDate: date,
    },
    { enabled: !!dai._id },
  );

  return (
    <Modal onOpenChange={onOpenChange} open={isOpen}>
      <Modal.Content className="!min-h-fit !max-w-[90%] md:!min-h-[80%]" innerClassName="flex flex-col">
        <div className="mb-6 flex max-h-fit w-full flex-1 items-center justify-between">
          <div className="flex items-center gap-3 text-xl font-medium">
            <ChartNoAxesColumn className="size-5" />
            Trading view
          </div>

          <Button color="dark" onClick={() => onOpenChange(false)} size="extra-small">
            <Icon className="size-4" name="shrink" />
            Exit Tradeview
          </Button>
        </div>

        <div className="grid grow grid-cols-1 gap-6 md:grid-cols-6">
          <div className="col-span-1 md:col-span-3 xl:col-span-4">
            {' '}
            <div className="h-full max-h-[300px] min-h-[300px] md:max-h-full">
              {daiPriceChartQuery.isPending ? (
                <div className="flex items-center justify-center">
                  <Spinner className="size-4" />
                </div>
              ) : daiPriceChartQuery.data?.length ? (
                <TradingBarView
                  daiId={dai._id}
                  data={daiPriceChartQuery.data.map(({ time, ...item }) => ({
                    ...item,
                    time: DateTime.fromMillis(time).toUnixInteger() as Time,
                  }))}
                />
              ) : (
                <div className="flex h-full items-center justify-center text-sm text-clay-300">
                  No chart data available
                </div>
              )}
            </div>
            {/* <TradingView allowSymbolChange={false} hideVolume /> */}
          </div>
          <div className="col-span-1 md:col-span-3 xl:col-span-2">
            {' '}
            <TradeForm daiToken={dai?.token} />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

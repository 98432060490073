import { Modal } from 'shared/ui/Modal';

import { EVMWalletProviderContent } from './EVMWalletProviderContent';

interface Props {
  onConnected?: () => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export const WalletEvmProviderModal = ({ onConnected, onOpenChange, open }: Props) => {
  // const {} = useChains()

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full max-w-[600px]">
        <Modal.CloseButton />
        <EVMWalletProviderContent onConnected={onConnected} onOpenChange={onOpenChange} />
      </Modal.Content>
    </Modal>
  );
};

import type { BarProps } from 'recharts';

import { useCallback, useState } from 'react';

import { DateTime } from 'luxon';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import type { NodeChatPeriod } from 'shared/api/nodes/types';

import { theme } from 'app/theme';

const CustomBar = (props: BarProps) => {
  const { height, width, x = 0, y = 0 } = props;

  const [isOver, setIsOver] = useState(false);

  return (
    <>
      <Rectangle
        className="cursor-pointer transition-colors"
        fill={isOver ? theme.colors.tusk[100] : theme.colors.primary[50]}
        height={height}
        onMouseEnter={() => {
          setIsOver(true);
        }}
        onMouseLeave={() => {
          setIsOver(false);
        }}
        width={width}
        x={Number(x)}
        y={Number(y)}
      />

      <Rectangle
        className="z-20 transition-colors"
        fill={isOver ? '#90A845' : theme.colors.primary[800]}
        height={2}
        width={width}
        x={Number(x)}
        y={Number(y)}
      />
    </>
  );
};

type Props = {
  data: { count: number; date: string }[];
  period: NodeChatPeriod;
};
export const Bars = ({ data, period }: Props) => {
  const tickFormatter = useCallback(
    (value: string) => {
      if (period === 'day') {
        return DateTime.fromISO(value).toLocaleString({ timeStyle: 'short' });
      }

      if (period === 'week') {
        return DateTime.fromISO(value).weekdayLong || '';
      }

      if (period === 'month') {
        return DateTime.fromISO(value).toLocaleString({ day: 'numeric', month: 'numeric' });
      }

      if (['6month', 'year'].includes(period)) {
        return DateTime.fromISO(value).monthShort || '';
      }

      return DateTime.fromISO(value).toLocaleString();
    },
    [period],
  );
  const yTickFormatter = useCallback((value: string) => {
    const numValue = Number(value);
    if (Number.isNaN(numValue)) return value.toString();

    return numValue > 1000 ? `${(numValue / 1000).toFixed(1)}k` : `${numValue}`;
  }, []);

  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        className="bar"
        data={data}
        margin={{
          bottom: 0,
          left: -20,
          right: 0,
          top: 20,
        }}
      >
        <CartesianGrid strokeOpacity={0.3} />
        <XAxis
          axisLine={false}
          dataKey="date"
          tick={{ fill: theme.colors.clay[300], fontSize: 12, fontWeight: 300 }}
          tickFormatter={tickFormatter}
          tickLine={false}
        />
        <YAxis
          axisLine={false}
          tick={{ fill: theme.colors.clay[300], fontSize: 12, fontWeight: 300, width: 20 }}
          tickFormatter={yTickFormatter}
          tickLine={false}
          tickMargin={0}
        />
        <Bar dataKey="count" label={false} shape={CustomBar}>
          {data.map((entry, index) => (
            <Cell fill={theme.colors.primary[800]} key={`cell-${index}`} />
          ))}
        </Bar>

        <Tooltip
          content={({ active, payload }) => {
            if (!active) return null;

            return (
              <div className="flex max-w-52 flex-col rounded-lg bg-clay-800 p-3 text-white">
                <span className="text-xl">{payload?.[0]?.value}</span>

                <span className="text-[#B7C0CE]">Request served</span>
              </div>
            );
          }}
          cursor={false}
          wrapperStyle={{ outline: 'none' }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

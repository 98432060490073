import { Fragment, type ReactNode, createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BoxIcon, CoinsIcon, FileBoxIcon, TrophyIcon } from 'lucide-react';
import { twJoin, twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';

import { Icon } from 'shared/ui/Icon';
import { Popover } from 'shared/ui/Popover';
import { Select } from 'shared/ui/Select';
import { Tabs } from 'shared/ui/Tabs';

import { tableFilters } from './config';
import { default as DaiIcon } from './ui/dai.svg?react';

const leaderBoardLocalStorageSettingTableKey = 'leader-board-setting-table';

type LeaderBoardTabsProps = {
  children: ReactNode;
};

const tabs = [
  { disabled: false, icon: TrophyIcon, title: 'Toplist', value: 'top-list' },
  { disabled: false, icon: BoxIcon, title: 'Models', value: 'models' },
  { disabled: false, icon: DaiIcon, title: 'DAI', value: 'dai' },
  { disabled: false, icon: FileBoxIcon, title: 'Datasets', value: 'datasets' },
  { disabled: false, icon: CoinsIcon, title: 'Stakers', value: 'stakers' },
] as const;

const SettingTableKeysContext = createContext<Set<string>>(new Set());
export const useSettingTableKeysContext = () => useContext(SettingTableKeysContext);

const getTableSettings = (tab: string) => {
  const value = localStorage.getItem(`${leaderBoardLocalStorageSettingTableKey}-${tab}`);
  const tableSettings = (tab && tableFilters[tab]) || null;
  if (value) {
    const keys = value.split(',') as (keyof Model)[];
    if (keys.length) {
      return new Set(keys);
    }
  }
  return new Set(tableSettings?.settingList.filter((el) => el.type === 'active').map((el) => el.value) || []);
};

export function LeaderBoardTabs({ children }: LeaderBoardTabsProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get('tab') || 'top-list';
  const sort =
    searchParams.get('sort') ||
    (tab === 'models'
      ? 'likes'
      : tab === 'dai'
        ? 'newest'
        : tab === 'datasets'
          ? 'trending'
          : tab === 'stakers'
            ? 'totalCount'
            : null);
  const sortType = (searchParams.get('sortType') || 'desc') as 'asc' | 'desc';

  const selectedSort = sort ? `${sort}${(tab === 'models' && `-${sortType}`) || ''}` : undefined;

  const [isOpenTableSettings, setIsOpenTableSettings] = useState(false);
  const [selectedTableKeys, setSelectedTableKeys] = useState(() => getTableSettings(tab));

  const onSortChange = (sortKey: string, sortType: string) => {
    setSearchParams((prev) => {
      const newSearch = new URLSearchParams(prev);
      newSearch.set('sort', sortKey);
      if (sortType) {
        newSearch.set('sortType', sortType);
      }
      return newSearch;
    });
  };

  const tableSettings = (tab && tableFilters[tab]) || null;
  const onSelectedTableKeys = (keys: string[]) => {
    setSelectedTableKeys(new Set(keys));
    localStorage.setItem(`${leaderBoardLocalStorageSettingTableKey}-${tab}`, keys.join(','));
  };

  return (
    <Tabs.Root
      onValueChange={(value) => {
        setSearchParams(() => {
          const newSearch = new URLSearchParams();
          newSearch.set('tab', value);
          return newSearch;
        });
        setSelectedTableKeys(getTableSettings(value));
      }}
      tabs={
        <div className="mb-2 flex justify-between">
          <Tabs.List className="h-12 flex-row gap-1 overflow-hidden rounded-lg bg-clay-20 p-1">
            {tabs.map((tab) => (
              <Tabs.Trigger
                className="h-full justify-center rounded-lg px-3 pl-2"
                disabled={tab.disabled}
                key={tab.value}
                value={tab.value}
              >
                <div className=" flex size-5 items-center justify-center">
                  <tab.icon
                    className={twMerge('size-4 stroke-[currentColor]', tab.value === 'dai' && 'size-5')}
                  />
                </div>
                <span>{tab.title}</span>
              </Tabs.Trigger>
            ))}
          </Tabs.List>

          {tableSettings && (
            <div className="right-4 box-border flex h-12 items-center gap-1 rounded-xl bg-blue-50 p-1">
              <Select
                className="h-10 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
                iconName="arrowDownUp"
                onValueChange={(value) => {
                  const [sortKey, sortType] = value.split('-');
                  onSortChange(sortKey, sortType);
                }}
                placeholder="Sort by"
                placeholderClassName="font-medium text-clay-600"
                value={selectedSort}
              >
                <Select.Content className="z-10 rounded-lg p-1 shadow-sm">
                  <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                    Sort by
                  </div>
                  {tableSettings.sortList.map((el) => (
                    <Fragment key={el.value}>
                      <Select.Item
                        className="text-sm text-clay-700 hover:bg-blue-50"
                        indicatorClassName="size-3 text-clay-700"
                        value={el.value}
                      >
                        {el.title}
                      </Select.Item>
                      {el.withBorder && <div className="h-px w-full bg-blue-50" />}
                    </Fragment>
                  ))}
                </Select.Content>
              </Select>
              <Popover onOpenChange={setIsOpenTableSettings} open={isOpenTableSettings}>
                <Popover.Trigger
                  className={twMerge(
                    'flex h-10 w-fit items-center rounded-lg bg-clay-10 p-3 text-xs inner-border-0 hover:bg-clay-20 sm:text-sm',
                    isOpenTableSettings && 'bg-white',
                  )}
                >
                  <Icon className="mr-2 size-4 text-blue-800" name="betweenHorizontalStart" />
                  <span className="font-medium text-clay-600">Customize Table</span>
                </Popover.Trigger>
                <Popover.Content
                  align="start"
                  className="z-10 min-w-[var(--radix-popover-trigger-width)] rounded-lg p-1 shadow-sm"
                >
                  <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                    Table Columns
                  </div>
                  {tableSettings.settingList.map((el) => (
                    <div
                      className={twJoin(
                        'flex w-full cursor-pointer items-center justify-between gap-4 rounded-lg px-3 py-2 text-sm text-clay-700 transition-colors hover:bg-blue-50 focus:outline-none',
                      )}
                      key={el.value}
                      onClick={() => {
                        if (selectedTableKeys.has(el.value) && selectedTableKeys.size > 1) {
                          onSelectedTableKeys([...selectedTableKeys].filter((key) => key !== el.value));
                        }
                        if (!selectedTableKeys.has(el.value)) {
                          onSelectedTableKeys([...selectedTableKeys, el.value]);
                        }
                      }}
                    >
                      <span>{el.title}</span>
                      {selectedTableKeys.has(el.value) && (
                        <Icon className="size-3 text-clay-700" name="check" />
                      )}
                    </div>
                  ))}
                </Popover.Content>
              </Popover>
            </div>
          )}
        </div>
      }
      value={tab}
      variant="rounded-white"
    >
      <SettingTableKeysContext.Provider value={selectedTableKeys}>
        {children}
      </SettingTableKeysContext.Provider>
    </Tabs.Root>
  );
}

import { useNavigate, useSearchParams } from 'react-router-dom';

import { DateTime } from 'luxon';

import { uuidv4 } from 'shared/helpers/uuid';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';

import type { FunctionSort } from './ui/Header';

import bgSrc from './assets/bg.png';
import { CategoryCarousel } from './ui/CategoryCarousel';
import { FunctionCard } from './ui/FunctionCard';
import { Header } from './ui/Header';

const weekFMock = [
  {
    category: 'text-to-speech',
    description: 'Kokoro is a frontier TTS model for its size. It has 82 million parameters',
    icon: 'text2Speech',
    id: uuidv4(),
    publishedAt: DateTime.now().minus({ days: 4 }).toString(),
    title: 'Fn Placeholder Name',
  },
  {
    category: 'image-generation',
    description:
      'Kokoro is a frontier TTS model for its size. It has 82 million parameters. Kokoro is a frontier TTS model for its size. It has 82 million parameters',
    icon: 'imageGeneration',
    id: uuidv4(),
    publishedAt: DateTime.now().minus({ days: 3 }).toString(),
    title: 'Fn Placeholder Name',
  },
  {
    category: 'image-generation',
    description:
      'Kokoro is a frontier TTS model for its size. It has 82 million parameters.Kokoro is a frontier TTS model for its size. It has 82 million parameters',
    icon: 'imageGeneration',
    id: uuidv4(),
    publishedAt: DateTime.now().minus({ days: 34 }).toString(),
    title: 'Fn Placeholder Name',
  },
];
export const Functions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const sort = (searchParams.get('sort') as FunctionSort) || 'trending';
  const category = searchParams.get('category') || 'all';
  const searchValue = searchParams.get('search') || '';

  const onSearchParamsChange = (name: string, value?: string) => {
    setSearchParams((prev) => {
      const newSearch = new URLSearchParams(prev);
      if (value) {
        newSearch.set(name, value);
      } else {
        newSearch.delete(name);
      }
      return newSearch;
    });
  };

  return (
    <AnimateRoute className=" flex h-full flex-col overflow-hidden rounded-2xl bg-clay-20 p-2">
      {/* <AnimateRoute className="mx-4 mb-4 flex h-full flex-col overflow-hidden rounded-2xl bg-clay-20 p-2"> */}{' '}
      <div className="relative grow overflow-y-scroll rounded-2xl bg-white px-3 py-4 sm:px-6 sm:py-8">
        <div
          className="pointer-events-none absolute inset-x-0 top-0 min-h-full w-full bg-cover bg-top bg-no-repeat"
          style={{ backgroundImage: `url(${bgSrc})` }}
        ></div>
        <div className="pointer-events-none absolute inset-x-0 top-0 h-[80px] bg-gradient-to-b from-white to-transparent"></div>
        <Header className="mb-8" onSortChange={(value) => onSearchParamsChange('sort', value)} sort={sort} />
        <CategoryCarousel
          className="mb-8"
          onChange={(value) => onSearchParamsChange('category', value)}
          value={category}
        />
        <Input
          className="mb-5 max-w-2xl"
          classNameInputWrapper="bg-white rounded-xl font-display font-normal"
          onChange={(e) => onSearchParamsChange('search', e.target.value)}
          placeholder="Search for a Function"
          size="medium"
          startSlot={
            <Icon
              className="size-3.5 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-3.5 sm:text-clay-300"
              name="search"
            />
          }
          value={searchValue}
        />
        <div className="relative mb-8 flex flex-col">
          <h2 className="mb-5 text-xl/none font-semibold text-clay-1000">Functions of the week</h2>

          <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
            {weekFMock.map(({ category, description, icon, id, publishedAt, title }) => {
              return (
                <FunctionCard
                  category={category}
                  description={description}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  iconSlot={<Icon className="size-5 text-clay-350" name={icon as any} />}
                  key={id}
                  onClick={() => {
                    navigate(`/functions/${id}`);
                  }}
                  publishedAt={publishedAt}
                  title={title}
                />
              );
            })}
          </div>
        </div>
        <div className="relative mb-8 flex flex-col">
          <h2 className="mb-5 text-xl/none font-semibold text-clay-1000">All Running Functions</h2>

          <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
            <FunctionCard
              category="text-to-speech"
              description="Kokoro is a frontier TTS model for its size. It has 82 million parameters"
              iconSlot={<Icon className="size-5 text-clay-350" name="text2Speech" />}
              publishedAt={DateTime.now().minus({ days: 4 }).toString()}
              title="Fn Placeholder Name"
            />
            <FunctionCard
              category="image-generation"
              description="Kokoro is a frontier TTS model for its size. It has 82 million parameters. Kokoro is a frontier TTS model for its size. It has 82 million parameters"
              iconSlot={<Icon className="size-5 text-clay-350" name="imageGeneration" />}
              publishedAt={DateTime.now().minus({ days: 3 }).toString()}
              title="Fn Placeholder Name"
            />
            <FunctionCard
              category="text-to-speech"
              description="Kokoro is a frontier TTS model for its size. It has 82 million parameters"
              iconSlot={<Icon className="size-5 text-clay-350" name="text2Speech" />}
              publishedAt={DateTime.now().minus({ days: 0 }).toString()}
              title="Fn Placeholder Name"
            />
          </div>
        </div>
      </div>
    </AnimateRoute>
  );
};

import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { queryKeys } from './queryKeys';

type Dataset = {
  _id: string;
  datasetId: string;
  likes: number;
  timestamp: number;
};

type DatasetResponse = {
  limit: number;
  list: Dataset[];
  number: number;
  totalCount: number;
};

type Params = {
  userId: string;
};

export const useGetDatasetsByUserQuery = (params: Params, options: UseQueryOptions<DatasetResponse> = {}) => {
  const { userId } = params;
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get(`/datasets/users/${userId}/list`, {
        params: {
          limit: 1000,
          skip: 0,
        },
        signal,
        timeout: Number.POSITIVE_INFINITY,
      });

      return data;
    },
    queryKey: queryKeys.byUser({ userId }),
    ...options,
  });
};

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import { Button } from 'shared/ui/Button';

import columns from '../assets/columns.png';
import flower from '../assets/flower.png';
import layers from '../assets/layers.png';
import { WelcomeDAIModal } from './WelcomeDAIModal';

const banners = ['banner1', 'banner2', 'banner3'];

export const CreateDaiBanner = () => {
  const [bannerIndex, setBannerIndex] = useState(2);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const bannerName = banners[bannerIndex];

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerIndex((prev) => {
        if (prev >= banners.length - 1) return 0;
        return prev + 1;
      });
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="relative col-span-6 min-h-[17rem] overflow-hidden xl:col-span-4">
      <AnimatePresence>
        <motion.div
          animate={{ zIndex: bannerName === 'banner1' ? 20 : 0 }}
          className="absolute flex size-full flex-col gap-4 rounded-xl bg-[#A783F4] bg-cover bg-center bg-no-repeat p-6 md:px-8 md:py-6"
          exit={{ zIndex: 0 }}
          initial={{ zIndex: 0 }}
        >
          <div className="text-4xl/7 font-medium tracking-tighter text-white md:text-3xl/9">
            Bold AI ideas that can <br /> change the world.
          </div>
          <img
            alt="flower"
            className="absolute bottom-0 right-0 max-w-56 2xs:max-w-56 xs:max-w-80 sm:max-w-96 xl:max-w-80 2xl:max-w-[19rem]"
            src={flower}
          />
          <Button className="mt-auto w-fit" color="dark" onClick={() => setShowWelcomeModal(true)}>
            {/* <Icon className="size-5 text-white" name="customDai" />
              Create a DAI <sup className="-ml-0.5 text-[6.5px] font-light">TM</sup> */}
            How DAI<sup className="-ml-0.5 text-[6.5px] font-light">TM</sup> work
          </Button>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          animate={{ zIndex: bannerName === 'banner2' ? 20 : 0 }}
          className="absolute flex size-full flex-col rounded-xl bg-[#179E6C] bg-cover bg-center bg-no-repeat p-6 md:px-8 md:py-6"
          exit={{ zIndex: 0 }}
          initial={{ zIndex: 0 }}
        >
          <div className="text-2xl/7 font-normal tracking-tighter text-white md:text-3xl/9">
            When users stake $NES, <br />
            Nesa subsidizes that <br className="xs:xl:block" />
            DAI’s inference and
            <br /> training costs.
          </div>
          <img
            alt="layers"
            className="absolute bottom-0 right-4 max-h-40 xs:right-12 xs:max-h-52 xl:max-h-40 2xl:max-h-48"
            src={layers}
          />
          <div className="mt-auto flex flex-col">
            <div className="flex items-center gap-0.5 text-[#F5FFC7]">
              <div className="text-sm/none font-medium">
                <div>UP</div>
                <div>TO</div>
              </div>
              <div className="text-4xl font-medium">6%</div>
            </div>
            <div className="text-sm text-white">annualized of TVL</div>
          </div>
        </motion.div>
      </AnimatePresence>

      <AnimatePresence>
        <motion.div
          animate={{ zIndex: bannerName === 'banner3' ? 20 : 0 }}
          className="absolute flex size-full flex-col justify-between rounded-xl bg-[#C785DB] bg-cover bg-center bg-no-repeat p-6 md:px-8 md:py-6"
          exit={{ zIndex: 0 }}
          initial={{ zIndex: 0 }}
        >
          <div className="text-2xl/7 font-normal tracking-tighter text-white md:text-3xl/9">
            Trade DAIs to grow
            <br />
            your portfolio value.
            <br />
            The best traders
            <br />
            get rewarded.
          </div>
          <img
            alt="columns"
            className="absolute bottom-0 right-0 max-h-40 xs:max-h-52 xl:max-h-40 2xl:max-h-48"
            src={columns}
          />
          <div className="">
            <Button as={Link} color="tusk" to="/wallet/evm/assets">
              Get $USDN to Trade
            </Button>
          </div>
        </motion.div>
      </AnimatePresence>

      <WelcomeDAIModal onOpenChange={setShowWelcomeModal} open={showWelcomeModal} showFooter={false} />
    </div>
  );
};

import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { Post } from './types';

import { postsKeys } from './queryKeys';

export const usePostListQuery = (options: { enabled?: boolean } = {}) => {
  return useInfiniteQuery({
    getNextPageParam: (last: Post[] = [], all: Post[][]) => (last.length === 10 ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0 }) => {
      const res = await axiosInstance.get<Post[]>('/posts', {
        params: {
          skip: pageParam * 10,
        },
      });

      return res.data;
    },
    queryKey: postsKeys.postList(),
    ...(options || {}),
  });
};

import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { User } from '../user/types';

import { daiKeys } from './queryKeys';

export type GroupStakerRes = {
  stakes: {
    _id: string;
    account: string;
    amount: string;
    company_id: string;
    lockup_seconds: number;
    timestamp: number;
    user_id: string;
  }[];
  totalAmount: number;
  user?: User;
};

type Response = {
  list: [];
};

type Params = {
  limit: number;
  sort?: 'stakesLength' | 'totalCount';
};

export const useGroupStakerListInfinityQuery = (params: Params) => {
  return useInfiniteQuery({
    getNextPageParam: (last: Response, all: Response[]) =>
      last.list.length === params.limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await axiosInstance.get<Response>(`/dai/stakers-list-group`, {
        params: {
          limit: params.limit,
          skip: pageParam * params.limit,
          sort: params.sort,
        },
      });

      return data;
    },
    queryKey: daiKeys.groupStakerListInfinityQuery(params),
  });
};

export type RepoFile = {
  content: string;
  name: string;
};

export type RepoFolder = {
  files: RepoFile[];
  folders: RepoFolder[];
  name: string;
};

export type Repo = {
  files: RepoFile[];
  folders: RepoFolder[];
  name: string;
};

export const mockRepo: Repo = {
  files: [
    { content: `logs\n`, name: '.gitignore' },
    { content: `const message = 'Code Editor!'\nconsole.log(message);\n`, name: 'index.js' },
  ],
  folders: [
    {
      files: [
        { content: `logs\n`, name: '.gitignore' },
        { content: `const message = 'Code Editor!'\nconsole.log(message);\n`, name: 'index.js' },
      ],
      folders: [
        {
          files: [{ content: `const message = 'Code Editor!'\nconsole.log(message);\n`, name: 'app.js' }],
          folders: [
            {
              files: [{ content: `const message = 'Code Editor!'\nconsole.log(message);\n`, name: 'app.js' }],
              folders: [],
              name: 'app',
            },
          ],
          name: 'app',
        },
      ],
      name: 'src',
    },
  ],
  name: 'nesa-mainfolder',
};

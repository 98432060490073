import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Card } from 'shared/ui/Card';
import { Icon } from 'shared/ui/Icon';

type Props = {
  _id: string;
  description?: string;
  image?: string;
  index?: number;
  isPublic?: boolean;
  name?: string;
  onClick?: () => void;
  requestCount?: number;
} & ClassName;

export const ModelCard = ({
  _id,
  className,
  description,
  image,
  index: i,
  isPublic,
  name,
  onClick,
}: Props) => {
  return (
    <Card
      className={twMerge(
        '!p-6',
        !!onClick && 'cursor-pointer transition-colors hover:border-clay-100',
        className,
      )}
      onClick={onClick}
    >
      <header className="flex flex-col justify-between gap-3 border-b border-clay-20 pb-3 sm:flex-row">
        <div className="flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-3">
          <div className="border-r border-transparent pr-3 text-lg font-medium text-clay-1000 sm:border-clay-20">
            {name || `Model${i !== undefined && `#${i + 1}`}`}
          </div>

          <div className="text-sm font-normal">
            ID: <span className="font-light text-clay-500">{_id}</span>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1 rounded-full bg-white p-0 text-sm/none">
            <Icon className="size-4" name="globeDigital" />
            <span className="text-sm/none">{isPublic ? 'Public' : 'Private'}</span>
          </div>
        </div>
      </header>

      <div className="flex flex-col gap-6 pt-4 sm:flex-row">
        <div className="flex size-40 justify-center rounded-lg bg-clay-20 sm:min-w-40">
          {image && (
            <img
              alt="kernel"
              className="size-40 overflow-hidden rounded-xl sm:size-40 sm:min-w-40"
              src={image}
            />
          )}
        </div>

        <div className="flex flex-col gap-2 sm:gap-4 sm:border-b-0">
          <p className="text-base font-light text-clay-380">{description}</p>
        </div>
      </div>
    </Card>
  );
};

export const randomModels = [
  {
    id: '66ffbb2f43eb873115c9b26e',
    name: 'Llama-3.2-1B-Instruct',
  },
  {
    id: '67363dc048ec1d138d626cb1',
    name: 'FaceResUp-HD-Evo',
  },
  {
    id: '67363dc048ec1d138d626cc9',
    name: 'SharpVision-4xEnhance-Optima',
  },
  {
    id: '673e705248ec1d138de362ef',
    name: 'Realistic_Vision_V6.0_B1_noVAE_8-steps',
  },
  {
    id: '67363dc048ec1d138d626f6d',
    name: 'vision-elevate-upscale3',
  },
  {
    id: '66ffbb2f43eb873115c9b26f',
    name: 'Llama-3.2-3B-Instruct',
  },
  {
    id: '673e705248ec1d138de36358',
    name: 'ToonYou_4-steps',
  },
  {
    id: '67363dc048ec1d138d626d1c',
    name: 'ClarityBoost-VisionX-Enhancer',
  },
  {
    id: '678ed6343be2f0499f015d52',
    name: 'Llama-3.1-8B-Instruct',
  },
  {
    id: '663a05612c30d64ab6044c0a',
    name: 'Llama-3-8B-Lexi-Uncensored',
  },
  {
    id: '6743f8f858990e44e60e6b74',
    name: 'CogVideoX',
  },
  {
    id: '66ffbb2f43eb873115c9b270',
    name: 'BioMistral-7B',
  },
  {
    id: '66c03a1f797a38f9d141c5d8',
    name: 'PonyRealism-Uncensored',
  },
  {
    id: '66c013619fcb42e691353792',
    name: 'LazyMix-Uncensored',
  },
  {
    id: '66b7685cc55f370092aa7e69',
    name: 'Hyper-SD',
  },
  {
    id: '66b7685cc55f370092aa7e68',
    name: 'FLUX.1-schnell',
  },
  {
    id: '666703b975f1475b174f0ba2',
    name: 'kivotos-xl-2.0',
  },
  {
    id: '666703b975f1475b174f0ba5',
    name: 'Fluently-XL-Final',
  },
  {
    id: '66fd6111405c6715efdde2e1',
    name: 'layoutlm-document-qa',
  },
  {
    id: '666703b975f1475b174f0ba4',
    name: 'NSFW-gen-v2',
  },
  {
    id: '673e706348ec1d138de4975f',
    name: 'all-MiniLM-L6-v2',
  },
  {
    id: '666703b875f1475b174f0b9e',
    name: 'stable-diffusion-xl-base-1.0',
  },
  {
    id: '66b7685cc55f370092aa7e6a',
    name: 'sdxl-flash',
  },
  {
    id: '666c5daf1d70f14308e39a27',
    name: 'sdxl-turbo',
  },
  {
    id: '66fd6111405c6715efdde2db',
    name: 'whisper-large-v2',
  },
  {
    id: '666703b975f1475b174f0ba3',
    name: 'mobius',
  },
  {
    id: '666703b875f1475b174f0ba1',
    name: 'holodayo-xl-2.1',
  },
  {
    id: '673e706348ec1d138de497a5',
    name: 'bart-large-mnli',
  },
  {
    id: '673e706348ec1d138de4a5d0',
    name: 'bark',
  },
  {
    id: '663a05612c30d64ab6044b41',
    name: 'bart-large-cnn',
  },
  {
    id: '673293fc48ec1d138da53406',
    name: 'roberta-base-squad2',
  },
  {
    id: '673e702748ec1d138de06b99',
    name: 'seamless-m4t-v2-large',
  },
  {
    id: '663a05612c30d64ab6044be5',
    name: 'detr-resnet-50',
  },
  {
    id: '663a05612c30d64ab60448a2',
    name: 'distilbert-base-uncased-finetuned-sst-2-english',
  },
  {
    id: '663a05612c30d64ab60449c0',
    name: 'twitter-roberta-base-sentiment-latest',
  },
  {
    id: '663a05612c30d64ab6044621',
    name: 'bert-base-NER',
  },
  {
    id: '663a05612c30d64ab6044871',
    name: 'opus-mt-zh-en',
  },
  {
    id: '663a05612c30d64ab6044a29',
    name: 'roberta-base-go_emotions',
  },
  {
    id: '673e705d48ec1d138de43d80',
    name: 'nsfw_image_detection',
  },
  {
    id: '673e705d48ec1d138de43dcf',
    name: 'resnet-50',
  },
];

randomModels.sort(() => Math.random() - 0.5);

import { ArrowUpRight, Box, Power, Sparkle } from 'lucide-react';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import type { DAI } from 'shared/api/dai/types';

import { useGetDAIConnectedWalletsQuery } from 'shared/api/dai/useGetDAIConnectedWalletsQuery';
import { useTransactionsWalletActivityQuery } from 'shared/api/transactions';
import { timeAgo } from 'shared/helpers/timeAgo';

type Props = {
  createdAt?: string;
  daiId: string;
  daiName: string;
  tokenData: DAI['token'];
};

const limit = 8;
export const InteractionLogs = ({ createdAt, daiId, daiName, tokenData }: Props) => {
  const daiWalletsQuery = useGetDAIConnectedWalletsQuery({ daiId: daiId }, { enabled: !!daiId });

  const walletAddresses = daiWalletsQuery.data?.list.map((wallet) => wallet.walletAddress) || [];

  const { data } = useTransactionsWalletActivityQuery(
    { limit, skip: 0, walletAddresses },
    { enabled: walletAddresses.length > 0 },
  );

  const txCount = (data?.list || []).length;

  const hasMore = txCount === limit;

  return (
    <div className="flex max-w-full flex-col gap-4 overflow-hidden px-6 pt-8 lg:pr-8">
      <div className="text-xl font-medium md:text-2xl">Interaction Logs</div>

      <div className="flex flex-col gap-2 overflow-scroll">
        {data?.list.map((item) => {
          return (
            <div
              className={twMerge(
                'grid min-w-[600px] grid-cols-5 rounded-lg bg-clay-10 p-2 pr-4 transition md:h-12 md:grid-cols-5',
                !!item.txhash && 'cursor-pointer hover:bg-clay-20',
              )}
              key={item.txhash}
              onClick={() => {
                window.open(`https://explorer.nesa.ai/nesa/transactions/${item.txhash}`);
              }}
            >
              <div className="col-span-4 flex items-center justify-start truncate font-light text-clay-350">
                <div className="mr-3 flex size-8 min-w-8 items-center justify-center rounded-lg bg-clay-30/70">
                  {' '}
                  <Box className="size-4 text-clay-300" />
                </div>
                Interacted with{' '}
                <span className="ml-2 mr-4 text-clay-600">
                  {item?.tx?.body?.messages?.[0]?.model_name || ''}
                </span>
                <ArrowUpRight
                  className="size-4 cursor-pointer transition hover:text-primary-800"
                  onClick={() => {
                    window.open(`https://explorer.nesa.ai/nesa/transactions/${item.txhash}`);
                  }}
                />
                {/* {item.} */}
              </div>

              <div className="flex items-center justify-end text-end text-sm font-light text-clay-350">
                {timeAgo(DateTime.fromISO(item.tx_response.timestamp))}
                {/* 41 minutes ago */}
              </div>
            </div>
          );
        })}

        {!hasMore && !!tokenData && !!tokenData?.creation?.date && !!tokenData?.creation?.hash && (
          <div
            className="grid min-w-[600px] cursor-pointer grid-cols-5 rounded-lg bg-clay-10 p-2 pr-4 transition hover:bg-clay-20 md:h-12 md:grid-cols-5"
            onClick={() => {
              window.open(`https://explorer-evm.test.nesa.ai/tx/${tokenData?.creation?.hash}`);
            }}
          >
            <div className="col-span-4 flex items-center justify-start truncate font-light text-clay-350">
              <div className="mr-3 flex size-8 min-w-8 items-center justify-center rounded-lg bg-clay-30/70">
                {' '}
                <Power className="size-4 text-clay-300" />
              </div>
              Creation of{' '}
              <span className="mx-1.5 font-medium uppercase text-clay-800">${tokenData?.ticker}</span> token
              (EVM)
              <span className="ml-2 mr-4 text-clay-600"></span>
            </div>

            {tokenData?.creation?.date && (
              <div className="flex items-center justify-end text-end text-sm font-light text-clay-350">
                {timeAgo(DateTime.fromISO(tokenData.creation?.date))}
              </div>
            )}
          </div>
        )}
        {!hasMore && (
          <div
            className="grid min-w-[600px] grid-cols-5 rounded-lg bg-clay-10 p-2 pr-4 md:h-12 md:grid-cols-5"
            // key={item.txhash}
          >
            <div className="col-span-4 flex items-center justify-start truncate font-light text-clay-350">
              <div className="mr-3 flex size-8 min-w-8 items-center justify-center rounded-lg bg-clay-30/70">
                {' '}
                <Sparkle className="size-4 text-clay-300" />
              </div>
              Creation of <span className="mx-1.5 font-medium text-clay-800">{daiName}</span> DAI
              <span className="ml-2 mr-4 text-clay-600">
                {/* {item?.tx?.body?.messages?.[0]?.model_name || ''} */}
              </span>
              {/* <ArrowUpRight
                className="size-4 cursor-pointer transition hover:text-primary-800"
                onClick={() => {
                  window.open(`https://explorer.nesa.ai/nesa/transactions/${item.txhash}`);
                }}
              /> */}
              {/* {item.} */}
            </div>

            {createdAt && (
              <div className="flex items-center justify-end text-end text-sm font-light text-clay-350">
                {timeAgo(DateTime.fromISO(createdAt))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

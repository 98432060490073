import type { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  content: ReactNode;
  title: ReactNode;
  truncateContent?: boolean;
};
export const Row = ({ content, title, truncateContent }: Props) => {
  return (
    <div className="grid grid-cols-3 items-center md:grid-cols-[164px_1fr]">
      <div className="col-span-1 mr-3 flex h-10 items-center justify-end border-r border-clay-380 pr-3 text-sm font-medium text-clay-300 sm:mr-6 sm:pr-6 sm:text-base md:col-auto lg:h-12 lg:text-lg">
        {title}
      </div>

      <div
        className={twMerge(
          'md: col-span-2 text-xl font-medium text-white md:col-auto lg:text-2xl',
          truncateContent && 'truncate',
        )}
      >
        {content}
      </div>
    </div>
  );
};

import { Icon } from 'shared/ui/Icon';

import type { RepoFile as RepoFileType } from '../../mock';

type Props = {
  file: RepoFileType;
  onFileChange: (path: string) => void;
  path: string;
  selectedFilePath?: string;
};

export const RepoFile = ({ file, onFileChange, path, selectedFilePath }: Props) => {
  const filePath = path ? `${path}/${file.name}` : file.name;
  return (
    <div
      aria-selected={selectedFilePath === filePath}
      className="flex cursor-pointer items-center rounded px-1 py-2 duration-200 aria-selected:bg-clay-10 hover:bg-clay-10"
      onClick={() => onFileChange(filePath)}
    >
      <Icon className="size-4" name="file" safeArea="0" />
      <div className="ml-2 line-clamp-1 flex-1 text-sm font-medium text-clay-500">{file.name}</div>
    </div>
  );
};

import type { FormEvent } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';

import { useUser } from 'app/stores/user';
import { Button } from 'shared/ui/Button';
import { Card } from 'shared/ui/Card';
import { Input } from 'shared/ui/Input';
import { toaster } from 'shared/ui/Toast';

import { SettingLine } from './SettingLine';

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const Security = () => {
  const [showPasswordForm, setPasswordForm] = useState(false);

  const { isUpdating, updateUser } = useUser();

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<FormValues>({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onChange',
  });

  const onSubmitPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { password } = getValues();
      await updateUser({ password }, { sendRequest: true });
      setPasswordForm(false);
    } catch (error) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <Card className="flex flex-col xs:px-6 xs:pb-2">
      <SettingLine
        buttonText={showPasswordForm ? '' : 'Edit'}
        className="border-b border-clay-20"
        middleSlot={
          showPasswordForm ? (
            <form className="flex w-full flex-col" onSubmit={onSubmitPassword}>
              <Input
                disabled={isUpdating}
                errorSpacing
                placeholder="New Password"
                type="password"
                {...register('password', {
                  validate: (val) => {
                    return val.length < 6 ? 'Password should have at least 6 characters' : true;
                  },
                })}
                error={errors.password?.message}
              />
              <Input
                disabled={isUpdating}
                errorSpacing
                placeholder="New Password Confirmation"
                type="password"
                {...register('passwordConfirmation', {
                  validate: (value, formValues) =>
                    formValues.password !== value ? 'Passwords do not match' : value.length >= 6,
                })}
                error={errors.passwordConfirmation?.message}
              />
              <div>
                <Button disabled={!isValid} isLoading={isUpdating} type="submit">
                  Reset password
                </Button>
              </div>
            </form>
          ) : (
            <span className="text-sm font-light text-clay-500">****************</span>
          )
        }
        onButtonClick={() => setPasswordForm(true)}
        title="Password"
      ></SettingLine>

      <SettingLine
        buttonText="Edit"
        middleSlot={<span className="text-sm font-light text-clay-500">Disabled</span>}
        onButtonClick={() => toaster.info('Coming soon')}
        title="Enable 2FA"
      ></SettingLine>
    </Card>
  );
};

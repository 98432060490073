import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { Badge } from 'shared/ui/Badge';
import { Icon } from 'shared/ui/Icon';

import { FunctionPopover } from './FunctionPopover';
import { Rating } from './Rating';

type Props = ClassName;
export const Header = ({ className }: Props) => {
  return (
    <div className={twMerge('flex justify-between border-b border-clay-20 p-4', className)}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-4">
          <div className="flex size-12 items-center justify-center rounded-lg bg-clay-10">
            <Icon className="size-6 text-clay-350" name="text2Speech" />
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-6">
              <h1 className="text-2xl font-semibold">manimator</h1>

              <Rating />
            </div>
            <div className="flex items-center gap-1.5">
              <Badge className="rounded-md bg-clay-10 px-2 py-1.5 text-xs/none font-medium uppercase">
                tag
              </Badge>
              <Badge className="rounded-md bg-clay-10 px-2 py-1.5 text-xs/none font-medium uppercase">
                tag 2
              </Badge>
            </div>
          </div>
        </div>
        <div className="text-sm font-light text-clay-500">
          This tool helps you create visualizations of complex concepts using natural language or PDF papers:
          Text Prompt: Describe the concept you want to visualize
        </div>
      </div>
      <div>
        <FunctionPopover />
      </div>
    </div>
  );
};

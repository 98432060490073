import type { Descendant } from 'slate';
import type { ReactEditor } from 'slate-react';

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { HuggingfacePost } from 'shared/api/huggingface';

import { queryClient } from 'app/queryClient';
import { huggingfaceKeys } from 'shared/api/huggingface';
import {
  type Post,
  useEditCommentPostMutation,
  useEditHuggingfaceCommentPostMutation,
} from 'shared/api/posts';
import { postsKeys } from 'shared/api/posts/queryKeys';
import { CommentEditor } from 'shared/ui/TextEditor';

type Props = {
  commentId: string;
  initialValue: Descendant[];
  onComplete?: () => void;
  postId: string;
  type: 'huggingface' | 'nesa';
};

export const EditComment = ({ commentId, initialValue, onComplete, postId, type }: Props) => {
  const [searchParams] = useSearchParams();

  const sort = searchParams.get('sort') || 'trending';

  const [content, setContent] = useState<Descendant[]>(initialValue);
  const [, setFiles] = useState<File[]>([]);

  const { isPending: isLoadingEditComment, mutateAsync: editComment } = useEditCommentPostMutation();
  const { isPending: isLoadingEditHuggingfaceComment, mutateAsync: editHuggingfaceComment } =
    useEditHuggingfaceCommentPostMutation();

  const isLoading = isLoadingEditComment || isLoadingEditHuggingfaceComment;

  const handleSend = async (editor: ReactEditor) => {
    if (type === 'nesa') {
      const newComment = await editComment({
        content: JSON.stringify(content),
        id: commentId,
      });
      editor.children = [{ children: [{ text: '' }], type: 'paragraph' }];
      setContent([{ children: [{ text: '' }], type: 'paragraph' }]);

      console.log(newComment.content);

      const queryKey = postsKeys.postList();
      const data: { pages: Post[][] } | undefined = queryClient.getQueryData(queryKey);
      if (data) {
        queryClient.setQueryData(queryKey, {
          ...data,
          pages: data.pages.map((page) =>
            page.map((post) =>
              post._id === postId
                ? {
                    ...post,
                    comments: post.comments.map((comment) =>
                      comment._id === newComment._id ? newComment : comment,
                    ),
                  }
                : post,
            ),
          ),
        });
      }
      onComplete?.();
      return;
    }

    const newComment = await editHuggingfaceComment({
      content: JSON.stringify(content),
      id: commentId,
    });
    editor.children = [{ children: [{ text: '' }], type: 'paragraph' }];
    setContent([{ children: [{ text: '' }], type: 'paragraph' }]);

    const queryKey = huggingfaceKeys.postList({ sort });
    const data: { pages: HuggingfacePost[][] } | undefined = queryClient.getQueryData(queryKey);
    if (data) {
      queryClient.setQueryData(queryKey, {
        ...data,
        pages: data.pages.map((page) =>
          page.map((post) =>
            post.slug === postId
              ? {
                  ...post,
                  comments: post.comments.map((comment) =>
                    comment._id === newComment._id ? newComment : comment,
                  ),
                }
              : post,
          ),
        ),
      });
    }
    onComplete?.();
  };

  return (
    <CommentEditor
      isLoading={isLoading}
      onChange={setContent}
      onFileChange={(files) => setFiles((prev) => [...prev, ...files])}
      onSend={handleSend}
      value={content}
    />
  );
};

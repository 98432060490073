import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type {
  HuggingfacePostComment,
  HuggingfacePostReaction,
  HuggingfacePostRequest,
  HuggingfacePostResponse,
} from './types';

import { huggingfaceKeys } from './queryKeys';

export const useHuggingfacePostsQuery = (
  { sort }: HuggingfacePostRequest = {},
  options: { enabled?: boolean } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (
      last: HuggingfacePostResponse['socialPosts'] = [],
      all: HuggingfacePostResponse['socialPosts'][],
    ) => (last.length === 10 ? all.length : null),
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0 }) => {
      const searchParams = new URLSearchParams();
      searchParams.set('skip', `${pageParam * 10}`);
      searchParams.set('sort', sort || 'trending');
      const res = await axiosInstance.post<HuggingfacePostResponse>('/huggingface/proxy', {
        params: `?${searchParams.toString()}`,
        path: '/posts',
      });

      const slugs = res.data.socialPosts.map((el) => el.slug);

      if (slugs.length) {
        const comments = await axiosInstance.get<HuggingfacePostComment[]>(
          '/posts/get-huggingface-comments',
          {
            params: {
              slugs,
            },
          },
        );

        const mapComments = comments.data.reduce<Map<string, HuggingfacePostComment[]>>((acc, comment) => {
          const byPost = acc.get(comment.slug);
          if (byPost) {
            byPost.push(comment);
          } else {
            acc.set(comment.slug, [comment]);
          }

          return acc;
        }, new Map());

        res.data.socialPosts.forEach((post) => {
          const comments = mapComments.get(post.slug);
          post.comments = comments || [];
        });

        const reactions = await axiosInstance.get<HuggingfacePostReaction[]>(
          '/posts/get-huggingface-reactions',
          {
            params: {
              slugs,
            },
          },
        );
        const mapReactions = reactions.data.reduce<Map<string, HuggingfacePostReaction[]>>(
          (acc, reaction) => {
            const byPost = acc.get(reaction.slug);
            if (byPost) {
              byPost.push(reaction);
            } else {
              acc.set(reaction.slug, [reaction]);
            }

            return acc;
          },
          new Map(),
        );
        res.data.socialPosts.forEach((post) => {
          const reactions = mapReactions.get(post.slug);
          post.reactions = reactions || [];
        });
      }

      return res.data.socialPosts;
    },
    queryKey: huggingfaceKeys.postList({ sort }),
    ...(options || {}),
  });
};

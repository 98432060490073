import { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';

import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { BackButton } from 'app/ui/BackButton';
import { ReportModal } from 'features/ReportModal';
import { getFormattedNumber } from 'pages/Datasets/helpers/getFormattedNumber';
import { useDatasetUserReactionsQuery } from 'shared/api/datasets/useDatasetUserReactionsQuery';
import { useGetDatasetRowsQuery } from 'shared/api/datasets/useGetDatasetRowsQuery';
import { useGetDatasetSplitsQuery } from 'shared/api/datasets/useGetDatasetSplitsQuery';
import { useGetDatasetsQuery } from 'shared/api/datasets/useGetDatasetsQuery';
import { useLikeDatasetMutation } from 'shared/api/datasets/useLikeDatasetMutation';
import { useRemoveLikeDatasetMutation } from 'shared/api/datasets/useRemoveLikeDatasetMutation';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Spinner } from 'shared/ui/Spinner';
import { Tabs } from 'shared/ui/Tabs';
import { toaster } from 'shared/ui/Toast';

import { getConfigsFromSplits } from './helpers/getConfigsFromSplits';
import { getErrorMessage } from './helpers/getErrorMessage';
import { DatasetModal } from './ui/DatasetModal';
import { DatasetViewer } from './ui/DatasetViewer';
import { FileExplorer } from './ui/FileExplorer';
import { MorePopover } from './ui/MorePopover';
import { Tags } from './ui/Tags';
import { UseDatasetPopover } from './ui/UseDatasetPopover';

type Section = 'dataset' | 'files';

export const Page = () => {
  const params = useParams<{ id: string; section?: Section }>();
  const navigate = useNavigate();
  const { user } = useUser();

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<Section>(params.section || 'dataset');
  const [isFullscreen, setIsFullScreen] = useState(false);
  const [datasetSearch, setDatasetSearch] = useState('');
  const datasetName = params?.id?.replace('---', '/');

  const { data, isPending } = useGetDatasetsQuery(
    { id: params?.id?.replace('---', '/') || '', sort: 'trending' },
    { enabled: !!params?.id },
  );

  const { data: splitData, isPending: isSplitsPending } = useGetDatasetSplitsQuery(
    { dataset: params?.id?.replace('---', '/') || '' },
    { enabled: !!params.id, retry: 0 },
  );

  const { data: reaction } = useDatasetUserReactionsQuery(
    { datasetId: datasetName || '', userId: user?._id || '' },
    { enabled: !!user?._id && !!datasetName },
  );

  const dataset = data?.datasets && data?.datasets?.length > 0 ? data.datasets[0] : undefined;
  const datasetTitle = dataset?.datasetId?.split('/')?.[1] || '';

  const { mutateAsync: likeDataset } = useLikeDatasetMutation();
  const { mutateAsync: removeLike } = useRemoveLikeDatasetMutation();

  const configs = useMemo(
    () => splitData && getConfigsFromSplits(splitData?.splits)?.slice(0, 3),
    [splitData],
  );

  const { error: rowsError } = useGetDatasetRowsQuery(
    {
      config: splitData?.splits?.[0].config ?? 'default',
      dataset: datasetName || '',
      length: 0,
      offset: 0,
      split: splitData?.splits?.[0].split || '',
    },
    { enabled: !!datasetName && !!splitData?.splits?.[0], retry: 0 },
  );

  const isLicenseLink = !!dataset?.license && /^https:\/\//.test(dataset?.license);

  const { empty, error } = getErrorMessage(rowsError);

  const hideButton = !empty && !!error;

  if (!dataset && !isPending) {
    return (
      <AnimateRoute className="flex !max-w-full flex-col pt-4" container>
        <div className="mb-6">
          <BackButton
            className="gap-2 text-base font-normal text-clay-350 hover:text-clay-500"
            onClick={() => navigate(-1)}
          >
            Back to Datasets
          </BackButton>
        </div>

        <div className="flex flex-col items-center justify-center gap-4 py-12">
          <h1 className="text-center text-base font-light text-clay-400">Dataset not found</h1>
          <Button onClick={() => navigate('/datasets')}>Go Back to Datasets</Button>
        </div>
      </AnimateRoute>
    );
  }
  return (
    <AnimateRoute className="flex !max-w-full flex-col pt-4" container>
      <div className="mb-6">
        <BackButton
          className="gap-2 text-base font-normal text-clay-350 hover:text-clay-500"
          onClick={() => navigate(-1)}
        >
          Back to Datasets
        </BackButton>
      </div>

      <header className="mb-4 flex flex-col justify-between gap-5 lg:flex-row">
        <div className="flex min-w-fit flex-col gap-2">
          <div className="flex flex-col items-start gap-0.5 text-lg sm:flex-row sm:items-center sm:gap-2 sm:text-2xl">
            <span>Dataset: </span>
            {isPending ? (
              <Spinner className="size-4" />
            ) : (
              <div className="flex min-w-fit items-center gap-4 ">
                <span className="min-w-fit truncate font-semibold">{datasetTitle}</span>
                <span
                  className="group flex cursor-pointer select-none items-center gap-1"
                  onClick={async () => {
                    const datasetId = dataset?.datasetId;
                    if (!datasetId) return;

                    try {
                      if (!reaction?.data) {
                        await likeDataset({ datasetId: datasetId || '' });

                        return;
                      }

                      removeLike({ datasetId: datasetId || '' });
                    } catch (e) {
                      toaster.error('Something went wrong');
                    }
                  }}
                >
                  <Icon
                    className={twMerge(
                      'size-4 stroke-clay-350 text-transparent group-hover:stroke-primary-800',
                      reaction?.data?.type === 'like' && 'stroke-primary-800 text-primary-800',
                    )}
                    name="heart"
                  />
                  <span className="text-sm/none text-clay-600">
                    {dataset && getFormattedNumber(dataset?.likes || 0)}
                  </span>
                </span>
              </div>
            )}
          </div>

          <div className="min-w-fit text-sm font-medium text-clay-400">
            {(dataset?.creatorName || '').replace(/huggingface/i, '')}
          </div>
          {/* <div className="min-w-fit text-sm font-medium text-clay-400">{dataset?.author}</div> */}
        </div>
        <div className="flex flex-wrap items-center justify-start gap-3 md:justify-end">
          <Tags tags={dataset?.keywords?.slice(0, 24) || []} title="Tags" />
          {/* <Tags tags={dataset?.datasetsServerInfo.formats || []} title="Formats" />
          <Tags tags={dataset?.datasetsServerInfo.libraries || []} title="Libraries" /> */}
          {/* <Tags tags={dataset?.datasetsServerInfo['languages'] || []} title="Languages" /> */}
        </div>
      </header>

      <div className="flex flex-1 flex-col gap-2 rounded-xl bg-clay-20 p-2 pb-20 md:pb-2">
        <div className="flex w-full flex-col items-center justify-between gap-2 md:flex-row">
          <Tabs.Root
            className="w-full"
            onValueChange={(value) => {
              setActiveSection(value);
              navigate(`/datasets/${params?.id}/${value}`, { replace: true });
            }}
            tabs={
              <Tabs.List className="h-fit flex-col md:flex-row">
                <Tabs.Trigger className="whitespace-nowrap" value="dataset">
                  <Icon className="size-4 stroke-clay-900 text-transparent" name="fileBox" />
                  Dataset
                </Tabs.Trigger>
                <Tabs.Trigger value="files">
                  <Icon className="size-4 stroke-clay-900 text-transparent" name="folderOpen" />
                  Files and versions
                </Tabs.Trigger>
              </Tabs.List>
            }
            value={activeSection}
            variant="button"
          />

          <div className="flex w-full min-w-fit gap-2.5 md:w-fit">
            {datasetName && !hideButton && <UseDatasetPopover configs={configs} datasetId={datasetName} />}

            <MorePopover
              onActionClick={(action) => {
                if (action === 'report') {
                  setIsReportModalOpen(true);
                }
              }}
            />
          </div>
        </div>

        {activeSection === 'dataset' && (
          <div className="relative grid flex-1 grid-cols-1 grid-rows-1 gap-3 md:grid-cols-4">
            <div className="row-end-2 rounded-lg bg-white p-3 md:col-span-3 md:row-end-auto">
              {isSplitsPending && (
                <div className="flex w-full flex-col items-center justify-center py-12">
                  <Spinner className="size-5" />
                </div>
              )}

              {splitData?.splits && datasetName && (
                <div className="relative flex flex-col overflow-hidden rounded-lg border border-clay-20 bg-white pb-4 pt-2 shadow-sm">
                  <DatasetViewer
                    className="max-h-[500px] px-4 "
                    dataset={datasetName}
                    onSearchChange={setDatasetSearch}
                    search={datasetSearch}
                    splits={splitData?.splits}
                  />

                  <Button
                    className="absolute bottom-2 right-2 rounded-lg bg-clay-20 text-xs"
                    color="secondary"
                    onClick={() => setIsFullScreen(true)}
                    size="extra-small"
                    variant="filled-light"
                  >
                    <Icon className="size-4" name="expand" />
                    Fullscreen
                  </Button>
                </div>
              )}

              <div className={twMerge('flex flex-col gap-2 px-2 py-6')}>
                <h2 className="text-base font-medium">Description</h2>

                {dataset?.description ? (
                  <ReactMarkdown className="whitespace-pre-wrap  text-clay-600">
                    {dataset?.description}
                  </ReactMarkdown>
                ) : (
                  <div className="flex select-none items-center justify-center py-10 text-center text-sm font-light text-clay-500">
                    No description available
                  </div>
                )}
              </div>

              {dataset?.license && (
                <div className="flex flex-col gap-2 px-2 py-6">
                  <h2 className="text-base font-medium">License</h2>

                  <div
                    className={twMerge(
                      'whitespace-pre-wrap break-all text-clay-600',
                      isLicenseLink && 'cursor-pointer hover:text-primary-800',
                    )}
                    onClick={() => isLicenseLink && window.open(dataset.license, '_blank')}
                  >
                    {dataset?.license}
                  </div>
                </div>
              )}
            </div>
            <div className="relative col-span-1 row-end-1 flex flex-col rounded-lg bg-white px-6 py-5  md:row-end-auto">
              <div className="sticky right-0 top-4 flex flex-col">
                {data?.datasets[0]?.downloads !== undefined && (
                  <div className="flex flex-col gap-2 border-b border-clay-20 pb-3.5">
                    <div className="text-sm font-normal text-clay-350">Downloads</div>

                    <div>{data?.datasets[0].downloads?.toLocaleString('en')}</div>
                  </div>
                )}
                {data?.datasets[0]?.nRows !== undefined && (
                  <div className="flex flex-col gap-2 border-b border-clay-20 py-3.5">
                    <div className="text-sm font-normal text-clay-350">Number of Rows</div>

                    <div>{data?.datasets[0]?.nRows?.toLocaleString('en')}</div>
                  </div>
                )}
                {data?.datasets[0]?.timestamp && (
                  <div className="flex flex-col gap-2 border-b border-clay-20 py-3.5">
                    <div className="text-sm font-normal text-clay-350">Last Update</div>

                    <div>
                      {DateTime.fromMillis(data?.datasets[0]?.timestamp || 0).toLocaleString(
                        DateTime.DATE_SHORT,
                      )}
                    </div>
                  </div>
                )}
                {typeof data?.datasets[0]?.nBytesFiles === 'number' && (
                  <div className="flex flex-col gap-2 py-3.5">
                    <div className="text-sm font-normal text-clay-350">Size</div>

                    <div>{data?.datasets[0]?.nBytesFiles?.toLocaleString('en')} bytes</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeSection === 'files' && (
          <div className="flex flex-1 flex-col rounded-xl bg-white">
            {dataset && <FileExplorer dataset={dataset} />}
          </div>
        )}
      </div>

      {datasetName && splitData?.splits && (
        <DatasetModal
          datasetName={datasetName}
          isOpen={isFullscreen}
          onOpenChange={setIsFullScreen}
          onSearchChange={setDatasetSearch}
          search={datasetSearch}
          splits={splitData?.splits}
        />
      )}

      {isReportModalOpen && (
        <ReportModal
          datasetId={dataset?._id ?? ''}
          isOpen={isReportModalOpen}
          onOpenChange={setIsReportModalOpen}
          title={datasetTitle}
        />
      )}
    </AnimateRoute>
  );
};

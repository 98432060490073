import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { twMerge } from 'tailwind-merge';

import { Card } from 'shared/ui/Card';
import { Spinner } from 'shared/ui/Spinner';
import { TruncateMiddle } from 'shared/ui/TruncateMiddle';

export type TableItem = {
  avatar?: string;
  id?: string;
  link?: string;
  onClick?: () => void;
  rightAcc?: ReactNode;
  rightValue: ReactNode;
  subtitle?: string;
  title: string;
  titleAcc?: ReactNode;
};

type Props = {
  fetchNextPage: VoidFunction;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isPending: boolean;
  items: TableItem[];
  label?: string;
};

export const Table = ({ fetchNextPage, hasNextPage, isFetchingNextPage, isPending, items, label }: Props) => {
  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  return (
    <Card className="h-fit !p-4">
      {label && (
        <div className="mb-3 border-b border-clay-20 pb-2 text-sm font-light text-clay-500">{label}</div>
      )}

      {isPending ? (
        <div className="flex items-center justify-center py-20">
          <Spinner className="size-6" />
        </div>
      ) : items.length === 0 ? (
        <div className="flex flex-col items-center gap-4 py-10">
          <div className="text-base font-light text-clay-400">There were no transactions made yet.</div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 pb-4">
          {items.map(
            ({ avatar, id, link, onClick, rightAcc, rightValue, subtitle, title, titleAcc }, idx) => {
              const content = (
                <>
                  <div className="flex items-center gap-4">
                    <div className="flex size-11 items-center justify-center overflow-hidden rounded-full bg-tusk-100">
                      {avatar && <img alt="avatar" className="size-full" src={avatar} />}
                    </div>

                    <div className="flex max-w-44 flex-col gap-1">
                      <div className="text-base/none">
                        <TruncateMiddle approximateCharWidth={6} largeString={title} />
                      </div>
                      <div className="text-xs/none font-light text-clay-500">{subtitle || 'Unknown'}</div>
                    </div>

                    {titleAcc}
                  </div>
                  {typeof rightValue === 'string' ? (
                    <div className="flex items-center gap-2">
                      <div className="text-base">{rightValue}</div>
                      {rightAcc}
                    </div>
                  ) : (
                    rightValue
                  )}
                </>
              );

              return link ? (
                <Link className="flex cursor-pointer items-center justify-between" target="_blank" to={link}>
                  {content}
                </Link>
              ) : (
                <div
                  className={twMerge('flex items-center justify-between', onClick && 'cursor-pointer')}
                  key={id || `${idx}-${title}`}
                  onClick={onClick}
                >
                  {content}
                </div>
              );
            },
          )}
        </div>
      )}
      <div className="-mt-4" ref={ref} />

      {isFetchingNextPage && (
        <div className="flex items-center justify-center py-4">
          <Spinner className="size-5" />
        </div>
      )}
    </Card>
  );
};
